import React, { useEffect } from "react";
import SocketHandlerReader from "../../../utils/sockets/handleSocketsReader";
import { State } from "../../../state/interfaces";

function useFetchAgreementUrl(
  state: State,
  account: string | null | undefined,
  contractId: number,
  setAgreementUrl: React.Dispatch<React.SetStateAction<string>>
) {
  useEffect(() => {
    if (!(state.socketHandlerReader instanceof SocketHandlerReader)) {
      state.socketHandlerReader = new SocketHandlerReader();
    }

    state.socketHandlerReader?.fetchAgreementUrl(
      account,
      contractId,
      setAgreementUrl
    );
  }, []);
}

export default useFetchAgreementUrl;
