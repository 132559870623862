// ----------------------
// Internal/External Packages
// ----------------------
import React from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

// ----------------------
// Assets
// ----------------------
import { ReactComponent as Keyboard } from "../../../assets/images/keyboard.svg";

// ----------------------
// Interfaces
// ----------------------
import { Action, ActionType } from "../../../state/interfaces";

interface KeyboardButtonProps {
  dispatch: React.Dispatch<Action>;
}

function KeyboardButton({ dispatch }: KeyboardButtonProps) {
  const { listening, resetTranscript } = useSpeechRecognition();

  const handleKeyboard = () => {
    if (listening) {
      SpeechRecognition.stopListening();
    }
    resetTranscript();
    dispatch({
      type: ActionType.SET_LISTENING,
      payload: false,
    });
  };
  return (
    <button
      onClick={handleKeyboard}
      className="w-[50px] h-[50px] md:w-[60px] md:h-[60px] flex items-center justify-center rounded-lg  border-[1px] border-[#e8e8e8] bg-[#f0f1f7]"
    >
      <div className="w-[24px]">
        <Keyboard className="w-full h-full" />
      </div>
    </button>
  );
}

export default KeyboardButton;
