import React, { useState } from "react";

interface TransactionHistoryCategoriesProps {
  active: string;
  setActive: (category: string) => void;
}

function TransactionHistoryCategories({
  active,
  setActive,
}: TransactionHistoryCategoriesProps) {
  const menuItems = ["ALL", "VAI POINTS", "PAYBACK"];

  return (
    <div className="flex w-full max-w-xl mx-auto overflow-hidden bg-white rounded-lg shadow-md">
      {menuItems.map((item) => (
        <div
          key={item}
          className={`flex-1 text-center py-3 text-sm font-medium cursor-pointer text-[18px] ${
            active === item
              ? "bg-[#0023EF] text-white"
              : "bg-[#F7F8FD] text-[#0023EF"
          } hover:bg-blue-700 hover:text-white transition-colors duration-300`}
          onClick={() => setActive(item)}
        >
          {item}
        </div>
      ))}
    </div>
  );
}

export default TransactionHistoryCategories;
