// ----------------------
// Packages
// ----------------------
import React, { useRef, useEffect, useState } from "react";

// ----------------------
// Assets
// ----------------------
import { ReactComponent as ArrowUp } from "../../assets/images/arrowUp.svg";
import { ReactComponent as ArrowDown } from "../../assets/images/arrowDown.svg";
import gbFlag from "../../assets/images/great-britain-flag.svg";

// ----------------------
// Interfaces
// ----------------------
import { Action, ActionType, State } from "../../state/interfaces";

interface LanguageChoiceProps {
  dispatch: React.Dispatch<Action>;
  state: State;
}
const topStyle = window.innerWidth >= 640 ? "120%" : "160%";

export const LanguageChoice: React.FC<LanguageChoiceProps> = ({
  dispatch,
  state,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const languageButtonRef = useRef<HTMLDivElement>(null);
  const comingSoonRef = useRef<HTMLDivElement>(null);
  const languageButtonContainerRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      languageButtonContainerRef.current &&
      !languageButtonContainerRef.current.contains(event.target as Node) &&
      (!comingSoonRef.current ||
        (comingSoonRef.current &&
          !comingSoonRef.current.contains(event.target as Node)))
    ) {
      dispatch({
        type: ActionType.SET_LANGUAGE_CHOICE_ACTIVE,
        payload: false,
      });
    }
  };

  const handleOpenLanguages = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setTimeout(() => {
      setMenuOpen(!menuOpen);

      dispatch({
        type: ActionType.SET_LANGUAGE_CHOICE_ACTIVE,
        payload: !state.languageChoiceActive,
      });
    }, 0);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="items-center gap-[20px] ml-auto flex flex-shrink-0 w-[50px] overflow-hidden justify-end sm:justify-start">
      <div onClick={handleOpenLanguages} className="relative flex">
        <div
          onClick={(event) => event.stopPropagation()}
          className="w-[26px] cursor-pointer mr-[5px]"
        >
          <img
            src={gbFlag}
            className="w-full h-full cursor-pointer"
            alt="Great Britain Flag"
          />
        </div>

        {/* 
        {!state.languageChoiceActive ? (
          <ArrowDown className="w-[16px] h-[auto] cursor-pointer" />
        ) : (
          <>
            <ArrowUp className="w-[16px] cursor-pointer h-[auto]" />
            <div
              style={{ top: topStyle }}
              className="z-[1000] flex flex-col items-center justify-center gap-[10px] absolute left-[-105%] sm:left-[-105%] bg-[#e3e3e3] rounded-md p-[10px] w-[110px]"
              ref={languageButtonContainerRef}
            >
              <div ref={languageButtonRef}>
                <p className="opacity-30 text-[12px] font-bold">COMING SOON</p>
              </div>
            </div>
          </>
        )
        } */}
      </div>
    </div>
  );
};

export default LanguageChoice;
