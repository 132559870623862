import axios from "axios";
import handleToastError from "../utility/handleToastError";

interface Withdrawal {
  address: string;
  amount: string;
  transactionHash: string;
  blockNumber: number;
  timestamp: string;
}

export const fetchPaybackWithdrawalHistory = async (
  walletAddress: string
): Promise<Withdrawal[]> => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/payback/withdrawal-history`,
      {
        walletAddress,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 403) {
        handleToastError(
          "Access denied! Please log out and log back in to solve this issue."
        );
      } else {
        console.error("Error when fetching payback withdrawal history:", error);
      }
    } else {
      console.error("An unexpected error occurred:", error);
    }
    throw error;
  }
};
