import { useEffect } from "react";
import { Action, State } from "../../state/interfaces";
import SocketHandler from "./handleSocket";

export const useChatInitiation = (
  state: State,
  dispatch: React.Dispatch<Action>
) => {
  useEffect(() => {
    const checkAndHandleUserMessage = async () => {
      if (
        state.dialogState.length === 0 &&
        state.conversationStarted &&
        state.conversationId === "" &&
        state.messages.length === 0
      ) {
        if (!(state.socketHandler instanceof SocketHandler)) {
          state.socketHandler = new SocketHandler();
        }
        state.socketHandler.setDispatch(dispatch, state);
        state.socketHandler.createConversation(state, dispatch);
      }
    };
    const timeoutId = setTimeout(() => checkAndHandleUserMessage(), 5000);
    return () => clearTimeout(timeoutId);
  }, [state, dispatch]);
};
