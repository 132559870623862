import React from "react";

interface MenuOptionParagraphProps {
  text: string;
  onClick: () => void;
  highlighted: boolean;
  fontSize: number;
  icon?: string;
}

function MenuOptionParagraph({
  text,
  onClick,
  highlighted,
  fontSize,
  icon,
}: MenuOptionParagraphProps) {
  return (
    <button
      onClick={onClick}
      // Use template literals to dynamically set the font size
      className={`py-[10px] border-b-[1px] border-[#CECDDA] w-full text-start px-[15px] font-TT text-[${fontSize}px] font-light ${
        highlighted ? "text-[#0023EF] bg-[#fff]" : ""
      } ${icon ? "flex items-center justify-start gap-[10px]" : ""}`}
    >
      {icon && <img src={icon} />}
      <p>{text}</p>
    </button>
  );
}

export default MenuOptionParagraph;
