// ----------------------
// Utility Functions
// ----------------------
import { downloadFile } from "../utils/downloadFile";

interface DownloadButtonProps {
  file: any;
  output: string;
}

function DownloadButton({ file, output }: DownloadButtonProps) {
  return (
    <button
      onClick={() => {
        downloadFile(file, output);
      }}
      className="text-[#0023EF] font-bold flex items-center justify-center w-[100px] sm:w-36 h-10 px-10 gap-2.5 border border-blue-800/60 bg-blue-800/20 shadow-button rounded-sm flex-shrink-0"
    >
      Download
    </button>
  );
}

export default DownloadButton;
