// ----------------------
// Packages
// ----------------------
import React from "react";

function AccountCreationOrLine() {
  return (
    <div className="flex items-center justify-between w-full">
      <div className="h-[1px] w-[140px] bg-[#CECDDA]"></div>
      <p className="text-[#CECDDA] text-[14px] font-semibold">OR</p>
      <div className="h-[1px] w-[140px] bg-[#CECDDA]"></div>
    </div>
  );
}

export default AccountCreationOrLine;
