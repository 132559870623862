// ----------------------
// Packages
// ----------------------
import React from "react";

// ----------------------
// Assets
// ----------------------
import { ReactComponent as Hash } from "../../../assets/images/hash.svg";
import { ReactComponent as Checkmark } from "../../../assets/images/checkmarkWhite.svg";
import { ReactComponent as CopyIcon } from "../../../assets/images/copyIconTwo.svg";

// ----------------------
// Utility Functions
// ----------------------
import { copyToClipboard } from "../../../utils/utility/copyToClipboard";

// ----------------------
// Components
// ----------------------
import DownloadButton from "./DownloadButton";

// ----------------------
// Interfaces
// ----------------------
import { Action, ActionType } from "../../../state/interfaces";

interface AddressInputProps {
  certificateContractAddress: string;
  dispatch: React.Dispatch<Action>;
}

function AddressInput({
  certificateContractAddress,
  dispatch,
}: AddressInputProps) {
  return (
    <div className="flex flex-col gap-[10px] w-full">
      <div className="text-[#fff] gap-[10px] relative">
        <div className="hidden sm:block absolute w-[30px] sm:left-[-21%] sm900:left-[-17%] lg:left-[-13%] top-[12%] lg:w-[35px]">
          <Checkmark className="w-full h-full" />
        </div>
        <p className="font-semibold text-[14px] lg:text-[16px]">
          Contract Address
        </p>
      </div>
      <div className="border-[1px] bg-[transparent] flex justify-between px-[8px] py-[10px] gap-[20px] items-center text-[#fff] border-[gray] flex-shrink-0">
        <p className="text-[12px] sm:hidden">
          {certificateContractAddress.slice(0, 25) +
            "..." +
            certificateContractAddress.slice(37, 42)}
        </p>
        <p className="text-[12px] hidden sm:block sm900:hidden">
          {certificateContractAddress.slice(0, 10) +
            "..." +
            certificateContractAddress.slice(37, 42)}
        </p>
        <p className="text-[12px] hidden sm900:block lg:hidden">
          {certificateContractAddress.slice(0, 15) +
            "..." +
            certificateContractAddress.slice(32, 42)}
        </p>
        <p className="text-[12px] hidden lg:block">
          {certificateContractAddress}
        </p>
        <div className="flex items-center justify-center gap-[10px]">
          <button
            onClick={() =>
              dispatch({
                type: ActionType.SET_HASH_WINDOW_ACTIVE,
                payload: true,
              })
            }
          >
            <Hash className="cursor-pointer" />
          </button>
          <div
            className="cursor-pointer"
            onClick={(event) =>
              copyToClipboard(event, certificateContractAddress)
            }
          >
            <CopyIcon />
          </div>
        </div>
      </div>

      <DownloadButton
        file={certificateContractAddress}
        output="contractAddress.txt"
      />
    </div>
  );
}

export default AddressInput;
