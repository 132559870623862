// ----------------------
// External/Internal Packages
// ----------------------
import React from "react";

// ----------------------
// Utility Functions
// ----------------------
import SocketHandler from "../../../utils/sockets/handleSocket";

// ----------------------
// Interfaces
// ----------------------
import { Action, ActionType, State } from "../../../state/interfaces";

interface StripeWidgetProps {
  additionalData?: any;
  state: State;
  dispatch: React.Dispatch<Action>;
  id: string;
}

function StripeWidget(props: StripeWidgetProps) {
  const handleStripePayment = () => {
    props.dispatch({
      type: ActionType.SET_THINKING_MESSAGE,
      payload: "Transaction is pending",
    });
    props.dispatch({ type: ActionType.SET_FETCHING_MESSAGE, payload: true });

    if (!(props.state.socketHandler instanceof SocketHandler)) {
      props.state.socketHandler = new SocketHandler();
    }

    props.state.socketHandler.agreementCreateCheckout(props.state);
  };

  return (
    <button
      onClick={handleStripePayment}
      className="rounded-md hover:opacity-90 hover:text-[#ccd1f0] text-[#ffff] bg-gradient-button font-bold text-[12px] md:text-[14px] py-[10px]  bg-[#0023EF12] px-[20px]"
    >
      Accept & Pay
    </button>
  );
}

export default StripeWidget;
