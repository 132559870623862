import React from "react";
import "./slick.css";

interface PaybackMobileSliderElementProps {
  bigText: string;
  smallText: string;
  darkStyle: boolean;
}

function PaybackMobileSliderElement({
  bigText,
  smallText,
  darkStyle,
}: PaybackMobileSliderElementProps) {
  return (
    <div className="slider-item">
      <p
        className={`text-[54px] md:text-[64px] ${
          darkStyle ? "opacity-10" : "text-[#0023EF]"
        }`}
      >
        {bigText}
      </p>
      <p className="text-[14px] font-bold">{smallText}</p>
    </div>
  );
}

export default PaybackMobileSliderElement;
