import { handleUserMessage } from "../../../../utils/sockets/handleUserMessage";
import { Action, QueueItem } from "../../../../state/interfaces";
import handleToastError from "../../../../utils/utility/handleToastError";
import { handleVoiceSkip } from "../../../../utils/dialog/handleVoiceSkip";

const handleUserInput = async (
  inputValue: string,
  allMessagesDisplayed: boolean,
  state: any,
  dispatch: React.Dispatch<Action>,
  setInputValue: Function,
  messageQueue: React.MutableRefObject<QueueItem[]>,
  currentAudio: React.MutableRefObject<HTMLAudioElement | null>,
  walletAddress: string | null | undefined
) => {
  if (
    inputValue !== "" &&
    allMessagesDisplayed &&
    state.conversationStarted &&
    state.conversationId !== "" &&
    !state.fetchingMessage
  ) {
    // Check if the message is too long
    if (inputValue.length > state.maxMessageLength) {
      handleToastError("Message is too long!");
      return;
    }

    // Check if the user input only contains special characters or spaces
    const onlySpecialCharactersOrSpacesRegex = /^[\s\W]*$/;
    if (onlySpecialCharactersOrSpacesRegex.test(inputValue)) {
      handleToastError("Please send meaningful content!");
      return;
    }

    // Filter out URLs
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    if (urlRegex.test(inputValue)) {
      handleToastError("Please avoid sending URLs!");

      return;
    }

    const tempUserInput = inputValue.trim();
    setInputValue("");

    if (messageQueue !== undefined) {
      handleVoiceSkip(messageQueue, currentAudio, dispatch);
    }

    handleUserMessage(tempUserInput, dispatch, state, walletAddress);
  }
};

export default handleUserInput;
