// ----------------------
// Packages
// ----------------------
import React, { useState, useEffect } from "react";
import { Steps } from "antd";
import Lottie from "lottie-react";

// ----------------------
// Assets
// ----------------------
import certificateLoader from "./certificateLoader.json";
import { ReactComponent as SendIcon } from "../../../assets/images/sendIconWhite.svg";
import { GridLoader } from "react-spinners";

// ----------------------
// Utility Functions
// ----------------------
import { useHandleResize } from "../../../utils/utility/useHandleResize";
import { getParagraphs } from "../utils/getParagraphs";
import { useSnarkStatus } from "../utils/useSnarkStatus";

// ----------------------
// Interfaces and styles
// ----------------------
import { Action, ActionType, State } from "../../../state/interfaces";
import "./circles.css";

type StepSize = "small" | "default";

interface CertificateAnimationProps {
  stepsFinished: boolean;
  state: State;
  setStepsFinished: React.Dispatch<React.SetStateAction<boolean>>;
  current: number;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  dispatch: React.Dispatch<Action>;
  setCertificateContractAddress: React.Dispatch<React.SetStateAction<string>>;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  error: boolean;
}

function CertificateAnimation({
  stepsFinished,
  state,
  setStepsFinished,
  current,
  setCurrent,
  dispatch,
  setCertificateContractAddress,
  setError,
  error,
}: CertificateAnimationProps) {
  const [animationActive, setAnimationActive] = useState(false);
  const [stepSize, setStepSize] = useState<StepSize>(
    window.innerWidth < 1000 ? "small" : "default"
  );

  // UseEffect to enlarge timer
  useEffect(() => {
    if (current === 6) {
      setAnimationActive(true);

      // A new setTimeout for enlarging
      const enlargeTimer = setTimeout(() => {
        setStepsFinished(true);
      }, 3000);

      return () => clearTimeout(enlargeTimer);
    }
  }, [current]);

  useSnarkStatus(
    setCurrent,
    dispatch,
    setCertificateContractAddress,
    state,
    setError,
    error
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1000 && window.innerWidth >= 577) {
        setStepSize("small");
      } else {
        setStepSize("default");
      }
    };
    // Add the event listener
    window.addEventListener("resize", handleResize);

    // Cleanup function: remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const paragraphSize = useHandleResize(577, "shortParagraph", "longParagraph");
  const paragraphs = getParagraphs(paragraphSize);

  return (
    <div
      className={`mx-[20px]  h-full md:mx-[70px] ml-[10px] my-steps-component flex items-center justify-center sm577:justify-start sm577:flex-col ${
        stepsFinished ? "enlarged" : ""
      }`}
    >
      {!stepsFinished && (
        <Steps
          current={current}
          items={[{}, {}, {}, {}, {}, {}]}
          size={stepSize}
          className="h-[98%] sm577:h-auto ml-[20px] sm577:ml-0"
        />
      )}

      {!animationActive && !stepsFinished && (
        <div className="sm577:mt-[60px] sm577:mb-0 max-w-[700px] text-[16px] md:text-[18px] hidden sm577:block text-center  h-full">
          <p>{paragraphs[current]}</p>
          <div
            className={`mt-auto flex h-full  justify-center ${
              current === 5 ? "items-start pt-[40px]" : "items-center"
            }`}
          >
            <GridLoader color="gray" size={14} className="" />
          </div>
        </div>
      )}
      {animationActive && (
        <div
          className={`mt-[25px] ${
            stepsFinished
              ? "enlarged-lottie flex flex-col items-center justify-center relative z-0"
              : ""
          }`}
        >
          <Lottie
            animationData={certificateLoader}
            style={{
              width: stepsFinished ? "400px" : "280px",
              height: stepsFinished ? "400px" : "280px",
              transition: "all 1s",
            }}
          />

          {stepsFinished && (
            <button
              onClick={() => {
                dispatch({
                  type: ActionType.SET_DOWNLOAD_CERTIFICATE,
                  payload: true,
                });
              }}
              className="bg-connect-button w-[180px] h-[60px] text-[#fff] text-[20px] font-bold rounded-[2px] flex items-center justify-center gap-[8px]"
            >
              <p>Next</p>
              <SendIcon className="mt-[6px]" />
            </button>
          )}
        </div>
      )}
    </div>
  );
}

export default CertificateAnimation;
