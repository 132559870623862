import axios from "axios";
import handleToastError from "../../utils/utility/handleToastError";

export const fetchPaymentState = async (token: string, contractId: string) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/payments/verify-payment",
      {
        token: token,
        contractId: contractId,
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 403) {
        handleToastError(
          "Access denied! Please log out and log back in to solve this issue."
        );
      } else {
        console.error("Error sending wallet connection request:", error);
      }
    } else {
      console.error("An unexpected error occurred:", error);
    }
  }
};
