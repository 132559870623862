import vaiotBubble from "../../../assets/images/vaiotBubble.svg";

interface SkipVaiotProps {
  setPopupActive: React.Dispatch<React.SetStateAction<boolean>>;
  text: string;
}
function SkipVaiot({ setPopupActive, text }: SkipVaiotProps) {
  return (
    <div className="fixed inset-0 flex items-center justify-center md:bottom-[24px] md:left-[20px] md:items-end md:justify-start z-[10]">
      <div className="flex items-center justify-center z-[10] gap-[20px]">
        <img src={vaiotBubble} alt="Vaiot Logo" />
        <div className="p-4 rounded-[2px] chat-box max-w-[400px] z-[10]">
          <p
            className={
              "font-semibold text-[14px] tracking-wide max-w-[130px] sm500:max-w-[200px]"
            }
          >
            {text}
          </p>
          <div className="w-full items-center justify-center flex mt-[10px] sm500:mt-[20px]">
            <button
              onClick={() => {
                setPopupActive(false);
              }}
              className="bg-reader-button text-[#ffff] w-[50px] h-[35px] rounded-[2px]"
            >
              Skip
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SkipVaiot;
