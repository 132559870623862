import React, { useEffect } from "react";
import SocketHandlerReader from "../../../utils/sockets/handleSocketsReader";
import { Action, State } from "../../../state/interfaces";

function useFetchAllCertificates(
  state: State,
  contractId: number,
  dispatch: React.Dispatch<Action>,
  account: string | null | undefined,
  typeOfChosenContract: "SA" | "NDA" | "PoAG" | "PoAS" | ""
) {
  useEffect(() => {
    if (state.certificatePaid) {
      if (!(state.socketHandlerReader instanceof SocketHandlerReader)) {
        state.socketHandlerReader = new SocketHandlerReader();
      }

      state.socketHandlerReader?.fetchAllCertificates(
        contractId.toString(),
        dispatch,
        account,
        typeOfChosenContract
      );
    }
  }, []);
}

export default useFetchAllCertificates;
