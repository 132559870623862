// ----------------------
// External/Internal Packages
// ----------------------
import React from "react";

// ----------------------
// Utility functions
// ----------------------
import { useTypingEffect } from "../../utils/dialog/useTypingEffect";

interface TypingTextProps {
  text?: string | string[];
  typingSpeed: number;
  handleTypingDone: () => void;
}

const TypingText: React.FC<TypingTextProps> = ({
  text,
  typingSpeed,
  handleTypingDone,
}) => {
  // Pre-process the text to replace HTML tags and HTML entities with non-HTML representations.
  const preProcessedText = Array.isArray(text)
    ? text.map(processHtmlTags)
    : typeof text === "string"
    ? processHtmlTags(text)
    : "";

  const displayedText = useTypingEffect(
    preProcessedText,
    typingSpeed,
    handleTypingDone
  );

  return (
    <p className="font-semibold text-[14px] sm:text-[16px] tracking-wide">
      {displayedText}
    </p>
  );
};

// Function to replace HTML tags and HTML entities in a string.
function processHtmlTags(str: string): string {
  return str
    .replace(/<br\/?>/g, "\n")
    .replace(/<ul>(.*?)<\/ul>/gs, (_, innerContent) => {
      const items = innerContent.match(/<li>(.*?)<\/li>/g);
      return items
        ? items
            .map((item: string) => `- ${item.replace(/<\/?li>/g, "")}`)
            .join("\n")
        : "";
    })
    .replace(/&nbsp;/g, " ");
}

export default TypingText;
