import axios from "axios";
import { State } from "../../../state/interfaces";
import handleToastError from "../../../utils/utility/handleToastError";

export const handleQRCode = async (
  state: State,
  setQrCodeDownloading?: React.Dispatch<React.SetStateAction<boolean>> // Mark parameter as optional with '?'
) => {
  try {
    // Check if setQrCodeDownloading is provided before using it
    setQrCodeDownloading && setQrCodeDownloading(true);

    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/snarks/generateQRCode`,
      { proofId: state.proofId.toString() }
    );

    if (response.status === 201 && response.data) {
      const base64Data = response.data;

      const a = document.createElement("a");
      a.style.display = "none";
      a.href = base64Data;
      a.download = "QRCode.png";

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      // Check if setQrCodeDownloading is provided before using it
      setQrCodeDownloading && setQrCodeDownloading(false);
    } else {
      console.error(`Received unexpected status code: ${response.status}`);
    }
  } catch (error) {
    setQrCodeDownloading && setQrCodeDownloading(false);
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 403) {
        handleToastError(
          "Access denied! Please log out and log back in to solve this issue."
        );
      } else {
        console.error("Error creating QR Code:", error);
      }
    } else {
      console.error("An unexpected error occurred:", error);
    }
  }
};
