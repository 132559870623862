import React, { useState, useEffect, useCallback } from "react";
import LoginHistoryElement from "./LoginHistoryElement";
import { getUserDetails } from "../../../utils/api/getUserDetails";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

interface Device {
  id: number;
  isTrusted: boolean;
  device: string;
  date: string;
  ipAddress: string;
}

interface LoginHistoryProps {
  devices: Device[];
  setDevices: React.Dispatch<React.SetStateAction<Device[]>>;
  isLoading: boolean;
}

function LoginHistory({ devices, setDevices, isLoading }: LoginHistoryProps) {
  return (
    <div className="bg-[#F7F8FD] w-full py-[20px] px-[10px] sm500:px-[20px]">
      {!isLoading ? (
        <div className="flex flex-col font-TT gap-[5px] tracking-wide bg-[#fff] px-[5px] py-[15px] rounded-[6px] shadow-sm border-[#E5E4F0] border-[1px] overflow-y-auto max-h-[300px]">
          <p className="font-bold mb-[20px] sm500:pl-[15px]">Login History</p>
          <div className="flex justify-between text-center mb-[10px] font-bold tracking-wide">
            <span className="w-[20%]">Trusted</span>
            <span className="w-[30%]">Device</span>
            <span className="w-[25%] hidden sm400:block">IP address</span>
            <span className="w-[25%] sm400:hidden">IP</span>
            <span className="w-[20%]">Date</span>
            <span className="w-[5%]"></span>
          </div>
          {devices.map(({ id, isTrusted, device, date, ipAddress }) => (
            <LoginHistoryElement
              key={id}
              isTrusted={isTrusted}
              device={device}
              date={date}
              ipAddress={ipAddress}
              setDevices={setDevices}
            />
          ))}
        </div>
      ) : (
        <div className="flex flex-col gap-[5px] bg-[#fff] px-[5px] py-[15px] rounded-[6px] shadow-sm border-[#E5E4F0] border-[1px] h-[195px]">
          <Skeleton className="w-[140px] h-[20px] mb-[20px] ml-[12px]" />
          {Array.from({ length: 4 }, (_, index) => (
            <div
              key={index}
              className="flex justify-between text-center w-full px-2"
            >
              <div className="w-[19%] mx-1">
                <Skeleton />
              </div>
              <div className="w-[29%] mx-1">
                <Skeleton />
              </div>
              <div className="w-[24%] hidden sm:block mx-1">
                <Skeleton />
              </div>
              <div className="w-[24%] block sm:hidden mx-1">
                <Skeleton />
              </div>
              <div className="w-[19%] mx-1">
                <Skeleton />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default LoginHistory;
