// ----------------------
// External/Internal Packages
// ----------------------
import React, { useState } from "react";

// ----------------------
// Components
// ----------------------
import AgreementField from "../SnarkFieldChoice/AgreementField";

// ----------------------
// Interfaces
// ----------------------
import { Action } from "../../../../state/interfaces";

interface ContractBulletPointJsonProps {
  iterator: string;
  nameOfBulletPoint: string;
  dispatch: React.Dispatch<Action>;
  fieldChoice: boolean;
  rawAgreementTerms: (string | string[])[];
}

function ContractBulletPointJson(props: ContractBulletPointJsonProps) {
  const [agreementOpened, setAgreementOpened] = useState(false);
  const [fieldChosen, setFieldChosen] = useState<Record<string, boolean>>({});

  const agreementTerms = {
    duration: props.rawAgreementTerms[0],
    finalDate: props.rawAgreementTerms[1],
    earlyTerminationPossible: props.rawAgreementTerms[2],
    terminationPeriod: props.rawAgreementTerms[3],
    supplierReimbursement: props.rawAgreementTerms[4],
    intellectualPropertyOwner: props.rawAgreementTerms[5],
    courtJurisdiction: props.rawAgreementTerms[10],
    compensation: {
      currency: props.rawAgreementTerms[6],
      fixedAmount: props.rawAgreementTerms[7],
      includeTax: props.rawAgreementTerms[8],
      typeOfCompensation: props.rawAgreementTerms[9],
    },
  };

  const handleAgreementClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setAgreementOpened((prev) => !prev);
  };

  const toggleFieldChosen = (fieldName: string) => {
    setFieldChosen((prevFieldChosen) => ({
      ...prevFieldChosen,
      [fieldName]: !prevFieldChosen[fieldName],
    }));
  };

  const fields = [
    { label: "duration", value: agreementTerms.duration },
    { label: "finalDate", value: agreementTerms.finalDate },
    {
      label: "earlyTerminationPossible",
      value: agreementTerms.earlyTerminationPossible,
    },
    { label: "terminationPeriod", value: agreementTerms.terminationPeriod },
    {
      label: "supplierReimbursement",
      value: agreementTerms.supplierReimbursement,
    },
    {
      label: "intellectualPropertyOwner",
      value: agreementTerms.intellectualPropertyOwner,
    },
    { label: "courtJurisdiction", value: agreementTerms.courtJurisdiction },
    { label: "currency", value: agreementTerms.compensation.currency },
    { label: "fixedAmount", value: agreementTerms.compensation.fixedAmount },
    { label: "includeTax", value: agreementTerms.compensation.includeTax },
    {
      label: "typeOfCompensation",
      value: agreementTerms.compensation.typeOfCompensation,
    },
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-x-8 gap-y-[10px] items-center pb-5 px-10 md:px-20">
      <div className="flex items-center gap-[20px]">
        {props.fieldChoice && (
          <div className={`w-[20px] h-[20px] mt-[2px]`}></div>
        )}
        <p className="text-gray-500 font-bold text-[14px] sm:text-[16px]">
          {props.iterator}
        </p>
        <p className="text-[#7682E6] font-semibold text-[14px] sm:text-[16px]">
          {props.nameOfBulletPoint}
        </p>
      </div>
      <div className="flex items-center ml-[20px] md:ml-0">
        <p
          className={`text-red-500 ${
            props.fieldChoice ? "mx-5" : "mx-2 md:mx-0"
          }`}
        >
          .
        </p>
        <div
          className="text-green-500 font-semibold cursor-pointer flex flex-col items-start ml-2 md:hidden text-[14px] sm:text-[16px]"
          onClick={handleAgreementClick}
        >
          agreementTerms: {"{"}
          {!agreementOpened && "..."}
          {agreementOpened && (
            <div className="pl-5 ">
              {fields.map((field) => {
                if (typeof field.value === "string" || field.value === null) {
                  return (
                    <AgreementField
                      key={field.label}
                      fieldChoice={props.fieldChoice}
                      label={field.label}
                      value={field.value || "null"}
                      fieldChosen={fieldChosen[field.label] || false}
                      setFieldChosen={() => toggleFieldChosen(field.label)}
                      dispatch={props.dispatch}
                    />
                  );
                }
                return null;
              })}
            </div>
          )}
          {"}"}
        </div>
      </div>
      <div
        className="text-green-500 font-semibold cursor-pointer flex flex-col items-start hidden md:block"
        onClick={handleAgreementClick}
      >
        agreementTerms: {"{"}
        {!agreementOpened && "..."}
        {agreementOpened && (
          <div className="pl-5">
            {fields.map((field) => {
              if (typeof field.value === "string" || field.value === null) {
                return (
                  <AgreementField
                    key={field.label}
                    fieldChoice={props.fieldChoice}
                    label={field.label}
                    value={field.value || "null"}
                    fieldChosen={fieldChosen[field.label] || false}
                    setFieldChosen={() => toggleFieldChosen(field.label)}
                    dispatch={props.dispatch}
                  />
                );
              }
              return null;
            })}
          </div>
        )}
        {"}"}
      </div>
    </div>
  );
}

export default ContractBulletPointJson;
