import { commands } from "./listeningCommands";
import { calculateNumber } from "./calculateNumber";

export function wordsToNumbers(words: string[]): (number | string)[] {
  let numberBuffer: number[] = [];
  let result: (number | string)[] = [];

  words.forEach((word, idx) => {
    const command = commands.find((cmd) => cmd.command === word.toLowerCase());

    if (command) {
      numberBuffer.push(command.number);
    } else if (
      word.toLowerCase() === "and" &&
      commands.find(
        (cmd) => cmd.command === (words[idx - 1] || "").toLowerCase()
      ) &&
      commands.find(
        (cmd) => cmd.command === (words[idx + 1] || "").toLowerCase()
      )
    ) {
      // If 'and' is encountered, and both the word before and after 'and' are numbers, just skip it
      return;
    } else {
      // When we find a non-number word or 'and' between non-number words, handle the numberBuffer if it has values
      if (numberBuffer.length) {
        result.push(calculateNumber(numberBuffer));
        numberBuffer = []; // Reset buffer
      }
      result.push(word);
    }
  });

  // After all words have been processed, handle remaining numbers in the buffer
  if (numberBuffer.length) {
    result.push(calculateNumber(numberBuffer));
  }

  return result;
}
