// ----------------------
// Packages
// ----------------------
import Lottie from "lottie-react";

// ----------------------
// Assets
// ----------------------
import animationData from "./progressBar.json";

// ----------------------
// Utility Functions
// ----------------------
import { useHandleResize } from "../../../utils/utility/useHandleResize";

function DecodingAnimation() {
  const desktopSize = useHandleResize(800, "small", "desktop");

  const style =
    desktopSize === "small"
      ? { width: 400, height: 400 }
      : { width: 600, height: 600 };
  return (
    <div className="flex justify-center items-center w-full h-full flex-col gap-[5px]">
      <Lottie animationData={animationData} style={style} />
    </div>
  );
}

export default DecodingAnimation;
