// ----------------------
// Packages
// ----------------------
import React from "react";

// ----------------------
// Utility functions
// ----------------------
import handleToastError from "../../../utils/utility/handleToastError";

// ----------------------
// Interfaces
// ----------------------
import { Action, State, ActionType } from "../../../state/interfaces";

interface ApproveButtonProps {
  state: State;
  dispatch: React.Dispatch<Action>;
}

function ApproveButton({ state, dispatch }: ApproveButtonProps) {
  const handleApprove = () => {
    if (state.summary === "") {
      handleToastError("Create a contract summary first!");
      return;
    }
    dispatch({
      type: ActionType.SET_PAYMENT_ACTIVE,
      payload: true,
    });
  };

  return (
    <button
      onClick={handleApprove}
      className={`${
        state.summary !== "" ? "bg-connect-button" : "bg-[#CECDDA]"
      } sm:w-[180px] h-[60px] text-[#fff] md:text-[20px] font-bold rounded-[2px] mb-[80px] w-full mx-[30px]`}
    >
      Approve
    </button>
  );
}

export default ApproveButton;
