import React, { useEffect } from "react";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import handleToastError from "../utility/handleToastError";

export const useWrongChain = () => {
  const { selectedNetworkId } = useWeb3ModalState();

  useEffect(() => {
    if (
      selectedNetworkId !== undefined &&
      parseInt(selectedNetworkId) !== 11155111
    ) {
      handleToastError("The connected blockchain network is not supported.");
    }
  }, [selectedNetworkId]);
};

export default useWrongChain;
