import React, { useState, useEffect } from "react";
import { Action, ActionType, State } from "../../state/interfaces";
interface ResetPasswordConfirmationModalProps {
  dispatch: React.Dispatch<Action>;
  state: State;
}

function ResetPasswordConfirmationModal({
  dispatch,
  state,
}: ResetPasswordConfirmationModalProps) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleVisibility = () => {
      setIsVisible(true);
    };

    handleVisibility();
  }, []);

  const handleCloseModal = async () => {
    dispatch({
      type: ActionType.SET_RESET_PASSWORD_CONFIRMATION_MODAL,
      payload: false,
    });
  };

  return (
    <div className="w-full h-full flex items-center justify-center bg-[#050934] bg-opacity-[75%] fixed z-[101] font-TT tracking-wide">
      <div
        className={`bg-[#fff] w-[500px] h-[230px] border-2 shadow-lg rounded-[6px] flex flex-col gap-[20px] max-h-[80%] overflow-y-auto
transition-all duration-100 ease-in-out transform-gpu ${
          isVisible ? "scale-100" : "scale-[90%]"
        }
transition-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);`}
      >
        <div className="w-full flex items-center justify-between bg-[#E5E4F0] px-[30px] py-[20px] font-bold">
          <p>Reset Password Request Received</p>
        </div>
        <div className="flex flex-col items-center justify-center px-[30px] gap-[30px]">
          <p>
            We've sent instructions on how to reset your password to
            <span className="font-bold">{" " + state.forgotPasswordEmail}</span>
            , please check your inbox or the spam folder.
          </p>
          <button
            onClick={handleCloseModal}
            className="bg-connect-button w-[100px] h-[50px] font-bold text-[#fff] mb-[10px]"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordConfirmationModal;
