import { useState, useEffect } from "react";
import TransactionHistory from "../TransactionHistory/TransactionHistory";
import PaybackMobileBalanceSlider from "./PaybackMobileBalanceSlider";
import PaybackMobileHeader from "./PaybackMobileHeader";
import { useAccount } from "wagmi";
import { Action, State } from "../../../../state/interfaces";
import { getUserDetails } from "../../../../utils/api/getUserDetails";
import { fetchPaybackUserInfo } from "../../../../utils/api/fetchPaybackUserInfo";
import { fetchPaybackWithdrawalHistory } from "../../../../utils/api/fetchPaybackWithdrawalHistory";
import { getVaiPointsHistory } from "../../../../utils/api/getVaiPointsHistory";
import handleToastError from "../../../../utils/utility/handleToastError";
import PaybackScreensChoice from "../PaybackScreensChoice";
import PaybackSettings from "../PaybackSettings";
import PaybackClaim from "../PaybackClaim/PaybackClaim";
import { checkIfWalletIsRegistered } from "../../../../utils/api/checkIfWalletIsRegistered";

interface UnifiedTransaction {
  type: "payback" | "vaiPoints";
  date: string;
  amount: string;
  address?: string;
  transactionHash?: string;
  blockNumber?: number;
  fromCollection?: string;
  fromId?: string;
}

interface PaybackMobileProps {
  state: State;
  dispatch: React.Dispatch<Action>;
}

function PaybackMobile({ state, dispatch }: PaybackMobileProps) {
  const [stakedVai, setStakedVai] = useState("0");
  const [claimedVai, setClaimedVai] = useState("0");
  const [transactions, setTransactions] = useState<UnifiedTransaction[]>([]);
  const [vaiPoints, setVaiPoints] = useState("0");
  const [refetchData, setRefetchData] = useState(false);
  const [stakingActive, setStakingActive] = useState(true);
  const [walletRegistered, setWalletRegistered] = useState(false);
  const [web2WalletAddress, setWeb2WalletAddress] = useState("");
  const [fetchingBalance, setFetchingBalance] = useState(true);

  const { address } = useAccount();

  useEffect(() => {
    if (address !== undefined) {
      const checkWallet = async () => {
        const response = await checkIfWalletIsRegistered(address);
        setWalletRegistered(response);
      };
      checkWallet();
    }
  }, [address]);

  useEffect(() => {
    const fetchWallet = async () => {
      if (state.web2Login) {
        const response = await getUserDetails();
        if (!response) {
          handleToastError("Unexpected error!");
          return;
        }
        setWeb2WalletAddress(response.userDetails.walletAddress);
      }
    };
    fetchWallet();
  }, [state.web2Login]);

  const convertDateToISO = (dateStr: string) => {
    // Check if the date is in ISO 8601 date-time format (includes time)
    if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(dateStr)) {
      // Convert to date-only string in yyyy-mm-dd format for consistent comparison
      return dateStr.split("T")[0];
    }
    // Check if the date is already in ISO format (yyyy-mm-dd) without time
    else if (/^\d{4}-\d{2}-\d{2}$/.test(dateStr)) {
      return dateStr; // Return as is if already in ISO format
    }
    // Assume dateStr is in dd.mm.yyyy format
    const parts = dateStr.split(".");
    if (parts.length === 3) {
      return `${parts[2]}-${parts[1]}-${parts[0]}`; // Convert to YYYY-MM-DD format
    }
    // Log an error if the date format is unexpected to catch any issues
    console.error("Unexpected date format:", dateStr);
    return dateStr; // Fallback to return the original string
  };

  const sortTransactions = (transactions: any) => {
    return transactions.sort((a: any, b: any) => {
      const dateA = new Date(convertDateToISO(a.date)).getTime();
      const dateB = new Date(convertDateToISO(b.date)).getTime();
      return dateB - dateA; // For ascending order
    });
  };

  useEffect(() => {
    const fetchUserData = async () => {
      setFetchingBalance(true);
      try {
        if (state.web2Login) {
          const web2UserInfo = await getUserDetails();
          if (!web2UserInfo) {
            handleToastError("Unexpected error!");
            return;
          }
          setStakingActive(web2UserInfo.userDetails.stakingActive);
          const vaiPoints = web2UserInfo.userDetails.vaiPoints;
          const walletAddress = web2UserInfo.userDetails.walletAddress;
          setVaiPoints(vaiPoints.toFixed());
          if (typeof walletAddress === "string" && walletAddress !== "") {
            const userInfo = await fetchPaybackUserInfo(walletAddress);
            setStakedVai(
              (
                parseFloat(userInfo.balance) + parseFloat(userInfo.rewards)
              ).toFixed(0)
            );
            const withdrawalHistory = await fetchPaybackWithdrawalHistory(
              walletAddress
            );

            const normalizedWithdrawals = withdrawalHistory.map((w) => ({
              ...w,
              type: "payback" as "payback",
              date: w.timestamp,
              amount: w.amount.toString(),
            })) as UnifiedTransaction[];

            const vaiPointsHistory = await getVaiPointsHistory();

            const normalizedVaiPoints = vaiPointsHistory.history.map((v) => ({
              type: "vaiPoints" as "vaiPoints",
              date: v.date,
              amount: v.amount.toString(),
              fromCollection: v.fromCollection,
              fromId: v.fromId,
            })) as UnifiedTransaction[];

            const allTransactions = [
              ...normalizedWithdrawals,
              ...normalizedVaiPoints,
            ];

            allTransactions.sort((a, b) => {
              const dateA = new Date(convertDateToISO(a.date)).getTime();
              const dateB = new Date(convertDateToISO(b.date)).getTime();
              return dateA - dateB;
            });

            setTransactions(allTransactions);

            const totalClaimed = withdrawalHistory.reduce((acc, withdrawal) => {
              const amount = parseFloat(withdrawal.amount);
              // Sum up only negative amounts, convert them to positive before adding
              if (amount < 0) {
                return acc + Math.abs(amount);
              }
              return acc;
            }, 0);

            setClaimedVai(totalClaimed.toFixed());
          }
        } else if (state.web3Login && address) {
          const userInfo = await fetchPaybackUserInfo(address);
          setStakedVai(
            (
              parseFloat(userInfo.balance) + parseFloat(userInfo.rewards)
            ).toFixed()
          );
          const withdrawalHistory = await fetchPaybackWithdrawalHistory(
            address
          );

          const normalizedWithdrawals = withdrawalHistory.map((w) => ({
            ...w,
            type: "payback" as "payback",
            date: w.timestamp,
            amount: w.amount.toString(),
          })) as UnifiedTransaction[];

          const vaiPointsHistory = await getVaiPointsHistory();

          const normalizedVaiPoints = vaiPointsHistory.history.map((v) => ({
            type: "vaiPoints" as "vaiPoints",
            date: v.date,
            amount: v.amount.toString(),
            fromCollection: v.fromCollection,
            fromId: v.fromId,
          })) as UnifiedTransaction[];

          let allTransactions = [
            ...normalizedWithdrawals,
            ...normalizedVaiPoints,
          ];

          allTransactions = sortTransactions(allTransactions);

          // allTransactions.sort(
          //   (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
          // );

          setTransactions(allTransactions);
          const totalClaimed = withdrawalHistory.reduce(
            (acc, withdrawal) => acc + parseFloat(withdrawal.amount),
            0
          );
          setClaimedVai(totalClaimed.toFixed());
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData().finally(() => setFetchingBalance(false));
  }, [refetchData]);

  return (
    <div className="flex flex-col items-center w-full h-full gap-[10px] bg-[#fff] pb-[20px] font-TT tracking-wide overflow-y-auto">
      <PaybackMobileHeader />
      <PaybackMobileBalanceSlider
        stakedVai={stakedVai}
        claimedVai={claimedVai}
        vaiPoints={vaiPoints}
      />
      {state.paybackActiveScreen === "TransactionHistory" && (
        <TransactionHistory
          mobile={true}
          transactions={transactions === undefined ? [] : transactions}
          dispatch={dispatch}
        />
      )}
      {state.paybackActiveScreen === "" && (
        <PaybackScreensChoice
          dispatch={dispatch}
          walletRegistered={walletRegistered}
          web2WalletAddress={web2WalletAddress}
          address={address}
          state={state}
        />
      )}

      {state.paybackActiveScreen === "Settings" && (
        <PaybackSettings
          dispatch={dispatch}
          stakingActive={stakingActive}
          setStakingActive={setStakingActive}
        />
      )}
      {state.paybackActiveScreen === "ClaimPayback" && (
        <PaybackClaim
          dispatch={dispatch}
          setRefetchData={setRefetchData}
          refetchData={refetchData}
        />
      )}
    </div>
  );
}

export default PaybackMobile;
