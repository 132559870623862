// ----------------------
// External/Internal Packages
// ----------------------
import React from "react";
import ServicesList from "./ServicesList";

// ----------------------
// Interfaces
// ----------------------
import { Action } from "../../../../state/interfaces";

// Redefine props interface
interface ContractBulletPointListProps {
  iterator: string;
  nameOfBulletPoint: string;
  services: Array<[string, string[]]>;
  fieldChoice: boolean;
  dispatch: React.Dispatch<Action>;
}

function ContractBulletPointList({
  iterator,
  nameOfBulletPoint,
  services,
  fieldChoice,
  dispatch,
}: ContractBulletPointListProps) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-x-8 gap-y-[10px] items-center pb-5 px-10 md:px-20">
      <div className="flex items-center gap-[20px]">
        {fieldChoice && (
          <div
            className={`w-[20px] h-[20px] mt-[2px] text-[14px] sm:text-[16px]`}
          ></div>
        )}
        <p className="text-gray-500 font-bold">{iterator}</p>
        <p className="text-[#7682E6] font-semibold">{nameOfBulletPoint}</p>
      </div>
      <div className="flex items-center ml-[20px] md:ml-0">
        <p className={`text-red-500 ${fieldChoice ? "mx-5" : "mx-2 md:mx-0"}`}>
          .
        </p>

        <div className="md:hidden text-[14px] sm:text-[16px]">
          <ServicesList
            services={services}
            dispatch={dispatch}
            fieldChoice={fieldChoice}
          />
        </div>
      </div>
      <div className="hidden md:block">
        <ServicesList
          services={services}
          dispatch={dispatch}
          fieldChoice={fieldChoice}
        />
      </div>
    </div>
  );
}

export default ContractBulletPointList;
