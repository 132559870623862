// ----------------------
// Packages
// ----------------------
import React from "react";

// ----------------------
// Assets
// ----------------------
import { ReactComponent as Dzin } from "../../../assets/images/certificateDzin.svg";

import { ReactComponent as Chevron } from "../../../assets/images/chevronLeft.svg";

// ----------------------
// Utility Function
// ----------------------
import { handleRestartDownload } from "../utils/handleRestartDownload";

// ----------------------
// Components
// ----------------------
import Title from "./Title";
import AddressInput from "./AddressInput";
import ProofInput from "./ProofInput";
import QRCodeDownload from "./QRCodeDownload";
import DownloadAll from "./DownloadAll";

// ----------------------
// Interfaces
// ----------------------
import { Action, State } from "../../../state/interfaces";

interface ContractTerms {
  agreementTerms: Array<any>;
  parties: Array<string>;
  services: Array<Array<string>>;
  onBlockchain: string;
}

interface ContractInfoNDA {
  creationTimestamp: string;
  fieldNames: string[];
  fieldValues: string[];
  partyA: string;
  partyB: string;
}

interface DownloadCertificateProps {
  dispatch: React.Dispatch<Action>;
  certificateContractAddress: string;
  state: State;

  setContractInfoSA: React.Dispatch<
    React.SetStateAction<ContractTerms | undefined>
  >;
  setContractInfoNDA: React.Dispatch<
    React.SetStateAction<ContractInfoNDA | undefined>
  >;
}

function DownloadCertificate({
  dispatch,
  certificateContractAddress,
  state,
  setContractInfoNDA,
  setContractInfoSA,
}: DownloadCertificateProps) {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="bg-[#050934] w-full sm:w-[92%] h-[90%] sm:h-[80%] sm900:w-[800px] md:w-[950px] lg:w-[1100px] xl:w-[1200px] xl:px-[50px] xl:py-[25px] rounded-md flex flex-col justify-between py-[10px] px-[20px] sm:py-[20px] sm:px-[40px] relative">
        <button
          onClick={() =>
            handleRestartDownload(
              dispatch,
              setContractInfoSA,
              setContractInfoNDA
            )
          }
          className="absolute top-[45%] left-[-8%] sm900:left-[-7%] w-[35px] hidden sm:block"
        >
          <Chevron className="w-full h-full" />
        </button>
        <Title
          dispatch={dispatch}
          setContractInfoNDA={setContractInfoNDA}
          setContractInfoSA={setContractInfoSA}
        />
        <div className="flex justify-between flex-col sm:flex-row items-start h-full">
          <div className="hidden sm:block w-[25%] max-w-[220px]">
            <Dzin className="w-full h-full" />
          </div>
          <div className="flex flex-col items-start sm:items-center justify-start gap-[30px] w-full sm:w-[35%] mb-[30px] sm:mb-0">
            <AddressInput
              certificateContractAddress={certificateContractAddress}
              dispatch={dispatch}
            />
            <ProofInput state={state} dispatch={dispatch} />
          </div>
          <QRCodeDownload state={state} />
          <div className="block sm:hidden">
            <DownloadAll
              certificateContractAddress={certificateContractAddress}
              state={state}
            />
          </div>
        </div>
        <div className="hidden sm:flex w-full items-center justify-center">
          <DownloadAll
            certificateContractAddress={certificateContractAddress}
            state={state}
          />
        </div>
      </div>
    </div>
  );
}

export default DownloadCertificate;
