// ----------------------
// Packages
// ----------------------
import React from "react";

// ----------------------
// Assets
// ----------------------
import { ReactComponent as ContractVaiotAvatar } from "../../../assets/images/contractVaiotAvatar.svg";

// ----------------------
// Components
// ----------------------
import ContractInfo from "./ContractInfo";

// ----------------------
// Utility Functions
// ----------------------
import { useHandleResize } from "../../../utils/utility/useHandleResize";

// ----------------------
// Interfaces
// ----------------------
import { State, Action } from "../../../state/interfaces";
import Header from "./Header";

interface ContractListProps {
  state: State;
  dispatch: React.Dispatch<Action>;
}

function ContractList({ state, dispatch }: ContractListProps) {
  const desktopSize = useHandleResize(900, "small", "default");

  const contracts = state.contractList.map((contract: Array<string>, index) => (
    <ContractInfo
      key={"contractInfo-" + index}
      dispatch={dispatch}
      content={contract}
    />
  ));
  return (
    <div className="flex items-start justify-center w-full relative mt-[140px] sm:mt-[150px] h-[60%]">
      <ContractVaiotAvatar
        className={`absolute z-10 lg:top-[-140px] lg:left-[140px] sm:top-[-130px] sm:left-[40px] h-auto lg:w-[550px] sm:w-[400px] left-[10px] w-[300px] top-[-110px]`}
      />
      <div className="bg-white bg-opacity-50 w-[90%] lg:w-[70%] p-[10px] sm:p-[25px] h-full z-20 flex flex-col gap-[5px] max-h-[600px]">
        <div className="flex gap-[3px] sm:gap-[10px] justify-between items-center">
          <Header text={"Type"} width={10} />
          <Header text="Party A" width={desktopSize === "small" ? 30 : 35} />
          <Header text="Party B" width={desktopSize === "small" ? 30 : 35} />
          <Header text="Date" width={desktopSize === "small" ? 30 : 20} />
        </div>
        <div className="flex flex-col gap-[5px] overflow-y-auto max-h-[550px] overflow-x-hidden">
          {contracts}
        </div>
        {contracts.length === 0 && (
          <p className="flex w-full h-[50%] items-center justify-center">
            No contracts found!
          </p>
        )}
      </div>
    </div>
  );
}

export default ContractList;
