// ----------------------
// Packages
// ----------------------
import React, { useState } from "react";

// ----------------------
// Utility functions
// ----------------------
import { updateSummary } from "../utils/updateSummary";

// ----------------------
// Interfaces
// ----------------------
import { ContractTerms, OptionType } from "../../../state/interfaces";

interface OptionsButtonProps {
  text: string;
  selectedOption: OptionType;
  contractInfo: ContractTerms | undefined;
  fetchingSummary: boolean;
  messageForSummary: any[];
  setMessageForSummary: React.Dispatch<React.SetStateAction<any[]>>;
  value: any;
  selectedFields: {
    [key: string]: string[];
    Supplier: string[];
    Buyer: string[];
    General: string[];
  };
  setSelectedFields: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string[];
      Supplier: string[];
      Buyer: string[];
      General: string[];
    }>
  >;
}

function OptionsButton({
  text,
  selectedOption,
  contractInfo,
  fetchingSummary,
  messageForSummary,
  setMessageForSummary,
  value,
  setSelectedFields,
  selectedFields,
}: OptionsButtonProps) {
  const [activeField, setActiveField] = useState<string | null>(null);

  const getButtonClasses = (field: string) => {
    let baseClass = "p-[5px] border-[1px] border-black rounded-[2px]";

    // Helper function to process the key
    const processKey = (key: string) => {
      if (key.startsWith("buyer ") || key.startsWith("supplier ")) {
        return key.replace(/^(buyer |supplier )/, "");
      }
      return key;
    };

    // Check if any key in messageForSummary matches the field
    const isFieldInSummary = messageForSummary.some((obj) => {
      const key = Object.keys(obj)[0]; // Assuming each object has one key
      return processKey(key) === field;
    });

    if (activeField === field) {
      return `${baseClass} bg-[#0023EF26]`;
    } else if (selectedFields[selectedOption].includes(field)) {
      return `${baseClass} bg-[#0023EFB2] border-none text-[white]`;
    } else if (isFieldInSummary) {
      // Add additional styling or class if needed when the field matches a key in messageForSummary
      return `${baseClass} additional-class-or-style`;
    } else {
      return baseClass;
    }
  };

  return (
    <button
      className={getButtonClasses(text)}
      onMouseDown={() => setActiveField(text)}
      onMouseUp={() => setActiveField(null)}
      onClick={() =>
        updateSummary(
          text,
          value,
          "",
          fetchingSummary,
          setSelectedFields,
          selectedOption,
          messageForSummary,
          setMessageForSummary
        )
      }
    >
      {text}
    </button>
  );
}

export default OptionsButton;
