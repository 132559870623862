import React, { useState } from "react";
import profileCheckmark from "../../../assets/images/profileCheckmark.svg";
import profileUnchecked from "../../../assets/images/profileUnchecked.svg";
import { deleteAuthorizedDevice } from "../../../utils/api/deleteAuthorizedDevice";
import { getUserDetails } from "../../../utils/api/getUserDetails";
import handleToastError from "../../../utils/utility/handleToastError";

interface LoginHistoryElementProps {
  isTrusted: boolean;
  device: string;
  date: string;
  ipAddress: string;
  setDevices: React.Dispatch<React.SetStateAction<Device[]>>;
}

interface Device {
  id: number;
  isTrusted: boolean;
  device: string;
  date: string;
  ipAddress: string;
}

function LoginHistoryElement({
  isTrusted,
  device,
  date,
  ipAddress,
  setDevices,
}: LoginHistoryElementProps) {
  const handleDelete = async () => {
    try {
      await deleteAuthorizedDevice(ipAddress);
      await handleRefetchDevices();
    } catch (error) {
      console.error("Error deleting device:", error);
    }
  };

  const handleRefetchDevices = async () => {
    const userDetails = await getUserDetails();
    if (!userDetails) {
      handleToastError("Unexpected error");
      return;
    }
    const transformedDevices = userDetails.userDetails.devices.map(
      (device, index) => ({
        id: index,
        isTrusted: device.access === 1,
        device: device.name,
        date: new Date(device.createdAt).toLocaleDateString(),
        ipAddress: device.ipAddress,
      })
    );
    setDevices(transformedDevices);
  };

  return (
    <div className="flex justify-between items-center gap-[5px] sm400:text-[14px] sm500:text-[14px]">
      <div className="group relative w-[20%] flex justify-center items-center">
        <img
          src={isTrusted ? profileCheckmark : profileUnchecked}
          alt={isTrusted ? "Trusted" : "Not Trusted"}
          className="w-[30px] h-[25px]"
        />
        {!isTrusted && (
          <div className="absolute w-56 p-2 left-[140%] transform -translate-x-1/2 bg-black text-white text-xs rounded-lg shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-[1000]">
            Untrusted device - failed login attempt - no code authentication
          </div>
        )}
      </div>

      <div className="w-[30%] bg-profile-background-gradient flex justify-center items-center h-[35px] overflow-hidden">
        <span className="whitespace-nowrap overflow-x-auto overflow-y-hidden scrollbar-width-none scrollbar-hide text-[12px] sm400:text-[14px]">
          {device}
        </span>
      </div>
      <div className="w-[25%] bg-profile-background-gradient flex justify-center items-center h-[35px] overflow-hidden">
        <span className="whitespace-nowrap overflow-x-auto overflow-y-hidden scrollbar-width-none scrollbar-hide text-[12px] sm400:text-[14px]">
          {ipAddress}
        </span>
      </div>
      <div className="w-[20%] bg-profile-background-gradient flex justify-center items-center h-[35px] text-[12px] sm400:text-[14px]">
        <span>{date}</span>
      </div>
      <button
        onClick={handleDelete}
        className="w-[5%] flex justify-center items-center"
      >
        🗑️
      </button>
    </div>
  );
}

export default LoginHistoryElement;
