// ----------------------
// Packages
// ----------------------
import React from "react";

// ----------------------
// Assets
// ----------------------
import { ReactComponent as ChevronLeft } from "../../../assets/images/chevronLeftWhite.svg";
import certificateLock from "../../../assets/images/getCertificateLock.svg";

// ----------------------
// Components
// ----------------------
import CertificatePrice from "./CertificatePrice";

// ----------------------
// Interfaces
// ----------------------
import { Action, ActionType } from "../../../state/interfaces";

interface GetCertificateProps {
  dispatch: React.Dispatch<Action>;
  setBubbleMessageActive: React.Dispatch<React.SetStateAction<boolean>>;
  setBubbleMessageContent: React.Dispatch<React.SetStateAction<string>>;
}

function GetCertificate({
  dispatch,
  setBubbleMessageActive,
  setBubbleMessageContent,
}: GetCertificateProps) {
  const goBack = () => {
    dispatch({
      type: ActionType.SET_GET_CERTIFICATE,
      payload: false,
    });
  };

  const handleBuy = () => {
    setBubbleMessageActive(true);
    setBubbleMessageContent(
      "Choose the fields you want to include in the ZK-Snark proof"
    );
    dispatch({
      type: ActionType.SET_CERTIFICATE_FIELD_CHOICE,
      payload: true,
    });
  };

  return (
    <div className="flex sm:justify-center justify-between items-center gap-[80px] sm900:gap-[120px] md:gap-[160px] rounded-md lg:gap-[200px] max-h-[800px] max-w-[1100px] w-full sm:w-[90%] h-[600px] sm450:h-[700px] sm:h-[450px] md:h-[500px] bg-[#050934] text-[#fff] font-TT border-4 border-[red]flex-shrink-0">
      <div className="flex flex-col items-start w-full sm:w-[260px] md:w-[300px] lg:w-[340px] gap-[20px] px-8 sm:px-0">
        <div className="relative text-[28px] sm:text-[34px] sm400:text-[38px] sm900:text-[40px] md:text-[44px] lg:text-[48px] font-bold">
          <div className="flex items-center gap-[5px]">
            <button onClick={goBack} className="w-[30px] h-[30px] sm:hidden">
              <ChevronLeft className="w-full h-full" />
            </button>
            <p>Get Certificate</p>
          </div>
          <button
            onClick={goBack}
            className="absolute sm:left-[-15%] sm800:left-[-20%] top-[22%] sm900:top-[27%] sm900:left-[-17%] lg:w-[35px] lg:h-[35px] lg:top-[26%] w-[30px] h-[30px] hidden sm:block"
          >
            <ChevronLeft className="w-full h-full" />
          </button>
        </div>
        <p className="text-[18px] sm400:text-[20px] md:text-[24px] lg:text-[26px] mb-[40px]">
          Certificates ensure security and transparency of your intelligent
          contracts.
        </p>

        <div className="flex items-center justify-between sm450:gap-[30px] sm:hidden w-full">
          <CertificatePrice />
          <div className=" w-[130px] sm400:w-[180px] sm450:w-[210px]">
            <img
              src={certificateLock}
              className="w-full h-full"
              alt="Certificate Lock Icon"
            />
          </div>
        </div>

        <button
          onClick={handleBuy}
          className="bg-connect-button w-[100%] sm:w-[170px] h-[70px] lg:w-[200px] lg:h-[80px] lg:text-[24px] text-[20px] mt-[50px] sm:mt-0 font-bold"
        >
          Buy
        </button>
      </div>
      <div className="hidden sm:flex flex-col items-center gap-[30px]">
        <div>
          <img
            src={certificateLock}
            className="w-full h-full"
            alt="Certificate Lock Icon"
          />
        </div>
        <CertificatePrice />
      </div>
    </div>
  );
}

export default GetCertificate;
