// ----------------------
// External/Internal Packages
// ----------------------
import React, { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";

// ----------------------
// Assets
// ----------------------
import { ReactComponent as Success } from "../../assets/images/success.svg";
import { ReactComponent as Error } from "../../assets/images/error.svg";
import { ReactComponent as Outdated } from "../../assets/images/outdated.svg";

// ----------------------
// Interfaces
// ----------------------
import { Action, ActionType, State } from "../../state/interfaces";

interface VerificationResultProps {
  state: State;
  dispatch: React.Dispatch<Action>;
}

function VerificationResult(props: VerificationResultProps) {
  const [isVisible, setIsVisible] = useState(false);
  const [fetchingCompleted, setFetchingCompleted] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setFetchingCompleted(true);
    }, 2000);
  }, []);

  const closeWindow = () => {
    props.dispatch({
      type: ActionType.SET_PROOF_VERIFICATION_ACTIVE,
      payload: false,
    });
  };

  const renderContent = () => {
    let svgComponent, title, description;
    switch (props.state.proofVerificationResult) {
      case "SUCCESS":
        svgComponent = (
          <Success className="absolute w-[100px] sm:w-[160px] sm400:left-[36%] sm450:left-[37%] left-[34%] top-[-20%] sm:left-[34%]" />
        );
        title = "Success!";
        description = props.state.proofOverview;

        break;
      case "OUTDATED":
        svgComponent = (
          <Outdated className="absolute w-[100px] sm:w-[120px] sm400:left-[36%] sm450:left-[37%] left-[33%] top-[-24%] sm:left-[38%]" />
        );
        title = "Outdated!";
        description = "The contract is out of date. Create a new contract...";
        break;
      case "ERROR":
        svgComponent = (
          <Error className="absolute w-[100px] sm:w-[120px] sm400:left-[36%] sm450:left-[37%] left-[34%] top-[-20%] sm:left-[38%]" />
        );
        title = "Error!";
        description =
          "An error occurred while trying to verify the contract. Please verify your proof.";
        break;
      default:
        return null;
    }

    return (
      <div className="w-full h-full flex flex-col justify-between">
        {svgComponent}
        <p
          className={`text-center mt-[40px] sm:mt-[60px] text-[30px] font-bold tracking-wide ${
            props.state.proofVerificationResult === "SUCCESS"
              ? "text-[#3CAD6C]"
              : props.state.proofVerificationResult === "ERROR"
              ? "text-[#E05051]"
              : "text-[#0023EF]"
          }`}
        >
          {title}
        </p>
        <p className="text-center text-[14px] sm:text-[16px] px-[5px] sm:px-[20px] text-[gray] overflow-y-auto max-h-[150px]">
          {description}
        </p>
        <div className="flex items-center justify-center w-full pb-[20px]">
          <button
            onClick={closeWindow}
            className="bg-[#0023EF1A] w-[180px] h-[40px] border-[1px] font-bold text-[#0023EF]"
          >
            OK
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full h-full flex items-center justify-center bg-[#050934] bg-opacity-[75%] fixed z-50">
      <div
        className={`bg-[white] shadow-lg w-[300px] sm400:w-[350px] sm450:w-[400px] sm:w-[480px] ${
          props.state.proofVerificationResult === "ERROR" ||
          props.state.proofVerificationResult === "OUTDATED"
            ? "h-[350px]"
            : "h-[450px]"
        } p-[20px] border-2 shadow-lg rounded-lg flex flex-col gap-[20px] justify-between
    transition-all duration-100 ease-in-out relative transform-gpu ${
      isVisible ? "scale-100" : "scale-[90%]"
    } 
    transition-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);`}
      >
        {fetchingCompleted && renderContent()}
        {!fetchingCompleted && (
          <div className="w-full h-full items-center justify-center flex">
            <ClipLoader color="#7682E6" size={120} />
          </div>
        )}
      </div>
    </div>
  );
}

export default VerificationResult;
