// ----------------------
// Assets
// ----------------------
import { ReactComponent as Checkmark } from "../../../assets/images/checkmarkWhite.svg";
import { ReactComponent as CopyIcon } from "../../../assets/images/copyIconTwo.svg";

// ----------------------
// Utility Functions
// ----------------------
import { copyToClipboard } from "../../../utils/utility/copyToClipboard";
import { handleVerifyProof } from "../utils/handleVerifyProof";
import { useAccount } from "wagmi";

// ----------------------
// Components
// ----------------------
import DownloadButton from "./DownloadButton";

// ----------------------
// Interfaces
// ----------------------
import { Action, ActionType, State } from "../../../state/interfaces";

interface ProofInputProps {
  state: State;
  dispatch: React.Dispatch<Action>;
}

function ProofInput({ state, dispatch }: ProofInputProps) {
  const { address } = useAccount();

  const truncateJsonString = (json: any, length: number) => {
    return `${JSON.stringify(json, null, 2).substring(0, length)}...`;
  };

  const handleVerify = () => {
    dispatch({ type: ActionType.SET_CONTRACT_READER, payload: false });
    dispatch({ type: ActionType.SET_VERIFY_PROOF_ACTIVE, payload: true });
    handleVerifyProof(state.proofJson, dispatch, address);
  };

  return (
    <div className="flex flex-col gap-[10px] w-full">
      <div className="text-[#fff] gap-[10px] relative">
        <div className="hidden sm:block absolute w-[30px] left-[-21%] sm900:left-[-17%] lg:left-[-13%] top-[12%] lg:w-[35px]">
          <Checkmark className="w-full h-full" />
        </div>
        <p className="font-semibold text-[14px] lg:text-[16px]">Proof.json</p>
      </div>
      <div className="border-[1px] bg-[transparent] flex justify-between px-[8px] py-[10px] gap-[20px] items-center text-[#fff] border-[gray]">
        <p className="text-[12px] sm:hidden">
          {truncateJsonString(state.proofJson, 43)}
        </p>
        <p className="text-[12px] hidden sm:block sm900:hidden">
          {truncateJsonString(state.proofJson, 25)}
        </p>
        <p className="text-[12px] hidden sm900:block lg:hidden">
          {truncateJsonString(state.proofJson, 35)}
        </p>
        <p className="text-[12px] hidden lg:block">
          {truncateJsonString(state.proofJson, 50)}
        </p>

        <div
          className="cursor-pointer"
          onClick={(event) =>
            copyToClipboard(event, JSON.stringify(state.proofJson, null, 2))
          }
        >
          <CopyIcon />
        </div>
      </div>
      <div className="flex items-center  gap-[10px]">
        <DownloadButton
          file={JSON.stringify(state.proofJson, null, 2)}
          output="proof.txt"
        />
        <button
          onClick={handleVerify}
          className="text-[#0023EF] font-bold flex items-center justify-center w-36 h-10 px-10 gap-2.5 border border-blue-800/60 bg-blue-800/20 shadow-button rounded-sm flex-shrink-0"
        >
          Verify
        </button>
      </div>
    </div>
  );
}

export default ProofInput;
