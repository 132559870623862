// ----------------------
// External/Internal Packages
// ----------------------
import { useState } from "react";
import { ImCheckmark } from "react-icons/im";

// ----------------------
// Interfaces
// ----------------------
import { Action, ActionType } from "../../../../state/interfaces";

interface ContractBulletPointStringProps {
  iterator: string;
  nameOfBulletPoint: string;
  value: string;
  fieldChoice: boolean;
  dispatch: React.Dispatch<Action>;
}

function ContractBulletPointString(props: ContractBulletPointStringProps) {
  const [fieldChosen, setFieldChosen] = useState(false);

  const handleAddField = () => {
    if (fieldChosen) {
      setFieldChosen(false);
      props.dispatch({
        type: ActionType.REMOVE_CERTIFICATE_FIELDS,
        payload: {
          fieldName: props.nameOfBulletPoint.replace(/ /g, ""),
          value: props.value,
        },
      });
    } else {
      setFieldChosen(true);
      props.dispatch({
        type: ActionType.APPEND_CERTIFICATE_FIELDS,
        payload: {
          fieldName: props.nameOfBulletPoint.replace(/ /g, ""),
          value: props.value,
        },
      });
    }
  };

  return (
    <div
      className={`grid grid-cols-1 md:grid-cols-3 gap-x-8 items-center pb-5 px-10 md:px-20`}
    >
      <div className="flex items-center gap-8">
        <div className="flex flex-col gap-[10px] md:flex-row md:items-center">
          <div className="flex items-center gap-[20px]">
            {props.fieldChoice && (
              <button
                onClick={handleAddField}
                className={`border-2 border-[gray] flex-shrink-0 w-[20px] h-[20px] mt-[2px] flex justify-center items-center ${
                  fieldChosen ? "text-gray-500" : "text-transparent"
                }`}
              >
                {fieldChosen ? <ImCheckmark color="#32a852" /> : null}
              </button>
            )}
            <p className="text-gray-500 font-bold align-middle text-[14px] sm:text-[16px]">
              {props.iterator}
            </p>
            <p className="text-[#7682E6] font-semibold align-middle md:ml-2 text-[14px] sm:text-[16px]">
              {props.nameOfBulletPoint}
            </p>
          </div>
          <div className="flex items-center md:hidden ml-[20px]">
            <p
              className={`text-red-500 ${props.fieldChoice ? "mx-5" : "mx-2"}`}
            >
              .
            </p>
            <p className="text-green-500 text-[14px] sm:text-[16px] font-semibold ml-2 flex-shrink min-w-0 whitespace-normal break-words overflow-wrap break-word">
              {'"' + props.value + '";'}
            </p>
          </div>
        </div>
      </div>
      <div className="hidden md:block">
        <p className="text-red-500 justify-self-center">.</p>
      </div>
      <div className="hidden md:block text-green-500 font-semibold ">
        {'"' + props.value + '";'}
      </div>
    </div>
  );
}

export default ContractBulletPointString;
