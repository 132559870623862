// ----------------------
// Internal/External Packages
// ----------------------
import React from "react";
import { useSpeechRecognition } from "react-speech-recognition";

// ----------------------
// Assets
// ----------------------
import listeningAnimation from "../../../assets/images/listeninganimation.gif";

// ----------------------
// Utility Functions
// ----------------------
import handleSkip from "./inputUtils/handleSkip";

// ----------------------
// Interfaces
// ----------------------
import { Action, State, QueueItem } from "../../../state/interfaces";

interface ListeningButtonProps {
  state: State;
  dispatch: React.Dispatch<Action>;
  messageQueue: React.MutableRefObject<QueueItem[]>;
  currentAudio: React.MutableRefObject<HTMLAudioElement | null>;
}

function ListeningButton({
  state,
  dispatch,
  messageQueue,
  currentAudio,
}: ListeningButtonProps) {
  const { listening, resetTranscript } = useSpeechRecognition();

  const allMessagesDisplayed = state.messages.every(
    (message) => message.messageState === "DISPLAYED"
  );

  function debounce(func: Function, wait: number) {
    let timeout: NodeJS.Timeout;
    return function executedFunction(...args: any[]) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  return (
    <button
      onClick={() =>
        debounce(
          () =>
            handleSkip(
              listening,
              state,
              currentAudio,
              dispatch,
              messageQueue,
              resetTranscript
            ),
          200
        )
      }
      className="flex flex-col justify-center items-center gap-[10px]"
    >
      <img
        src={listeningAnimation}
        className="w-[60px] md:w-[80px]"
        alt="Listening Animation"
      />
      <p className="text-[12px] md:text-[14px] lg:text-[16px] text-transparent bg-clip-text bg-gradient-to-r from-[#0023EF] to-[#77F3E0]">
        {!state.fetchingMessage && allMessagesDisplayed && "I'm listening..."}
        {(state.fetchingMessage || !allMessagesDisplayed) &&
          "Wait for the response..."}
      </p>
    </button>
  );
}

export default ListeningButton;
