import axios from "axios";
import handleToastError from "../utility/handleToastError";

export const updateUserAvatar = async (file: File) => {
  // Define allowed file types
  const allowedFileTypes = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "image/gif",
  ];
  const maxFileSize = 1000000; // Max file size in bytes (1MB)

  try {
    // Check if the file type is allowed
    if (!allowedFileTypes.includes(file.type)) {
      handleToastError(
        "Invalid file type. Only PNG, JPG, JPEG, and GIF files are allowed."
      );
      return;
    }

    // Check if the file size exceeds the limit
    if (file.size > maxFileSize) {
      handleToastError("File size exceeds the 1MB limit.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    const response = await axios.post<{ status: string }>(
      `${process.env.REACT_APP_BASE_URL}/users/avatar`,
      formData,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(error);
    } else {
      console.error("An unexpected error occurred:", error);
      handleToastError("An unexpected error occurred.");
    }
  }
};
