import axios from "axios";
import { Action, ActionType } from "../../../state/interfaces";
import handleToastError from "../../../utils/utility/handleToastError";

export const fetchContractList = async (
  account: string | null | undefined,
  dispatch: React.Dispatch<Action>
) => {
  try {
    // Fetch Service Agreements
    const responseSA = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/transactions/get-contract-list`,
      { owner: account }
    );

    // Fetch NDAs
    const responseNDA = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/transactions/get-all-ndas`,
      { owner: account }
    );

    // Process Service Agreements by inserting "SA" as the second element
    const modifiedSAData = responseSA.data.map((contractArray: any[]) => {
      // Copy the array to avoid mutating the original data
      const modifiedArray = [...contractArray];
      // Insert "SA" after the contractId
      modifiedArray.splice(1, 0, "SA");
      return modifiedArray;
    });

    // Process NDAs with a check for contractId
    const ndaDataArray = responseNDA.data
      .filter((nda: any) => nda.contractId !== undefined) // Only include NDAs with a defined contractId
      .map((nda: any) => [
        nda.contractId.toString(), // Keep contractId as the first element
        nda.contractType,
        nda.partyA,
        nda.partyB,
        nda.creationTimestamp,
      ]);

    // Combine both arrays
    const combinedContractList = [...modifiedSAData, ...ndaDataArray];

    dispatch({
      type: ActionType.SET_CONTRACT_LIST,
      payload: combinedContractList,
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 403) {
        handleToastError(
          "Access denied! Please log out and log back in to solve this issue."
        );
      } else {
        console.error("Error fetching contract list:", error);
      }
    } else {
      console.error("An unexpected error occurred:", error);
    }
  }
};
