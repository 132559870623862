import React, { useEffect } from "react";

export const useHandleDropDown = (
  setDropDown: React.Dispatch<React.SetStateAction<boolean>>,
  dropDownOpen: boolean,
  stringRef: string
) => {
  useEffect(() => {
    if (dropDownOpen) {
      const closeDropdown = (event: MouseEvent) => {
        if (!(event.target as Element).closest(stringRef)) {
          setDropDown(false);
        }
      };
      document.addEventListener("click", closeDropdown);
      return () => document.removeEventListener("click", closeDropdown);
    }
  }, [dropDownOpen]);
};
