import axios from "axios";
import { Action, ActionType } from "../../state/interfaces";

function removeCookie(name: string) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

export const handleLogOut = async (
  dispatch: React.Dispatch<Action>,
  onClose?: () => void
) => {
  try {
    await axios.get(`${process.env.REACT_APP_BASE_URL}/auth/logout`, {
      withCredentials: true,
    });
    removeCookie("connect.sid");
    dispatch({ type: ActionType.SET_WEB2_EMAIL, payload: "" });
    dispatch({ type: ActionType.SET_WEB2_LOGIN, payload: false });
    if (onClose) {
      onClose();
    }
  } catch (error) {
    console.error("Logout failed", error);
  }
};
