// Other packages
import React from "react";

// Context and Interfaces
import { ActionType, State, Action, QueueItem } from "./state/interfaces";

// Components
import Tutorial from "./components/Tutorial/Tutorial";
import Account from "./components/Account/Account";
import ContractReader from "./components/ContractReader/ContractReader";
import Menu from "./components/Menu/Menu";
import ChatBox from "./components/ChatBox/ChatBox";
import Input from "./components/ChatBox/Input/Input";
import ProgressBar from "./components/ProgressBar/Progress/ProgressBar";
import PdfReader from "./components/ProgressBar/Pdf/PdfReader";
import Proof from "./components/Proof/Proof";
import HashWindow from "./components/ContractReader/hashWindow/HashWindow";
import Navbar from "./components/Navbar/Navbar";
import VerificationResult from "./components/Proof/VerificationResult";
import SignUpPage from "./components/AccountCreation/SignUpPage";
import Profile from "./components/Profile/Profile";
import "./index.css";
import LogInPage from "./components/AccountCreation/LogInPage";
import DeleteAccountModal from "./components/Profile/DeleteAccount/DeleteAccountModal";
import ChangePassword from "./components/Profile/ChangePassword/ChangePassword";
import ChangePasswordModal from "./components/Profile/ChangePassword/ChangePasswordModal";
import ResetPasswordConfirmationModal from "./components/AccountCreation/ResetPasswordConfirmationModal";
import ResetPasswordModal from "./components/AccountCreation/ResetPasswordModal";

// Constants

// Function that renders the components based on their state
export function renderBasedOnState(
  active: boolean,
  chainId: number | undefined,
  state: State,
  dispatch: React.Dispatch<Action>,
  messageQueue: React.MutableRefObject<QueueItem[]>,
  currentAudio: React.MutableRefObject<HTMLAudioElement | null>,
  processedMessageIds: React.MutableRefObject<Set<unknown>>,
  navbarRef: React.RefObject<HTMLDivElement>
) {
  return (
    <div className="flex flex-col h-full">
      <Navbar state={state} dispatch={dispatch} navbarRef={navbarRef} />
      {!state.contractReader &&
        state.tutorialActive &&
        renderTutorialState(dispatch, state)}
      {state.logInPageActive && renderLogInState(state, dispatch)}
      {state.accountWindowActive &&
        renderAccountState(dispatch, active, chainId)}
      {state.signUpPageActive && renderSignUpState(state, dispatch, navbarRef)}
      {state.profilePageActive &&
        renderProfileState(state, dispatch, navbarRef)}
      {state.deleteAccountModalActive &&
        renderDeleteAccountModal(state, dispatch, navbarRef)}
      {state.resetPasswordConfirmationModal &&
        renderResetPasswordConfirmationModal(state, dispatch, navbarRef)}
      {state.resetPasswordModal &&
        renderResetPasswordModal(state, dispatch, navbarRef)}
      {state.changePasswordModalActive &&
        renderChangePasswordModal(state, dispatch, navbarRef)}
      {state.proofVerificationActive &&
        renderVerificationResult(state, dispatch)}
      {state.hashWindowActive && renderHashWindow(dispatch, active, state)}
      {state.pdfReader && renderPdfState(dispatch, state)}
      <div className="h-custom-bar flex-1 overflow-y-auto">
        {renderContractReaderState(state, dispatch, navbarRef)}

        {state.verifyProofActive &&
          renderVerifyProofState(state, dispatch, navbarRef)}

        {!state.contractReader &&
          !state.verifyProofActive &&
          renderDefaultState(
            state,
            dispatch,
            messageQueue,
            currentAudio,
            processedMessageIds,
            navbarRef
          )}
      </div>
    </div>
  );
}

// Function that renders the state of contract reader
function renderContractReaderState(
  state: State,
  dispatch: React.Dispatch<Action>,
  navbarRef: React.RefObject<HTMLDivElement>
) {
  if (!state.contractReader) return null;
  return (
    <div className="h-full relative">
      {renderMenu(dispatch, state, navbarRef)}
      <ContractReader dispatch={dispatch} state={state} />
    </div>
  );
}

// Function that renders the state of verify proof
function renderVerifyProofState(
  state: State,
  dispatch: React.Dispatch<Action>,
  navbarRef: React.RefObject<HTMLDivElement>
) {
  return (
    <div className="h-full relative">
      {renderMenu(dispatch, state, navbarRef)}
      <Proof dispatch={dispatch} state={state} />
    </div>
  );
}

// Function that renders the sign up page
function renderSignUpState(
  state: State,
  dispatch: React.Dispatch<Action>,
  navbarRef: React.RefObject<HTMLDivElement>
) {
  return (
    <FullScreenWrapper>
      <SignUpPage dispatch={dispatch} state={state} />
    </FullScreenWrapper>
  );
}
// Function that renders the profile page
function renderProfileState(
  state: State,
  dispatch: React.Dispatch<Action>,
  navbarRef: React.RefObject<HTMLDivElement>
) {
  return (
    <FullScreenWrapper>
      <Profile dispatch={dispatch} state={state} />
    </FullScreenWrapper>
  );
}

// Function that renders the delete account modal in the profile page
function renderDeleteAccountModal(
  state: State,
  dispatch: React.Dispatch<Action>,
  navbarRef: React.RefObject<HTMLDivElement>
) {
  return (
    <FullScreenWrapper>
      <DeleteAccountModal dispatch={dispatch} />
    </FullScreenWrapper>
  );
}

// Function that renders the confirmation modal for the reset password action
function renderResetPasswordConfirmationModal(
  state: State,
  dispatch: React.Dispatch<Action>,
  navbarRef: React.RefObject<HTMLDivElement>
) {
  return (
    <FullScreenWrapper>
      <ResetPasswordConfirmationModal dispatch={dispatch} state={state} />
    </FullScreenWrapper>
  );
}

// Function that renders the modal for resetting password
function renderResetPasswordModal(
  state: State,
  dispatch: React.Dispatch<Action>,
  navbarRef: React.RefObject<HTMLDivElement>
) {
  return (
    <FullScreenWrapper>
      <ResetPasswordModal dispatch={dispatch} />
    </FullScreenWrapper>
  );
}

// Function that renders the delete account modal in the profile page
function renderChangePasswordModal(
  state: State,
  dispatch: React.Dispatch<Action>,
  navbarRef: React.RefObject<HTMLDivElement>
) {
  return (
    <FullScreenWrapper>
      <ChangePasswordModal dispatch={dispatch} />
    </FullScreenWrapper>
  );
}

// Function that renders the log in page
function renderLogInState(state: State, dispatch: React.Dispatch<Action>) {
  return (
    <FullScreenWrapper>
      <LogInPage dispatch={dispatch} state={state} />
    </FullScreenWrapper>
  );
}

// Function that renders the state of menu
function renderMenu(
  dispatch: React.Dispatch<Action>,
  state: State,
  navbarRef: React.RefObject<HTMLDivElement>
) {
  return (
    <Menu
      isOpen={state.hamburgerMenuOpen}
      onClose={() =>
        dispatch({
          type: ActionType.SET_HAMBURGER_OPEN,
          payload: false,
        })
      }
      dispatch={dispatch}
      state={state}
      navbarRef={navbarRef}
    />
  );
}
// Function that renders the state of tutorial
function renderTutorialState(dispatch: React.Dispatch<Action>, state: State) {
  return (
    <FullScreenWrapper>
      <Tutorial dispatch={dispatch} state={state} />
    </FullScreenWrapper>
  );
}

// Function that renders the state of verification result
function renderVerificationResult(
  state: State,
  dispatch: React.Dispatch<Action>
) {
  return (
    <FullScreenWrapper>
      <VerificationResult state={state} dispatch={dispatch} />
    </FullScreenWrapper>
  );
}

// Wrapper for other components
function FullScreenWrapper({ children }: { children: React.ReactNode }) {
  return <div className="h-screen w-screen absolute">{children}</div>;
}

// Function that renders the state of pdf
function renderPdfState(dispatch: React.Dispatch<Action>, state: State) {
  return (
    <FullScreenWrapper>
      <PdfReader dispatch={dispatch} state={state} />
    </FullScreenWrapper>
  );
}
// Function that renders the state of account window
function renderAccountState(
  dispatch: React.Dispatch<Action>,
  active: boolean,
  chainId: number | undefined
) {
  if (active) {
    return (
      <FullScreenWrapper>
        <Account dispatch={dispatch} />
      </FullScreenWrapper>
    );
  }
  return null;
}

// Function that renders the state of hash window
function renderHashWindow(
  dispatch: React.Dispatch<Action>,
  active: boolean,
  state: State
) {
  if (active) {
    return (
      <FullScreenWrapper>
        <HashWindow dispatch={dispatch} state={state} />
      </FullScreenWrapper>
    );
  }
  return null;
}

// Function that renders the default state of the application
function renderDefaultState(
  state: State,
  dispatch: React.Dispatch<Action>,
  messageQueue: React.MutableRefObject<QueueItem[]>,
  currentAudio: React.MutableRefObject<HTMLAudioElement | null>,
  processedMessageIds: React.MutableRefObject<Set<unknown>>,
  navbarRef: React.RefObject<HTMLDivElement>
) {
  return (
    <div className="h-full w-full relative">
      <Menu
        isOpen={state.hamburgerMenuOpen}
        onClose={() =>
          dispatch({
            type: ActionType.SET_HAMBURGER_OPEN,
            payload: false,
          })
        }
        dispatch={dispatch}
        state={state}
        navbarRef={navbarRef}
      />
      <div
        className={`w-full flex h-full overflow-y-auto ${
          state.hamburgerMenuOpen ? "dimmed" : ""
        }`}
      >
        {state.hamburgerMenuOpen && <div className="dim-overlay" />}
        <div
          className={`flex flex-1 justify-between flex-col h-full w-custom sm:w-[65%] bg-[#F7F8FD]`}
        >
          <ChatBox
            messageQueue={messageQueue}
            currentAudio={currentAudio}
            processedMessageIds={processedMessageIds}
            dispatch={dispatch}
            state={state}
          />
          <div className="h-[150px] flex-shrink-0 w-full flex items-center justify-center pb-[20px]">
            <Input
              messageQueue={messageQueue}
              currentAudio={currentAudio}
              dispatch={dispatch}
              state={state}
            />
          </div>
        </div>
        <div className="hidden sm:block sm:w-[35%] lg:w-[450px] h-custom-bar">
          <ProgressBar state={state} dispatch={dispatch} />
        </div>
      </div>
    </div>
  );
}
