import axios from "axios";
import handleToastError from "../utility/handleToastError";

export const updateUserWallet = async (walletAddress: string) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/users/data`,
      {
        walletAddress,
      },
      {
        withCredentials: true,
      }
    );
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 403) {
        handleToastError(
          "Access denied! Please log out and log back in to solve this issue."
        );
      } else {
        console.error("Failed to update user wallet:", error);
      }
    } else {
      console.error("An unexpected error occurred:", error);
    }
  }
};
