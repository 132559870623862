import React, { useState, useEffect } from "react";
import { Action, ActionType } from "../../../state/interfaces";
import crossIcon from "../../../assets/images/cross.svg";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import handleToastError from "../../../utils/utility/handleToastError";
import { handleChangePassword } from "../../../utils/api/handleChangePassword";

interface ChangePasswordModalProps {
  dispatch: React.Dispatch<Action>;
}

function ChangePasswordModal({ dispatch }: ChangePasswordModalProps) {
  const [isVisible, setIsVisible] = useState(true);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  const handleCloseModal = () => {
    dispatch({
      type: ActionType.SET_CHANGE_PASSWORD_MODAL_ACTIVE,
      payload: false,
    });
  };

  useEffect(() => {
    const handleVisibility = () => {
      setIsVisible(true);
    };

    handleVisibility();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      handleToastError("The new passwords do not match. Please try again.");
      return;
    }
    if (newPassword === "" || confirmNewPassword === "" || oldPassword === "") {
      handleToastError("Password cannot be empty!");
      return;
    }
    if (newPassword === oldPassword) {
      handleToastError("New password can't be the same as the new one!");
      return;
    }
    await handleChangePassword(
      dispatch,
      newPassword,
      oldPassword,
      setOldPassword,
      setNewPassword,
      setConfirmNewPassword
    );
  };

  const toggleShowPassword = (
    toggleFunction: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    toggleFunction((prevState) => !prevState);
  };

  return (
    <div className="w-full h-full flex items-center justify-center bg-[#050934] bg-opacity-[75%] fixed z-[91] font-TT tracking-wide">
      <form
        className={`bg-white w-[500px] border-2 shadow-lg rounded-[6px] flex flex-col justify-between max-h-[80%] overflow-y-auto transition-all duration-100 ease-in-out transform-gpu ${
          isVisible ? "scale-100" : "scale-90"
        }`}
        onSubmit={handleSubmit}
      >
        <div className="flex items-center justify-between bg-gray-200 px-8 py-4">
          <h3 className="text-lg font-semibold">Change Password</h3>
          <button type="button" onClick={handleCloseModal}>
            <img src={crossIcon} alt="Close" className="w-5 h-5" />
          </button>
        </div>
        <div className="px-8 py-8 flex flex-col gap-6">
          <div className="relative">
            <input
              type={showOldPassword ? "text" : "password"}
              placeholder="Current Password"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              required
              autoComplete="new-password"
            />
            <div
              className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
              onClick={() => toggleShowPassword(setShowOldPassword)}
            >
              {showOldPassword ? (
                <AiOutlineEye className="cursor-pointer" />
              ) : (
                <AiOutlineEyeInvisible className="cursor-pointer" />
              )}
            </div>
          </div>
          <div className="relative">
            <input
              type={showNewPassword ? "text" : "password"}
              placeholder="New Password"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              autoComplete="new-password"
            />
            <div
              className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
              onClick={() => toggleShowPassword(setShowNewPassword)}
            >
              {showNewPassword ? (
                <AiOutlineEye className="cursor-pointer" />
              ) : (
                <AiOutlineEyeInvisible className="cursor-pointer" />
              )}
            </div>
          </div>
          <div className="relative">
            <input
              type={showConfirmNewPassword ? "text" : "password"}
              placeholder="Confirm New Password"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              required
              autoComplete="new-password"
            />
            <div
              className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
              onClick={() => toggleShowPassword(setShowConfirmNewPassword)}
            >
              {showConfirmNewPassword ? (
                <AiOutlineEye className="cursor-pointer" />
              ) : (
                <AiOutlineEyeInvisible className="cursor-pointer" />
              )}
            </div>
          </div>
          <button
            type="submit"
            className="px-6 py-2 bg-blue-600 text-white font-bold rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Change Password
          </button>
        </div>
      </form>
    </div>
  );
}

export default ChangePasswordModal;
