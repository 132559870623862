import { useState, useEffect } from "react";

export const useHandleResize = (
  breakpoint: number,
  smallLabel: string,
  defaultLabel: string
) => {
  const [size, setSize] = useState(
    typeof window !== "undefined" && window.innerWidth < breakpoint
      ? smallLabel
      : defaultLabel
  );

  useEffect(() => {
    const handleResize = () => {
      setSize(window.innerWidth < breakpoint ? smallLabel : defaultLabel);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [breakpoint, smallLabel, defaultLabel]);

  return size;
};
