// ----------------------
// External/Internal Packages
// ----------------------
import React from "react";

// ----------------------
// Utility Functions
// ----------------------
import { handleUserMessage } from "../../utils/sockets/handleUserMessage";
import { handleVoiceSkip } from "../../utils/dialog/handleVoiceSkip";
import { useAccount } from "wagmi";

// ----------------------
// Interfaces
// ----------------------
import { State, Message, Action, QueueItem } from "../../state/interfaces";

interface ChatButtonProps {
  buttonText: string;
  messageId: string;
  state: State;
  dispatch: React.Dispatch<Action>;
  messageQueue: React.MutableRefObject<QueueItem[]>;
  currentAudio: React.MutableRefObject<HTMLAudioElement | null>;
  value: any;
}

function ChatButton(props: ChatButtonProps) {
  const { address } = useAccount();

  const handleButtonClick = async () => {
    const isLastMessage = checkIfLastMessage(
      props.state.messages,
      props.messageId
    );

    if (isLastMessage) {
      handleUserMessage(
        props.buttonText,
        props.dispatch,
        props.state,
        address,
        props.value
      );
      handleVoiceSkip(props.messageQueue, props.currentAudio, props.dispatch);
    }
  };

  const checkIfLastMessage = (
    messages: Message[],
    messageId: string
  ): boolean => {
    if (messages.length === 0) {
      return false;
    }

    const lastMessage = messages[messages.length - 1];
    return lastMessage.id === messageId;
  };

  return (
    <button
      onClick={handleButtonClick}
      className="rounded-sm bg-gradient-button hover:bg-gradient-button-hover text-[#ffff] font-bold text-[12px] md:text-[14px] py-[10px] bg-[#0023EF]  px-[20px]"
    >
      {props.buttonText}
    </button>
  );
}

export default React.memo(ChatButton);
