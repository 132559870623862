// ----------------------
// External/Internal Packages
// ----------------------
import { BiError } from "react-icons/bi";

// ----------------------
// Interfaces and styling
// ----------------------
import { Message } from "../../state/interfaces";
import "./chatbox.css";

interface UserChatProps {
  text: string | string[];
  message?: Message;
  values?: any[];
}

function UserChat(props: UserChatProps) {
  return (
    <div className="flex w-full items-center justify-end my-[20px] md:my-0">
      <div
        className={`relative ${
          props.message?.messageTooLong ? "error-gradient" : "user-gradient"
        } p-4 rounded-lg shadow-md inline-block max-w-[70vw] sm:max-w-[40vw] relative`}
      >
        <p
          className={`font-semibold text-[16px] tracking-wide ${
            props.message?.messageTooLong
              ? "error-gradient-text"
              : "user-gradient-text"
          }`}
          style={{ wordWrap: "break-word" }}
        >
          {props.text}
        </p>
        {props.message?.messageTooLong && (
          <div className="absolute left-[-10%] md:left-[-8%] lg:left-[-5%] text-[18px] top-[50%] text-[red]">
            <BiError />
          </div>
        )}
      </div>
    </div>
  );
}

export default UserChat;
