// ----------------------
// Interfaces
// ----------------------
import { State } from "../../state/interfaces";

interface ApplicationNameProps {
  state: State;
}

function ApplicationName({ state }: ApplicationNameProps) {
  const { contractReader, verifyProofActive } = state;

  let content = null;
  if (contractReader && !verifyProofActive) {
    content = "Intelligent Contract Reader";
  } else if (!contractReader && verifyProofActive) {
    content = "Proof Verifier";
  }

  return (
    <div
      className={`ml-auto ${
        state.verifyProofActive ? "lg:mr-[30px]" : "lg:mr-[200px]"
      } hidden md:block font-bold tracking-wide`}
    >
      {content}
    </div>
  );
}

export default ApplicationName;
