import React from "react";
import { FiDownload } from "react-icons/fi";

interface UnifiedTransaction {
  type: "payback" | "vaiPoints";
  date: string;
  amount: string;
  address?: string;
  transactionHash?: string;
  blockNumber?: number;
  fromCollection?: string;
  fromId?: string;
}

interface TransactionDownloadHistoryMobileProps {
  transactions: UnifiedTransaction[];
}

function TransactionDownloadHistoryMobile({
  transactions,
}: TransactionDownloadHistoryMobileProps) {
  const formatDate = (isoDateString: string): string => {
    const alreadyFormattedPattern = /^\d{2}\.\d{2}\.\d{4}$/;
    if (alreadyFormattedPattern.test(isoDateString)) {
      return isoDateString;
    }

    const date = new Date(isoDateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };
  const handleDownload = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      [
        "Type,Date,Amount", // Adjusted CSV header
      ]
        .concat(
          transactions.map((transaction) => {
            // Adjust type to be user-friendly
            const type =
              transaction.type === "vaiPoints" ? "vai points" : "payback";
            return `${type},${formatDate(transaction.date)},${
              transaction.amount
            }`;
          })
        )
        .join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "transactions.csv");
    document.body.appendChild(link); // Required for FF
    link.click(); // This will download the data file named "transactions.csv".
    document.body.removeChild(link); // Clean up
  };

  return (
    <button
      onClick={handleDownload}
      className="text-blue-500 items-center gap-2 cursor-pointer flex md:hidden w-full justify-end"
    >
      <span>Download History</span>
      <FiDownload />
    </button>
  );
}

export default TransactionDownloadHistoryMobile;
