// ----------------------
// External/Internal Packages
// ----------------------
import React from "react";

// ----------------------
// Components
// ----------------------
import ContractBulletPointString from "./ContractBulletPointString";

// ----------------------
// Interfaces
// ----------------------
import { Action } from "../../../../state/interfaces";

export const RenderStrings = (
  party: string,
  iteratorStart: number,
  dispatch: React.Dispatch<Action>,
  fieldChoice: boolean
) => {
  const partyType = party[0] === "buyer" ? "buyer" : "supplier";

  const partyFields = [
    "Full Name",
    "Street Name",
    "Street Number",
    "Postal Code",
    "City",
    "Country",
    "Signatory Name",
  ];

  const getBulletPointName = (partyType: string, fieldName: string) => {
    return `${partyType.toLowerCase()} ${fieldName}`;
  };

  return partyFields.map((field, index) => {
    const iterator = iteratorStart + index;
    return (
      <ContractBulletPointString
        key={iterator}
        iterator={iterator.toString()}
        nameOfBulletPoint={getBulletPointName(partyType, field)}
        value={party[index + 2]}
        fieldChoice={fieldChoice}
        dispatch={dispatch}
      />
    );
  });
};
