// ----------------------
// External/Internal Packages
// ----------------------
import React, { useState } from "react";
import { useAccount } from "wagmi";

// ----------------------
// Components
// ----------------------
import ContractBulletPointList from "./BulletPoints/ContractBulletPointList";
import ContractBulletPointString from "./BulletPoints/ContractBulletPointString";
import ContractBulletPointJson from "./BulletPoints/ContractBulletPointJson";
import ShowCertificate from "./Buttons/ShowCertificate";
import DownloadAgreement from "./Buttons/DownloadAgreement";
import BuyCertificate from "./Buttons/BuyCertificate";
import NextButton from "./SnarkFieldChoice/NextButton";
import ChevronLeft from "./Buttons/ChevronLeft";
import { RenderStrings } from "./BulletPoints/RenderStrings";
import SkipVaiot from "./SkipVaiot";

// ----------------------
// Utility Functions
// ----------------------
import useFetchAllCertificates from "../utils/useFetchAllCertificates";
import useFetchAgreementUrl from "../utils/useFetchAgreementUrl";

// ----------------------
// Interfaces.f
// ----------------------
import { Action, State } from "../../../state/interfaces";

interface ContractTerms {
  agreementTerms: Array<any>;
  parties: Array<string>;
  services: Array<Array<string>>;
  onBlockchain: string;
}

interface ContractInfoNDA {
  creationTimestamp: string;
  fieldNames: string[];
  fieldValues: string[];
  partyA: string;
  partyB: string;
}

interface ContractDecodedProps {
  setBubbleMessageActive: React.Dispatch<React.SetStateAction<boolean>>;
  setBubbleMessageContent: React.Dispatch<React.SetStateAction<string>>;
  contractInfoSA: ContractTerms | undefined;
  dispatch: React.Dispatch<Action>;
  state: State;
  contractId: number;
  fieldChoice: boolean;

  setContractInfoSA: React.Dispatch<
    React.SetStateAction<ContractTerms | undefined>
  >;
  contractInfoNDA: ContractInfoNDA | undefined;
  setContractInfoNDA: React.Dispatch<
    React.SetStateAction<ContractInfoNDA | undefined>
  >;
  typeOfChosenContract: "SA" | "NDA" | "PoAG" | "PoAS" | "";
}

function ContractDecoded({
  setBubbleMessageActive,
  setBubbleMessageContent,
  contractId,
  contractInfoSA,
  dispatch,
  state,
  fieldChoice,
  setContractInfoSA,
  contractInfoNDA,
  setContractInfoNDA,
  typeOfChosenContract,
}: ContractDecodedProps) {
  const [agreementUrl, setAgreementUrl] = useState("");
  const [popupActive, setPopupActive] = useState(true);
  const { address } = useAccount();

  useFetchAgreementUrl(state, address, contractId, setAgreementUrl);

  useFetchAllCertificates(
    state,
    contractId,
    dispatch,
    address,
    typeOfChosenContract
  );

  const renderNDAInfo = () => {
    if (contractInfoNDA !== undefined) {
      const bulletPoints = [
        { name: "Creation Date", value: contractInfoNDA.creationTimestamp },
        { name: "Party A", value: contractInfoNDA.partyA },
        { name: "Party B", value: contractInfoNDA.partyB },
      ];

      contractInfoNDA.fieldNames.forEach((fieldName, index) => {
        bulletPoints.push({
          name: fieldName,
          value: contractInfoNDA.fieldValues[index],
        });
      });

      // Render bullet points
      return bulletPoints.map((bullet, index) => (
        <ContractBulletPointString
          key={index}
          iterator={String(index + 1)}
          nameOfBulletPoint={bullet.name}
          value={bullet.value}
          fieldChoice={fieldChoice}
          dispatch={dispatch}
        />
      ));
    }
  };

  return (
    <div className="w-full h-full items-center flex flex-col justify-between md:justify-start md:gap-[20px] relative overflow-y-auto">
      {popupActive && fieldChoice && (
        <div className="fixed w-full h-full bg-opacity-75 bg-[#050934] z-50">
          <SkipVaiot
            setPopupActive={setPopupActive}
            text="Choose the terms you wish to create a ZK-Snark proof for."
          />
        </div>
      )}
      <div className="flex items-center h-[70%] w-full md:w-[90%] gap-[40px] md:ml-[-80px]  ">
        <div className="hidden md:block">
          <ChevronLeft
            setBubbleMessageActive={setBubbleMessageActive}
            setBubbleMessageContent={setBubbleMessageContent}
            fieldChoice={fieldChoice}
            dispatch={dispatch}
            color="black"
            setContractInfoSA={setContractInfoSA}
            setContractInfoNDA={setContractInfoNDA}
          />
        </div>

        <div className="bg-[#050934] w-full h-full overflow-y-auto relative ">
          <div>
            <div className="font-TT border-b-[#CECDDA] border-b-[1px] border-opacity-50 font-semibold px-[30px] py-[20px] text-[#CECDDA] tracking-wider text-[18px] flex items-center gap-[15px]">
              <div className="md:hidden">
                <ChevronLeft
                  setBubbleMessageActive={setBubbleMessageActive}
                  setBubbleMessageContent={setBubbleMessageContent}
                  fieldChoice={fieldChoice}
                  dispatch={dispatch}
                  color="white"
                  setContractInfoSA={setContractInfoSA}
                  setContractInfoNDA={setContractInfoNDA}
                />
              </div>
              <p>Smart Contract Content</p>
            </div>
          </div>
          {contractInfoSA !== undefined && (
            <div className="flex flex-col pt-5 h-[70%]">
              <ContractBulletPointString
                iterator="1"
                nameOfBulletPoint="creation Date"
                value={contractInfoSA.agreementTerms[2]}
                fieldChoice={fieldChoice}
                dispatch={dispatch}
              />
              <ContractBulletPointString
                iterator="2"
                nameOfBulletPoint="effective Date"
                value={contractInfoSA.agreementTerms[1]}
                fieldChoice={fieldChoice}
                dispatch={dispatch}
              />
              {RenderStrings(
                contractInfoSA.parties[0],
                3,
                dispatch,
                fieldChoice
              )}
              {RenderStrings(
                contractInfoSA.parties[1],
                10,
                dispatch,
                fieldChoice
              )}

              <ContractBulletPointList
                iterator="15"
                nameOfBulletPoint="services"
                services={contractInfoSA.services as Array<[string, string[]]>}
                fieldChoice={fieldChoice}
                dispatch={dispatch}
              />
              <ContractBulletPointJson
                iterator="16"
                nameOfBulletPoint="agreementTerms"
                dispatch={dispatch}
                fieldChoice={fieldChoice}
                rawAgreementTerms={contractInfoSA.agreementTerms[3]}
              />
            </div>
          )}
          {contractInfoNDA !== undefined && (
            <div className="flex flex-col pt-5 h-[70%]">
              {contractInfoNDA && renderNDAInfo()}
            </div>
          )}
        </div>
      </div>
      {!state.certificateFieldChoice ? (
        <div className="flex flex-col justify-center items-center md:flex-row gap-[20px] py-[40px] flex-shrink-0 w-full px-[20px]">
          <div className="flex flex-col md:flex-row gap-[10px] items-center w-full justify-center md:gap-[50px]">
            {!state.certificatePaid ? (
              <BuyCertificate
                setBubbleMessageActive={setBubbleMessageActive}
                setBubbleMessageContent={setBubbleMessageContent}
                dispatch={dispatch}
              />
            ) : (
              <ShowCertificate
                setBubbleMessageActive={setBubbleMessageActive}
                setBubbleMessageContent={setBubbleMessageContent}
                dispatch={dispatch}
              />
            )}

            <DownloadAgreement
              setBubbleMessageActive={setBubbleMessageActive}
              setBubbleMessageContent={setBubbleMessageContent}
              agreementUrl={agreementUrl}
            />
          </div>
        </div>
      ) : (
        <div className="py-[40px] px-[10px] md:px-0 flex items-center justify-center w-full max-w-[400px]">
          <NextButton
            setBubbleMessageActive={setBubbleMessageActive}
            setBubbleMessageContent={setBubbleMessageContent}
            dispatch={dispatch}
            state={state}
          />
        </div>
      )}
    </div>
  );
}

export default ContractDecoded;
