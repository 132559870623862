import axios from "axios";
import { Action, ActionType } from "../../state/interfaces";
import handleToastError from "../utility/handleToastError";

export const deleteUserAvatar = async (dispatch: React.Dispatch<Action>) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/users/avatar`,
      {
        withCredentials: true,
      }
    );
    dispatch({ type: ActionType.SET_USER_AVATAR, payload: "" });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 403) {
        handleToastError(
          "Access denied! Please log out and log back in to solve this issue."
        );
      } else {
        console.error("Unexpected error:", error);
      }
    } else {
      console.error("An unexpected error occurred:", error);
    }
  }
};
