export function calculateNumber(numArray: number[]): number {
  let total = 0;
  let currentNumber = 0;

  numArray.forEach((num, idx) => {
    if (num < 100) {
      currentNumber += num;
    } else {
      currentNumber *= num;
      if (idx === numArray.length - 1 || numArray[idx + 1] < 100) {
        total += currentNumber;
        currentNumber = 0;
      }
    }
  });

  total += currentNumber;

  return total;
}
