import { useState } from "react";

// ----------------------
// Components
// ----------------------
import OptionsButton from "./OptionsButton";

// ----------------------
// Interfaces
// ----------------------
import { ContractTerms, OptionType } from "../../../state/interfaces";

type RenderButtonsProps = {
  fields: {
    text: string;
    value: any;
  }[];
  selectedOption: OptionType;
  contractInfo: ContractTerms | undefined;
  fetchingSummary: boolean;
  messageForSummary: any[];
  setMessageForSummary: React.Dispatch<React.SetStateAction<any[]>>;
  selectedFields: {
    [key: string]: string[];
    Supplier: string[];
    Buyer: string[];
    General: string[];
  };
  setSelectedFields: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string[];
      Supplier: string[];
      Buyer: string[];
      General: string[];
    }>
  >;
};

export function renderButtons({
  fields,
  selectedOption,
  contractInfo,
  fetchingSummary,
  messageForSummary,
  setMessageForSummary,
  selectedFields,
  setSelectedFields,
}: RenderButtonsProps) {
  return fields.map((field) => (
    <OptionsButton
      key={field.text}
      text={field.text}
      selectedOption={selectedOption}
      contractInfo={contractInfo}
      fetchingSummary={fetchingSummary}
      messageForSummary={messageForSummary}
      setMessageForSummary={setMessageForSummary}
      value={field.value}
      selectedFields={selectedFields}
      setSelectedFields={setSelectedFields}
    />
  ));
}
