import axios from "axios";
import handleToastError from "../utility/handleToastError";

export const checkIfWalletIsRegistered = async (wallet: string) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/users/wallet-exist/${wallet}`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 403) {
        handleToastError(
          "Access denied! Please log out and log back in to solve this issue."
        );
      } else {
        console.error("Error when checking if wallet is registered:", error);
      }
    } else {
      console.error("An unexpected error occurred:", error);
    }
  }
};
