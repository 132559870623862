// ----------------------
// Packages
// ----------------------
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

// ----------------------
// Assets
// ----------------------
import chevronLeft from "../../../../../assets/images/chevronLeft.svg";

// ----------------------
// Components
// ----------------------
import PaybackMethodChosen from "./PaybackMethodChosen";
import DefaultPayback from "./DefaultPayback";
import PaybackDropDown from "./PaybackDropDown";

interface PaymentPaybackDropDownProps {
  dropdownOpen: boolean;
  setDropDownOpen: React.Dispatch<React.SetStateAction<boolean>>;
  methodChosen: string;
  setMethodChosen: React.Dispatch<React.SetStateAction<string>>;
}

function PaymentPaybackDropDown({
  dropdownOpen,
  setDropDownOpen,
  methodChosen,
  setMethodChosen,
}: PaymentPaybackDropDownProps) {
  const handleDropDown = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setDropDownOpen(!dropdownOpen);
  };

  return (
    <div className="flex gap-[5px] items-center ml-[-10px] sm:ml-0">
      <LazyLoadImage
        alt="Chevron left"
        effect="blur"
        src={chevronLeft}
        className="hidden sm:block sm900:hidden"
      />

      <div className="flex gap-4 items-center">
        <div
          className="bg-payment-background h-[25px] w-[120px] sm400:w-[150px] lg:w-[200px] relative cursor-pointer first-dropdown flex items-center justify-center"
          onClick={(event) => handleDropDown(event)}
        >
          {methodChosen === "" ? (
            <DefaultPayback dropdownOpen={dropdownOpen} />
          ) : (
            <PaybackMethodChosen
              dropdownOpen={dropdownOpen}
              methodChosen={methodChosen}
            />
          )}

          {dropdownOpen && (
            <PaybackDropDown setMethodChosen={setMethodChosen} />
          )}
        </div>
      </div>
    </div>
  );
}

export default PaymentPaybackDropDown;
