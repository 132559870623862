import { useEffect } from "react";
import { State, ActionType, Action } from "../../../state/interfaces";
import { fetchContractById } from "./fetchContractById";
import { checkIfCertificateIsPaid } from "./checkIfCertificateIsPaid";

interface ContractInfoNDA {
  creationTimestamp: string;
  fieldNames: string[];
  fieldValues: string[];
  partyA: string;
  partyB: string;
}

function useFetchAndCheckContract(
  state: State,
  account: string | null | undefined,
  idOfChosenContract: string,
  dispatch: React.Dispatch<Action>,
  active: boolean,
  setContractInfo: React.Dispatch<React.SetStateAction<any>>,
  typeOfChosenContract: string,
  setContractInfoNDA: React.Dispatch<
    React.SetStateAction<ContractInfoNDA | undefined>
  >
) {
  useEffect(() => {
    if (state.contractChosen && active && idOfChosenContract !== "") {
      fetchContractById(
        idOfChosenContract,
        account,
        setContractInfo,
        typeOfChosenContract,
        setContractInfoNDA
      );
      checkIfCertificateIsPaid(
        state,
        account,
        idOfChosenContract,
        dispatch,
        typeOfChosenContract
      );

      const timeoutId = setTimeout(() => {
        dispatch({
          type: ActionType.SET_DECODING_ANIMATION_FINISHED,
          payload: true,
        });
      }, 5000);

      // Clean up function in case the component unmounts before the timeout finishes
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [
    state.contractChosen,
    state.getCertificate,
    account,
    idOfChosenContract,
    active,
    dispatch,
    setContractInfo,
  ]);
}

export default useFetchAndCheckContract;
