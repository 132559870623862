// ----------------------
// Packages
// ----------------------
import React, { Fragment, useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { getUserDetails } from "../../utils/api/getUserDetails";

// ----------------------
// Components
// ----------------------
import ConnectionScreen from "./ConnectionScreen/ConnectionScreen";
import ContractDecoded from "./DecodedContract/ContractDecoded";
import ContractList from "./ContractList/ContractList";
import DecodingAnimation from "./DecodedContract/DecodingAnimation";
import FetchingContractListAnimation from "./ContractList/FetchingContractListAnimation";
import ListOfCertificates from "./CertificateList/ListOfCertificates";
import GetCertificate from "./GetCertificate/GetCertificate";
import ContractSummary from "./ContractSummary/ContractSummary";
import PaymentMethod from "./Payment/PaymentMethods/PaymentMethod";
import CertificateCreation from "./certificate/CertificateCreation";
import DownloadCertificate from "./DownloadCertificate/DownloadCertificate";

// ----------------------
// Assets
// ----------------------
import vaiotBubble from "../../assets/images/vaiotBubble.svg";

// ----------------------
// Interfaces
// ----------------------
import { State, Action } from "../../state/interfaces";
import "./renderer.css";
import CertificateError from "./CertificateList/CertificateError";
import ConnectionScreenNoWalletLinked from "./ConnectionScreen/ConnectionScreenNoWalletLinked";
import ConnectionScreenNoWeb3 from "./ConnectionScreen/ConnectionScreenNoWeb3";
import { checkIfWalletIsRegistered } from "../../utils/api/checkIfWalletIsRegistered";
import ConnectionScreenWalletRegistered from "./ConnectionScreen/ConnectionScreenWalletRegistered";
import DecodedContractError from "./DecodedContract/DecodedContractError";
import ConnectionScreenAnimation from "./ConnectionScreen/ConnectionScreenAnimation";
import handleToastError from "../../utils/utility/handleToastError";

interface ContractTerms {
  agreementTerms: Array<any>;
  parties: Array<string>;
  services: Array<Array<string>>;
  onBlockchain: string;
}

interface ContractInfoNDA {
  creationTimestamp: string;
  fieldNames: string[];
  fieldValues: string[];
  partyA: string;
  partyB: string;
}

export function RenderContractReader(
  state: State,
  dispatch: React.Dispatch<Action>,
  idOfChosenContract: string,
  contractInfoSA: ContractTerms | undefined,

  contractInfoNDA: ContractInfoNDA | undefined,
  setContractInfoSA: React.Dispatch<
    React.SetStateAction<ContractTerms | undefined>
  >,
  setContractInfoNDA: React.Dispatch<
    React.SetStateAction<ContractInfoNDA | undefined>
  >,
  typeOfChosenContract: "SA" | "NDA" | "PoAG" | "PoAS" | ""
) {
  const { address } = useAccount();
  const isActive = address !== undefined;

  const [bubbleMessageActive, setBubbleMessageActive] = useState(false);

  const [bubbleMessageContent, setBubbleMessageContent] = useState("");

  const [certificateContractAddress, setCertificateContractAddress] =
    useState("");

  const [chainChosen, setChainChosen] = useState("");

  const [walletAddress, setWalletAddress] = useState("");

  const [walletRegistered, setWalletRegistered] = useState(false);

  const [finishedFetchWallet, setFinishedFetchWallet] = useState(false);

  const [finishedCheckIfWalletRegistered, setFinishedCheckIfWalletRegistered] =
    useState(false);

  const [minimumAnimationTimePassed, setMinimumAnimationTimePassed] =
    useState(false); // flag checking if contract reader animation passed 2 seconds

  useEffect(() => {
    const fetchWallet = async () => {
      if (state.web2Login) {
        const response = await getUserDetails();
        if (!response) {
          handleToastError("Unexpected error!");
          return;
        }
        setWalletAddress(response.userDetails.walletAddress);
        setFinishedFetchWallet(true);
      }
    };
    fetchWallet();
  }, [state.web2Login]);

  useEffect(() => {
    if (address !== undefined) {
      const checkIfWalletRegistered = async () => {
        const response = await checkIfWalletIsRegistered(address);
        setWalletRegistered(response);
        setFinishedCheckIfWalletRegistered(true);
      };
      checkIfWalletRegistered();
    }
  }, [address]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setMinimumAnimationTimePassed(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="custom-height w-[100vw]">
      {((address !== undefined && !finishedCheckIfWalletRegistered) ||
        (state.web2Login && !finishedFetchWallet) ||
        !minimumAnimationTimePassed) && <ConnectionScreenAnimation />}

      {isActive &&
        state.web2Login &&
        address !== walletAddress &&
        finishedFetchWallet &&
        finishedCheckIfWalletRegistered &&
        minimumAnimationTimePassed && (
          <ConnectionScreenNoWalletLinked state={state} dispatch={dispatch} />
        )}
      {state.web2Login &&
        !isActive &&
        finishedFetchWallet &&
        minimumAnimationTimePassed && (
          <ConnectionScreenNoWeb3 state={state} dispatch={dispatch} />
        )}
      {!state.web2Login &&
        state.web3Login &&
        walletRegistered &&
        finishedCheckIfWalletRegistered &&
        minimumAnimationTimePassed && (
          <ConnectionScreenWalletRegistered state={state} dispatch={dispatch} />
        )}
      {!state.web2Login &&
        !state.web3Login &&
        !state.downloadCertificate &&
        !state.paymentFinished &&
        renderConnectionScreen(state, dispatch)}
      {((isActive && state.web2Login && address === walletAddress) ||
        (!state.web2Login && state.web3Login && !walletRegistered)) &&
        renderActiveList(
          state,
          dispatch,
          idOfChosenContract,
          contractInfoSA,
          setBubbleMessageActive,
          setBubbleMessageContent,
          setCertificateContractAddress,
          setChainChosen,
          chainChosen,
          bubbleMessageActive,
          bubbleMessageContent,
          contractInfoNDA,
          setContractInfoSA,
          setContractInfoNDA,
          typeOfChosenContract
        )}

      {renderInactiveList(
        state,
        dispatch,
        setCertificateContractAddress,
        certificateContractAddress,
        setContractInfoSA,
        setContractInfoNDA
      )}
    </div>
  );
}

function renderActiveList(
  state: State,
  dispatch: React.Dispatch<Action>,
  idOfChosenContract: string,
  contractInfoSA: ContractTerms | undefined,
  setBubbleMessageActive: React.Dispatch<React.SetStateAction<boolean>>,
  setBubbleMessageContent: React.Dispatch<React.SetStateAction<string>>,
  setCertificateContractAddress: React.Dispatch<React.SetStateAction<string>>,
  setChainChosen: React.Dispatch<React.SetStateAction<string>>,
  chainChosen: string,
  bubbleMessageActive: boolean,
  bubbleMessageContent: string,

  contractInfoNDA: ContractInfoNDA | undefined,
  setContractInfoSA: React.Dispatch<
    React.SetStateAction<ContractTerms | undefined>
  >,
  setContractInfoNDA: React.Dispatch<
    React.SetStateAction<ContractInfoNDA | undefined>
  >,
  typeOfChosenContract: "SA" | "NDA" | "PoAG" | "PoAS" | ""
) {
  return (
    <>
      {[
        <Fragment key="contractList">
          {renderContractList(state, dispatch)}
        </Fragment>,
        <Fragment key="decodedContract">
          {renderDecodedContract(
            state,
            dispatch,
            idOfChosenContract,
            contractInfoSA,
            setBubbleMessageActive,
            setBubbleMessageContent,
            contractInfoNDA,
            setContractInfoSA,
            setContractInfoNDA,
            typeOfChosenContract
          )}
        </Fragment>,
        <Fragment key="listOfCertificates">
          {renderListOfCertificates(
            state,
            dispatch,
            setCertificateContractAddress
          )}
        </Fragment>,
        <Fragment key="getCertificate">
          {renderGetCertificate(
            state,
            dispatch,
            setBubbleMessageActive,
            setBubbleMessageContent
          )}
        </Fragment>,
        <Fragment key="snarkFieldChoice">
          {renderSnarkFieldChoice(
            state,
            dispatch,
            idOfChosenContract,
            contractInfoSA,
            setBubbleMessageActive,
            setBubbleMessageContent,
            setContractInfoSA,
            contractInfoNDA,
            setContractInfoNDA,
            typeOfChosenContract
          )}
        </Fragment>,
        <Fragment key="contractSummary">
          {renderContractSummary(
            state,
            dispatch,
            contractInfoSA,
            idOfChosenContract,
            typeOfChosenContract,
            contractInfoNDA
          )}
        </Fragment>,
        <Fragment key="paymentMethod">
          {renderPaymentMethod(
            state,
            dispatch,
            idOfChosenContract,
            setBubbleMessageActive,
            setBubbleMessageContent,
            setChainChosen,
            chainChosen,
            typeOfChosenContract
          )}
        </Fragment>,
        <Fragment key="renderDzin">
          {renderDzin(state, bubbleMessageActive, bubbleMessageContent)}
        </Fragment>,
      ]}
    </>
  );
}
function renderInactiveList(
  state: State,
  dispatch: React.Dispatch<Action>,
  setCertificateContractAddress: React.Dispatch<React.SetStateAction<string>>,
  certificateContractAddress: string,

  setContractInfoSA: React.Dispatch<
    React.SetStateAction<ContractTerms | undefined>
  >,
  setContractInfoNDA: React.Dispatch<
    React.SetStateAction<ContractInfoNDA | undefined>
  >
) {
  return (
    <>
      {[
        <Fragment key="certificateCreation">
          {renderCertificateCreation(
            state,
            dispatch,
            setCertificateContractAddress
          )}
        </Fragment>,
        <Fragment key="downloadCertificate">
          {renderDownloadCertificate(
            state,
            dispatch,
            certificateContractAddress,
            setContractInfoSA,
            setContractInfoNDA
          )}
        </Fragment>,
      ]}
    </>
  );
}

function renderConnectionScreen(
  state: State,
  dispatch: React.Dispatch<Action>
) {
  return <ConnectionScreen dispatch={dispatch} state={state} />;
}

function renderContractList(state: State, dispatch: React.Dispatch<Action>) {
  if (state.contractChosen) return null;
  if (state.paymentFinished) return null;
  return !state.contractListAnimationFinished ? (
    <FetchingContractListAnimation />
  ) : (
    <ContractList dispatch={dispatch} state={state} />
  );
}

function renderDecodedContract(
  state: State,
  dispatch: React.Dispatch<Action>,
  idOfChosenContract: string,
  contractInfo: ContractTerms | undefined,
  setBubbleMessageActive: React.Dispatch<React.SetStateAction<boolean>>,
  setBubbleMessageContent: React.Dispatch<React.SetStateAction<string>>,
  contractInfoNDA: ContractInfoNDA | undefined,

  setContractInfoSA: React.Dispatch<
    React.SetStateAction<ContractTerms | undefined>
  >,
  setContractInfoNDA: React.Dispatch<
    React.SetStateAction<ContractInfoNDA | undefined>
  >,
  typeOfChosenContract: "SA" | "NDA" | "PoAG" | "PoAS" | ""
) {
  if (!state.contractChosen) return null;
  if (state.listOfCertificates) return null;
  if (state.getCertificate) return null;
  if (state.downloadCertificate) return null;
  return !state.decodingAnimationFinished ? (
    <DecodingAnimation />
  ) : contractInfo !== undefined || contractInfoNDA !== undefined ? (
    <ContractDecoded
      dispatch={dispatch}
      contractInfoSA={contractInfo}
      state={state}
      contractId={parseInt(idOfChosenContract)}
      fieldChoice={false}
      setBubbleMessageActive={setBubbleMessageActive}
      setBubbleMessageContent={setBubbleMessageContent}
      setContractInfoSA={setContractInfoSA}
      contractInfoNDA={contractInfoNDA}
      setContractInfoNDA={setContractInfoNDA}
      typeOfChosenContract={typeOfChosenContract}
    />
  ) : (
    <DecodedContractError
      state={state}
      dispatch={dispatch}
      setContractInfoNDA={setContractInfoNDA}
      setContractInfoSA={setContractInfoSA}
    />
  );
}

function renderListOfCertificates(
  state: State,
  dispatch: React.Dispatch<Action>,
  setCertificateContractAddress: React.Dispatch<React.SetStateAction<string>>
) {
  if (state.downloadCertificate) return null;

  return state.certificateError ? (
    <CertificateError dispatch={dispatch} />
  ) : state.listOfCertificates ? (
    <ListOfCertificates
      state={state}
      dispatch={dispatch}
      setCertificateContractAddress={setCertificateContractAddress}
    />
  ) : null;
}
function renderGetCertificate(
  state: State,
  dispatch: React.Dispatch<Action>,
  setBubbleMessageActive: React.Dispatch<React.SetStateAction<boolean>>,
  setBubbleMessageContent: React.Dispatch<React.SetStateAction<string>>
) {
  if (state.downloadCertificate) return null;
  if (state.certificateFieldChoice) return null;
  return state.getCertificate ? (
    <div className="w-full h-full flex items-center justify-center">
      <GetCertificate
        dispatch={dispatch}
        setBubbleMessageActive={setBubbleMessageActive}
        setBubbleMessageContent={setBubbleMessageContent}
      />
    </div>
  ) : null;
}

function renderSnarkFieldChoice(
  state: State,
  dispatch: React.Dispatch<Action>,
  idOfChosenContract: string,
  contractInfo: ContractTerms | undefined,
  setBubbleMessageActive: React.Dispatch<React.SetStateAction<boolean>>,
  setBubbleMessageContent: React.Dispatch<React.SetStateAction<string>>,

  setContractInfoSA: React.Dispatch<
    React.SetStateAction<ContractTerms | undefined>
  >,
  contractInfoNDA: ContractInfoNDA | undefined,
  setContractInfoNDA: React.Dispatch<
    React.SetStateAction<ContractInfoNDA | undefined>
  >,
  typeOfChosenContract: "SA" | "NDA" | "PoAG" | "PoAS" | ""
) {
  if (state.downloadCertificate) return null;
  if (state.contractSummaryActive) return null;
  return state.certificateFieldChoice &&
    (contractInfo !== undefined || contractInfoNDA !== undefined) ? (
    <ContractDecoded
      dispatch={dispatch}
      contractInfoSA={contractInfo}
      state={state}
      contractId={parseInt(idOfChosenContract)}
      fieldChoice={true}
      setBubbleMessageActive={setBubbleMessageActive}
      setBubbleMessageContent={setBubbleMessageContent}
      setContractInfoSA={setContractInfoSA}
      contractInfoNDA={contractInfoNDA}
      setContractInfoNDA={setContractInfoNDA}
      typeOfChosenContract={typeOfChosenContract}
    />
  ) : null;
}

function renderContractSummary(
  state: State,
  dispatch: React.Dispatch<Action>,
  contractInfo: ContractTerms | undefined,
  idOfChosenContract: string,
  typeOfChosenContract: "SA" | "NDA" | "PoAG" | "PoAS" | "",
  contractInfoNDA: ContractInfoNDA | undefined
) {
  if (state.downloadCertificate) return null;
  return state.contractSummaryActive ? (
    <div className="w-full h-custom-bar flex items-center justify-center overflow-y-auto">
      <ContractSummary
        dispatch={dispatch}
        state={state}
        contractId={parseInt(idOfChosenContract)}
        contractInfo={contractInfo}
        typeOfChosenContract={typeOfChosenContract}
        contractInfoNDA={contractInfoNDA}
      />
    </div>
  ) : null;
}

function renderPaymentMethod(
  state: State,
  dispatch: React.Dispatch<Action>,
  idOfChosenContract: string,
  setBubbleMessageActive: React.Dispatch<React.SetStateAction<boolean>>,
  setBubbleMessageContent: React.Dispatch<React.SetStateAction<string>>,
  setChainChosen: React.Dispatch<React.SetStateAction<string>>,
  chainChosen: string,
  typeOfChosenContract: "SA" | "NDA" | "PoAG" | "PoAS" | ""
) {
  if (state.downloadCertificate) return null;
  if (state.paymentFinished) return null;
  return state.paymentActive ? (
    <PaymentMethod
      chainChosen={chainChosen}
      setChainChosen={setChainChosen}
      state={state}
      idOfChosenContract={idOfChosenContract}
      dispatch={dispatch}
      setBubbleMessageActive={setBubbleMessageActive}
      setBubbleMessageContent={setBubbleMessageContent}
      typeOfChosenContract={typeOfChosenContract}
    />
  ) : null;
}

function renderCertificateCreation(
  state: State,
  dispatch: React.Dispatch<Action>,
  setCertificateContractAddress: React.Dispatch<React.SetStateAction<string>>
) {
  if (state.downloadCertificate) return null;
  if (state.paymentActive) return null;
  return state.paymentFinished ? (
    <CertificateCreation
      state={state}
      dispatch={dispatch}
      setCertificateContractAddress={setCertificateContractAddress}
    />
  ) : null;
}

function renderDownloadCertificate(
  state: State,
  dispatch: React.Dispatch<Action>,
  certificateContractAddress: string,

  setContractInfoSA: React.Dispatch<
    React.SetStateAction<ContractTerms | undefined>
  >,
  setContractInfoNDA: React.Dispatch<
    React.SetStateAction<ContractInfoNDA | undefined>
  >
) {
  if (state.paymentActive) return null;
  return state.downloadCertificate ? (
    <DownloadCertificate
      state={state}
      dispatch={dispatch}
      certificateContractAddress={certificateContractAddress}
      setContractInfoNDA={setContractInfoNDA}
      setContractInfoSA={setContractInfoSA}
    />
  ) : null;
}

function renderDzin(
  state: State,
  bubbleMessageActive: boolean,
  bubbleMessageContent: string
) {
  if (!state.decodingAnimationFinished) return null;
  if (!state.contractListAnimationFinished) return null;
  if (state.listOfCertificates) return null;
  if (state.decodingAnimationFinished && !state.getCertificate) return null;
  if (state.certificateFieldChoice && !state.contractSummaryActive) return null;
  if (state.contractSummaryActive && !state.paymentActive) return null;
  if (state.getCertificate && !state.certificateFieldChoice) return null;
  return (
    <div className="fixed bottom-[20px] left-[20px] flex items-center justify-center z-[10]">
      <img src={vaiotBubble} alt="Vaiot Logo" />
      {bubbleMessageActive && (
        <div className="bottom-[30px] left-[20px] p-4 rounded-md chat-box max-w-[400px] z-[10]">
          <p
            className={`font-semibold text-[12px] tracking-wide max-w-[200px] ${
              state.getCertificate ? "max-w-[250px]" : "max-w-[200px]"
            } `}
          >
            {bubbleMessageContent}
          </p>
        </div>
      )}
    </div>
  );
}
