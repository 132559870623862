// ----------------------
// Packages
// ----------------------
import { HashLoader } from "react-spinners";

// ----------------------
// Assets
// ----------------------
import { ReactComponent as Checkmark } from "../../../assets/images/blueCheckmark.svg";

function PaymentFinished() {
  return (
    <div className="h-full w-full flex flex-col items-center justify-around font-TT">
      <div className="text-center flex flex-col items-center justify-around w-full h-full py-[30px] ">
        <div className="w-[100px] h-[100px] sm:w-[140px] sm:h-[140px]">
          <Checkmark className="w-full h-full" />
        </div>
        <h1 className="text-[30px] sm:text-[38px] font-bold text-[#0023EF]">
          Payment Finished!
        </h1>
        <p className="text-[16px] sm:text-[18px] font-semibold px-[10px]">
          The payment was successful. ZK-Snark proof will start generating
          soon...
        </p>
        <div className="mt-[20px]">
          <HashLoader />
        </div>
      </div>
    </div>
  );
}

export default PaymentFinished;
