import PaybackBalanceElement from "./PaybackBalanceElement";

interface PaybackBalanceProps {
  claimedVai: string;
  stakedVai: string;
  vaiPoints: string;
  fetchingBalance: boolean;
}

function PaybackBalance({
  claimedVai,
  stakedVai,
  vaiPoints,
  fetchingBalance,
}: PaybackBalanceProps) {
  return (
    <div className="flex flex-col gap-[20px] items-center justify-center font-TT tracking-wide font-bold w-[90%] md:w-[80%]">
      <p className="text-[#0023EF] text-[24px] md:mr-[60px]">Your Balance</p>
      <div className="flex w-full items-center justify-between pb-[30px] border-b-[1px] border-[#CECDDA] px-[20px] md:px-[40px]">
        <PaybackBalanceElement
          darkStyle={false}
          bigText={vaiPoints}
          smallText="VAI POINTS"
          fetchingBalance={fetchingBalance}
        />
        <PaybackBalanceElement
          darkStyle={false}
          bigText={stakedVai}
          smallText="STAKED VAI PAYBACK"
          fetchingBalance={fetchingBalance}
        />
        <PaybackBalanceElement
          darkStyle={true}
          bigText={claimedVai}
          smallText="CLAIMED VAI PAYBACK"
          fetchingBalance={fetchingBalance}
        />
      </div>
    </div>
  );
}

export default PaybackBalance;
