// ----------------------
// Assets
// ----------------------
import { ReactComponent as VaiotBubble } from "../../../assets/images/vaiotBubble.svg";

interface BubbleMessageProps {
  content: string;
}

function BubbleMessage(props: BubbleMessageProps) {
  return (
    <div className="absolute bottom-[10px] sm:bottom-[20px] left-[0] sm:left-[20px] flex items-center justify-center z-[100]">
      <VaiotBubble />

      <div className="bottom-[30px] left-[0] sm:left-[20px] p-4 rounded-md chat-box max-w-[200px] sm:max-w-[300px] z-[100]">
        <p className={`font-semibold text-[14px] tracking-wide max-w-[200px]`}>
          {props.content}
        </p>
      </div>
    </div>
  );
}

export default BubbleMessage;
