import axios from "axios";

export const handleResendCode = async (
  username: string,
  password: string,
  isLogIn: boolean
) => {
  const requestBody = isLogIn
    ? { username, password, authCode: "" }
    : { username, password, verificationToken: "" };

  try {
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/auth/login`,
      requestBody,
      { withCredentials: true }
    );
  } catch (error) {
    console.error("Error when resending code:", error);
  }
};
