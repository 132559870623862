// ----------------------
// External/Internal Packages
// ----------------------
import React, { useEffect } from "react";

// ----------------------
// Utility functions
// ----------------------
import { handleRestartBot } from "../../../utils/utility/handleRestartBot";
import handleToastError from "../../../utils/utility/handleToastError";
// ----------------------
// Interfaces
// ----------------------
import { Action, State, ActionType } from "../../../state/interfaces";

interface RedirectWidgetProps {
  url: string;
  state: State;
  dispatch: React.Dispatch<Action>;
}

function RedirectWidget(props: RedirectWidgetProps) {
  useEffect(() => {
    props.dispatch({ type: ActionType.SET_THINKING_MESSAGE, payload: "" });
    props.dispatch({ type: ActionType.SET_FETCHING_MESSAGE, payload: false });
    props.dispatch({
      type: ActionType.SET_DIALOGUE_PAYMENT_PENDING,
      payload: false,
    });
  }, []);
  return (
    <div className="flex flex-col gap-[5px]">
      <button
        onClick={() => window.open(props.url, "_blank")}
        className="rounded-md hover:opacity-90 hover:text-[#ccd1f0] text-[#ffff] bg-gradient-button font-bold text-[12px] md:text-[14px] py-[10px]  bg-[#0023EF12] px-[20px]"
      >
        Download my contract
      </button>
      <button
        onClick={async () => {
          if (props.state.dialogIsRestarting) {
            handleToastError("Wait for the restart to complete first!");
          } else {
            props.dispatch({
              type: ActionType.SET_DIALOG_IS_RESTARTING,
              payload: true,
            });
            await handleRestartBot(props.state, props.dispatch);
          }
        }}
        className="rounded-md hover:opacity-90 hover:text-[#ccd1f0] text-[#ffff] bg-gradient-button font-bold text-[12px] md:text-[14px] py-[10px]  bg-[#0023EF12] px-[20px]"
      >
        Start Again
      </button>
    </div>
  );
}

export default RedirectWidget;
