import React, { ChangeEvent, useState, useEffect } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import paybackLogo from "../../../../../assets/images/paybackLogo.svg";
import PaybackOptionsDue from "./PaybackOptionsDue";

interface PaybackOptionsProps {
  paybackAmount: number;
  vaiPoints: number;
  paybackChosen: string;
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
}

function PaybackOptions({
  paybackAmount,
  vaiPoints,
  paybackChosen,
  inputValue,
  setInputValue,
}: PaybackOptionsProps) {
  const [sliderValue, setSliderValue] = useState<number>(1);

  useEffect(() => {
    setInputValue(sliderValue.toString());
  }, [sliderValue]);

  const displayValue =
    paybackChosen === "Payback"
      ? paybackAmount
      : paybackChosen === "Points"
      ? vaiPoints
      : null;
  const displayValueStr =
    displayValue !== null ? displayValue.toString() : null;

  let positionClass = "";
  if (displayValueStr !== null) {
    if (displayValueStr.length === 1) {
      positionClass = "top-[18%] left-[40%] text-[40px]";
    } else if (displayValueStr.length === 2) {
      positionClass = "top-[20%] left-[30%] text-[36px]";
    } else if (displayValueStr.length === 3) {
      positionClass = "top-[27%] left-[27%] text-[28px]";
    } else {
      positionClass = "top-[32%] left-[27%] text-[22px]";
    }
  }

  const handleSliderChange = (value: number | number[]) => {
    if (typeof value === "number") {
      setSliderValue(value);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    // Allow any input that could eventually form a valid input (1-5) or be empty
    if (/^[1-5]?$/.test(newValue) || newValue === "") {
      setInputValue(newValue);
    }
  };

  const handleInputBlur = () => {
    // Enforce a valid value when the input loses focus
    const validValue = Math.min(
      Math.max(1, parseInt(inputValue || "1", 10)),
      5
    );
    setSliderValue(validValue);
    setInputValue(validValue.toString());
  };

  return (
    <div className="flex flex-col items-center w-full gap-[20px] mt-[20px]">
      <div className="flex flex-col gap-[20px] sm500:gap-0 sm500:flex-row w-full justify-between items-center font-TT tracking-wider">
        <div className="flex flex-col items-center justify-center">
          <p>Account Balance</p>
          <div className="w-[100px] h-[100px] relative">
            <img
              src={paybackLogo}
              className="w-full h-full"
              alt="Payback Logo"
            />
            <p className={`absolute ${positionClass} text-[#0023EF] font-bold`}>
              {displayValueStr !== null
                ? displayValueStr.length > 3
                  ? "999+"
                  : displayValueStr
                : null}
            </p>
          </div>
          <p className="text-[#0023EF] font-semibold">VAI Payback</p>
        </div>
        {/* Right side div with input and slider */}
        <div className="flex flex-col items-center">
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            className="mb-4 border-[1px] border-[#CECDDA] py-[18px] px-[12px] rounded-[6px] border-opacity-50 outline-none"
          />
          <Slider
            min={1}
            max={5}
            value={sliderValue}
            onChange={handleSliderChange}
          />
        </div>
      </div>
      <PaybackOptionsDue
        paybackChosen={paybackChosen}
        inputValue={inputValue}
      />
    </div>
  );
}

export default PaybackOptions;
