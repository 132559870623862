import axios from "axios";
import { Action, ActionType } from "../../state/interfaces";
import handleToastError from "../utility/handleToastError";

export const handleChangePassword = async (
  dispatch: React.Dispatch<Action>,
  newPassword: string,
  oldPassword: string,
  setOldPassword: React.Dispatch<React.SetStateAction<string>>,
  setNewPassword: React.Dispatch<React.SetStateAction<string>>,
  setConfirmNewPassword: React.Dispatch<React.SetStateAction<string>>
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/users/security/password`,
      {
        newPassword,
        oldPassword,
      },
      {
        withCredentials: true,
      }
    );
    if (response.data.status === "success") {
      dispatch({
        type: ActionType.SET_CHANGE_PASSWORD_MODAL_ACTIVE,
        payload: false,
      });
    }
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      if (error.response.data.status === "wrongPassword") {
        handleToastError("Old password is incorrect!");
        setOldPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
        return;
      } else {
        console.error(error);
      }
    } else {
      console.error(error);
    }
  }
};
