// ----------------------
// Packages
// ----------------------
import React from "react";

// ----------------------
// Assets
// ----------------------
import { ReactComponent as Cross } from "../../../assets/images/cross.svg";

// ----------------------
// Interfaces
// ----------------------
import { ActionType, Action } from "../../../state/interfaces";

interface CrossIconProps {
  dispatch: React.Dispatch<Action>;
}

function CrossIcon({ dispatch }: CrossIconProps) {
  return (
    <button
      className="w-[25px] border-[#0023EF] border-[1px]"
      onClick={() =>
        dispatch({
          type: ActionType.SET_HASH_WINDOW_ACTIVE,
          payload: false,
        })
      }
    >
      <Cross className="h-full w-full" />
    </button>
  );
}

export default CrossIcon;
