import { State, QueueItem, Action } from "../../../../state/interfaces";
import handleSkip from "./handleSkip";
import handleUserInput from "./handleUserInput";

export const handleSendButtonEvent = (
  listening: boolean,
  state: State,
  currentAudio: React.MutableRefObject<HTMLAudioElement | null>,
  dispatch: React.Dispatch<Action>,
  messageQueue: React.MutableRefObject<QueueItem[]>,
  resetTranscript: () => void,
  inputValue: string,
  allMessagesDisplayed: boolean,
  setInputValue: (value: string) => void,
  walletAddress: string | null | undefined
) => {
  return state.isSpeaking ||
    state.messages.filter((msg) => msg.messageState === "DISPLAYING").length > 0
    ? () =>
        handleSkip(
          listening,
          state,
          currentAudio,
          dispatch,
          messageQueue,
          resetTranscript
        )
    : () =>
        handleUserInput(
          inputValue,
          allMessagesDisplayed,
          state,
          dispatch,
          setInputValue,
          messageQueue,
          currentAudio,
          walletAddress
        );
};
