import { ActionType, Action } from "../../../state/interfaces";

interface ContractTerms {
  agreementTerms: Array<any>;
  parties: Array<string>;
  services: Array<Array<string>>;
  onBlockchain: string;
}

interface ContractInfoNDA {
  creationTimestamp: string;
  fieldNames: string[];
  fieldValues: string[];
  partyA: string;
  partyB: string;
}

export const handleRestartDownload = (
  dispatch: React.Dispatch<Action>,

  setContractInfoSA: React.Dispatch<
    React.SetStateAction<ContractTerms | undefined>
  >,
  setContractInfoNDA: React.Dispatch<
    React.SetStateAction<ContractInfoNDA | undefined>
  >
) => {
  window.history.replaceState(
    {},
    document.title,
    window.location.origin + window.location.pathname
  );
  dispatch({
    type: ActionType.SET_CONTRACT_LIST_ANIMATION_FINISHED,
    payload: true,
  });
  dispatch({ type: ActionType.SET_CONTRACT_CHOSEN, payload: false });
  dispatch({
    type: ActionType.SET_DECODING_ANIMATION_FINISHED,
    payload: false,
  });
  dispatch({ type: ActionType.SET_GET_CERTIFICATE, payload: true });
  dispatch({
    type: ActionType.SET_CERTIFICATE_FIELD_CHOICE,
    payload: false,
  });
  dispatch({
    type: ActionType.SET_CONTRACT_SUMMARY_ACTIVE,
    payload: false,
  });
  dispatch({
    type: ActionType.SET_DOWNLOAD_CERTIFICATE,
    payload: false,
  });
  dispatch({ type: ActionType.SET_PAYMENT_ACTIVE, payload: false });
  dispatch({ type: ActionType.SET_PAYMENT_FINISHED, payload: false });
  dispatch({ type: ActionType.SET_GET_CERTIFICATE, payload: false });
  dispatch({
    type: ActionType.SET_LIST_OF_CERTIFICATES,
    payload: false,
  });
  dispatch({
    type: ActionType.SET_SUMMARY,
    payload: "",
  });
  dispatch({
    type: ActionType.SET_CERTIFICATE_FIELDS,
    payload: [],
  });
  dispatch({ type: ActionType.SET_ID_OF_CHOSEN_CONTRACT, payload: "" });
  setContractInfoSA(undefined);
  setContractInfoNDA(undefined);
};
