// ----------------------
// Packages
// ----------------------
import { useState } from "react";

// ----------------------
// Assets
// ----------------------
import { ReactComponent as Avatar } from "../../assets/images/vaiotBubble.svg";

// ----------------------
// Interfaces
// ----------------------
import { State } from "../../state/interfaces";

interface VaiotBubbleProps {
  state: State;
  text: string;
}

function VaiotBubble(props: VaiotBubbleProps) {
  const [bubbleActive, setBubbleActive] = useState(true);
  const [bubbleClicked, setBubbleClicked] = useState(true);

  const showBubble = bubbleActive || bubbleClicked;

  return (
    <div className="absolute bottom-[10px] left-[10px] sm:bottom-[20px] sm:left-[20px] flex items-center justify-center z-[100]">
      <button
        onMouseEnter={() => setBubbleActive(true)}
        onMouseLeave={() => {
          if (!bubbleClicked) setBubbleActive(false);
        }}
        onClick={() => {
          setBubbleClicked(!bubbleClicked);
          if (!bubbleClicked) setBubbleActive(true);
        }}
        className="flex-shrink-0 w-[60px]"
      >
        <Avatar className="w-full h-full" />
      </button>
      {showBubble && (
        <div className="bottom-[30px] left-[10px] sm:left-[20px] p-4 rounded-md chat-box max-w-[400px] z-[100]">
          <p
            className={`font-semibold text-[12px] tracking-wide ${
              props.state.getCertificate ? "max-w-[250px]" : "max-w-[220px]"
            } `}
          >
            {props.text}
          </p>
        </div>
      )}
    </div>
  );
}

export default VaiotBubble;
