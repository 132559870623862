export const downloadFile = (content: string, fileName: string) => {
  // Create a Blob object with the content
  const blob = new Blob([content], { type: "text/plain" });
  // Create a Blob URL
  const url = URL.createObjectURL(blob);
  // Create a hidden anchor element
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = fileName;
  // Append the anchor element to the DOM, and trigger a click to download the file
  document.body.appendChild(a);
  a.click();
  // Remove the anchor element and revoke the Blob URL
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};
