import React from "react";
import handleToastError from "../../../utils/utility/handleToastError";
import axios from "axios";
import { ActionType, Action } from "../../../state/interfaces";
import { updateGameField } from "../../../utils/api/gameApi";

export const handleVerifyProof = async (
  proofJson: any,
  dispatch: React.Dispatch<Action>,
  account: string | null | undefined
) => {
  if (proofJson === undefined) {
    handleToastError("Please make sure your proof.json is correct!");
    return;
  }
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/snarks/verifyProof",
      {
        proof: proofJson,
      }
    );

    if (response.data.verification) {
      dispatch({
        type: ActionType.SET_PROOF_OVERVIEW,
        payload: response.data.overview,
      });
      dispatch({
        type: ActionType.SET_PROOF_VERIFICATION_ACTIVE,
        payload: true,
      });
      dispatch({
        type: ActionType.SET_PROOF_VERIFICATION_RESULT,
        payload: "SUCCESS",
      });
      await updateGameField(account, "certificateVerified", true);
    } else {
      dispatch({
        type: ActionType.SET_PROOF_VERIFICATION_ACTIVE,
        payload: true,
      });
      dispatch({
        type: ActionType.SET_PROOF_VERIFICATION_RESULT,
        payload: "ERROR",
      });
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 403) {
        handleToastError(
          "Access denied! Please log out and log back in to solve this issue."
        );
      } else {
        console.error("Error sending certificate payment request:", error);
      }
    } else {
      console.error("An unexpected error occurred:", error);
    }
  }
};
