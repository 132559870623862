// ----------------------
// External/Internal Packages
// ----------------------
import React from "react";

// ----------------------
// Utility Functions
// ----------------------
import { handleDzinMessage } from "../../utils/handleDzinMessage";

interface DownloadAgreementProps {
  setBubbleMessageActive: React.Dispatch<React.SetStateAction<boolean>>;
  setBubbleMessageContent: React.Dispatch<React.SetStateAction<string>>;
  agreementUrl: string;
}

function DownloadAgreement({
  setBubbleMessageActive,
  setBubbleMessageContent,
  agreementUrl,
}: DownloadAgreementProps) {
  return (
    <button
      onClick={() => {
        handleDzinMessage(
          setBubbleMessageActive,
          setBubbleMessageContent,
          false,
          ""
        );

        window.open(agreementUrl, "_blank");
      }}
      className="tracking-wider font-TT bg-[#0023EF08] w-full md:w-[220px] max-w-[400px] h-[65px] rounded-[2px] text-[#0023EF] font-bold text-[16px] border-[1px] border-[#0023EF73]"
      onMouseEnter={() => {
        handleDzinMessage(
          setBubbleMessageActive,
          setBubbleMessageContent,
          true,
          "Choose this option to download the agreement you see in the overview."
        );
      }}
      onMouseLeave={() => {
        handleDzinMessage(
          setBubbleMessageActive,
          setBubbleMessageContent,
          false,
          ""
        );
      }}
    >
      Download Agreement
    </button>
  );
}

export default DownloadAgreement;
