// External Packages
import { useRef, useEffect, useContext, useState } from "react";
import { ToastContainer } from "react-toastify";
import { useSpeechRecognition } from "react-speech-recognition";
import { useAccount } from "wagmi";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Context and Interfaces
import { AppContext } from "./state/context";
import { ActionType, QueueItem } from "./state/interfaces";

// Utility Functions and Hookss
import { renderBasedOnState } from "./renderHelpers";
import { handleCreateUserId } from "./utils/storage/handleCreateUserId";
import useHandleVoiceInput from "./utils/listening/useHandleVoiceInput";
import usePostToRedis from "./utils/storage/usePostToRedis";
import { useCreateGame } from "./utils/utility/useCreateGame";
import useWrongChain from "./utils/dialog/useWrongChain";

// Components
import Maintenance from "./components/Maintenance/Maintenance";
import SkipVaiot from "./components/ContractReader/DecodedContract/SkipVaiot";

// Styles
import "react-toastify/dist/ReactToastify.css";
import { useHandleResize } from "./utils/utility/useHandleResize";
import ResetPasswordModal from "./components/AccountCreation/ResetPasswordModal";

function App() {
  // Context and stateee
  const [isPopupVisible, setIsPopupVisible] = useState(true);
  const desktopSize = useHandleResize(768, "small", "default");
  const { state, dispatch } = useContext(AppContext);
  const { address } = useAccount();
  const { selectedNetworkId } = useWeb3ModalState();
  const isActive = address !== undefined;
  const chainId =
    selectedNetworkId !== undefined ? parseInt(selectedNetworkId) : undefined;

  // Refs
  const messageQueue = useRef<QueueItem[]>([]);
  const currentAudio = useRef<InstanceType<typeof Audio> | null>(null);
  const navbarRef = useRef<HTMLDivElement>(null);
  const processedMessageIds = useRef(new Set());

  // Hooks
  const { resetTranscript } = useSpeechRecognition();
  usePostToRedis(state);
  useHandleVoiceInput(state, dispatch, resetTranscript, address);
  useCreateGame(address); // Everytime someone connects an account, check if it has a game created yet
  useWrongChain();

  // Use Effects
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    handleCreateUserId(state, dispatch);
  }, []);

  return (
    <Router>
      <div className="flex flex-col h-full">
        <ToastContainer />
        <Routes>
          <Route
            path="/"
            element={
              <>
                {desktopSize === "small" && isPopupVisible && (
                  <div className="fixed w-full h-full bg-opacity-75 bg-[#050934] z-[100]">
                    <SkipVaiot
                      setPopupActive={setIsPopupVisible}
                      text="We've noticed that you're using our platform on a mobile device. To ensure a smooth and uninterrupted experience, we strongly recommend connecting the wallet you want to use before starting a conversation with the assistant."
                    />
                  </div>
                )}
                {/* {process.env.REACT_APP_NODE_ENV === "production" &&
                !state.adminAccess ? (
                  <Maintenance />
                ) : (
                  renderBasedOnState(
                    isActive,
                    chainId,
                    state,
                    dispatch,
                    messageQueue,
                    currentAudio,
                    processedMessageIds,
                    navbarRef
                  )
                )} */}
                {renderBasedOnState(
                  isActive,
                  chainId,
                  state,
                  dispatch,
                  messageQueue,
                  currentAudio,
                  processedMessageIds,
                  navbarRef
                )}
              </>
            }
          />
          <Route
            path="/reset-password/:token"
            element={<ResetPasswordModal dispatch={dispatch} />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
