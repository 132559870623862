import React from "react";
import Applications from "./Applications";
import { Action, State } from "../../state/interfaces";

interface MobileApplicationsProps {
  dispatch: React.Dispatch<Action>;
  state: State;
}

function MobileApplications({ dispatch, state }: MobileApplicationsProps) {
  return (
    <div className="w-full h-full bg-[#ffff] py-[10px] px-[20px] flex items-center justify-between flex-col">
      <Applications
        state={state}
        dispatch={dispatch}
        layoutClass="flex-row flex-wrap gap-[50px] sm450:gap-[80px] justify-center"
        applicationClass="w-[80px] sm450:w-[140px]"
        layoutPadding=" px-[40px]"
      />
    </div>
  );
}

export default MobileApplications;
