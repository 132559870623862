import React from "react";
import vaiPaybackIcon from "../../../../assets/images/vaiPaybackIcon.svg";
import paybackIcon from "../../../../assets/images/paybackIcon.svg";

interface TransactionHistoryElementProps {
  amount: string;
  date: string;
  mobile: boolean;
  type: "payback" | "vaiPoints";
}

const TransactionHistoryElement: React.FC<TransactionHistoryElementProps> = ({
  amount,
  date,
  type,
  mobile,
}) => {
  const isPositive = parseFloat(amount) >= 0;

  return (
    <div
      className={`flex font-TT w-full mt-[20px] items-center justify-between p-4 border-r-4 border-b-[#CECDDA] border-b-[1px] ${
        isPositive ? "border-green-500" : "border-red-500"
      }`}
    >
      <img
        src={type === "vaiPoints" ? paybackIcon : vaiPaybackIcon}
        alt="Transaction"
        className="w-[54px] mr-4"
      />{" "}
      <div
        className={`font-bold  w-[120px] ${
          isPositive ? "text-[#3CAD6C]" : "text-[#E05051]"
        }
        ${
          mobile
            ? "text-[16px] sm400:text-[18px] sm500:text-[24px]"
            : "text-[18px] sm800:text-[24px]"
        }
        
        `}
      >
        {isPositive ? `+ ${amount}` : `${amount}`}
      </div>
      <div
        className={`flex items-center md:gap-[20px] w-[100px]  md:flex-row flex-col ${
          mobile
            ? "mr-[15px] text-[12px] sm400:text-[14px] sm500:text-[16px]"
            : "text-[14px] md:text-[16px] mr-[35px]"
        } `}
      >
        <span className="text-[#57536D] opacity-50">Date</span>{" "}
        <span className="font-bold">{date}</span>
      </div>
    </div>
  );
};

export default TransactionHistoryElement;
