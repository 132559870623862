import thinkingVaiot from "../../../assets/images/thinkingVaiot.svg";
import purpleCircle from "../../../assets/images/purpleCircle.svg";
import { BiSend } from "react-icons/bi";
import { Action, ActionType } from "../../../state/interfaces";

interface CertificateErrorProps {
  dispatch: React.Dispatch<Action>;
}
// .

function CertificateError({ dispatch }: CertificateErrorProps) {
  const handleGoBack = () => {
    dispatch({ type: ActionType.SET_CERTIFICATE_ERROR, payload: false });
  };

  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="flex sm:flex-row flex-col items-center justify-center sm:justify-start sm:items-start gap-[50px]">
        <img
          src={thinkingVaiot}
          className="w-[120px] sm:w-[150px] sm900:w-[200px]"
        />
        <div className="flex items-center flex-col max-w-[470px] gap-[50px]">
          <div className="flex gap-[20px]">
            <img
              src={purpleCircle}
              className="w-[20px] sm450:w-[30px] sm900:w-[36px]"
            />
            <p className="gradient-text text-[20px] sm450:text-[26px] sm900:text-[32px] font-bold tracking-wider">
              Something Went Wrong
            </p>
          </div>
          <p className="text-[#050934] text-[14px] sm450:text-[16px] sm900:text-[18px] tracking-wide max-w-[80%] sm450:max-w-[380px] text-center sm900:max-w-[400px] opacity-50">
            We apologize, due to network congestion, your Certificate will be
            created with a delay. It will be automatically assigned to your
            document. After its creation, the ready certificate can be found by
            selecting the document from the list of documents in Contract Reader
            and clicking the 'Show Certificate' button.
          </p>
          <button
            onClick={handleGoBack}
            className="bg-[#0023EF1A] border-[1px] border-[gray] border-opacity-20 w-full sm:w-[150px] h-[50px] flex items-center justify-center gap-[10px] text-[#0023EF] font-bold"
          >
            <p>Back</p>
            <BiSend color="#0023EF" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default CertificateError;
