// ----------------------
// Internal packages
// ----------------------
import { useRef } from "react";
import handleToastError from "../../../utils/utility/handleToastError";

// ----------------------
// Assets
// ----------------------
import { ReactComponent as SoundOffIcon } from "../../../assets/images/soundOffIcon.svg";
import { ReactComponent as SoundOnIcon } from "../../../assets/images/soundOn.svg";

// ----------------------
// Interfaces
// ----------------------
import { Action, ActionType, State } from "../../../state/interfaces";

interface SoundIconProps {
  dispatch: React.Dispatch<Action>;
  state: State;
}

function SoundIcon({ dispatch, state }: SoundIconProps) {
  const animationLock = useRef(false);

  const toggleSound = () => {
    // Check if there is already an ongoing toggle action
    if (animationLock.current) return;

    const isSafari = () => {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    };

    if (isSafari()) {
      handleToastError("Text-to-speech is not available on this browser!");
      return;
    } else {
      animationLock.current = true;
      dispatch({
        type: ActionType.SET_SOUND_ON,
        payload: !state.soundOn,
      });
      setTimeout(() => {
        animationLock.current = false;
      }, 500);
    }
  };

  return (
    <div
      onClick={toggleSound}
      className="flex items-center gap-[5px] cursor-pointer"
    >
      <div className="flex gap-[5px] items-center">
        <button className="w-[16px]">
          {state.soundOn ? (
            <SoundOnIcon className="w-full h-full" />
          ) : (
            <SoundOffIcon className="w-full h-full" />
          )}
        </button>
        <p className="text-[#ffff] hidden lg:block text-[14px] leading-[20px]">
          Sound {state.soundOn ? "on" : "off"}
        </p>
        <p className="text-[#ffff] block lg:hidden text-[14px]">Sound</p>
      </div>
    </div>
  );
}

export default SoundIcon;
