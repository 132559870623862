// useProviderLogin.js
import { useEffect } from "react";
import axios from "axios";
import { Action, ActionType } from "../../state/interfaces";
import handleToastError from "../utility/handleToastError";

interface UserDetailsResponse {
  status: "success";
  userDetails: {
    avatar: string;
    wallet: string;
    devices: Array<{
      access: number;
      ipAddress: string;
      name: string;
      createdAt: Date;
    }>;
  };
}

const getUserDetails = async (): Promise<UserDetailsResponse | undefined> => {
  try {
    const response = await axios.get<UserDetailsResponse>(
      `${process.env.REACT_APP_BASE_URL}/users/data`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 403) {
        handleToastError(
          "Access denied! Please log out and log back in to solve this issue."
        );
      } else {
        console.error("Failed to update user wallet:", error);
      }
    } else {
      console.error("An unexpected error occurred:", error);
    }
  }
};

const getAuthUser = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/auth/user`,
    {
      withCredentials: true,
    }
  );
  return response.data;
};

const useProviderLogin = (
  provider: string | null,
  dispatch: React.Dispatch<Action>
) => {
  useEffect(() => {
    if (provider) {
      (async () => {
        try {
          const userData = await getAuthUser();

          const data = await getUserDetails();

          if (!data) {
            handleToastError("Unexpected error!");
            return;
          }

          dispatch({
            type: ActionType.SET_USER_AVATAR,
            payload: data.userDetails.avatar,
          });

          // Assuming the username is somehow retrievable; adjust as needed
          dispatch({
            type: ActionType.SET_WEB2_EMAIL,
            payload: userData.userData.email,
          });

          dispatch({ type: ActionType.SET_WEB2_LOGIN, payload: true });
        } catch (error) {
          console.error("Error fetching user details:", error);
          // Handle error, e.g., by showing a notification to the user
        }
      })();
    }
  }, [provider, dispatch]);
};

export default useProviderLogin;
