// ----------------------
// External/Internal Packages
// ----------------------
import React from "react";

// ----------------------
// Utility Functions
// ----------------------
import { handleDzinMessage } from "../../utils/handleDzinMessage";

// ----------------------
// Interfaces
// ----------------------
import { Action, ActionType } from "../../../../state/interfaces";

interface BuyCertificateProps {
  setBubbleMessageActive: React.Dispatch<React.SetStateAction<boolean>>;
  setBubbleMessageContent: React.Dispatch<React.SetStateAction<string>>;
  dispatch: React.Dispatch<Action>;
}

function BuyCertificate({
  setBubbleMessageActive,
  setBubbleMessageContent,
  dispatch,
}: BuyCertificateProps) {
  return (
    <button
      onClick={() => {
        dispatch({
          type: ActionType.SET_GET_CERTIFICATE,
          payload: true,
        });
        handleDzinMessage(
          setBubbleMessageActive,
          setBubbleMessageContent,
          false,
          ""
        );
      }}
      className="font-TT bg-connect-button tracking-widest text-[#ffff] w-full max-w-[400px] md:w-[220px] h-[65px] rounded-[2px] font-bold text-[16px]"
      onMouseEnter={() => {
        handleDzinMessage(
          setBubbleMessageActive,
          setBubbleMessageContent,
          true,
          "Once the transaction is completed, you can get your certificate from here."
        );
      }}
      onMouseLeave={() => {
        handleDzinMessage(
          setBubbleMessageActive,
          setBubbleMessageContent,
          false,
          ""
        );
      }}
    >
      Buy Certificate
    </button>
  );
}

export default BuyCertificate;
