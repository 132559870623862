// ----------------------
// Packages
// ----------------------
import React from "react";
import ChainButton from "./ChainButton";

// ----------------------
// Assets
// ----------------------

interface ChainDropDownProps {
  setChainChosen: React.Dispatch<React.SetStateAction<string>>;
}

function ChainDropDown(props: ChainDropDownProps) {
  return (
    <div className="absolute top-full mt-2 font-bold z-[100] w-[120px] lg:w-[150px] bg-white divide-y divide-gray-100 shadow-lg rounded-lg overflow-hidden first-dropdown">
      <ChainButton setChainChosen={props.setChainChosen} type="Ethereum" />
    </div>
  );
}

export default ChainDropDown;
