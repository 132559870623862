// ----------------------
// External/Internal Packages
// ----------------------
import React, { useState, useCallback } from "react";
import Calendar from "react-calendar";

// ----------------------
// Utility Functions
// ----------------------
import { handleUserMessage } from "../../../utils/sockets/handleUserMessage";
import { useAccount } from "wagmi";

// ----------------------
// Interfaces and styling
// ----------------------
import { State, Action, Message } from "../../../state/interfaces";
import "react-calendar/dist/Calendar.css";
interface CalendarProps {
  state: State;
  dispatch: React.Dispatch<Action>;
  messageId: string;
}

function CalendarWidget({ state, dispatch, messageId }: CalendarProps) {
  const [dateValue, setDateValue] = useState<Date | null>(new Date());
  const { address } = useAccount();

  const formatDate = useCallback((date: Date) => {
    return date.toLocaleDateString(undefined, {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  }, []);

  const handleWidgetConfirm = async (value: string) => {
    const isLastMessage = checkIfLastMessage(state.messages, messageId);

    if (isLastMessage) {
      handleUserMessage(value, dispatch, state, address);
    }
  };

  const checkIfLastMessage = (
    messages: Message[],
    messageId: string
  ): boolean => {
    if (messages.length === 0) {
      return false;
    }

    const lastMessage = messages[messages.length - 1];
    return lastMessage.id === messageId;
  };

  return (
    <div className="flex flex-col gap-[10px] flex-shrink-0">
      <Calendar
        onChange={(nextValue) => {
          if (Array.isArray(nextValue)) {
            setDateValue(nextValue[0]);
          } else {
            setDateValue(nextValue);
          }
        }}
        value={dateValue}
      />

      <button
        onClick={() => handleWidgetConfirm(formatDate(dateValue as Date))}
        className="rounded-md hover:opacity-90 hover:text-[#ccd1f0] text-[#ffff] bg-gradient-button font-bold text-[12px] md:text-[14px] py-[10px] bg-[#0023EF12]  px-[20px]"
      >
        CONFIRM
      </button>
    </div>
  );
}

export default CalendarWidget;
