import { Action, State } from "../../../state/interfaces";
import SocketHandlerReader from "../../../utils/sockets/handleSocketsReader";

export const updateBackendPayment = async (
  txStatus: string,
  txHash: string,
  tokenType: string | undefined,
  state: State,
  dispatch: React.Dispatch<Action>,
  account: string | null | undefined,
  idOfChosenContract: string,
  paybackActive: boolean,
  typeOfChosenContract: string,
  paybackChosen?: string,
  inputValue?: string
) => {
  if (!(state.socketHandlerReader instanceof SocketHandlerReader)) {
    state.socketHandlerReader = new SocketHandlerReader();
  }

  if (tokenType !== undefined) {
    if (
      paybackActive &&
      paybackChosen &&
      inputValue &&
      inputValue !== "" &&
      inputValue !== "0" &&
      inputValue !== "5" &&
      paybackChosen !== ""
    ) {
      const responseStatus =
        await state.socketHandlerReader?.updateBackendPayment(
          txStatus,
          txHash,
          tokenType,
          dispatch,
          state.summary,
          account,
          idOfChosenContract,
          state,
          paybackActive,
          typeOfChosenContract,
          paybackChosen,
          inputValue
        );

      return responseStatus;
    } else {
      const responseStatus =
        await state.socketHandlerReader?.updateBackendPayment(
          txStatus,
          txHash,
          tokenType,
          dispatch,
          state.summary,
          account,
          idOfChosenContract,
          state,
          paybackActive,
          typeOfChosenContract
        );

      return responseStatus;
    }
  }
};
