// ----------------------
// Packages
// ----------------------
import React from "react";

// ----------------------
// Assets
// ----------------------
import { ReactComponent as LogoVaiot } from "../../assets/images/logoVaiot.svg";

// ----------------------
// Interfaces
// ----------------------
import { Action, ActionType, State } from "../../state/interfaces";

interface LogoButtonProps {
  dispatch: React.Dispatch<Action>;
  state: State;
}

function LogoButton({ dispatch, state }: LogoButtonProps) {
  return (
    <div className="w-full flex items-center justify-center">
      <div
        className={`flex gap-0 sm:gap-[7px] items-center sm900:items-end flex-col sm:flex-row ${
          state.contractReader ||
          state.verifyProofActive ||
          state.signUpPageActive
            ? "sm:flex-row"
            : ""
        }`}
      >
        <h1 className="text-[14px] sm500:text-[16px] lg:text-[18px] text-[#050934] font-bold sm400:block hidden">
          Your Personal Legal Assistant
        </h1>
        <h1 className="text-[14px] sm500:text-[16px] lg:text-[18px] text-[#050934] font-bold sm400:hidden">
          AI Legal Assistant
        </h1>
        <div className="flex items-center justify-center gap-[8px]">
          <p className="text-[16px] font-medium text-[#CECDDA] italic sm900:mb-0 mb-[5px]">
            by
          </p>
          <div className="mb-[4px]">
            <LogoVaiot />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LogoButton;
