import { fetchPaymentState } from "./fetchPaymentState";
import { Action, ActionType, State } from "../interfaces";
import SocketHandler from "../../utils/sockets/handleSocket";
import { updateAgreementRoom } from "../../components/ContractReader/utils/updateAgreementRoom";

export async function handlePaymentStatus(
  token: string | null,
  paymentType: string | null,
  contractId: string | null,
  status: string | null,
  paymentFinished: boolean,
  state: State,
  dispatch: React.Dispatch<Action>,
  account: string | null | undefined
) {
  if (status) {
    if (status !== "success") {
      window.history.replaceState(
        {},
        document.title,
        window.location.origin + window.location.pathname
      );
      return;
    }
  }

  switch (paymentType) {
    case "snark":
      updateAgreementRoom(
        state,
        dispatch,
        account,
        state.idOfChosenContract,
        state.typeOfChosenContract
      );
      if (!paymentFinished && token && contractId) {
        const tokenOk = await fetchPaymentState(token, contractId);
        if (tokenOk) {
          dispatch({ type: ActionType.SET_PAYMENT_FINISHED, payload: true });
        } else {
          dispatch({ type: ActionType.SET_PAYMENT_ACTIVE, payload: false });
          window.history.replaceState(
            {},
            document.title,
            window.location.origin + window.location.pathname
          );
        }
      }
      break;

    case "agreement":
      if (!(state.socketHandler instanceof SocketHandler)) {
        state.socketHandler = new SocketHandler();
      }

      state.socketHandler
        .verifyPayment(token)
        .then((tokenOk) => {
          if (tokenOk) {
            // dispatch({ type: ActionType.SET_FETCHING_MESSAGE, payload: false });
            // dispatch({ type: ActionType.SET_THINKING_MESSAGE, payload: "" });
            state.socketHandler?.setDispatch(dispatch, state);
          }
        })
        .catch((error) => {
          console.error("An error occurred:", error);
        });

      window.history.replaceState(
        {},
        document.title,
        window.location.origin + window.location.pathname
      );
      break;
  }
}
