import { useCallback, useEffect } from "react";
import { Action, QueueItem, State } from "../../state/interfaces";
import { ActionType } from "../../state/interfaces";
import { synthesizeText } from "../api/api";

export function useMessageQueue(
  state: State,
  dispatch: React.Dispatch<Action>,
  messageQueue: React.MutableRefObject<QueueItem[]>,
  currentAudio: React.MutableRefObject<HTMLAudioElement | null>
) {
  const isSafari = () => {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  };

  function textToSSML(text: string): string {
    const cleanedText = text.replace(/&nbsp;|<br\/>|<br>/g, "");
    return `<speak>${cleanedText.replace(
      /VAIOT/g,
      '<phoneme alphabet="ipa" ph="ˈveɪ.ət">VAIOT</phoneme>'
    )}</speak>`;
  }

  const playAudio = (audioSrc: any, volume: any) => {
    if (isSafari()) {
      volume = 0;
    }

    const audio = new Audio(audioSrc);
    audio.volume = volume;
    currentAudio.current = audio;

    return new Promise((resolve, reject) => {
      audio.onended = resolve;
      audio.onerror = reject;
      audio.play().catch((error) => {
        console.error("Playback failed", error);
        reject(error);
      });
    });
  };

  const speak = useCallback(
    async (
      text: string | string[],
      speakingRate: number = 1.2
    ): Promise<void> => {
      if (process.env.REACT_APP_NODE_ENV === "development" || isSafari()) {
        return new Promise((resolve) => setTimeout(resolve, 3000));
      }

      const ssmlText = textToSSML(Array.isArray(text) ? text.join(" ") : text);

      const requestBody = {
        input: { ssml: ssmlText },
        voice: { languageCode: "en-US", name: "en-US-Neural2-D" },
        audioConfig: { audioEncoding: "MP3", speakingRate },
      };

      try {
        const response = await synthesizeText(requestBody);
        const audioSrc = `data:audio/mp3;base64,${response.data.audioContent}`;
        const volume = isSafari() ? 0 : state.soundOn ? 1 : 0;

        // Use playAudio function to handle audio playback
        await playAudio(audioSrc, volume);
      } catch (error) {
        console.error(error);
      }
    },
    [state.soundOn, playAudio]
  );

  const handleMessages = useCallback(async () => {
    while (messageQueue.current.length > 0) {
      const message = messageQueue.current.shift() as QueueItem;
      if (message.type === "BUTTONS" || message.type === "BUTTONS-FIRST") {
        // Pass the array of texts for buttons directly
        await speak(message.text[0]);
      } else {
        // Read aloud the single string message
        await speak(message.text);
      }
    }
    dispatch({ type: ActionType.SET_IS_SPEAKING, payload: false });
  }, [dispatch, speak, messageQueue, ActionType]);

  useEffect(() => {
    if (isSafari() && currentAudio.current) {
      currentAudio.current.volume = 0;
      return;
    }
    if (currentAudio.current) {
      currentAudio.current.volume = state.soundOn ? 1 : 0;
    }
  }, [state.soundOn]);

  useEffect(() => {
    // When soundOn changes, stop any currently playing audio
    if (currentAudio.current && !currentAudio.current.paused) {
      currentAudio.current.pause();
      currentAudio.current.currentTime = 0; // Reset the audio to the start
    }
  }, [state.soundOn]);

  useEffect(() => {
    state.messages.forEach((message, index) => {
      if (message.type !== "USER" && !message.doneReading) {
        const textArray = Array.isArray(message.text)
          ? message.text
          : [message.text];
        const buttonTextArray =
          message.type === "BUTTONS" || message.type === "BUTTONS-FIRST"
            ? [message.aboveButtons, ...textArray].filter(
                (text): text is string => typeof text !== "undefined"
              )
            : textArray;

        messageQueue.current.push({
          text: buttonTextArray,
          type: message.type,
        });

        // Update the doneReading flag
        dispatch({
          type: "UPDATE_MESSAGE",
          payload: {
            index: index,
            message: { doneReading: true },
          },
        });
      }
    });

    if (!state.isSpeaking && messageQueue.current.length > 0) {
      dispatch({ type: ActionType.SET_IS_SPEAKING, payload: true });

      handleMessages();
    }
  }, [
    state.messages,
    handleMessages,
    dispatch,
    messageQueue,
    state.isSpeaking,
  ]);

  return { handleMessages };
}
