import axios, { AxiosResponse } from "axios";
import handleToastError from "../utility/handleToastError";

// Define a type for our Game model
interface Game {
  walletAddress: string;
  saveAgreementOnBlockchain: boolean;
  payByCrypto: boolean;
  payByFiat: boolean;
  summaryCreated: boolean;
  certificateCreated: boolean;
  certificateCryptoPayment: boolean;
  certificateFiatPayment: boolean;
  certificateVerified: boolean;
}

// Create Game
export const createGame = async (walletAddress: string | null | undefined) => {
  if (typeof walletAddress === "string" && walletAddress !== "") {
    try {
      const response: AxiosResponse<Game> = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/beta-test-game/create-game`,
        {
          walletAddress,
        }
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 403) {
          handleToastError(
            "Access denied! Please log out and log back in to solve this issue."
          );
        } else {
          console.error(error);
        }
      } else {
        console.error("An unexpected error occurred:", error);
      }
    }
  }
};

// Update Game Field
export const updateGameField = async (
  walletAddress: string | null | undefined,
  field: string,
  value: boolean
) => {
  if (typeof walletAddress === "string" && walletAddress !== "") {
    try {
      const response: AxiosResponse<Game> = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/beta-test-game/update-field`,
        {
          walletAddress,
          field,
          value,
        }
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 403) {
          handleToastError(
            "Access denied! Please log out and log back in to solve this issue."
          );
        } else {
          console.error(error);
        }
      } else {
        console.error("An unexpected error occurred:", error);
      }
    }
  }
};
