import { Action, ActionType } from "../../../state/interfaces";

export const resetListOfCertificates = (dispatch: React.Dispatch<Action>) => {
  dispatch({ type: ActionType.SET_GET_CERTIFICATE, payload: true });
  dispatch({
    type: ActionType.SET_LIST_OF_CERTIFICATES,
    payload: false,
  });
  dispatch({
    type: ActionType.SET_CONTRACT_CHOSEN,
    payload: true,
  });
  dispatch({ type: ActionType.SET_PAYMENT_FINISHED, payload: false });
  dispatch({ type: ActionType.SET_CERTIFICATE_PAID, payload: false });
  dispatch({
    type: ActionType.SET_LIST_OF_CERTIFICATES,
    payload: false,
  });
  dispatch({
    type: ActionType.SET_DOWNLOAD_CERTIFICATE,
    payload: false,
  });
  dispatch({
    type: ActionType.SET_CERTIFICATE_FIELD_CHOICE,
    payload: false,
  });
};
