import { useEffect } from "react";
import { State } from "../../state/interfaces";
import { saveToLocalStorage } from "./saveToLocalStorage";
import axios from "axios";

const usePostToRedis = (state: State) => {
  useEffect(() => {
    const {
      websocket,
      socketHandler,
      socketHandlerReader,
      dialogState,
      modal,
      ...rest
    } = state;
    saveToLocalStorage(state);

    // const postToRedis = async () => {
    //   try {
    //     await axios.post(
    //       `${process.env.REACT_APP_BASE_URL}/conversations/post-to-redis`,
    //       {
    //         key: state.userId,
    //         value: rest,
    //       }
    //     );
    //   } catch (error) {
    //     console.error("Error posting to Redis: ", error);
    //   }
    // };

    // postToRedis();
  }, [state]);
};

export default usePostToRedis;
