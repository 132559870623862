// ----------------------
// Assets
// ----------------------
import { ReactComponent as PolygonFeature } from "../../../assets/images/polygon-feature.svg";
import { ReactComponent as AuditedIcon } from "../../../assets/images/auditedIcon.svg";
import { ReactComponent as Checkmark } from "../../../assets/images/checkmark.svg";
import { ReactComponent as GPTLogo } from "../../../assets/images/GPTLogo.svg";

// ----------------------
// Interfaces and styling
// ----------------------
import { State } from "../../../state/interfaces";
import "../Progress/progressBarStyles.css";

interface ProgramFeaturesProps {
  state: State;
}

const featuresData = [
  { icon: PolygonFeature, label: "Powered by Polygon", hasMargin: true },
  { icon: Checkmark, label: "First VFAA-regulated asset", hasMargin: true },
  { icon: AuditedIcon, label: "Powered by Microsoft CLU", hasMargin: false },
  { icon: GPTLogo, label: "Assisted by GPT-4", hasMargin: false },
];

function ProgramFeatures(props: ProgramFeaturesProps) {
  return (
    <div
      className={`text-[#ffff] h-full p-[20px] gap-[5px] bg-gradient-to-r from-[#1A2980] to-[#0b7c9c] w-full hidden sm:flex flex-col justify-center items-start ${
        props.state.hamburgerMenuOpen ? "dimmed" : ""
      }`}
    >
      <h1 className="font-bold text-[14px] mb-[5px]">
        VAIOT Platform Features:
      </h1>
      <div className="grid grid-cols-2 gap-[3px] sm800:gap-[5px] w-full">
        {featuresData.map(({ icon: FeatureIcon, label, hasMargin }, index) => (
          <div
            key={index}
            className={`flex items-center text-[10px] sm800:text-[12px] md:text-[14px] gap-[5px] ${
              hasMargin ? "mb-[5px]" : ""
            }`}
          >
            <div className="w-[20px] h-[20px] flex-shrink-0">
              <FeatureIcon className="w-full h-full" />
            </div>
            <p>{label}</p>
          </div>
        ))}
      </div>
      {props.state.hamburgerMenuOpen && <div className="dim-overlay"></div>}
    </div>
  );
}

export default ProgramFeatures;
