// ----------------------
// Packages
// ----------------------
import React, { useState } from "react";

// ----------------------
// Utility Functions
// ----------------------
import { useHandleResize } from "../../../utils/utility/useHandleResize";
import { handleChooseCertificate } from "../utils/handleChooseCertificate";

// ----------------------
// Interfaces
// ----------------------
import { Action } from "../../../state/interfaces";

interface ContractListElementProps {
  dispatch: React.Dispatch<Action>;
  purchaseDate: any;
  contractSummary: string;
  proofJson: any;
  proofId: string;
  setCertificateContractAddress: React.Dispatch<React.SetStateAction<string>>;
  certificateContractAddress: string;
}

function CertificateListElement({
  dispatch,
  purchaseDate,
  contractSummary,
  proofJson,
  proofId,
  setCertificateContractAddress,
  certificateContractAddress,
}: ContractListElementProps) {
  const [hover, setHover] = useState(false);
  const desktopSize = useHandleResize(500, "small", "default");
  const largeScreen = useHandleResize(1000, "small", "large");

  function toHumanReadableFormat(isoDateString: string) {
    const date = new Date(isoDateString);
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    const hh = String(date.getHours()).padStart(2, "0");
    const min = String(date.getMinutes()).padStart(2, "0");
    const ss = String(date.getSeconds()).padStart(2, "0");

    return `${yyyy}-${mm}-${dd} ${hh}:${min}:${ss}`;
  }

  return (
    <button
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() =>
        handleChooseCertificate(
          setCertificateContractAddress,
          proofJson,
          dispatch,
          certificateContractAddress,
          proofId
        )
      }
      className="flex gap-[10px] text-[12px] sm:text-[14px] cursor-pointer justify-between items-center w-full mt-[10px] text-[#0023EF]"
    >
      <div
        className={`${
          hover
            ? "bg-certificate-background-hover shadow-lg"
            : "bg-certificate-background"
        }  w-[30%]  font-bold tracking-wider rounded-md py-[15px] h-[100px] flex items-center justify-center`}
      >
        {toHumanReadableFormat(purchaseDate)}
      </div>

      <div
        className={`${
          hover
            ? "bg-certificate-background-hover shadow-lg"
            : "bg-certificate-background"
        } w-[70%]  font-bold tracking-wider rounded-md p-[15px] h-[100px] text-start`}
      >
        {desktopSize === "small"
          ? contractSummary.length > 80
            ? contractSummary.slice(0, 90) + "..."
            : contractSummary
          : largeScreen === "large"
          ? contractSummary.slice(0, 250) + "..."
          : contractSummary.length > 100
          ? contractSummary.slice(0, 170) + "..."
          : contractSummary}
      </div>
    </button>
  );
}

export default CertificateListElement;
