import React, { useState } from "react";
import { Action, State } from "../../../state/interfaces";

interface DeleteAccountProps {
  dispatch: React.Dispatch<Action>;
  state: State;
}

function DeleteAccount({ dispatch, state }: DeleteAccountProps) {
  const handleDeleteAccount = () => {
    dispatch({ type: "SET_DELETE_ACCOUNT_MODAL_ACTIVE", payload: true });
  };

  return (
    <div className="bg-[#F7F8FD] w-full py-[20px] px-[10px] sm500:px-[20px]">
      <div className="flex flex-col font-TT gap-[10px] tracking-wide bg-[#fff] px-[20px] py-[15px] rounded-[6px] shadow-sm border-[#E5E4F0] border-[1px]">
        <p className="font-bold">Delete Account</p>
        <p className="font-bold">
          Would you like to delete your{" "}
          <span className="text-[#0057FF]">
            {"@" + state.web2Email.split("@")[0]}
          </span>{" "}
          account?
        </p>
        <p>
          Deleting your account will remove all of your content and data
          associated with it.
        </p>
        <div>
          <button
            onClick={handleDeleteAccount}
            className="text-[#0057FF] font-bold"
          >
            I want to delete my account
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteAccount;
