import { State } from "../../state/interfaces";

export function saveToLocalStorage(state: State) {
  try {
    const stateToStore =
      state.watermark !== 0
        ? {
            messages: state.messages,
            tutorialActive: state.tutorialActive,
            contractReader: state.contractReader,
            conversationStarted: state.conversationStarted,
            conversationId: state.conversationId,
            connectionScreenActive: state.connectionScreenActive,
            fetchingMessage: state.fetchingMessage,
            web2Login: state.web2Login,
            web2Email: state.web2Email,
            watermark: state.watermark,
            userAvatar: state.userAvatar,
            buttonStates: state.buttonStates,
            dialoguePaymentPending: state.dialoguePaymentPending,
            conversationState: state.conversationState,
            idOfChosenContract: state.idOfChosenContract,
            typeOfChosenContract: state.typeOfChosenContract,
            thinkingMessage: state.thinkingMessage,
          }
        : {
            messages: state.messages,
            tutorialActive: state.tutorialActive,
            contractReader: state.contractReader,
            conversationStarted: state.conversationStarted,
            conversationId: state.conversationId,
            connectionScreenActive: state.connectionScreenActive,
            fetchingMessage: state.fetchingMessage,
            web2Login: state.web2Login,
            web2Email: state.web2Email,
            userAvatar: state.userAvatar,
            buttonStates: state.buttonStates,
            dialoguePaymentPending: state.dialoguePaymentPending,
            conversationState: state.conversationState,
            idOfChosenContract: state.idOfChosenContract,
            typeOfChosenContract: state.typeOfChosenContract,
            thinkingMessage: state.thinkingMessage,
          };
    const serializedState = JSON.stringify(stateToStore);
    localStorage.setItem("state", serializedState);
  } catch (e) {
    console.warn("Error saving state to local storage.");
  }
}
