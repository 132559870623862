// ----------------------
// Packages
// ----------------------
import React, { useState } from "react";
import { useAccount } from "wagmi";

// ----------------------
// Utility Functions
// ----------------------
import { RenderContractReader } from "./renderContractReader";
import useFetchAndCheckContract from "./utils/useFetchAndCheckContract";
import useFetchContractList from "./utils/useFetchContractList";

// ----------------------
// Interfaces and Styles
// ----------------------
import { Action, State } from "../../state/interfaces";

interface ContractTerms {
  agreementTerms: Array<any>;
  parties: Array<string>;
  services: Array<Array<string>>;
  onBlockchain: string;
}

interface ContractInfoNDA {
  creationTimestamp: string;
  fieldNames: string[];
  fieldValues: string[];
  partyA: string;
  partyB: string;
}

interface ContractReaderProps {
  dispatch: React.Dispatch<Action>;
  state: State;
}

function ContractReader({ dispatch, state }: ContractReaderProps) {
  const { address } = useAccount();

  const isActive = address !== undefined;

  const [contractInfoSA, setContractInfoSA] = useState<ContractTerms>();

  const [contractInfoNDA, setContractInfoNDA] = useState<ContractInfoNDA>();

  useFetchAndCheckContract(
    state,
    address,
    state.idOfChosenContract,
    dispatch,
    isActive,
    setContractInfoSA,
    state.typeOfChosenContract,
    setContractInfoNDA
  );

  useFetchContractList(isActive, address, dispatch);

  return (
    <div
      key={state.paymentActive.toString()}
      className={`bg-[#F7F8FD] h-full w-full flex justify-center relative overflow-y-auto  ${
        isActive && state.contractChosen ? "items-start" : "items-center"
      }`}
    >
      {RenderContractReader(
        state,
        dispatch,
        state.idOfChosenContract,
        contractInfoSA,
        contractInfoNDA,
        setContractInfoSA,
        setContractInfoNDA,
        state.typeOfChosenContract
      )}
    </div>
  );
}

export default ContractReader;
