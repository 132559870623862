// ----------------------
// Packages
// ----------------------
import React from "react";
import { handleSignUp } from "../../utils/api/handleSignUp";
import handleToastError from "../../utils/utility/handleToastError";
import { handleLogIn } from "../../utils/api/handleLogIn";
import { Action, ActionType } from "../../state/interfaces";

interface AccountCreationConfirmButtonProps {
  text: string;
  isLogInPage: boolean;
  email: string;
  password: string;
  dispatch: React.Dispatch<Action>;
  passwordValidations: {
    length: boolean;
    uppercase: boolean;
    digit: boolean;
  };
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  setShowForgotPassword: React.Dispatch<React.SetStateAction<boolean>>;
}

function AccountCreationConfirmButton({
  text,
  isLogInPage,
  email,
  password,
  passwordValidations,
  dispatch,
  setPassword,
  setShowForgotPassword,
}: AccountCreationConfirmButtonProps) {
  const handleClick = async () => {
    if (!Object.values(passwordValidations).every(Boolean) && !isLogInPage) {
      handleToastError("Incorrect password format!");
      return;
    }

    if (!isLogInPage) {
      await handleSignUp("email", dispatch, {
        username: email,
        password: password,
      });
    } else {
      await handleLogIn(
        email,
        password,
        dispatch,
        setPassword,
        setShowForgotPassword
      );
    }
  };

  return (
    <button
      onClick={handleClick}
      className="bg-gradient-button w-[180px] h-[44px] text-[14px] font-semibold shadow-sm text-[#fff] rounded-[6px]"
    >
      {text}
    </button>
  );
}

export default AccountCreationConfirmButton;
