// ----------------------
// External/Internal Packages
// ----------------------
import React, { useRef, useState } from "react";

// ----------------------
// Interfaces, assets and styling
// ----------------------
import { Action, State, QueueItem } from "../../state/interfaces";
import "./chatbox.css";

// ----------------------
// Components
// ----------------------
import BeginningScreen from "./BeginningScreen";
import FullChat from "./FullChat";
import ThinkingChat from "./ThinkingChat";

// ----------------------
// Utility functions and hooks
// ----------------------
import { useChatInitiation } from "../../utils/sockets/useChatInitiation";
import useHandleWalletConnection from "../../utils/utility/useHandleWalletConnection";

interface ChatBoxProps {
  messageQueue: React.MutableRefObject<QueueItem[]>;
  currentAudio: React.MutableRefObject<HTMLAudioElement | null>;
  processedMessageIds: React.MutableRefObject<Set<unknown>>;
  dispatch: React.Dispatch<Action>;
  state: State;
}

function ChatBox(props: ChatBoxProps) {
  const chatContainerRef = useRef<HTMLDivElement>(null);

  // Hook that starts off the conversation + handles if someone refreshes when it is starting
  useChatInitiation(props.state, props.dispatch);
  useHandleWalletConnection(props.state);

  return (
    <div
      ref={chatContainerRef}
      className="flex flex-col w-full overflow-y-auto overflow-x-hidden chat-container"
    >
      <div className="flex-grow">
        <div className="p-[30px] md:p-[50px] lg:p-[70px] ">
          {!props.state.conversationStarted ? (
            <BeginningScreen state={props.state} dispatch={props.dispatch} />
          ) : (
            <div className="flex flex-col gap-[10px] ">
              {props.state.conversationId === "" ? (
                <ThinkingChat state={props.state} />
              ) : (
                <FullChat
                  chatContainerRef={chatContainerRef}
                  messageQueue={props.messageQueue}
                  currentAudio={props.currentAudio}
                  processedMessageIds={props.processedMessageIds}
                  dispatch={props.dispatch}
                  state={props.state}
                />
              )}
              {props.state.fetchingMessage && (
                <ThinkingChat state={props.state} />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default React.memo(ChatBox);
