// Interfaces
import { Action, State } from "../interfaces";

export const appendCertificateFields = (
  state: State,
  action: Action
): State => {
  if (action.type === "APPEND_CERTIFICATE_FIELDS") {
    return {
      ...state,
      certificateFields: [...state.certificateFields, action.payload],
    };
  } else {
    return state;
  }
};

export const removeCertificateFields = (
  state: State,
  action: Action
): State => {
  if (action.type === "REMOVE_CERTIFICATE_FIELDS") {
    return {
      ...state,
      certificateFields: state.certificateFields.filter(
        (certificateField) =>
          certificateField.fieldName !== action.payload.fieldName ||
          certificateField.value !== action.payload.value
      ),
    };
  } else {
    return state;
  }
};
