import axios from "axios";
import { Action, ActionType } from "../../state/interfaces";
import handleToastError from "../utility/handleToastError";
import { getUserDetails } from "./getUserDetails";

interface LoginResponse {
  status: "actionRequired" | "success" | "noData";
  userData?: {
    username: string;
    email: string;
  };
}

export const handleLogIn = async (
  username: string,
  password: string,
  dispatch: React.Dispatch<Action>,
  setPassword: React.Dispatch<React.SetStateAction<string>>,
  onIncorrectLogin?: React.Dispatch<React.SetStateAction<boolean>>,
  authCode?: number
) => {
  try {
    // Prepare requestBody with or without authCode based on its presence
    const requestBody = authCode
      ? { username, password, authCode: authCode.toString() }
      : { username, password };

    const response = await axios.post<LoginResponse>(
      `${process.env.REACT_APP_BASE_URL}/auth/login`,
      requestBody,
      { withCredentials: true }
    );

    // Handle the response based on the login attempt status
    switch (response.data.status) {
      case "success":
        localStorage.setItem(
          "userData",
          JSON.stringify(response.data.userData)
        );
        dispatch({ type: ActionType.SET_WEB2_LOGIN, payload: true });

        dispatch({ type: ActionType.SET_WEB3_LOGIN, payload: false });
        const data = await getUserDetails();
        if (!data) {
          handleToastError("Unexpected error!");
          return;
        }
        dispatch({
          type: ActionType.SET_USER_AVATAR,
          payload: data.userDetails.avatar,
        });
        dispatch({ type: ActionType.SET_WEB2_EMAIL, payload: username });
        dispatch({ type: ActionType.SET_LOG_IN_PAGE_ACTIVE, payload: false });
        dispatch({
          type: ActionType.SET_LOGIN_CODE_MODAL_ACTIVE,
          payload: false,
        });
        break;
      default:
        console.error("Login process encountered an unexpected status.");
    }
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const responseStatus = error.response.data.status;

      if (error.response.status === 429) {
        handleToastError("You are being rate limited.");
      } else if (responseStatus === "noData") {
        handleToastError("Incorrect password or email!");
        setPassword("");
        if (onIncorrectLogin) {
          dispatch({
            type: ActionType.SET_FORGOT_PASSWORD_EMAIL,
            payload: username,
          });
          onIncorrectLogin(true);
        }
        return;
      } else if (responseStatus === "actionRequired") {
        if (authCode) {
          handleToastError("Code invalid! Check your email for the new code.");
          return;
        }
        dispatch({
          type: ActionType.SET_LOGIN_CODE_MODAL_ACTIVE,
          payload: true,
        });
      } else {
        console.error("An error occurred during the login process:", error);
      }
    } else {
      console.error("An unexpected error occurred:", error);
    }
  }
};
