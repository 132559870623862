// ----------------------
// Assets
// ----------------------
import chevronDown from "../../../../../assets/images/chevronDown.svg";
import chevronUp from "../../../../../assets/images/chevronUp.svg";

// ----------------------
// Components
// ----------------------
import ImageDropDown from "./ImageDropDown";

interface DefaultCurrencyProps {
  secondDropdownOpen: boolean;
}

function DefaultCurrency({ secondDropdownOpen }: DefaultCurrencyProps) {
  return (
    <div className="flex items-center justify-between gap-[5px]">
      <p className="text-[gray] opacity-[30%] text-[12px]">Choose currency</p>
      {!secondDropdownOpen && <ImageDropDown src={chevronDown} />}
      {secondDropdownOpen && <ImageDropDown src={chevronUp} />}
    </div>
  );
}

export default DefaultCurrency;
