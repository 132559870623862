import axios from "axios";

export const handleResetPassword = async (email: string) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/auth/reset-password`,
      { email },
      {
        withCredentials: true,
      }
    );
  } catch (error) {
    console.error("Error when reseting password:", error);
  }
};
