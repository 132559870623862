// ----------------------
// External/Internal Packages
// ----------------------
import React from "react";

// ----------------------
// Interfaces, assets and styling
// ----------------------
import { State, Action, ActionType } from "../../state/interfaces";
import dzinAvatar from "../../assets/images/dzinAvatar.svg";
import triangle from "../../assets/images/whiteTriangle.svg";
import "./chatbox.css";

// ----------------------
// Utils Functions
// ----------------------
import { handleStartConversation } from "../../utils/sockets/handleStartConversation";

interface BegginingScreenProps {
  state: State;
  dispatch: React.Dispatch<Action>;
}

function BeginningScreen(props: BegginingScreenProps) {
  return (
    <div className={`flex gap-[20px] items-start`}>
      <img
        src={dzinAvatar}
        className="flex-shrink-0 mt-[20px]"
        alt="Dzin avatar"
      />

      <div className={"flex flex-col justify-center gap-[5px]"}>
        <p className="text-[12px] font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-[#0023EF] blue via-[#77F3E0] to-[#77F3E0]  tracking-wider">
          VAIOT ADVISOR
        </p>
        <div
          className={`bg-[#ffff] p-4 rounded-[2px] max-w-[70vw]   sm:max-w-[40vw] flex flex-col gap-[10px] relative`}
        >
          <img
            src={triangle}
            className="absolute bottom-0 left-[-2.5%]"
            alt="White triangle"
          />
          <p className="font-semibold">
            Hi! My name is VAIOT. I will help you create the legal document you
            need.
          </p>
        </div>
        <div
          className={`bg-[#ffff] p-2 rounded-[2px] w-[150px] flex items-center justify-center gap-[10px]`}
        >
          <button
            onClick={() => {
              props.dispatch({
                type: ActionType.SET_DIALOG_IS_RESTARTING,
                payload: true,
              });
              handleStartConversation(props.state, props.dispatch, true);
            }}
            className="rounded-[2px]  text-[#ffff] bg-gradient-button hover:bg-gradient-button-hover font-bold text-[12px] md:text-[14px] w-[140px] h-[50px]"
          >
            Get started
          </button>
        </div>
      </div>
    </div>
  );
}

export default BeginningScreen;
