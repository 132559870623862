import React, { useEffect, useState } from "react";
import { Action, ActionType, State } from "../../../state/interfaces";
import { handleOpenSignUpModal } from "../../../utils/utility/handleOpenSignUpModal";
import { handleOpenLogInModal } from "../../../utils/utility/handleOpenLogInModal";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import gbFlag from "../../../assets/images/great-britain-flag.svg";
import { useAccount } from "wagmi";

interface Web2AccountButtons {
  state: State;
  dispatch: React.Dispatch<Action>;
  setModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  mobile: boolean;
  setIsUpdatingWallet?: React.Dispatch<React.SetStateAction<boolean>>;
}

function Web2AccountButtons({
  state,
  dispatch,
  setModalOpen,
  mobile,
  setIsUpdatingWallet,
}: Web2AccountButtons) {
  const { open } = useWeb3Modal();
  const { address } = useAccount();

  const isActive = address !== undefined;

  return (
    <div className={`flex flex-col gap-[10px] tracking-wide `}>
      {mobile && (
        <button className="my-[20px]">
          <img
            src={gbFlag}
            className="w-[30px] h-full cursor-pointer "
            alt="Great Britain Flag"
          />
        </button>
      )}
      <button
        onClick={() => handleOpenSignUpModal(dispatch, setModalOpen)}
        className={`text-center hover:bg-gray-50 bg-connect-button font-bold text-[#fff]  ${
          mobile
            ? "text-[14px] w-[240px] h-[50px]"
            : "text-[12px] w-[120px] h-[35px]"
        }`}
      >
        Sign Up
      </button>
      {!isActive && (
        <button
          onClick={() => {
            if (setIsUpdatingWallet) {
              setIsUpdatingWallet(true);
            }
            try {
              if (setModalOpen) {
                setModalOpen(false);
              }
              open();
            } catch (error) {
              console.error(error);
            }
          }}
          className={`text-center hover:bg-gray-50 bg-connect-button font-bold  ${
            mobile
              ? "text-[14px] w-[240px] h-[50px] bg-second-connect-button text-[#0023EF] border-[1px] border-[#0023EF12]"
              : "text-[12px] w-[120px] h-[35px] bg-connect-button text-[#fff]"
          }`}
        >
          Connect Wallet
        </button>
      )}
      <button
        onClick={() => handleOpenLogInModal(dispatch, setModalOpen)}
        className={`bg-second-connect-button border-[1px] border-[#0023EF12] font-bold text-[#0023EF] ${
          mobile
            ? "text-[14px] w-[240px] h-[50px]"
            : "text-[12px] w-[120px] h-[35px]"
        }`}
      >
        Log In
      </button>
    </div>
  );
}

export default Web2AccountButtons;
