import handleToastError from "./handleToastError";

export const redirectToChainExplorer = (
  e: React.MouseEvent,
  account: string | null | undefined,
  chainId: number | undefined,
  active: boolean
) => {
  e.preventDefault();
  let url = "";

  const VALID_CHAIN_IDS = [1, 137, 80001, 5, 11155111];

  if (account && active) {
    if (chainId && !VALID_CHAIN_IDS.includes(chainId)) {
      handleToastError("Connect to a supported network!");
      return;
    }

    if (chainId)
      if (chainId === 1) {
        url = `https://etherscan.io/address/${account}`;
      } else if (chainId === 137) {
        url = `https://polygonscan.com/address/${account}`;
      } else if (chainId === 80001) {
        url = `https://mumbai.polygonscan.com/address/${account}`;
      } else if (chainId === 5) {
        url = `https://goerli.etherscan.io/address/${account}`;
      } else if (chainId === 11155111) {
        url = `https://sepolia.etherscan.io/address/${account}`;
      }

    window.open(url, "_blank");
  }
};
