import React from "react";
import errorVaiot from "../../../assets/images/errorVaiot.svg";
import errorSign from "../../../assets/images/errorCoin.svg";
import { Action, ActionType, State } from "../../../state/interfaces";

interface ContractTerms {
  agreementTerms: Array<any>;
  parties: Array<string>;
  services: Array<Array<string>>;
  onBlockchain: string;
}

interface ContractInfoNDA {
  creationTimestamp: string;
  fieldNames: string[];
  fieldValues: string[];
  partyA: string;
  partyB: string;
}

interface DecodedContractErrorProps {
  dispatch: React.Dispatch<Action>;
  state: State;
  setContractInfoSA: React.Dispatch<
    React.SetStateAction<ContractTerms | undefined>
  >;
  setContractInfoNDA: React.Dispatch<
    React.SetStateAction<ContractInfoNDA | undefined>
  >;
}

function DecodedContractError({
  dispatch,
  state,
  setContractInfoSA,
  setContractInfoNDA,
}: DecodedContractErrorProps) {
  const handleGoBack = () => {
    setContractInfoNDA(undefined);
    setContractInfoSA(undefined);
    dispatch({
      type: ActionType.SET_CONTRACT_CHOSEN,
      payload: false,
    });
    dispatch({
      type: ActionType.SET_CONTRACT_CHOSEN,
      payload: false,
    });
  };

  return (
    <div className="p-[20px] flex flex-col h-full gap-[40px] justify-center items-center">
      <img
        src={errorVaiot}
        alt="Error Sign"
        className="w-[60px] sm450:w-[80px] sm:w-[150px]"
      />
      <div className="flex items-center gap-[10px]">
        <img src={errorSign} alt="Error Sign" className="w-[30px]" />
        <p className="font-bold text-[24px] sm:text-[28px] gradient-text">
          Error
        </p>
      </div>
      <p className="text-center text-[gray] text-[14px] sm450:text-[16px] sm:text-[18px]">
        Error fetching contract data.
      </p>
      <button
        onClick={handleGoBack}
        className="rounded-[2px] py-[10px] px-[40px] border-[2px] border-[#0023EF1A] text-[#0023EF] bg-[#0023EF12] font-semibold border-opacity-[10]"
      >
        Go Back
      </button>
    </div>
  );
}

export default DecodedContractError;
