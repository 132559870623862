import axios from "axios";
import handleToastNotification from "../utility/handleToastNotification";
import { Action, ActionType } from "../../state/interfaces";
import handleToastError from "../utility/handleToastError";

function removeCookie(name: string) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

export const handleDeleteAccount = async (
  dispatch: React.Dispatch<Action>,
  setIsLoading: (value: React.SetStateAction<boolean>) => void
) => {
  try {
    await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/users/security/account`,
      { withCredentials: true }
    );
    removeCookie("connect.sid");
    dispatch({
      type: ActionType.SET_DELETE_ACCOUNT_MODAL_ACTIVE,
      payload: false,
    });
    dispatch({ type: ActionType.SET_WEB2_LOGIN, payload: false });
    dispatch({ type: ActionType.SET_WEB3_LOGIN, payload: false });
    dispatch({ type: ActionType.SET_USER_AVATAR, payload: "" });
    dispatch({ type: ActionType.SET_PROFILE_PAGE_ACTIVE, payload: false });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 403) {
        handleToastError(
          "Access denied! Please log out and log back in to solve this issue."
        );
      } else {
        console.error("Error when deleting user account:", error);
      }
    } else {
      console.error("An unexpected error occurred:", error);
    }
    setIsLoading(false);
  }
};
