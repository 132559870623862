import { useEffect } from "react";
import { Action, ActionType } from "../../../state/interfaces";
import { fetchContractList } from "./fetchContractList";

function useFetchContractList(
  active: boolean,
  account: string | null | undefined,
  dispatch: React.Dispatch<Action>
) {
  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    if (active) {
      fetchContractList(account, dispatch);

      timeoutId = setTimeout(() => {
        dispatch({
          type: ActionType.SET_CONTRACT_LIST_ANIMATION_FINISHED,
          payload: true,
        });
      }, 10000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [active, account, dispatch]);
}

export default useFetchContractList;
