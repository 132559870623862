import axios from "axios";
import handleToastError from "../utility/handleToastError";

interface UserInfo {
  balance: string;
  rewards: string;
  depositTimestamp: string;
  lastUpdateTimestamp: string;
  exists: boolean;
}

export const fetchPaybackUserInfo = async (
  walletAddress: string
): Promise<UserInfo> => {
  try {
    const response = await axios.post<UserInfo>(
      `${process.env.REACT_APP_BASE_URL}/payback/userinfo`,
      { walletAddress },
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 403) {
        handleToastError(
          "Access denied! Please log out and log back in to solve this issue."
        );
      } else {
        console.error("Error when fetching user info:", error);
      }
    } else {
      console.error("An unexpected error occurred:", error);
    }
    throw error;
  }
};
