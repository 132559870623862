import React from "react";

function AmountDue() {
  return (
    <div className="font-TT tracking-wide w-full items-center justify-end text-[#0023EF] flex">
      <div className="flex gap-[100px] items-center justify-center border-b-[1px] border-[#CECDDA]">
        <p className="font-semibold text-[24px]">Amount due</p>
        <p className="font-bold text-[38px]">5$</p>
      </div>
    </div>
  );
}

export default AmountDue;
