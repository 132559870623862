import { useEffect } from "react";
import React from "react";
import { Action, State } from "../../../../state/interfaces";
import { handlePaymentStatusUpdate } from "./handlePaymentStatusUpdate";
import { fetchPaymentDialogState } from "./fetchPaymentDialogState";

const useHandleEmergencyPayment = (
  hasRun: React.MutableRefObject<boolean>,
  numOfCalls: number,
  setNumOfCalls: React.Dispatch<React.SetStateAction<number>>,
  firstCall: boolean,
  setFirstCall: React.Dispatch<React.SetStateAction<boolean>>,
  state: State,
  dispatch: React.Dispatch<Action>,
  id: string,
  normalPaymentPending: boolean
) => {
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const buttonState = state.buttonStates.find((button) => button.id === id);
    if (!hasRun.current && buttonState && !normalPaymentPending) {
      const handlePayment = async () => {
        if (
          state.dialoguePaymentPending &&
          buttonState !== undefined &&
          buttonState.state === "PAID"
        ) {
          if (buttonState.numOfCalls === 0) {
            await handlePaymentStatusUpdate(
              "PENDING",
              buttonState.chain,
              buttonState.txHash,
              "Transaction accepted",
              state,
              id,
              setNumOfCalls,
              numOfCalls,
              dispatch
            );
            await fetchPaymentDialogState(
              state,
              dispatch,
              setFirstCall,
              firstCall
            );
          } else if (
            buttonState.numOfCalls > 0 &&
            state.messages.length > 0 &&
            state.messages[state.messages.length - 1].aboveButtons?.includes(
              "Payment summary"
            )
          ) {
            await fetchPaymentDialogState(
              state,
              dispatch,
              setFirstCall,
              firstCall
            );
          }
        } else if (
          state.dialoguePaymentPending &&
          buttonState !== undefined &&
          buttonState.state === "REVOKED"
        ) {
          if (buttonState.numOfCalls === 0) {
            await handlePaymentStatusUpdate(
              "FAILED",
              buttonState.chain,
              "",
              "Transaction declined",
              state,
              id,
              setNumOfCalls,
              numOfCalls,
              dispatch
            );
            await fetchPaymentDialogState(
              state,
              dispatch,
              setFirstCall,
              firstCall
            );
          } else if (
            buttonState.numOfCalls > 0 &&
            state.messages.length > 0 &&
            state.messages[state.messages.length - 1].aboveButtons?.includes(
              "Payment summary"
            )
          ) {
            await fetchPaymentDialogState(
              state,
              dispatch,
              setFirstCall,
              firstCall
            );
          }
        }
      };

      handlePayment();
      hasRun.current = true;
    }
  }, [state.buttonStates]);
};

export default useHandleEmergencyPayment;
