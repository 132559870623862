// ----------------------
// External/Internal Packages
// ----------------------
import React, { useMemo } from "react";

// ----------------------
// Assets
// ----------------------
import dzinAvatar from "../../assets/images/dzinAvatar.svg";
import triangle from "../../assets/images/whiteTriangle.svg";

// ----------------------
// Components
// ----------------------
import ChatButton from "./ChatButton";
import WidgetRenderer from "./WidgetRenderer";

// ----------------------
// Interfaces
// ----------------------
import { State, Action, QueueItem } from "../../state/interfaces";
import TextRenderer from "./TextRenderer";

interface ChatContentProps {
  text?: string | string[];
  buttonText?: string[];
  aboveButtonText?: string;
  textWritten: boolean;
  handleTypingDone: (messageId: string) => void;
  messageId: string;
  messageState: string;
  widget?: boolean;
  widgetType?: string;
  additionalData?: any;
  state: State;
  dispatch: React.Dispatch<Action>;
  firstChat: boolean;
  messageQueue: React.MutableRefObject<QueueItem[]>;
  currentAudio: React.MutableRefObject<HTMLAudioElement | null>;
  values: any[];
}

function ChatContent({
  buttonText = [],
  text,
  aboveButtonText,
  textWritten,
  handleTypingDone,
  messageId,
  messageState,
  widget,
  widgetType,
  state,
  dispatch,
  additionalData,
  firstChat,
  messageQueue,
  currentAudio,
  values,
}: ChatContentProps) {
  const buttons = useMemo(() => {
    if (buttonText !== undefined && messageState === "DISPLAYED" && !widget) {
      return buttonText.map((text, index) => (
        <ChatButton
          key={state.messages[index]?.id || index}
          buttonText={text}
          messageId={messageId}
          state={state}
          dispatch={dispatch}
          messageQueue={messageQueue}
          currentAudio={currentAudio}
          value={values[index]}
        />
      ));
    }
    return null;
  }, [buttonText, messageState, widget, messageId, state, dispatch]);

  return (
    <div
      className={`flex ${
        firstChat
          ? "gap-[20px] items-end"
          : widget && widgetType === "date"
          ? "ml-0 sm450:ml-[66px] sm:ml-[84px]"
          : "ml-[66px] sm:ml-[84px]"
      } `}
    >
      {firstChat && (
        <img
          src={dzinAvatar}
          className="flex-shrink-0 w-[50px] sm:w-[64px]"
          alt="Dzin avatar "
        />
      )}
      <div
        className={`${
          firstChat ? "flex flex-col justify-center gap-[5px]" : ""
        }`}
      >
        {firstChat && (
          <p className="text-[12px] font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-[#0023EF] blue via-[#77F3E0] to-[#77F3E0]  tracking-wider">
            VAIOT ADVISOR
          </p>
        )}
        <div
          className={`bg-[#ffff] p-4 rounded-md ${
            widget && widgetType === "date"
              ? "max-w-[80vw] sm450:max-w-[70vw]"
              : "max-w-[80vw]"
          }  sm:max-w-[40vw] flex flex-col gap-[10px] relative`}
        >
          {firstChat && (
            <img
              src={triangle}
              className="absolute bottom-0 left-[-2.5%]"
              alt="White triangle"
            />
          )}
          {buttonText.length !== 0 && !widget && (
            <TextRenderer
              text={aboveButtonText}
              textWritten={textWritten}
              handleTypingDoneProp={handleTypingDone}
              messageState={messageState}
              messageId={messageId}
            />
          )}
          {buttonText.length === 0 && !widget && (
            <TextRenderer
              text={text}
              textWritten={textWritten}
              handleTypingDoneProp={handleTypingDone}
              messageState={messageState}
              messageId={messageId}
            />
          )}

          {widget && (
            <TextRenderer
              text={aboveButtonText}
              textWritten={textWritten}
              handleTypingDoneProp={handleTypingDone}
              messageState={messageState}
              messageId={messageId}
            />
          )}
          {buttonText !== undefined &&
            messageState === "DISPLAYED" &&
            !widget &&
            buttons}
          {widget && messageState === "DISPLAYED" && (
            <WidgetRenderer
              widgetType={widgetType}
              state={state}
              dispatch={dispatch}
              messageId={messageId}
              additionalData={additionalData}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ChatContent;
