import { ActionType } from "../../../../state/interfaces";

const handleSkip = (
  listening: boolean,
  state: any,
  currentAudio: any,
  dispatch: Function,
  messageQueue: any,
  resetTranscript: () => void
) => {
  setTimeout(() => {
    // Added setTimeout here
    if (!listening || (listening && !state.fetchingMessage)) {
      if (currentAudio.current !== null) {
        currentAudio.current.pause();
        currentAudio.current.currentTime = 0;
        dispatch({ type: ActionType.SET_IS_SPEAKING, payload: false });
      }
      messageQueue.current = [];
      dispatch({
        type: "UPDATE_ALL_MESSAGES",
        newMessageData: { textWritten: false, messageState: "DISPLAYED" },
      });

      resetTranscript();
      dispatch({
        type: ActionType.SET_VOICE_MESSAGE_SENT,
        payload: false,
      });
    }
  }, 200); // delay of 0.2s
};

export default handleSkip;
