// ----------------------
// Utility Functions
// ----------------------
import { downloadFile } from "../utils/downloadFile";

// ----------------------
// Interfaces
// ----------------------
import { State } from "../../../state/interfaces";
import { handleQRCode } from "../utils/handleQrCode";

interface DownloadProps {
  certificateContractAddress: string;
  state: State;
}

function DownloadAll({ certificateContractAddress, state }: DownloadProps) {
  const handleDownload = () => {
    // Download the contract address as a txt file
    downloadFile(certificateContractAddress, "contractAddress.txt");
    // Download the proof JSON as a txt file
    downloadFile(JSON.stringify(state.proofJson, null, 2), "proof.txt");
    // Download QRcode
    handleQRCode(state);
  };

  return (
    <div className="w-full items-center justify-center hidden sm:flex">
      <button className="bg-connect-button w-[180px] h-[60px] text-[#fff] text-[16px] font-bold rounded-md mb-[30px]  tracking-wide">
        <p onClick={handleDownload}>Download All</p>
      </button>
    </div>
  );
}

export default DownloadAll;
