// ----------------------
// Packages
// ----------------------
import React, { useState, useEffect, useRef } from "react";

interface HashDropDownProps {
  selectedNumber: number;
  setSelectedNumber: React.Dispatch<React.SetStateAction<number>>;
  number: number;
}

function HashDropDown(props: HashDropDownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const availableNumbers = [1, 2, 3, 4, 5].filter(
    (n) => n !== props.selectedNumber
  );
  return (
    <div className="relative" ref={wrapperRef}>
      <div className="bg-hash-button w-[100px] px-[10px] h-[30px] flex items-center justify-between">
        <p className="mx-auto">{props.number}</p>
      </div>
      {isOpen && (
        <div className="absolute top-[83%] mt-[5px] w-[100px] bg-[#fff] flex flex-col shadow-lg ">
          {availableNumbers.map((num) => (
            <div
              key={num}
              className="px-[10px] h-[30px] flex items-center justify-center hover:bg-opacity-80 hover:bg-[#0023EF26]"
            >
              {props.number}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default HashDropDown;
