import { useEffect, useState } from "react";
import { State } from "../../state/interfaces";
import { stepMapping } from "../../components/ProgressBar/Progress/constants";

export const useStep = (state: State) => {
  const [step, setStep] = useState(1);

  useEffect(() => {
    const newStep = stepMapping[state.conversationState];
    if (newStep) {
      setStep(newStep);
    }
  }, [state.conversationState]);

  return step;
};
