// ----------------------
// Packages
// ----------------------
import React from "react";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";

// ----------------------
// Utility functions
// ----------------------
import useHandleWalletConnection from "../../../utils/utility/useHandleWalletConnection";

// ----------------------
// Assets
// ----------------------
import polygonLogo from "../../../assets/images/polygonLogo.svg";
import ethereumLogo from "../../../assets/images/ethereumLogo.svg";

// ----------------------
// Interfaces
// ----------------------
import { Action, ActionType, State } from "../../../state/interfaces";

interface ContractReaderWalletProps {
  dispatch: React.Dispatch<Action>;
  state: State;
  customClassName: string;
  text: string;
}

function ContractReaderWallet({
  dispatch,
  state,
  customClassName,
  text,
}: ContractReaderWalletProps) {
  const { address, isActive, chainId } = useHandleWalletConnection(state);
  const { open } = useWeb3Modal();

  const openAccountWindow = (event: React.MouseEvent) => {
    event.stopPropagation();

    dispatch({
      type: ActionType.SET_ACCOUNT_WINDOW_ACTIVE,
      payload: true,
    });
  };

  return (
    <div className="w-full flex items-center justify-center">
      {!isActive ? (
        <button
          className={`bg-gradient-button font-bold rounded-[2px] tracking-wide ${customClassName}`}
          onClick={() => open()}
        >
          {text}
        </button>
      ) : (
        <button
          onClick={(e) => openAccountWindow(e)}
          className={`flex items-center bg-connect-button rounded-md relative`}
        >
          <div className="flex items-center pl-[15px] md:pl-[20px]  py-[10px] gap-[5px] outline-none">
            {chainId === 11155111 && (
              <img
                className="w-[15px] md:w-[20px]"
                src={ethereumLogo}
                alt="Ethereum Logo"
              />
            )}
          </div>
          <p className="text-[#ffff] font-bold pr-[15px] md:pr-[20px] py-[10px]  pl-[10px] md:pl-[15px] outline-none">
            {address?.slice(0, 4) + "..." + address?.slice(39, 42)}
          </p>
        </button>
      )}
    </div>
  );
}

export default ContractReaderWallet;
