// ----------------------
// Packages
// ----------------------
import { LazyLoadImage } from "react-lazy-load-image-component";

// ----------------------
// Components
// ----------------------
import ImageDropDown from "./ImageDropDown";

// ----------------------
// Assets
// ----------------------
import ethereumLogo from "../../../../../assets/images/ethereumLogo.svg";
import vaiLogo from "../../../../../assets/images/vaiLogo.svg";
import usdtLogo from "../../../../../assets/images/usdtLogo.svg";
import maticLogo from "../../../../../assets/images/maticLogo.svg";
import usdcLogo from "../../../../../assets/images/usdcLogo.svg";
import chevronDown from "../../../../../assets/images/chevronDown.svg";
import chevronUp from "../../../../../assets/images/chevronUp.svg";

interface CurrencyChosenProps {
  secondDropdownOpen: boolean;
  currencyChosen: string;
}

function CurrencyChosen({
  secondDropdownOpen,
  currencyChosen,
}: CurrencyChosenProps) {
  return (
    <div className="font-bold flex items-center justify-around w-full">
      <div className="flex items-center gap-[5px]">
        <LazyLoadImage
          alt={`${currencyChosen} Logo`}
          effect="blur"
          src={
            currencyChosen === "VAI"
              ? vaiLogo
              : currencyChosen === "USDT"
              ? usdtLogo
              : currencyChosen === "USDC"
              ? usdcLogo
              : currencyChosen === "MATIC"
              ? maticLogo
              : ethereumLogo
          }
          className="w-[20px]"
        />
        <p className="text-[14px]">{currencyChosen}</p>
      </div>
      {!secondDropdownOpen && <ImageDropDown src={chevronDown} />}
      {secondDropdownOpen && <ImageDropDown src={chevronUp} />}
    </div>
  );
}

export default CurrencyChosen;
