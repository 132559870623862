import { Socket } from "socket.io-client";
import SocketHandler from "../utils/sockets/handleSocket";
import SocketHandlerReader from "../utils/sockets/handleSocketsReader";
import { Web3Modal } from "@web3modal/wagmi/dist/types/src/client";
export interface Message {
  text: string | string[];
  type:
    | "USER"
    | "BOT-FIRST"
    | "BOT-NEXT"
    | "BUTTONS"
    | "BUTTONS-FIRST"
    | "WIDGET"
    | "WIDGET-FIRST";
  id: string;
  aboveButtons?: string;
  textWritten: boolean;
  messageState: "DISPLAYING" | "DISPLAYED" | "NOT DISPLAYED";
  doneReading: boolean;
  additionalData?: any;
  widgetType?: any;
  messageTooLong?: boolean;
  values: any[];
}

export interface QueueItem {
  text: string | string[];
  type:
    | "USER"
    | "BOT-FIRST"
    | "BOT-NEXT"
    | "BUTTONS"
    | "BUTTONS-FIRST"
    | "WIDGET"
    | "WIDGET-FIRST";
}

interface ButtonState {
  id: string;
  state: "PAID" | "REVOKED" | "NOT CLICKED";
  numOfCalls: number;
  txHash: string;
  chain: string;
}

export interface State {
  tutorialActive: boolean;
  conversationStarted: boolean;
  connectionScreenActive: boolean;
  changeChainActive: boolean;
  conversationId: string;
  dialogState: Array<any>;
  languageChoiceActive: boolean;
  listening: boolean;
  watermark: number;
  fetchingMessage: boolean;
  userInput: string;
  soundOn: boolean;
  isSpeaking: boolean;
  messages: Message[];
  voiceInput: string;
  voiceMessageSent: boolean;
  userId: string;
  contractReader: boolean;
  conversationState:
    | "GetStarted"
    | "ContractInformation"
    | "Description"
    | "Terms"
    | "PaymentDetails"
    | "FinalDetails"
    | "Payment"
    | "PaymentFinished";
  accountWindowActive: boolean;
  hamburgerMenuOpen: boolean;
  contractList: Array<any>;
  loginModalOpen: boolean;
  decodingAnimationFinished: boolean;
  contractListAnimationFinished: boolean;
  contractChosen: boolean;
  thinkingMessage: string;
  getCertificate: boolean;
  dialoguePaymentPending: boolean;
  maxMessageLength: number;
  paymentActive: boolean;
  paymentFinished: boolean;
  certificatePaid: boolean;
  idOfChosenContract: string;
  pdfReader: boolean;
  pdfContent: Array<string>;
  buttonStates: ButtonState[];
  websocket?: Socket | null;
  socketConnected: boolean;
  socketHandler?: SocketHandler | null;
  socketHandlerReader?: SocketHandlerReader | null;
  certificateFieldChoice: boolean;
  certificateFields: Array<any>;
  contractSummaryActive: boolean;
  downloadCertificate: boolean;
  hashWindowActive: boolean;
  listOfCertificates: boolean;
  verifyProofActive: boolean;
  summary: string;
  proofId: string;
  allCertificates: any;
  proofJson: any;
  creditCardChosen: boolean;
  readerPaymentActive: boolean;
  proofVerificationActive: boolean;
  proofOverview: string;
  proofVerificationResult: "SUCCESS" | "OUTDATED" | "ERROR" | "";
  mobileApplicationsScreen: boolean;
  qrCodeVerificationScreen: boolean;
  conditionsChecked: boolean;
  mobileStepsActive: boolean;
  certificateError: boolean;
  adminAccess: boolean;
  readerPaymentError: boolean;
  dialogIsRestarting: boolean;
  modal: Web3Modal;
  signUpPageActive: boolean;
  logInPageActive: boolean;
  profilePageActive: boolean;
  web2Login: boolean;
  ourApplicationsActive: boolean;
  deleteAccountModalActive: boolean;
  paybackPageActive: boolean;
  loginCodeModalActive: boolean;
  web2Email: string;
  userAvatar: string;
  changePasswordModalActive: boolean;
  web3Login: boolean;
  resetPasswordConfirmationModal: boolean;
  resetPasswordModal: boolean;
  forgotPasswordEmail: string;
  resetPasswordToken: string;
  typeOfChosenContract: "SA" | "NDA" | "PoAG" | "PoAS" | "";
  paybackActiveScreen: "Settings" | "TransactionHistory" | "ClaimPayback" | "";
}

export interface UpdateMessagePayload {
  index: number;
  message: Partial<Message>;
}

export interface PartialMessage {
  text?: string;
  id?: string;
  type?:
    | "USER"
    | "BOT-FIRST"
    | "BOT-NEXT"
    | "BUTTONS"
    | "BUTTONS-FIRST"
    | "WIDGET"
    | "WIDGET-FIRST";
  aboveButtons?: string;
  textWritten?: boolean;
  messageState?: "DISPLAYING" | "DISPLAYED" | "NOT DISPLAYED";
  doneReading?: boolean;
  additionalData?: any;
  widgetType?: any;
  messageTooLong?: boolean;
}

export type Action = {
  type: ActionTypes;
  payload?: any;
};

export interface AppProviderProps {
  children: React.ReactNode;
}

export type ActionTypes =
  | "UPDATE_MESSAGE"
  | "APPEND_MESSAGE"
  | "UPDATE_MESSAGE_BY_ID"
  | "UPDATE_NEXT_NON_USER_MESSAGE"
  | "SET_MESSAGES"
  | "UPDATE_MESSAGE_BY_INDEX"
  | "UPDATE_ALL_MESSAGES"
  | "SET_TUTORIAL_ACTIVE"
  | "SET_CONVERSATION_STARTED"
  | "INCREMENT_NUM_OF_CALLS"
  | "SET_BUTTON_STATE"
  | "SET_CONNECTION_SCREEN_ACTIVE"
  | "SET_ADMIN_ACCESS"
  | "SET_CHANGE_CHAIN_ACTIVE"
  | "SET_FORGOT_PASSWORD_EMAIL"
  | "SET_CONVERSATION_ID"
  | "SET_PDF_READER"
  | "SET_DIALOG_STATE"
  | "SET_LANGUAGE_CHOICE_ACTIVE"
  | "SET_LISTENING"
  | "SET_TYPE_OF_CHOSEN_CONTRACT"
  | "SET_WATERMARK"
  | "SET_SOCKET_CONNECTED"
  | "SET_FETCHING_MESSAGE"
  | "SET_USER_INPUT"
  | "SET_PAYBACK_ACTIVE_SCREEN"
  | "SET_SOUND_ON"
  | "SET_IS_SPEAKING"
  | "SET_QR_CODE_VERIFICATION_SCREEN"
  | "SET_PAYMENT_ACTIVE"
  | "SET_CONDITIONS_CHECKED"
  | "SET_VOICE_INPUT"
  | "SET_WEB2_LOGIN"
  | "SET_RESET_PASSWORD_TOKEN"
  | "SET_PROOF_ID"
  | "SET_ALL_CERTIFICATES"
  | "SET_LOGIN_MODAL_OPEN"
  | "SET_CHANGE_PASSWORD_MODAL_ACTIVE"
  | "SET_VOICE_MESSAGE_SENT"
  | "SET_USER_ID"
  | "SET_ID_OF_CHOSEN_CONTRACT"
  | "SET_GET_CERTIFICATE"
  | "SET_WEBSOCKET"
  | "SET_RESET_PASSWORD_CONFIRMATION_MODAL"
  | "SET_CONTRACT_LIST"
  | "SET_PDF_CONTENT"
  | "SET_PROOF_JSON"
  | "SET_SUMMARY"
  | "SET_CERTIFICATE_FIELD_CHOICE"
  | "APPEND_CERTIFICATE_FIELDS"
  | "SET_WEB3_LOGIN"
  | "SET_WEB2_EMAIL"
  | "REMOVE_CERTIFICATE_FIELDS"
  | "SET_INITIAL_STATE"
  | "SET_PAYMENT_FINISHED"
  | "SET_CONTRACT_CHOSEN"
  | "SET_CONTRACT_READER"
  | "SET_LIST_OF_CERTIFICATES"
  | "SET_DECODING_ANIMATION_FINISHED"
  | "SET_CONTRACT_SUMMARY_ACTIVE"
  | "SET_HASH_WINDOW_ACTIVE"
  | "SET_CONTRACT_LIST_ANIMATION_FINISHED"
  | "SET_VERIFY_PROOF_ACTIVE"
  | "RESET_CONTRACT_READER"
  | "RESET_CONVERSATION"
  | "SET_CONVERSATION_STATE"
  | "SET_MAX_MESSAGE_LENGTH"
  | "SET_ACCOUNT_WINDOW_ACTIVE"
  | "SET_HAMBURGER_OPEN"
  | "SET_DIALOGUE_PAYMENT_PENDING"
  | "SET_CERTIFICATE_FIELDS"
  | "SET_READER_PAYMENT_ACTIVE"
  | "SET_CERTIFICATE_PAID"
  | "SET_CREDIT_CARD_CHOSEN"
  | "SET_PROOF_VERIFICATION_ACTIVE"
  | "SET_PROOF_VERIFICATION_RESULT"
  | "SET_PROOF_OVERVIEW"
  | "SET_LOGIN_CODE_MODAL_ACTIVE"
  | "SET_RESET_PASSWORD_MODAL"
  | "SET_DOWNLOAD_CERTIFICATE"
  | "SET_MOBILE_STEPS_ACTIVE"
  | "SET_CERTIFICATE_ERROR"
  | "SET_READER_PAYMENT_ERROR"
  | "SET_DIALOG_IS_RESTARTING"
  | "SET_MODAL"
  | "SET_SIGN_UP_PAGE_ACTIVE"
  | "SET_USER_AVATAR"
  | "SET_LOG_IN_PAGE_ACTIVE"
  | "SET_PROFILE_PAGE_ACTIVE"
  | "SET_OUR_APPLICATIONS_ACTIVE"
  | "SET_DELETE_ACCOUNT_MODAL_ACTIVE"
  | "SET_PAYBACK_PAGE_ACTIVE"
  | "SET_THINKING_MESSAGE"
  | "SET_MOBILE_APPLICATIONS_SCREEN";

export enum ActionType {
  SET_TUTORIAL_ACTIVE = "SET_TUTORIAL_ACTIVE",
  SET_CONDITIONS_CHECKED = "SET_CONDITIONS_CHECKED",
  SET_MOBILE_STEPS_ACTIVE = "SET_MOBILE_STEPS_ACTIVE",
  SET_CONVERSATION_STARTED = "SET_CONVERSATION_STARTED",
  SET_CONNECTION_SCREEN_ACTIVE = "SET_CONNECTION_SCREEN_ACTIVE",
  SET_CHANGE_CHAIN_ACTIVE = "SET_CHANGE_CHAIN_ACTIVE",
  SET_CONVERSATION_ID = "SET_CONVERSATION_ID",
  SET_DIALOG_STATE = "SET_DIALOG_STATE",
  SET_LANGUAGE_CHOICE_ACTIVE = "SET_LANGUAGE_CHOICE_ACTIVE",
  SET_LISTENING = "SET_LISTENING",
  SET_THINKING_MESSAGE = "SET_THINKING_MESSAGE",
  SET_CHANGE_PASSWORD_MODAL_ACTIVE = "SET_CHANGE_PASSWORD_MODAL_ACTIVE",
  SET_LOGIN_MODAL_OPEN = "SET_LOGIN_MODAL_OPEN",
  SET_WATERMARK = "SET_WATERMARK",
  SET_FETCHING_MESSAGE = "SET_FETCHING_MESSAGE",
  SET_USER_INPUT = "SET_USER_INPUT",
  SET_READER_PAYMENT_ACTIVE = "SET_READER_PAYMENT_ACTIVE",
  SET_RESET_PASSWORD_TOKEN = "SET_RESET_PASSWORD_TOKEN",
  SET_RESET_PASSWORD_MODAL = "SET_RESET_PASSWORD_MODAL",
  SET_SOUND_ON = "SET_SOUND_ON",
  SET_WEB2_LOGIN = "SET_WEB2_LOGIN",
  SET_IS_SPEAKING = "SET_IS_SPEAKING",
  SET_MESSAGES = "SET_MESSAGES",
  SET_WEB3_LOGIN = "SET_WEB3_LOGIN",
  SET_WEB2_EMAIL = "SET_WEB2_EMAIL",
  SET_VOICE_INPUT = "SET_VOICE_INPUT",
  SET_VOICE_MESSAGE_SENT = "SET_VOICE_MESSAGE_SENT",
  SET_USER_ID = "SET_USER_ID",
  SET_CONTRACT_READER = "SET_CONTRACT_READER",
  SET_CONVERSATION_STATE = "SET_CONVERSATION_STATE",
  RESET_CONVERSATION = "RESET_CONVERSATION",
  SET_ACCOUNT_WINDOW_ACTIVE = "SET_ACCOUNT_WINDOW_ACTIVE",
  SET_HAMBURGER_OPEN = "SET_HAMBURGER_OPEN",
  SET_DECODING_ANIMATION_FINISHED = "SET_DECODING_ANIMATION_FINISHED",
  SET_CONTRACT_LIST = "SET_CONTRACT_LIST",
  SET_MAX_MESSAGE_LENGTH = "SET_MAX_MESSAGE_LENGTH",
  SET_CONTRACT_LIST_ANIMATION_FINISHED = "SET_CONTRACT_LIST_ANIMATION_FINISHED",
  SET_ADMIN_ACCESS = "SET_ADMIN_ACCESS",
  SET_CONTRACT_CHOSEN = "SET_CONTRACT_CHOSEN",
  SET_DELETE_ACCOUNT_MODAL_ACTIVE = "SET_DELETE_ACCOUNT_MODAL_ACTIVE",
  SET_GET_CERTIFICATE = "SET_GET_CERTIFICATE",
  SET_PAYMENT_FINISHED = "SET_PAYMENT_FINISHED",
  SET_PAYMENT_ACTIVE = "SET_PAYMENT_ACTIVE",
  SET_PROOF_VERIFICATION_ACTIVE = "SET_PROOF_VERIFICATION_ACTIVE",
  SET_ID_OF_CHOSEN_CONTRACT = "SET_ID_OF_CHOSEN_CONTRACT",
  SET_PROOF_VERIFICATION_RESULT = "SET_PROOF_VERIFICATION_RESULT",
  RESET_CONTRACT_READER = "RESET_CONTRACT_READER",
  SET_DIALOGUE_PAYMENT_PENDING = "SET_DIALOGUE_PAYMENT_PENDING",
  SET_CERTIFICATE_PAID = "SET_CERTIFICATE_PAID",
  SET_PDF_READER = "SET_PDF_READER",
  SET_PDF_CONTENT = "SET_PDF_CONTENT",
  APPEND_MESSAGE = "APPEND_MESSAGE",
  SET_INITIAL_STATE = "SET_INITIAL_STATE",
  SET_WEBSOCKET = "SET_WEBSOCKET",
  SET_LOG_IN_PAGE_ACTIVE = "SET_LOG_IN_PAGE_ACTIVE",
  SET_CERTIFICATE_FIELD_CHOICE = "SET_CERTIFICATE_FIELD_CHOICE",
  SET_CERTIFICATE_FIELDS = "SET_CERTIFICATE_FIELDS",
  SET_TYPE_OF_CHOSEN_CONTRACT = "SET_TYPE_OF_CHOSEN_CONTRACT",
  APPEND_CERTIFICATE_FIELDS = "APPEND_CERTIFICATE_FIELDS",
  REMOVE_CERTIFICATE_FIELDS = "REMOVE_CERTIFICATE_FIELDS",
  SET_RESET_PASSWORD_CONFIRMATION_MODAL = "SET_RESET_PASSWORD_CONFIRMATION_MODAL",
  SET_CONTRACT_SUMMARY_ACTIVE = "SET_CONTRACT_SUMMARY_ACTIVE",
  SET_DOWNLOAD_CERTIFICATE = "SET_DOWNLOAD_CERTIFICATE",
  SET_HASH_WINDOW_ACTIVE = "SET_HASH_WINDOW_ACTIVE",
  SET_LIST_OF_CERTIFICATES = "SET_LIST_OF_CERTIFICATES",
  SET_VERIFY_PROOF_ACTIVE = "SET_VERIFY_PROOF_ACTIVE",
  SET_SUMMARY = "SET_SUMMARY",
  SET_PROOF_ID = "SET_PROOF_ID",
  SET_ALL_CERTIFICATES = "SET_ALL_CERTIFICATES",
  SET_PROOF_JSON = "SET_PROOF_JSON",
  SET_CREDIT_CARD_CHOSEN = "SET_CREDIT_CARD_CHOSEN",
  SET_PROOF_OVERVIEW = "SET_PROOF_OVERVIEW",
  SET_MOBILE_APPLICATIONS_SCREEN = "SET_MOBILE_APPLICATIONS_SCREEN",
  SET_QR_CODE_VERIFICATION_SCREEN = "SET_QR_CODE_VERIFICATION_SCREEN",
  SET_CERTIFICATE_ERROR = "SET_CERTIFICATE_ERROR",
  SET_READER_PAYMENT_ERROR = "SET_READER_PAYMENT_ERROR",
  SET_PAYBACK_ACTIVE_SCREEN = "SET_PAYBACK_ACTIVE_SCREEN",
  SET_DIALOG_IS_RESTARTING = "SET_DIALOG_IS_RESTARTING",
  SET_SIGN_UP_PAGE_ACTIVE = "SET_SIGN_UP_PAGE_ACTIVE",
  SET_MODAL = "SET_MODAL",
  SET_PROFILE_PAGE_ACTIVE = "SET_PROFILE_PAGE_ACTIVE",
  SET_OUR_APPLICATIONS_ACTIVE = "SET_OUR_APPLICATIONS_ACTIVE",
  SET_PAYBACK_PAGE_ACTIVE = "SET_PAYBACK_PAGE_ACTIVE",
  SET_LOGIN_CODE_MODAL_ACTIVE = "SET_LOGIN_CODE_MODAL_ACTIVE",
  SET_USER_AVATAR = "SET_USER_AVATAR",
  SET_FORGOT_PASSWORD_EMAIL = "SET_FORGOT_PASSWORD_EMAIL",
}

export interface ContractTerms {
  agreementTerms: Array<any>;
  parties: Array<string>;
  services: Array<Array<string>>;
}

export type OptionType = "Supplier" | "Buyer" | "General";
