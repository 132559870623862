// ----------------------
// Packages
// ----------------------
import React, { useState, useEffect } from "react";

// ----------------------
// Assets
// ----------------------
import { ReactComponent as Cross } from "../../../assets/images/cross.svg";
import { ReactComponent as PdfNoContracts } from "../../../assets/images/pdfNoContracts.svg";
import { ReactComponent as PdfNoContractsMobile } from "../../../assets/images/pdfNoContractsMobile.svg";

// ----------------------
// Components
// ----------------------
import PdfContent from "./PdfContent";
import BubbleMessage from "./BubbleMessage";

// ----------------------
// Utils
// ----------------------
import { useEscapeKey } from "../../../utils/utility/useEscapeKey";

// ----------------------
// Interfaces
// ----------------------
import { Action, ActionType, State } from "../../../state/interfaces";
interface PdfReaderProps {
  dispatch: React.Dispatch<Action>;
  state: State;
}

function PdfReader(props: PdfReaderProps) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  useEscapeKey(props.dispatch, {
    type: "SET_PDF_READER",
    payload: false,
  });

  const handleCloseWindow = () => {
    setIsVisible(false);
    props.dispatch({
      type: ActionType.SET_PDF_READER,
      payload: false,
    });
  };

  const noPdfContent =
    (props.state.pdfContent && props.state.pdfContent.length === 0) ||
    props.state.pdfContent === undefined;

  return (
    <div
      className={`w-full h-full flex items-center justify-center bg-[#050934] bg-opacity-[75%] fixed z-[500]`}
    >
      <div
        className={`bg-[#F7F8FD] w-full sm:w-[80%] h-[90%] min-h-[600px] p-[15px] sm:p-[30px] border-2 max-w-[900px] max-h-[700px] shadow-lg rounded-lg flex flex-col gap-[20px] justify-between
        transition-all duration-300 ease-in-out transform-gpu ${
          isVisible ? "scale-100" : "scale-[75%]"
        } 
        transition-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);`}
      >
        <div className="flex flex-col gap-[30px] h-full w-full">
          <div className="flex justify-end items-center">
            <button
              onClick={handleCloseWindow}
              className="w-[25px] border-[#0023EF] border-[1px]"
            >
              <Cross className="h-full w-full" />
            </button>
          </div>
          <div
            className={`text-[18px] font-semibold w-full bg-[#F9F9F9] h-[90%] flex items-center justify-center ${
              noPdfContent ? "" : "px-[20px]"
            }`}
          >
            {noPdfContent ? (
              <>
                <PdfNoContracts className="hidden sm:block flex-shrink-0 sm:w-[450px] sm900:w-[550px] md:w-[600px]" />
                <PdfNoContractsMobile className="sm:hidden flex-shrink-0 w-full" />
              </>
            ) : (
              <PdfContent state={props.state} />
            )}
          </div>
        </div>
      </div>
      <BubbleMessage
        content={
          "Here you can see parts of the contract that you are currently working on"
        }
      />
    </div>
  );
}

export default PdfReader;
