// Logos
import vaiLogo from "../../../assets/images/vaiLogo.svg";
import usdtLogo from "../../../assets/images/usdtLogo.svg";
import usdcLogo from "../../../assets/images/usdcLogo.svg";
import maticLogo from "../../../assets/images/maticLogo.svg";
import ethereumLogo from "../../../assets/images/ethereumLogo.svg";

// Abis
import polygonMatic from "../../../assets/abis/polygonMatic.json";
import polygonUsdt from "../../../assets/abis/polygonUsdt.json";
import polygonUsdc from "../../../assets/abis/polygonUsdc.json";
import polygonVai from "../../../assets/abis/polygonVai.json";
import ethereumUsdt from "../../../assets/abis/ethereumUsdt.json";
import ethereumUsdc from "../../../assets/abis/ethereumUsdc.json";
import ethereumVai from "../../../assets/abis/ethereumVai.json";

// Package
import * as ethers from "ethers";

// Interfaces and types

type ChainType = string | undefined;
type CurrencyType = string | undefined;
interface ContractConfig {
  contractAddress: string;
  contractAbi: any;
  value: any;
}

export const currencyOptions = [
  // {
  //   name: "VAI",
  //   image: vaiLogo,
  //   chain: null,
  // },
  // {
  //   name: "USDT",
  //   image: usdtLogo,
  //   chain: null,
  // },
  // {
  //   name: "USDC",
  //   image: usdcLogo,
  //   chain: null,
  // },
  {
    name: "MATIC",
    image: maticLogo,
    chain: "Polygon",
  },
  {
    name: "ETH",
    image: ethereumLogo,
    chain: "Ethereum",
  },
];

export function getContractConfig(
  currencyChosen: CurrencyType,
  chainChosen: ChainType,
  paymentFee: number
): ContractConfig {
  let contractAddress = "";
  let contractAbi = {};
  let value;

  if (chainChosen === "Polygon") {
    switch (currencyChosen) {
      case "MATIC":
        contractAddress = "0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0";
        contractAbi = polygonMatic;
        value = ethers.parseEther(paymentFee.toString());
        break;
      case "USDT":
        contractAddress = "0xc2132D05D31c914a87C6611C10748AEb04B58e8F";
        contractAbi = polygonUsdt;
        value = paymentFee * 10 ** 6;
        break;
      case "USDC":
        contractAddress = "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174";
        contractAbi = polygonUsdc;
        value = paymentFee * 10 ** 6;
        break;
      case "VAI":
        contractAddress = "0xD13cfD3133239a3c73a9E535A5c4DadEE36b395c";
        contractAbi = polygonVai;
        value = ethers.parseEther(paymentFee.toString());
        break;
      default:
        break;
    }
  } else if (chainChosen === "Ethereum") {
    switch (currencyChosen) {
      case "ETH":
        contractAddress = "";
        contractAbi = {};
        value = ethers.parseEther(paymentFee.toString());
        break;
      case "USDT":
        contractAddress = "0xdAC17F958D2ee523a2206206994597C13D831ec7";
        contractAbi = ethereumUsdt;
        value = paymentFee * 10 ** 6;
        break;
      case "USDC":
        contractAddress = "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48";
        contractAbi = ethereumUsdc;
        value = paymentFee * 10 ** 6;
        break;
      case "VAI":
        contractAddress = "0xD13cfD3133239a3c73a9E535A5c4DadEE36b395c";
        contractAbi = ethereumVai;
        value = ethers.parseEther(paymentFee.toString());
        break;
      default:
        break;
    }
  }

  return { contractAddress, contractAbi, value };
}
