import axios from "axios";

interface VaiPointsHistory {
  amount: number;
  history: {
    amount: number;
    date: string;
    fromCollection: string;
    fromId: string;
  }[];
}

export const getVaiPointsHistory = async (): Promise<VaiPointsHistory> => {
  const response = await axios.get<VaiPointsHistory>(
    `${process.env.REACT_APP_BASE_URL}/users/vai-points`,
    {
      withCredentials: true,
    }
  );
  return response.data;
};
