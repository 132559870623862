// ----------------------
// External/Internal Packages
// ----------------------
import React, { useEffect, useState, useCallback } from "react";

// ----------------------
// Assets
// ----------------------
import { ReactComponent as ChevronLeft } from "../../assets/images/chevronLeft.svg";

// ----------------------
// Components
// ----------------------
import Applications from "./Applications";
import Sidebar from "./Sidebar";

// ----------------------
// Interfaces and styles
// ----------------------
import { Action, State } from "../../state/interfaces";
import MobileMenu from "./MobileMenu";
import "./menu.css";
import Payback from "./Payback/Payback";

interface MenuProps {
  isOpen: boolean;
  onClose: () => void;
  dispatch: React.Dispatch<Action>;
  state: State;
  navbarRef: React.RefObject<HTMLDivElement>;
}

function Menu({ isOpen, onClose, dispatch, state, navbarRef }: MenuProps) {
  const [isRendered, setIsRendered] = useState(false);
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Thanks to this, we have the animation of the menu sliding out and in

  useEffect(() => {
    if (isOpen) {
      setIsRendered(true);
      setTimeout(() => setShouldAnimate(true), 50); // introducing a small delay
    } else {
      setShouldAnimate(false);
      setTimeout(() => setIsRendered(false), 500); // corresponds to the transition duration
    }
  }, [isOpen]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const customWidth =
    state.mobileApplicationsScreen && windowWidth < 640
      ? "100%"
      : windowWidth < 400 && !state.paybackPageActive
      ? "80%"
      : windowWidth < 500 && !state.paybackPageActive
      ? "65%"
      : windowWidth < 640 && !state.paybackPageActive
      ? "50%"
      : state.contractReader || state.verifyProofActive
      ? "100%"
      : windowWidth < 1280
      ? "100%"
      : state.verifyProofActive || state.contractReader
      ? "100vw"
      : "100vw";

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const menuClasses = shouldAnimate
    ? "translate-x-0 ease-out duration-500"
    : "-translate-x-full ease-in duration-500";

  return isRendered ? (
    <div
      style={{ width: customWidth }}
      className="z-[90] absolute h-full flex-shrink-0"
    >
      <div
        className={`transform h-full ${menuClasses} transition-transform flex`}
      >
        <div className="hidden sm:flex items-start h-full w-[200px] flex-shrink-0">
          <Sidebar state={state} dispatch={dispatch} />
        </div>

        <div className="flex-grow flex flex-col overflow-y-auto">
          <div className="sm:hidden flex items-center h-full w-full">
            <MobileMenu
              state={state}
              dispatch={dispatch}
              navbarRef={navbarRef}
            />
          </div>
          <div className="bg-[#fff] h-[full] relative flex-grow hidden sm:block">
            {state.ourApplicationsActive ? (
              <Applications
                dispatch={dispatch}
                state={state}
                layoutClass="flex-col sm900:flex-row sm900:flex-wrap gap-[20px] sm900:gap-[40px] lg:gap-[80px]"
                applicationClass="w-[50px] sm400:w-[70px] sm900:w-[100px] 2xl:w-[130px]"
                layoutPadding="py-[40px] px-[40px] lg:px-[80px]"
              />
            ) : state.paybackPageActive ? (
              <Payback state={state} dispatch={dispatch} />
            ) : null}
            <button
              onClick={handleClose}
              className="absolute right-[20px] top-[45%] w-[35px] hidden md:block"
            >
              <ChevronLeft className="w-full h-full" />
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export default Menu;
