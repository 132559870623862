// ----------------------
// Assets
// ----------------------
import chevronWhite from "../../../assets/images/chevronLeftWhite.svg";

// ----------------------
// Utility Functions
// ----------------------
import { handleRestartDownload } from "../utils/handleRestartDownload";

// ----------------------
// Interfaces
// ----------------------
import { Action } from "../../../state/interfaces";

interface ContractTerms {
  agreementTerms: Array<any>;
  parties: Array<string>;
  services: Array<Array<string>>;
  onBlockchain: string;
}

interface ContractInfoNDA {
  creationTimestamp: string;
  fieldNames: string[];
  fieldValues: string[];
  partyA: string;
  partyB: string;
}

interface TitleProps {
  dispatch: React.Dispatch<Action>;

  setContractInfoSA: React.Dispatch<
    React.SetStateAction<ContractTerms | undefined>
  >;
  setContractInfoNDA: React.Dispatch<
    React.SetStateAction<ContractInfoNDA | undefined>
  >;
}

function Title({
  dispatch,
  setContractInfoNDA,
  setContractInfoSA,
}: TitleProps) {
  return (
    <div className="flex gap-[20px] ml-[-10px] sm:mb-[20px] sm900:mb-0 sm:ml-0">
      <button
        onClick={() =>
          handleRestartDownload(dispatch, setContractInfoSA, setContractInfoNDA)
        }
        className="w-[35px] sm900:hidden"
      >
        <img src={chevronWhite} className="w-full h-full" alt="Chevron Icon" />
      </button>
      <div className="text-[#fff] font-bold mb-[30px] h-full flex flex-col justify-center">
        <p className="text-[16px] sm:text-[18px]">Proofs</p>
        <p className="text-[18px] sm400:text-[24px] sm:text-[28px]">
          Download what you need
        </p>
      </div>
    </div>
  );
}

export default Title;
