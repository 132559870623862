// api.ts
import axios from "axios";

export async function synthesizeText(requestBody: any) {
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

  return axios.post(
    `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiKey}`,
    requestBody
  );
}
