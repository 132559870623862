import axios from "axios";
import handleToastError from "../utility/handleToastError";
import { Action, ActionType } from "../../state/interfaces";
import { toast } from "react-toastify";

type SignUpMethod = "emailCode" | "email";
interface UserData {
  username: string;
  password: string;
}

interface RegisterResponse {
  status: "emailExists" | "success";
  userData?: {
    username: string;
    email: string;
  };
}

export const handleSignUp = async (
  method: SignUpMethod,
  dispatch: React.Dispatch<Action>,
  userData: UserData,
  verificationToken?: number
): Promise<void> => {
  const baseUrl: string = process.env.REACT_APP_BASE_URL || "";

  try {
    if (method === "email") {
      const response = await axios.post<RegisterResponse>(
        `${baseUrl}/auth/register`,
        {
          username: userData.username,
          password: userData.password,
        },
        { withCredentials: true }
      );
      const data = response.data;
      if (data.status === "success" && !verificationToken) {
        dispatch({
          type: ActionType.SET_LOGIN_CODE_MODAL_ACTIVE,
          payload: true,
        });
      } else if (data.status === "emailExists") {
        handleToastError("User already exists.");
        return;
      }
    } else if (method === "emailCode" && verificationToken) {
      const response = await axios.post<RegisterResponse>(
        `${baseUrl}/auth/login`,
        {
          username: userData.username,
          password: userData.password,
          verificationToken: verificationToken.toString(),
        },
        { withCredentials: true }
      );
      const data = response.data;
      if (data.status === "success") {
        toast("Account has been created!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dispatch({ type: ActionType.SET_SIGN_UP_PAGE_ACTIVE, payload: false });
        dispatch({
          type: ActionType.SET_LOGIN_CODE_MODAL_ACTIVE,
          payload: false,
        });
      }
    } else {
      console.error(
        "Invalid sign-up method or missing user data for email sign-up."
      );
    }
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const responseStatus = error.response.data.status;
      if (error.response.status === 429) {
        handleToastError("You are being rate limited.");
        return;
      } else if (responseStatus === "noData" && verificationToken) {
        handleToastError("Code invalid! Check your email for the new code.");
        return;
      } else {
        // Handle other axios-specific errors
        console.error("Sign-up error:", error);
      }
    } else {
      // Handle non-axios errors
      console.error("An unexpected error occurred:", error);
    }
  }
};
