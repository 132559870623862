import { Action, QueueItem } from "../../../../state/interfaces";
import handleUserInput from "./handleUserInput";

const handleKeyPress = (
  event: React.KeyboardEvent<HTMLInputElement>,
  inputValue: string,
  allMessagesDisplayed: boolean,
  state: any,
  dispatch: React.Dispatch<Action>,
  setInputValue: Function,
  messageQueue: React.MutableRefObject<QueueItem[]>,
  currentAudio: React.MutableRefObject<HTMLAudioElement | null>,
  walletAddress: string | null | undefined
) => {
  if (
    event.key === "Enter" &&
    inputValue !== "" &&
    allMessagesDisplayed &&
    state.conversationStarted &&
    state.conversationId !== "" &&
    !state.fetchingMessage
  ) {
    handleUserInput(
      inputValue,
      allMessagesDisplayed,
      state,
      dispatch,
      setInputValue,
      messageQueue,
      currentAudio,
      walletAddress
    );
    setInputValue("");
  }
};

export default handleKeyPress;
