// ----------------------
// Interfaces + styling
// ----------------------
import "./pdfContent.css";

interface BlurredTextProps {
  text: string;
}

function BlurredText(props: BlurredTextProps) {
  return (
    <span className="blur-[10px] unselectable">
      <p className="text-[20px] font-bold text-black-500 uppercase overflow-wrap break-word word-break my-[20px]">
        {props.text}
      </p>
    </span>
  );
}

export default BlurredText;
