// ----------------------
// Packages
// ----------------------
import React from "react";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { handleSignUp } from "../../utils/api/handleSignUp";

// ----------------------
// Icons
// ----------------------
import googleIcon from "../../assets/images/googleIcon.svg";
import appleIcon from "../../assets/images/appleIcon.svg";
import metamaskIcon from "../../assets/images/metamaskIcon.svg";

// ----------------------
// Components
// ----------------------
import SignUpOtherLoginIcon from "./AccountCreationOtherLoginIcon";
import { State } from "../../state/interfaces";

interface AccountCreationOtherLoginMethodsProps {
  state: State;
  setIsUpdatingWallet: React.Dispatch<React.SetStateAction<boolean>>;
}

function AccountCreationOtherLoginMethods({
  state,
  setIsUpdatingWallet,
}: AccountCreationOtherLoginMethodsProps) {
  const { open } = useWeb3Modal();
  return (
    <div className="flex w-full justify-center gap-[20px] items-center">
      <SignUpOtherLoginIcon image={googleIcon} width="20px" type="google" />
      <SignUpOtherLoginIcon image={appleIcon} width="22px" type="apple" />
      {!state.web3Login && (
        <SignUpOtherLoginIcon
          image={metamaskIcon}
          width="26px"
          onClick={() => {
            setIsUpdatingWallet(true);
            try {
              open();
            } catch (error) {
              console.error(error);
            }
          }}
        />
      )}
    </div>
  );
}

export default AccountCreationOtherLoginMethods;
