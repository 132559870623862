// ----------------------
// Packages
// ----------------------
import React, { useRef } from "react";

// ----------------------
// Components
// ----------------------
import ConnectWallet from "./ConnectButton/ConnectWallet";
import LanguageChoice from "./LanguageChoice";
import LogoButton from "./LogoButton";
import ApplicationName from "./ApplicationName";
import MobileSteps from "./MobileSteps/MobileSteps";
import Web2Account from "./Web2Account/Web2Account";

// ----------------------
// Assets
// ----------------------
import hamburgerOpen from "../../assets/images/hamburgerOpen.svg";
import hamburgerClose from "../../assets/images/hamburgerClose.svg";
import chevronDown from "../../assets/images/chevronDown.svg";
import chevronUp from "../../assets/images/chevronUp.svg";
import cross from "../../assets/images/cross.svg";

// ----------------------
// Interfaces and styling
// ----------------------
import { State, Action, ActionType } from "../../state/interfaces";
import "./Navbar.css";

interface NavbarProps {
  state: State;
  dispatch: React.Dispatch<Action>;
  navbarRef: React.RefObject<HTMLDivElement>;
}

function Navbar({ state, dispatch, navbarRef }: NavbarProps) {
  const animationLock = useRef(false);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (animationLock.current) return; // Prevent toggling while animating

    animationLock.current = true; // Lock further actions
    event.stopPropagation();

    dispatch({ type: ActionType.SET_OUR_APPLICATIONS_ACTIVE, payload: true });
    dispatch({ type: ActionType.SET_PAYBACK_ACTIVE_SCREEN, payload: "" });
    dispatch({ type: ActionType.SET_PAYBACK_PAGE_ACTIVE, payload: false });

    dispatch({
      type: ActionType.SET_HAMBURGER_OPEN,
      payload: !state.hamburgerMenuOpen,
    });

    if (state.hamburgerMenuOpen) {
      dispatch({
        type: ActionType.SET_MOBILE_APPLICATIONS_SCREEN,
        payload: false,
      });
    }

    if (state.paybackPageActive) {
      dispatch({
        type: ActionType.SET_PAYBACK_PAGE_ACTIVE,
        payload: false,
      });
    }

    // Unlock after animation duration
    setTimeout(() => {
      animationLock.current = false;
    }, 500);
  };

  const handleCloseApplications = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    if (state.mobileApplicationsScreen) {
      dispatch({
        type: ActionType.SET_MOBILE_APPLICATIONS_SCREEN,
        payload: false,
      });
    }

    if (state.paybackActiveScreen !== "") {
      dispatch({ type: ActionType.SET_PAYBACK_ACTIVE_SCREEN, payload: "" });
    } else if (state.paybackPageActive) {
      dispatch({ type: ActionType.SET_PAYBACK_PAGE_ACTIVE, payload: false });
    }
  };

  const handleMobileSteps = () => {
    dispatch({
      type: ActionType.SET_MOBILE_STEPS_ACTIVE,
      payload: !state.mobileStepsActive,
    });
  };

  return (
    <div
      ref={navbarRef}
      className={`flex flex-col sm:flex-row  transition-height duration-300 ease-in-out ${
        state.hamburgerMenuOpen ||
        state.contractReader ||
        state.verifyProofActive
          ? "h-[88px]"
          : state.mobileStepsActive
          ? "h-[188px] sm:h-[88px]"
          : "h-[88px]"
      } items-center sm:items-start flex-shrink-0`}
    >
      <div
        className={`${
          state.contractReader ||
          state.verifyProofActive ||
          state.profilePageActive
            ? "w-[100%] border-black bg-[#ffff] h-full items-center"
            : "w-full lg:flex-grow h-[68px] sm:h-[88px] items-end sm:items-center"
        } flex  justify-between  `}
      >
        <div className="flex items-center w-full sm:w-[auto] pr-[20px]">
          <div className="flex-shrink-0">
            <button onClick={(event) => openMenu(event)} className="p-[20px]">
              <img
                src={state.hamburgerMenuOpen ? hamburgerOpen : hamburgerClose}
                alt="Hamburger logo"
                className="cursor-pointer flex-shrink-0 w-[20px] h-[20px]"
              />
            </button>
          </div>
          <div className="bg-[#E5E4F0] w-[1px] h-[20px] sm:mr-[20px]" />
          <LogoButton dispatch={dispatch} state={state} />
          <div
            className="sm:hidden relative"
            style={{ width: "50px", height: "25px" }}
          >
            {state.mobileApplicationsScreen || state.paybackPageActive ? (
              <button
                className="absolute top-0 right-0 w-[50px]"
                onClick={handleCloseApplications}
              >
                <img
                  src={cross}
                  className="w-[25px] sm400:mr-[25px] ml-auto sm:hidden"
                  alt="Cross icon"
                />
              </button>
            ) : (
              <div className="absolute top-0 right-0 hidden">
                <LanguageChoice dispatch={dispatch} state={state} />
              </div>
            )}
            <div className="hidden">
              <LanguageChoice dispatch={dispatch} state={state} />
            </div>
          </div>
        </div>

        <ApplicationName state={state} />
        <div
          className={`hidden gap-[10px] sm:flex items-center sm:justify-end mr-[30px] ${
            state.contractReader || state.verifyProofActive ? "ml-auto" : ""
          }`}
        >
          <LanguageChoice dispatch={dispatch} state={state} />
          <div className="bg-[#E5E4F0] w-[1px] h-[20px] sm:mr-[20px]" />
          <Web2Account dispatch={dispatch} state={state} />
        </div>
      </div>

      {!state.contractReader &&
        !state.verifyProofActive &&
        !state.hamburgerMenuOpen &&
        !state.profilePageActive && (
          <div
            onClick={() => {
              if (!state.mobileStepsActive) {
                handleMobileSteps();
              }
            }}
            className={`w-full bg-[#ffff] transition-height duration-300 ease-in-out rounded-b-md overflow-hidden flex items-center ${
              state.mobileStepsActive
                ? "justify-around cursor-default"
                : "justify-start cursor-pointer"
            } justify-center flex-col sm:hidden`}
            style={{
              height: state.mobileStepsActive ? "120px" : "20px",
            }}
          >
            {state.mobileStepsActive && (
              <MobileSteps state={state} dispatch={dispatch} />
            )}
            {state.mobileStepsActive ? (
              <button onClick={handleMobileSteps}>
                <img src={chevronUp} className="w-[20px]" alt="Chevron Up" />
              </button>
            ) : (
              <button onClick={handleMobileSteps}>
                <img
                  src={chevronDown}
                  className="w-[20px]"
                  alt="Chevron Down"
                />
              </button>
            )}
          </div>
        )}
    </div>
  );
}

export default Navbar;
