// ----------------------
// External/Internal Packages
// ----------------------
import React from "react";

// ----------------------
// Utility functions
// ----------------------
import { useMessageQueue } from "../../utils/listening/useMessageQueue";
import { useDialogStateHandler } from "../../utils/dialog/useDialogStateHandler";
import { useDialogEmergencyHandler } from "../../utils/sockets/useDialogEmergencyHandler";
import { useAutoScroll } from "../../utils/utility/useAutoScroll";
import { useTypingDoneHandler } from "../../utils/dialog/useTypingDoneHandler";
import { useNextMessageDisplayHandler } from "../../utils/dialog/useNextMessageDisplayHandler";

// ----------------------
// Components
// ----------------------
import ChatMessageRenderer from "./ChatMessageRenderer";

// ----------------------
// Interfaces
// ----------------------
import { Action, State, QueueItem } from "../../state/interfaces";

interface FullChatProps {
  chatContainerRef: React.RefObject<HTMLDivElement>;
  messageQueue: React.MutableRefObject<QueueItem[]>;
  currentAudio: React.MutableRefObject<HTMLAudioElement | null>;
  processedMessageIds: React.MutableRefObject<Set<unknown>>;
  dispatch: React.Dispatch<Action>;
  state: State;
}

function FullChat({
  chatContainerRef,
  messageQueue,
  currentAudio,
  processedMessageIds,
  dispatch,
  state,
}: FullChatProps) {
  useMessageQueue(state, dispatch, messageQueue, currentAudio); // Hook handling text-to-speech
  useDialogStateHandler({ state, dispatch, processedMessageIds }); // Hook handling converting dialogState from backend to messages
  useDialogEmergencyHandler(state, dispatch); // Hook handling calls to backend in case of a refresh (emergency call)
  useAutoScroll(chatContainerRef, state); // Hook allowing for scrolling down when new messages appear
  useNextMessageDisplayHandler(state, dispatch); // Hook allowing for giving the state "DISPLAYING" to the next message once one messages is fully displayed
  const handleTypingDone = useTypingDoneHandler(state, dispatch); // Hook that informs that a message is fully displayed

  return (
    <div className="flex flex-col gap-[10px]">
      {state.messages.map((item, index) => {
        const uniqueKey = `${item.id}-${index}`;

        if (
          item.messageState === "DISPLAYING" ||
          (item.messageState === "DISPLAYED" && !item.textWritten)
        ) {
          return (
            <ChatMessageRenderer
              key={uniqueKey}
              item={item}
              uniqueKey={uniqueKey}
              textWritten={item.textWritten}
              handleTypingDone={handleTypingDone}
              state={state}
              dispatch={dispatch}
              currentAudio={currentAudio}
              messageQueue={messageQueue}
            />
          );
        } else {
          return null;
        }
      })}
    </div>
  );
}

export default React.memo(FullChat);
