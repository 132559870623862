export const initialState = {
  tutorialActive: true,
  conversationStarted: false,
  connectionScreenActive: false,
  changeChainActive: false,
  conversationId: "",
  dialogState: [],
  languageChoiceActive: false,
  listening: false,
  watermark: 0,
  fetchingMessage: false,
  userInput: "",
  soundOn: false,
  isSpeaking: false,
  messages: [],
  voiceInput: "",
  voiceMessageSent: false,
  userId: "",
  contractReader: false,
  conversationState: "Get Started",
  accountWindowActive: false,
  hamburgerMenuOpen: false,
  contractList: [],
  decodingAnimationFinished: false,
  contractListAnimationFinished: false,
  contractChosen: false,
  getCertificate: false,
  loginModalOpen: false,
  paymentActive: false,
  dialoguePaymentPending: false,
  paymentFinished: false,
  certificatePaid: false,
  maxMessageLength: 180,
  pdfReader: false,
  pdfContent: [],
  buttonStates: [],
  socketConnected: false,
  websocket: null,
  certificateFieldChoice: false,
  certificateFields: [],
  contractSummaryActive: false,
  downloadCertificate: false,
  hashWindowActive: false,
  verifyProofActive: false,
  summary: "",
  proofId: "",
  allCertificates: [],
  creditCardChosen: false,
  readerPaymentActive: false,
  proofVerificationActive: false,
  proofVerificationResult: "",
  proofOverview: "",
  conditionsChecked: false,
  mobileStepsActive: false,
  certificateError: false,
  readerPaymentError: false,
  adminAccess: false,
  dialogIsRestarting: false,
  modal: undefined,
  signUpPageActive: false,
  logInPageActive: false,
  profilePageActive: false,
  web2Login: false,
  ourApplicationsActive: true,
  deleteAccountModalActive: false,
  paybackPageActive: false,
  loginCodeModalActive: false,
  web2Email: "",
  userAvatar: "",
  changePasswordModalActive: false,
  web3Login: false,
  resetPasswordConfirmationModal: false,
  forgotPasswordEmail: "",
  resetPasswordToken: "",
  resetPasswordModal: false,
  paybackActiveScreen: "",
  idOfChosenContract: "",
  typeOfChosenContract: "",
  thinkingMessage: "",
};
