// ----------------------
// Packages
// ----------------------
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

// ----------------------
// Assets
// ----------------------
import polygonLogo from "../../../../assets/images/readerPolygonLogo.svg";
import ethereumLogo from "../../../../assets/images/ethereumLogo.svg";

interface ChainChosenProps {
  setChainChosen: React.Dispatch<React.SetStateAction<string>>;
  type: "Polygon" | "Ethereum";
}

function ChainButton({ setChainChosen, type }: ChainChosenProps) {
  return (
    <button
      onClick={() => setChainChosen(type)}
      className="flex items-center p-2 first-dropdown gap-[5px] w-full"
    >
      <LazyLoadImage
        alt={`${type} Logo`}
        effect="blur"
        src={type === "Polygon" ? polygonLogo : ethereumLogo}
        className="w-[20px]"
      />
      <span>{type === "Polygon" ? "Mumbai" : "Sepolia"}</span>
    </button>
  );
}

export default ChainButton;
