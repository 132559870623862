// utils/useEscapeKey.tsx
import { useEffect } from "react";

interface EscapeKeyParams {
  type: string;
  payload: any;
}

export const useEscapeKey = (
  dispatch: React.Dispatch<any>,
  params: EscapeKeyParams
) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        dispatch({
          type: params.type,
          payload: params.payload,
        });
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Cleanup
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [dispatch, params]);
};
