import React from "react";

function AccountCreationTos() {
  return (
    <p className="text-[#a8a4a4] text-[12px] ">
      Secure login{" "}
      <a
        href="/TermsAndConditions.pdf"
        download="TermsAndConditions"
        target="_blank"
        rel="noopener noreferrer"
        className="font-bold underline"
      >
        Terms
      </a>{" "}
      &{" "}
      <a
        href="/PrivacyPolicy.pdf"
        download="PrivacyPolicy"
        target="_blank"
        rel="noopener noreferrer"
        className="font-bold underline"
      >
        Privacy
      </a>
    </p>
  );
}

export default AccountCreationTos;
