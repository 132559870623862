// ----------------------
// Packages
// ----------------------
import { LazyLoadImage } from "react-lazy-load-image-component";

// ----------------------
// Assets
// ----------------------
import chevronDown from "../../../../../assets/images/chevronDown.svg";
import chevronUp from "../../../../../assets/images/chevronUp.svg";
import vaiPaybackIcon from "../../../../../assets/images/vaiPaybackIcon.svg";
import paybackIcon from "../../../../../assets/images/paybackIcon.svg";

// ----------------------
// Components
// ----------------------
import ImageDropDown from "../Crypto/ImageDropDown";

interface ChainChosenProps {
  dropdownOpen: boolean;
  methodChosen: string;
}

function PaybackMethodChosen({ dropdownOpen, methodChosen }: ChainChosenProps) {
  return (
    <div className="font-bold flex items-center justify-around w-full">
      <div className="flex items-center gap-[10px]">
        <LazyLoadImage
          alt={`${methodChosen} logo`}
          effect="blur"
          src={methodChosen === "Payback" ? paybackIcon : vaiPaybackIcon}
          className="w-[18px]"
        />
        <p className="text-[14px] hidden sm400:block">
          {methodChosen === "Payback" ? "VAI Payback" : "VAI Points"}
        </p>
        <p className="text-[14px] sm400:hidden">
          {methodChosen === "Payback" ? "Payback" : "Points"}
        </p>
      </div>
      {!dropdownOpen && <ImageDropDown src={chevronDown} />}
      {dropdownOpen && <ImageDropDown src={chevronUp} />}
    </div>
  );
}

export default PaybackMethodChosen;
