import React, { useState, useEffect, useRef } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import TransactionDownloadHistory from "./TransactionDownloadHistory";

interface UnifiedTransaction {
  type: "payback" | "vaiPoints";
  date: string;
  amount: string;
  address?: string;
  transactionHash?: string;
  blockNumber?: number;
  fromCollection?: string;
  fromId?: string;
}

interface TransactionHistoryOptionsProps {
  onSortOptionSelected: (option: string) => void;
  transactions: UnifiedTransaction[];
}

const TransactionHistoryOptions: React.FC<TransactionHistoryOptionsProps> = ({
  onSortOptionSelected,
  transactions,
}) => {
  const [showSortOptions, setShowSortOptions] = useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState<string>("");
  const sortRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (sortRef.current && !sortRef.current.contains(event.target as Node)) {
        setShowSortOptions(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleSortOptionClick = (option: string) => {
    setSelectedSortOption((prevOption) =>
      prevOption === option ? "" : option
    );
    setShowSortOptions(false);
    onSortOptionSelected(option);
  };

  const sortOptions = [
    { key: "inflowOutflow", label: "Inflow/Outflow" },
    { key: "date", label: "Date" },
  ];

  return (
    <div className="flex items-center justify-between gap-4 text-[14px] md:text-[16px]">
      <TransactionDownloadHistory transactions={transactions} />
      <div className="relative w-[150px]" ref={sortRef}>
        <div
          className={`text-[#CECDDA] flex items-center justify-between gap-2 cursor-pointer p-2 ${
            showSortOptions
              ? "border-b-0 rounded-b-0 border-t-[1px] border-l-[1px] border-r-[1px] rounded-t-[6px] border-[#CECDDA]"
              : "border-[1px] border-[#CECDDA] rounded-[6px]"
          }`}
          onClick={() => setShowSortOptions(!showSortOptions)}
        >
          <span>{selectedSortOption ? selectedSortOption : "Sort by"}</span>
          {showSortOptions ? (
            <FiChevronUp className="mt-[4px]" />
          ) : (
            <FiChevronDown className="mt-[4px]" />
          )}
        </div>
        {showSortOptions && (
          <div className="absolute top-[100%] border-gray-300 bg-white w-full text-[#CECDDA]">
            {sortOptions.map((option) => (
              <div
                key={option.key}
                className={`p-2 hover:bg-gray-100 cursor-pointer ${
                  selectedSortOption === option.label ? "bg-gray-100" : ""
                } border-l-[1px] border-r-[1px] ${
                  option.key === "date" ? "border-b-[1px] rounded-b-[6px]" : ""
                } border-[#CECDDA]`}
                onClick={() => handleSortOptionClick(option.label)}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default TransactionHistoryOptions;
