// ----------------------
// Interfaces + styling
// ----------------------
import { State } from "../../../state/interfaces";
import "./pdfContent.css";

// ----------------------
// Components
// ----------------------
import BlurredText from "./BlurredText";

// ----------------------
// Utility functions
// ----------------------
import { generateRandomText } from "../../../utils/utility/generateRandomText";

interface PdfContentProps {
  state: State;
}

function PdfContent(props: PdfContentProps) {
  // Generate two random text strings of different lengths
  const randomText1 = generateRandomText(100);
  const randomText2 = generateRandomText(130);

  const generateContent = props.state.pdfContent.flatMap((content, index) => {
    // Generate random text
    const randomBlurText = generateRandomText(
      Math.floor(Math.random() * 70) + 70
    ); // For a random length between 70 to 100

    // Check if the content contains block-level tags
    const hasList = content.includes("<ol") || content.includes("<ul");

    // List of elements to be returned
    const elements = [];

    // Push the main content
    if (hasList) {
      elements.push(
        <div
          key={`${index}-content`}
          className="text-justify word-break"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      );
    } else {
      elements.push(
        <p
          key={`${index}-content`}
          className="text-justify word-break"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      );
    }

    // If it's not the last item, push the blurred text
    if (index !== props.state.pdfContent.length - 1) {
      elements.push(<BlurredText text={randomBlurText} />);
    }

    return elements;
  });

  return (
    <div className="flex flex-col px-[10px] sm:px-[30px] py-[20px] h-[600px] w-[100%] overflow-y-auto overflow-x-hidden">
      <BlurredText text={randomText1} />
      <div className="whitespace-normal">{generateContent}</div>

      <BlurredText text={randomText2} />
    </div>
  );
}

export default PdfContent;
