import React, { useState, useEffect } from "react";
import errorVaiot from "../../../assets/images/errorVaiot.svg";
import errorSign from "../../../assets/images/errorCoin.svg";
import { Action, ActionType, State } from "../../../state/interfaces";

interface ConnectionScreenWalletRegisteredProps {
  dispatch: React.Dispatch<Action>;
  state: State;
}

function ConnectionScreenWalletRegistered({
  dispatch,
  state,
}: ConnectionScreenWalletRegisteredProps) {
  const handleWeb2Login = () => {
    dispatch({ type: ActionType.SET_LOG_IN_PAGE_ACTIVE, payload: true });
  };

  return (
    <div className="p-[20px] flex flex-col h-full gap-[40px] justify-center items-center">
      <img
        src={errorVaiot}
        alt="Error Sign"
        className="w-[60px] sm450:w-[80px] sm:w-[150px]"
      />
      <div className="flex items-center gap-[10px]">
        <img src={errorSign} alt="Error Sign" className="w-[30px]" />
        <p className="font-bold text-[24px] sm:text-[28px] gradient-text">
          Error
        </p>
      </div>
      <p className="text-center text-[gray] text-[14px] sm450:text-[16px] sm:text-[18px]">
        The wallet you are using is linked to an existing account on our
        platform. Please log in to that account to continue.
      </p>
      <button
        onClick={handleWeb2Login}
        className="rounded-[2px] py-[10px] px-[40px] border-[2px] border-[#0023EF1A] text-[#0023EF] bg-[#0023EF12] font-semibold border-opacity-[10]"
      >
        Log In
      </button>
    </div>
  );
}

export default ConnectionScreenWalletRegistered;
