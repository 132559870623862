import React from "react";
import crossIcon from "../../assets/images/cross.svg";
import { Action, ActionType, State } from "../../state/interfaces";

interface ProfileHeaderProps {
  dispatch: React.Dispatch<Action>;
  state: State;
}

function ProfileHeader({ dispatch, state }: ProfileHeaderProps) {
  const handleCloseProfile = () => {
    dispatch({ type: ActionType.SET_PROFILE_PAGE_ACTIVE, payload: false });
  };

  return (
    <div className="flex items-center justify-between px-[20px] py-[20px] shadow-md bg-[#F7F8FD] w-full rounded-t-[6px] border-b-[#E5E4F0] border-[1px]">
      <p className="text-[#0023EF] text-[24px] font-TT">
        Hello,{" "}
        <span className="font-bold">@{state.web2Email.split("@")[0]}</span>
      </p>
      <button className="outline-none" onClick={handleCloseProfile}>
        <img src={crossIcon} className="w-[20px]" />
      </button>
    </div>
  );
}

export default ProfileHeader;
