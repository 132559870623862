import { useState, useEffect, useRef } from "react";

export const useTypingEffect = (
  text: string | string[],
  typingSpeed: number,
  onTypingDone: () => void
) => {
  const [displayedText, setDisplayedText] = useState("");
  const textRef = useRef(text);
  textRef.current = text;

  const typingState = useRef({
    currentText: "",
    currentIndex: 0,
  });

  useEffect(() => {
    let isCancelled = false;

    const typeNextCharacter = () => {
      if (document.hidden) {
        finishTyping();
      } else {
        if (typeof textRef.current === "string") {
          if (typingState.current.currentText.length < textRef.current.length) {
            typingState.current.currentText +=
              textRef.current[typingState.current.currentText.length];
            if (!isCancelled) {
              setDisplayedText(typingState.current.currentText);
            }
          } else {
            finishTyping();
          }
        } else if (Array.isArray(textRef.current)) {
          if (typingState.current.currentIndex < textRef.current.length) {
            if (
              typingState.current.currentText.length <
              textRef.current[typingState.current.currentIndex].length
            ) {
              typingState.current.currentText +=
                textRef.current[typingState.current.currentIndex][
                  typingState.current.currentText.length
                ];
              if (!isCancelled) {
                setDisplayedText(typingState.current.currentText);
              }
            } else {
              typingState.current.currentIndex++;
              typingState.current.currentText = "";
            }
          } else {
            finishTyping();
          }
        }
      }
    };

    const finishTyping = () => {
      if (!isCancelled) {
        onTypingDone();
        if (typeof textRef.current === "string") {
          setDisplayedText(textRef.current);
        } else if (Array.isArray(textRef.current)) {
          setDisplayedText(textRef.current.join(" "));
        }
      }
    };

    const intervalId = window.setInterval(typeNextCharacter, typingSpeed);

    return () => {
      isCancelled = true;
      window.clearInterval(intervalId);
    };
  }, [typingSpeed, onTypingDone]);

  return displayedText;
};
