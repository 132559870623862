// ----------------------
// Packages
// ----------------------
import { useState } from "react";
import { ClipLoader } from "react-spinners";

// ----------------------
// Assets
// ----------------------
import { ReactComponent as QRCode } from "../../../assets/images/certificateQrCode.svg";

// ----------------------
// Utility Functions
// ----------------------
import { handleQRCode } from "../utils/handleQrCode";

// ----------------------
// Interfaces
// ----------------------
import { State } from "../../../state/interfaces";

interface QRCodeDownloadProps {
  state: State;
}

function QRCodeDownload({ state }: QRCodeDownloadProps) {
  const [qrCodeDownloading, setQrCodeDownloading] = useState(false);

  return (
    <div className="flex flex-col items-center justify-center gap-[10px] w-full sm:w-[25%] flex-shrink-0">
      <p className="text-[#fff] text-[14px] font-semibold">
        Certificate QR Code
      </p>
      <div className="w-[120px] lg:w-[200px]">
        <QRCode className="w-full h-full" />
      </div>
      {!qrCodeDownloading && (
        <button
          onClick={() => handleQRCode(state, setQrCodeDownloading)}
          className="text-[#0023EF] font-bold flex items-center justify-center w-36 h-10 px-10 gap-2.5 border border-blue-800/60 bg-blue-800/20 shadow-button rounded-sm flex-shrink-0"
        >
          Download
        </button>
      )}
      {qrCodeDownloading && (
        <button className="text-[#0023EF] font-bold flex items-center justify-center w-36 h-10 px-10 gap-2.5 border border-blue-800/60 bg-blue-800/20 shadow-button rounded-sm flex-shrink-0">
          <ClipLoader />
        </button>
      )}
    </div>
  );
}

export default QRCodeDownload;
