import { Action, State } from "../../../../state/interfaces";
import React from "react";
import SocketHandler from "../../../../utils/sockets/handleSocket";

export const handlePaymentStatusUpdate = async (
  status: string,
  tokenType: string,
  txHash: string = "",
  reason: string,
  state: State,
  id: string,
  setNumOfCalls: React.Dispatch<React.SetStateAction<number>>,
  numOfCalls: number,
  dispatch: React.Dispatch<Action>
) => {
  if (numOfCalls === 0) {
    setNumOfCalls((prev) => prev + 1);

    if (!(state.socketHandler instanceof SocketHandler)) {
      state.socketHandler = new SocketHandler();
    }

    state.socketHandler.clientPaymentUpdate(
      status,
      state,
      txHash,
      tokenType.trim()
    );

    dispatch({
      type: "INCREMENT_NUM_OF_CALLS",
      payload: { id: id },
    });
  }
};
