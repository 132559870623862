import { toast } from "react-toastify";

export const copyToClipboard = async (e: React.MouseEvent, element: any) => {
  e.preventDefault();
  try {
    await navigator.clipboard.writeText(
      element === null || element === undefined ? "" : element
    );
    toast("Copied to clipboard!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } catch (err) {
    console.error("Failed to copy text: ", err);
  }
};
