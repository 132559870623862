// ----------------------
// Packages
// ----------------------
import React from "react";

// ----------------------
// Components
// ----------------------
import HashDropDown from "./HashDropDown";
import HashFields from "./HashFields";

// ----------------------
// Utility Functions
// ----------------------
import { handleHash } from "../utils/handleHash";

interface HashIncompleteProps {
  selectedNumber: number;
  fields: never[];
  setSelectedNumber: React.Dispatch<React.SetStateAction<number>>;
  setHashedInput: React.Dispatch<React.SetStateAction<never[]>>;
  setHashed: React.Dispatch<React.SetStateAction<boolean>>;
}

function HashIncomplete({
  selectedNumber,
  fields,
  setSelectedNumber,
  setHashedInput,
  setHashed,
}: HashIncompleteProps) {
  return (
    <div className="flex flex-col">
      <div className="flex gap-[20px] items-center font-semibold mb-[50px]">
        <p className="text-[16px] sm400:text-[18px]">Number of fields:</p>
        <HashDropDown
          selectedNumber={selectedNumber}
          setSelectedNumber={setSelectedNumber}
          number={fields.length}
        />
      </div>
      {fields.map((field, index) => {
        const [key, value] = Object.entries(field)[0]; // Assuming one key-value pair per object
        return (
          <HashFields
            key={index}
            fieldName={key}
            fieldValue={value === "" ? '""' : (value as string)}
          />
        );
      })}

      <button
        onClick={() => handleHash(fields, setHashedInput, setHashed)}
        className="bg-hash-confirm-button w-[150px] h-[50px] rounded-md text-[#0023EF] font-bold tracking-wide text-[20px] mx-auto mt-[50px]"
      >
        HASH
      </button>
    </div>
  );
}

export default HashIncomplete;
