// ----------------------
// Packages
// ----------------------
import React, { useEffect, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useAccount } from "wagmi";
import { useWeb3ModalState } from "@web3modal/wagmi/react";

// ----------------------
// Utility Functions
// ----------------------
import { fetchTokenPrice } from "../../../../utils/api/fetchTokenPrice";
import { getContractConfig } from "../config";
import { useSwitchChain } from "wagmi";
import { useSendTransaction, useWaitForTransactionReceipt } from "wagmi";

// ----------------------
// Interfaces
// ----------------------
import { Action, ActionType, State } from "../../../../state/interfaces";
import { updateBackendPayment } from "../../utils/updateBackendPayment";
import handleToastError from "../../../../utils/utility/handleToastError";
import { updatePaybackPayment } from "../../utils/updatePaybackPayment";
import { updateAgreementRoom } from "../../utils/updateAgreementRoom";

const total = process.env.REACT_APP_CERTIFICATE_PRICE;
interface PayButtonProps {
  paymentChosen: string;
  currencyChosen?: string;
  chainChosen?: string;
  setBubbleMessageContent: React.Dispatch<React.SetStateAction<string>>;
  state: State;
  idOfChosenContract: string;
  dispatch: React.Dispatch<Action>;
  paybackChosen: string;
  inputValue: string;
  paybackActive: boolean;
  paybackAmount: number;
  vaiPoints: number;
  typeOfChosenContract: "SA" | "NDA" | "PoAG" | "PoAS" | "";
}

function PayButton({
  paymentChosen,
  currencyChosen,
  chainChosen,
  setBubbleMessageContent,
  state,
  idOfChosenContract,
  dispatch,
  paybackChosen,
  inputValue,
  paybackActive,
  paybackAmount,
  vaiPoints,
  typeOfChosenContract,
}: PayButtonProps) {
  const { address } = useAccount();
  const { selectedNetworkId } = useWeb3ModalState();
  const { switchChain } = useSwitchChain();
  const { data: hash, sendTransaction, error } = useSendTransaction();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { isLoading: isConfirming, isSuccess: isConfirmed } =
    useWaitForTransactionReceipt({
      hash,
    });

  const chainId =
    selectedNetworkId !== undefined ? parseInt(selectedNetworkId) : undefined;

  const startPayment = async () => {
    dispatch({
      type: ActionType.SET_READER_PAYMENT_ACTIVE,
      payload: true,
    });

    const assetPrice = await fetchTokenPrice(
      currencyChosen !== undefined ? currencyChosen : ""
    );

    const totalInt = total ? parseFloat(total) : 0;
    const assetPriceFloat = assetPrice ? parseFloat(assetPrice.toString()) : 0;

    const paymentFee =
      totalInt && assetPriceFloat
        ? parseFloat((totalInt / assetPriceFloat).toFixed(6))
        : 0;

    const { contractAddress, contractAbi, value } = getContractConfig(
      currencyChosen,
      chainChosen,
      paymentFee
    );

    const to: `0x${string}` =
      (process.env.REACT_APP_FEE_RECIPIENT_ADDRESS as `0x${string}`) ||
      "0x0000000000000000000000000000000000000000";

    sendTransaction({ to, value });
  };

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.disabled = state.readerPaymentActive;
    }
  }, [state.readerPaymentActive]);

  const handleCryptoClick = async () => {
    if (chainChosen === "") {
      toast.error("Please choose a chain!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    } else if (currencyChosen === "") {
      toast.error("Please choose a currency!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (chainId === 80001 && chainChosen === "Ethereum") {
      switchChain({ chainId: 80001 });
    } else if (chainId === 11155111 && chainChosen === "Polygon") {
      switchChain({ chainId: 11155111 });
    }

    if (
      (chainId === 80001 && chainChosen === "Polygon") ||
      (chainId === 11155111 && chainChosen === "Ethereum")
    ) {
      try {
        await startPayment();
      } catch (error) {
        dispatch({ type: ActionType.SET_READER_PAYMENT_ERROR, payload: true });
      }
    }
  };
  useEffect(() => {
    const performUpdate = async () => {
      if (isConfirming) {
        setBubbleMessageContent("Wait for your transaction to complete...");

        const success = await updateBackendPayment(
          "PENDING",
          hash ?? "0x",
          currencyChosen,
          state,
          dispatch,
          address,
          idOfChosenContract,
          paybackActive,
          typeOfChosenContract,
          paybackChosen,
          inputValue
        );

        if (success === "SUCCESS") {
          setBubbleMessageContent("");
          dispatch({
            type: ActionType.SET_READER_PAYMENT_ACTIVE,
            payload: false,
          });
          dispatch({
            type: ActionType.SET_PAYMENT_ACTIVE,
            payload: false,
          });
          dispatch({
            type: ActionType.SET_PAYMENT_FINISHED,
            payload: true,
          });
        } else if (success === "walletExist") {
          handleToastError(
            "The wallet you are connected with is connected to a different account!"
          );
          dispatch({
            type: ActionType.SET_READER_PAYMENT_ACTIVE,
            payload: false,
          });
          return;
        } else if (success === "userNotAuthenticated") {
          handleToastError("Please log in first!");
          dispatch({
            type: ActionType.SET_READER_PAYMENT_ACTIVE,
            payload: false,
          });
          return;
        } else {
          dispatch({
            type: ActionType.SET_READER_PAYMENT_ACTIVE,
            payload: false,
          });
        }
      }
    };

    performUpdate();
  }, [isConfirming]);

  useEffect(() => {
    if (error) {
      dispatch({
        type: ActionType.SET_READER_PAYMENT_ACTIVE,
        payload: false,
      });
    }
  }, [error]);

  const handleCreditCard = async () => {
    dispatch({ type: ActionType.SET_CREDIT_CARD_CHOSEN, payload: true });
    dispatch({
      type: ActionType.SET_READER_PAYMENT_ACTIVE,
      payload: true,
    });

    interface Data {
      contractId: number;
      walletAddress: string | undefined;
      status: string;
      contractOverview: string;
      certificateFields: any[];
      typeOfContract: "SA" | "NDA" | "PoAG" | "PoAS" | "";
      payback?: number;
      vaiPoints?: number;
    }

    const data: Data = {
      contractId: parseInt(idOfChosenContract),
      walletAddress: address,
      status: "PENDING",
      contractOverview: state.summary,
      certificateFields: state.certificateFields,
      typeOfContract: typeOfChosenContract,
    };

    if (paybackActive) {
      if (
        paybackChosen === "Payback" &&
        inputValue !== "0" &&
        inputValue !== ""
      ) {
        data.payback = parseInt(inputValue);
      } else if (
        paybackChosen === "Points" &&
        inputValue !== "0" &&
        inputValue !== ""
      ) {
        data.vaiPoints = parseInt(inputValue);
      }
    }

    // Use the updated data object in your axios.post request
    const url = await axios.post(
      process.env.REACT_APP_BASE_URL + "/payments/create-checkout-session",
      data,
      { withCredentials: true }
    );

    let sessionUrl = "";

    if (url.data.status) {
      if (url.data.status === "walletExist") {
        handleToastError(
          "The wallet you are connected with is connected to a different account!"
        );
        dispatch({
          type: ActionType.SET_READER_PAYMENT_ACTIVE,
          payload: false,
        });
        return;
      } else if (url.data.status === "failed") {
        handleToastError("You do not have enough funds!");
        dispatch({
          type: ActionType.SET_READER_PAYMENT_ACTIVE,
          payload: false,
        });
        return;
      } else if (url.data.status === "userNotAuthenticated") {
        handleToastError("Please log in before paying!");
        dispatch({
          type: ActionType.SET_READER_PAYMENT_ACTIVE,
          payload: false,
        });
        return;
      }
    }

    if (url.data) {
      sessionUrl = url.data;
    }

    // Redirect to the session URL
    window.location.href = sessionUrl;
  };

  const handlePaybackPayment = async () => {
    dispatch({
      type: ActionType.SET_READER_PAYMENT_ACTIVE,
      payload: true,
    });

    const success = await updatePaybackPayment(
      state,
      dispatch,
      address,
      idOfChosenContract,
      typeOfChosenContract,
      paybackChosen
    );

    if (success === "success") {
      setBubbleMessageContent("");
      dispatch({
        type: ActionType.SET_READER_PAYMENT_ACTIVE,
        payload: false,
      });
      dispatch({
        type: ActionType.SET_PAYMENT_ACTIVE,
        payload: false,
      });
      dispatch({
        type: ActionType.SET_PAYMENT_FINISHED,
        payload: true,
      });
    } else if (success === "notFound") {
      handleToastError("Contract not found!");
      dispatch({
        type: ActionType.SET_READER_PAYMENT_ACTIVE,
        payload: false,
      });
      return;
    } else if (success === "failed") {
      handleToastError("You don't have enough funds!");
      dispatch({
        type: ActionType.SET_READER_PAYMENT_ACTIVE,
        payload: false,
      });
      return;
    } else if (success === "alreadyPaid") {
      handleToastError("Certificate has already been paid for!");
      dispatch({
        type: ActionType.SET_READER_PAYMENT_ACTIVE,
        payload: false,
      });
      return;
    } else if (success === "walletExist") {
      handleToastError(
        "The wallet you are connected with is connected to a different account!"
      );
      dispatch({
        type: ActionType.SET_READER_PAYMENT_ACTIVE,
        payload: false,
      });
      return;
    } else {
      handleToastError("Unexpected Error!");
      dispatch({
        type: ActionType.SET_READER_PAYMENT_ACTIVE,
        payload: false,
      });
      return;
    }
  };

  const handleClick = () => {
    if (inputValue === "5" && paybackActive) {
      if (paybackChosen === "Payback" && paybackAmount < 5) {
        handleToastError("Not enough funds to pay with your payback!");
        return;
      } else if (paybackChosen === "Points" && vaiPoints < 5) {
        handleToastError("Not enough funds to pay with VAI points!");
        return;
      } else {
        handlePaybackPayment();
      }
    } else if (paymentChosen === "Crypto") {
      updateAgreementRoom(
        state,
        dispatch,
        address,
        idOfChosenContract,
        typeOfChosenContract
      );
      handleCryptoClick();
    } else if (paymentChosen === "Online Payments") {
      updateAgreementRoom(
        state,
        dispatch,
        address,
        idOfChosenContract,
        typeOfChosenContract
      );
      handleCreditCard();
    }
  };

  return (
    <button
      ref={buttonRef}
      onClick={() => handleClick()}
      className={`px-[30px] py-[15px] font-bold rounded-md text-[#ffff] md:px-[40px] md:py-[20px] md:text-[18px] mb-[10px] ${
        !state.readerPaymentActive &&
        (paymentChosen === "Online Payments" ||
          (paymentChosen === "Crypto" &&
            chainChosen !== "" &&
            currencyChosen !== "") ||
          (paybackActive && paybackChosen !== "" && inputValue === "5"))
          ? "bg-connect-button"
          : "bg-[#CECDDA]"
      }`}
    >
      Pay
    </button>
  );
}

export default PayButton;
