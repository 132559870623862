// ----------------------
// Components
// ----------------------
import InputField from "./InputField";

interface ProofContentProps {
  proofJson: any;
  fields: any[];
}

const ProofContent = (props: ProofContentProps) => {
  if (!props.proofJson) return null;

  if (props.fields.length === 0) {
    return (
      <div className="text-[#fff] tracking-wider mb-[20px] md:text-[18px] w-full items-center justify-center flex">
        <p>No values found for this proof!</p>
      </div>
    );
  }

  return (
    <>
      <p className="text-[#fff] tracking-wider mb-[20px] md:text-[18px]">
        Values for which you check the contract
      </p>
      {props.fields.map((item: string, index: number) => {
        const entries = Object.entries(item);
        if (entries.length > 0) {
          const [name, value] = entries[0] as [string, string];
          return (
            <InputField
              name={name}
              value={value}
              iterator={(index + 1).toString()}
              key={index}
            />
          );
        }
        return null;
      })}
    </>
  );
};
export default ProofContent;
