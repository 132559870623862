// ----------------------
// Packages
// ----------------------
import React from "react";

// ----------------------
// Assets
// ----------------------
import { ReactComponent as Bitcoin } from "../../../../../assets/images/bitcoin.svg";

// ----------------------
// Components
// ----------------------
import CryptoNotSelected from "./CryptoNotSelected";
import CryptoSelected from "./CryptoSelected";

// ----------------------
// Interfaces and styling
// ----------------------
import "react-lazy-load-image-component/src/effects/blur.css";

interface CryptoDivProps {
  paymentChosen: string;
  setPaymentChosen: React.Dispatch<React.SetStateAction<string>>;
  setCurrencyChosen: React.Dispatch<React.SetStateAction<string>>;
  setChainChosen: React.Dispatch<React.SetStateAction<string>>;
  setSecondDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setFirstDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
  secondDropdownOpen: boolean;
  firstDropdownOpen: boolean;
  chainChosen: string;
  currencyChosen: string;
  inputValue: string;
  paybackActive: boolean;
}

function CryptoDiv(props: CryptoDivProps) {
  const handlePaymentChosen = () => {
    if (props.paymentChosen !== "Crypto") {
      props.setPaymentChosen("Crypto");
    } else {
      props.setPaymentChosen("");
    }
  };

  return (
    <button
      onClick={handlePaymentChosen}
      className={`bg-[#ffff] px-[20px] hover:shadow-xl md:px-[40px] cursor-pointer py-[20px] flex justify-between items-center border border-transparent hover:border-[gray] hover:border-opacity-[20%] ${
        props.paymentChosen === "Crypto" && props.inputValue !== "5"
          ? "border-gray border-opacity-[20%] shadow-xl"
          : ""
      } ${
        (props.paymentChosen !== "Crypto" && props.paymentChosen !== "") ||
        (props.paybackActive && props.inputValue === "5") ||
        (props.paybackActive && props.paymentChosen === "")
          ? "opacity-[50%]"
          : ""
      }`}
    >
      <div className="hidden sm:block">
        {props.paymentChosen === "Crypto" && props.inputValue !== "5" ? (
          <CryptoSelected
            firstDropdownOpen={props.firstDropdownOpen}
            setFirstDropDownOpen={props.setFirstDropdownOpen}
            secondDropdownOpen={props.secondDropdownOpen}
            setSecondDropdownOpen={props.setSecondDropdownOpen}
            chainChosen={props.chainChosen}
            setChainChosen={props.setChainChosen}
            currencyChosen={props.currencyChosen}
            setCurrencyChosen={props.setCurrencyChosen}
          />
        ) : (
          <CryptoNotSelected />
        )}
      </div>
      <div className="sm:hidden flex flex-col gap-[20px] w-full">
        <CryptoNotSelected />
        {props.paymentChosen === "Crypto" && props.inputValue !== "5" && (
          <CryptoSelected
            firstDropdownOpen={props.firstDropdownOpen}
            setFirstDropDownOpen={props.setFirstDropdownOpen}
            secondDropdownOpen={props.secondDropdownOpen}
            setSecondDropdownOpen={props.setSecondDropdownOpen}
            chainChosen={props.chainChosen}
            setChainChosen={props.setChainChosen}
            currencyChosen={props.currencyChosen}
            setCurrencyChosen={props.setCurrencyChosen}
          />
        )}
      </div>

      <div className="w-[35px] sm:w-[48px] hidden sm:block">
        <Bitcoin className="w-full h-full" />
      </div>
    </button>
  );
}

export default CryptoDiv;
