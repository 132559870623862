// ----------------------
// Interfaces
// ----------------------
interface SingleContractProps {
  hover: boolean;
  text: string;
  width: number;
}

function SingleContract({ hover, text, width }: SingleContractProps) {
  return (
    <div
      className={` ${
        hover
          ? "bg-contract-background-hover shadow-md"
          : "bg-contract-background"
      } text-center text-[10px] sm:text-[14px] sm900:text-[16px] lg:text-[18px] text-[#0023EF] font-bold tracking-wider rounded-md py-[15px]`}
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: `${width}%`,
      }}
    >
      {text}
    </div>
  );
}

export default SingleContract;
