import axios from "axios";
import handleToastNotification from "../utility/handleToastNotification";
import handleToastError from "../utility/handleToastError";

export const handleProfileSupport = async (message: string) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/users/submit-ticket`,
      { body: message },
      { withCredentials: true }
    );
    handleToastNotification("Message sent to support!");
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 403) {
        handleToastError(
          "Access denied! Please log out and log back in to solve this issue."
        );
      } else {
        console.error("Error when sending message to support:", error);
      }
    } else {
      console.error("An unexpected error occurred:", error);
    }
  }
};
