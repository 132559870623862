// ----------------------
// Interfaces
// ----------------------
interface HeaderProps {
  text: string;
  width: number;
}

function Header({ text, width }: HeaderProps) {
  return (
    <div
      style={{ width: `${width}%` }}
      className={`bg-reader-button text-[10px] sm:text-[16px]  text-center lg:text-[20px] text-[#ffff] font-bold tracking-wider rounded-md py-[15px]`}
    >
      {text}
    </div>
  );
}

export default Header;
