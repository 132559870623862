// ----------------------
// Packages
// ----------------------
import React, { useState } from "react";

// ----------------------
// Interfaces
// ----------------------
import { Action, State } from "../../state/interfaces";

// ----------------------
// Components
// ----------------------
import AccountCreationWindow from "./AccountCreation";
import LoginCodeModal from "./LoginCodeModal";

interface SignUpPageProps {
  dispatch: React.Dispatch<Action>;
  state: State;
}

function SignUpPage({ dispatch, state }: SignUpPageProps) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return state.loginCodeModalActive ? (
    <LoginCodeModal
      dispatch={dispatch}
      email={email}
      password={password}
      setPassword={setPassword}
      isLogIn={false}
    />
  ) : (
    <AccountCreationWindow
      dispatch={dispatch}
      state={state}
      headerText="Sign Up"
      isLogInPage={false}
      bottomText="Already have an account?"
      bottomTextBolded="Sign In"
      mainButtonText="Sign Up"
      email={email}
      password={password}
      setEmail={setEmail}
      setPassword={setPassword}
    />
  );
}

export default SignUpPage;
