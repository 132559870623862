// ----------------------
// Packages
// ----------------------
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

// ----------------------
// Assets
// ----------------------

import vaiPaybackIcon from "../../../../../assets/images/vaiPaybackIcon.svg";
import paybackIcon from "../../../../../assets/images/paybackIcon.svg";

interface PaybackButtonProps {
  setMethodChosen: React.Dispatch<React.SetStateAction<string>>;
  type: "Payback" | "Points";
}

function PaybackButton({ setMethodChosen, type }: PaybackButtonProps) {
  return (
    <button
      onClick={() => setMethodChosen(type)}
      className="flex items-center p-2 first-dropdown gap-[5px] w-full"
    >
      <LazyLoadImage
        alt={`${type} Logo`}
        effect="blur"
        src={type === "Payback" ? paybackIcon : vaiPaybackIcon}
        className="w-[20px]"
      />
      <span className="hidden sm400:block">
        {type === "Payback" ? "VAI Payback" : "VAI Points"}
      </span>
      <span className="sm400:hidden">
        {type === "Payback" ? "Payback" : "Points"}
      </span>
    </button>
  );
}

export default PaybackButton;
