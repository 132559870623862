// ----------------------
// External/Internal Packages
// ----------------------
import React from "react";

// ----------------------
// Utility Functions
// ----------------------
import { resetContractDecoded } from "../../utils/resetContractDecoded";

// ----------------------
// Assets
// ----------------------
import { ReactComponent as Chevron } from "../../../../assets/images/chevronLeft.svg";
import { ReactComponent as ChevronWhite } from "../../../../assets/images/chevronLeftWhite.svg";

// ----------------------
// Interfaces
// ----------------------
import { Action } from "../../../../state/interfaces";

interface ContractTerms {
  agreementTerms: Array<any>;
  parties: Array<string>;
  services: Array<Array<string>>;
  onBlockchain: string;
}

interface ContractInfoNDA {
  creationTimestamp: string;
  fieldNames: string[];
  fieldValues: string[];
  partyA: string;
  partyB: string;
}

interface ChevronLeftProps {
  fieldChoice: boolean;
  dispatch: React.Dispatch<Action>;
  setBubbleMessageActive: React.Dispatch<React.SetStateAction<boolean>>;
  setBubbleMessageContent: React.Dispatch<React.SetStateAction<string>>;
  color: "white" | "black";

  setContractInfoSA: React.Dispatch<
    React.SetStateAction<ContractTerms | undefined>
  >;
  setContractInfoNDA: React.Dispatch<
    React.SetStateAction<ContractInfoNDA | undefined>
  >;
}

function ChevronLeft({
  fieldChoice,
  dispatch,
  setBubbleMessageActive,
  setBubbleMessageContent,
  color,
  setContractInfoSA,
  setContractInfoNDA,
}: ChevronLeftProps) {
  return (
    <button
      onClick={() => {
        resetContractDecoded(
          fieldChoice,
          dispatch,
          setBubbleMessageActive,
          setBubbleMessageContent,
          setContractInfoSA,
          setContractInfoNDA
        );
      }}
      className="w-[30px] h-[30px] sm:w-[40px] sm:h-[40px] "
    >
      {color === "black" && <Chevron className="w-full h-full" />}
      {color === "white" && <ChevronWhite className="w-full h-full" />}
    </button>
  );
}

export default ChevronLeft;
