// ----------------------
// External/Internal Packages
// ----------------------
import React from "react";

// ----------------------
// Utility Functions
// ----------------------
import { handleDzinMessage } from "../../utils/handleDzinMessage";

// ----------------------
// Interfaces
// ----------------------
import { Action, ActionType } from "../../../../state/interfaces";

interface ShowCertificateProps {
  setBubbleMessageActive: React.Dispatch<React.SetStateAction<boolean>>;
  setBubbleMessageContent: React.Dispatch<React.SetStateAction<string>>;
  dispatch: React.Dispatch<Action>;
}

function ShowCertificate({
  setBubbleMessageActive,
  setBubbleMessageContent,
  dispatch,
}: ShowCertificateProps) {
  const handleMouse = (active: boolean, content: string) => {
    setBubbleMessageActive(active);
    setBubbleMessageContent(content);
  };

  const handleShowCertificate = () => {
    handleDzinMessage(
      setBubbleMessageActive,
      setBubbleMessageContent,
      false,
      ""
    );

    dispatch({
      type: ActionType.SET_LIST_OF_CERTIFICATES,
      payload: true,
    });
  };

  return (
    <button
      onClick={handleShowCertificate}
      className="font-TT bg-connect-button tracking-widest max-w-[400px] text-[#ffff] w-full md:w-[220px] h-[65px] rounded-[2px] font-bold text-[16px]"
      onMouseEnter={() =>
        handleMouse(true, "See all of your certificates in one place.")
      }
      onMouseLeave={() => {
        handleMouse(false, "");
      }}
    >
      Show Certificate
    </button>
  );
}

export default ShowCertificate;
