import React from "react";
import errorVaiot from "../../../assets/images/errorVaiot.svg";
import errorSign from "../../../assets/images/errorCoin.svg";
import "./circles.css";
import { Action, ActionType } from "../../../state/interfaces";

interface ErrorProps {
  dispatch: React.Dispatch<Action>;
}

function Error({ dispatch }: ErrorProps) {
  const handleReset = () => {
    dispatch({ type: ActionType.SET_PAYMENT_ACTIVE, payload: false });
    dispatch({ type: ActionType.SET_PAYMENT_FINISHED, payload: false });
    dispatch({
      type: ActionType.SET_DECODING_ANIMATION_FINISHED,
      payload: false,
    });
    dispatch({ type: ActionType.SET_CONTRACT_CHOSEN, payload: false });
    dispatch({ type: ActionType.SET_GET_CERTIFICATE, payload: false });
    dispatch({ type: ActionType.SET_CERTIFICATE_PAID, payload: false });
    dispatch({ type: ActionType.SET_CONTRACT_LIST, payload: [] });
  };
  return (
    <div className="p-[20px] flex flex-col sm:flex-row h-full items-center gap-[20px] justify-between">
      <img
        src={errorVaiot}
        alt="Error Sign"
        className="w-[60px] sm450:w-[80px] sm:w-[150px]"
      />
      <div className="flex flex-col items-center justify-between sm:justify-around h-full">
        <div className="flex items-center gap-[10px]">
          <img src={errorSign} alt="Error Sign" className="w-[30px]" />
          <p className="font-bold text-[24px] sm:text-[28px] gradient-text">
            Error
          </p>
        </div>
        <p className="text-center text-[gray] text-[14px] sm450:text-[16px] sm:text-[18px]">
          We apologize, but there was an unexpected error. We are working as
          hard as possible to resolve your problem.
        </p>
        <button
          onClick={handleReset}
          className="rounded-[2px] py-[10px] px-[40px] border-[2px] border-[#0023EF1A] text-[#0023EF] bg-[#0023EF12] font-semibold border-opacity-[10]"
        >
          Back
        </button>
      </div>
    </div>
  );
}

export default Error;
