import React, { useState } from "react";
import chevronLeft from "../../../assets/images/chevronLeft.svg";
import { Action, ActionType } from "../../../state/interfaces";
import { Switch } from "antd";
import "./Switch.css";
import { updateUserRewardType } from "../../../utils/api/updateUserRewardType";
import handleToastError from "../../../utils/utility/handleToastError";
import handleToastNotification from "../../../utils/utility/handleToastNotification";

interface PaybackSettingsProps {
  dispatch: React.Dispatch<Action>;
  stakingActive: boolean;
  setStakingActive: React.Dispatch<React.SetStateAction<boolean>>;
}

function PaybackSettings({
  dispatch,
  stakingActive,
  setStakingActive,
}: PaybackSettingsProps) {
  const [isTogglingAllowed, setIsTogglingAllowed] = useState(true);

  const closeSettings = () => {
    dispatch({ type: ActionType.SET_PAYBACK_ACTIVE_SCREEN, payload: "" });
  };

  const togglePaybackMode = async () => {
    try {
      if (isTogglingAllowed) {
        await updateUserRewardType(!stakingActive);
        setStakingActive(!stakingActive);
        setIsTogglingAllowed(false);
        handleToastNotification("Payback has been updated!");
        setTimeout(() => setIsTogglingAllowed(true), 2000);
      }
    } catch (error) {
      handleToastError("Unexpected error!");
      console.error(error);
      return;
    }
  };

  return (
    <div className="w-[90%] md:w-[80%] flex flex-col items-center justify-start py-[60px] sm:py-[20px] font-TT tracking-wider">
      <div className="flex items-center gap-[20px] w-full">
        <img
          src={chevronLeft}
          className="w-[30px] cursor-pointer"
          onClick={closeSettings}
          alt="Back"
        />
        <p className="text-[18px] font-bold">Payback Settings</p>
      </div>
      <div className="flex items-start justify-between w-full mt-[50px]">
        <div className="flex-1">
          <p className="font-bold">VAI CLAIM</p>
          <p>
            Tokens are automatically sent to your wallet. They do not earn any
            APY.
          </p>
        </div>
        <Switch
          checked={stakingActive}
          onChange={togglePaybackMode}
          disabled={!isTogglingAllowed}
          className="payback-switch mx-[20px] sm400:mx-[35px] sm:mx-[50px] mt-[70px] sm900:mt-[50px] lg:mt-[30px] px-[10px] bg-[#bebebe]"
        />
        <div className="flex-1">
          <p className="font-bold text-right">VAI PAYBACK</p>
          <p className="text-right">
            Tokens are automatically staked for users that earn 10% APY. Users
            can automatically use these tokens to pay for our services, or can
            withdraw the tokens later on.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PaybackSettings;
