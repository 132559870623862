import { useEffect } from "react";
import { State, Action } from "../../state/interfaces";

export function useNextMessageDisplayHandler(
  state: State,
  dispatch: React.Dispatch<Action>
) {
  useEffect(() => {
    let isDisplaying = false;
    let firstNotDisplayedIndex = -1;

    for (let i = 0; i < state.messages.length; i++) {
      if (state.messages[i].messageState === "DISPLAYING") {
        isDisplaying = true;
        break;
      }

      if (
        state.messages[i].messageState === "NOT DISPLAYED" &&
        firstNotDisplayedIndex === -1
      ) {
        firstNotDisplayedIndex = i;
      }
    }

    if (!isDisplaying && firstNotDisplayedIndex !== -1) {
      dispatch({
        type: "UPDATE_MESSAGE_BY_INDEX",
        payload: {
          index: firstNotDisplayedIndex,
          newMessageData: { messageState: "DISPLAYING" },
        },
      });
    }
  }, [state.messages, dispatch]);
}
