// ----------------------
// Internal Packages
// ----------------------
import React from "react";

// ----------------------
// Assets
// ----------------------
import { ReactComponent as QuestionMark } from "../../../assets/images/questionmark.svg";

// ----------------------
// Interfaces
// ----------------------
import { Action, ActionType } from "../../../state/interfaces";

interface QuestionMarkButtonProps {
  dispatch: React.Dispatch<Action>;
}

function QuestionMarkButton({ dispatch }: QuestionMarkButtonProps) {
  return (
    <button
      onClick={() =>
        dispatch({
          type: ActionType.SET_TUTORIAL_ACTIVE,
          payload: true,
        })
      }
      className="w-[30px] h-[30px] sm:w-[50px] sm:h-[50px] md:w-[60px] md:h-[60px] flex items-center justify-center rounded-[2px] border-[1px] border-[#e8e8e8] bg-[#f0f1f7]"
    >
      <div className="w-[34px]">
        <QuestionMark className="w-full h-full" />
      </div>
    </button>
  );
}

export default QuestionMarkButton;
