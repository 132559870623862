import React, { useState, useEffect } from "react";
import { Action, ActionType, State } from "../../state/interfaces";
import ProfileHeader from "./ProfileHeader";
import ChangeWallet from "./ChangeWallet";
import ChangeAvatar from "./ChangeAvatar";
import LoginHistory from "./LoginHistory/LoginHistory";
import ProfileSupport from "./ProfileSupport";
import DeleteAccount from "./DeleteAccount/DeleteAccount";
import ChangePassword from "./ChangePassword/ChangePassword";
import { getUserDetails } from "../../utils/api/getUserDetails";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

interface ProfileProps {
  dispatch: React.Dispatch<Action>;
  state: State;
}

interface Device {
  id: number;
  isTrusted: boolean;
  device: string;
  date: string;
  ipAddress: string;
}

function Profile({ dispatch, state }: ProfileProps) {
  const [isVisible, setIsVisible] = useState(false);
  const [wallet, setWallet] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [devices, setDevices] = useState<Device[]>([]);

  useEffect(() => {
    const handleVisibility = () => {
      setIsVisible(true);
    };

    handleVisibility();
  }, []);

  useEffect(() => {
    const fetchUserDetails = async () => {
      setIsLoading(true);
      try {
        const userDetails = await getUserDetails();
        if (!userDetails) {
          console.error("Unexpected error");
          return;
        }
        dispatch({
          type: ActionType.SET_USER_AVATAR,
          payload: userDetails.userDetails.avatar,
        });
        setWallet(userDetails.userDetails.walletAddress);
        const transformedDevices = userDetails.userDetails.devices.map(
          (device, index) => ({
            id: index,
            isTrusted: device.access === 1,
            device: device.name,
            date: new Date(device.createdAt).toLocaleDateString(),
            ipAddress: device.ipAddress,
          })
        );
        setDevices(transformedDevices);
      } catch (error) {
        console.error("Failed to fetch user details:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserDetails();
  }, []);

  return (
    <div className="w-full h-full flex items-center justify-center flex-col bg-[#050934] bg-opacity-[75%] fixed z-[91]">
      <div
        className={`bg-[#F7F8FD] w-full sm400:w-[400px] sm500:w-[500px] sm:w-[600px] md:w-[800px] border-2 shadow-lg  flex flex-col max-h-[80%] overflow-hidden  rounded-b-[6px]
        transition-all duration-100 ease-in-out transform-gpu ${
          isVisible ? "scale-100" : "scale-[90%]"
        } 
        transition-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);`}
      >
        <ProfileHeader dispatch={dispatch} state={state} />
        <div className="overflow-y-auto">
          <ChangeWallet
            wallet={wallet}
            setWallet={setWallet}
            isLoading={isLoading}
          />
          <ChangeAvatar
            state={state}
            dispatch={dispatch}
            isLoading={isLoading}
          />
          <LoginHistory
            devices={devices}
            setDevices={setDevices}
            isLoading={isLoading}
          />
          <ProfileSupport />
          <ChangePassword dispatch={dispatch} state={state} />
          <DeleteAccount dispatch={dispatch} state={state} />
        </div>
      </div>
    </div>
  );
}

export default Profile;
