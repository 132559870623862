import { useEffect } from "react";
import axios from "axios";
import { Action, ActionType } from "../interfaces";
import handleToastError from "../../utils/utility/handleToastError";

export const useVerificationStatus = (
  id: string | null,
  dispatch: React.Dispatch<Action>
) => {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/snarks/handleQRCodeVerification`,
          { id: id }
        );

        return result.data;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 403) {
            handleToastError(
              "Access denied! Please log out and log back in to solve this issue."
            );
          } else {
            console.error("Error fetching data:", error);
          }
        } else {
          console.error("An unexpected error occurred:", error);
        }
        return false;
      }
    };

    const handleVerify = async (proofJson: any) => {
      try {
        const response = await axios.post(
          process.env.REACT_APP_BASE_URL + "/snarks/verifyProof",
          {
            proof: proofJson,
          }
        );

        if (response.data.verification) {
          dispatch({
            type: ActionType.SET_PROOF_OVERVIEW,
            payload: response.data.overview,
          });
          dispatch({
            type: ActionType.SET_PROOF_VERIFICATION_ACTIVE,
            payload: true,
          });
          dispatch({
            type: ActionType.SET_PROOF_VERIFICATION_RESULT,
            payload: "SUCCESS",
          });
        } else {
          dispatch({
            type: ActionType.SET_PROOF_VERIFICATION_ACTIVE,
            payload: true,
          });
          dispatch({
            type: ActionType.SET_PROOF_VERIFICATION_RESULT,
            payload: "ERROR",
          });
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 403) {
            handleToastError(
              "Access denied! Please log out and log back in to solve this issue."
            );
          } else {
            console.error("Error sending certificate payment request:", error);
          }
        } else {
          console.error("An unexpected error occurred:", error);
        }
      }
    };

    const handleData = async () => {
      if (id && id !== "") {
        window.history.replaceState(
          {},
          document.title,
          window.location.origin + window.location.pathname
        );

        const proofJson = await fetchData();
        if (proofJson) {
          dispatch({ type: ActionType.SET_PROOF_JSON, payload: proofJson });
          dispatch({
            type: ActionType.SET_PROOF_VERIFICATION_ACTIVE,
            payload: true,
          });
          await handleVerify(proofJson);
        } else {
          dispatch({
            type: ActionType.SET_PROOF_VERIFICATION_ACTIVE,
            payload: true,
          });
          dispatch({
            type: ActionType.SET_PROOF_VERIFICATION_RESULT,
            payload: "ERROR",
          });
        }
      }
    };

    handleData();
  }, [id, dispatch]); // Added dispatch to dependency list as it is used inside useEffect
};
