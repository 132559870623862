import { Action, ActionType, State } from "../../state/interfaces";
import { handleStartConversation } from "../sockets/handleStartConversation";
import handleToastError from "./handleToastError";

export const handleRestartBot = (
  state: State,
  dispatch: React.Dispatch<Action>
) => {
  if (!state.dialoguePaymentPending) {
    // Clear the messages and conversationId in the Redux state
    dispatch({ type: ActionType.SET_MESSAGES, payload: [] });
    dispatch({ type: ActionType.SET_CONVERSATION_ID, payload: "" });
    dispatch({ type: ActionType.SET_WATERMARK, payload: 0 });
    dispatch({ type: ActionType.SET_FETCHING_MESSAGE, payload: false });
    dispatch({ type: ActionType.SET_USER_ID, payload: "" });

    // Read the current state from localStorage
    const localStorageState = JSON.parse(localStorage.getItem("state") || "{}");
    localStorage.setItem("userId", "");

    // Update the state object
    localStorageState.messages = [];
    localStorageState.conversationId = "";
    delete localStorageState.watermark; // Delete the watermark property

    // Write the updated state back to localStorage
    localStorage.setItem("state", JSON.stringify(localStorageState));

    // Also update the userId in the Redux state if necessary

    handleStartConversation(state, dispatch, true);
  } else {
    handleToastError("Wait for the payment to finish!");
    return;
  }
};
