// ----------------------
// Packages
// ----------------------
import { useState } from "react";

// ----------------------
// Components
// ----------------------
import CertificateListElement from "./CertificateListElement";
import Header from "../ContractList/Header";
import VaiotPopup from "./VaiotPopup";

// ----------------------
// Utility Functions
// ----------------------
import { useHandleResize } from "../../../utils/utility/useHandleResize";

// ----------------------
// Interfaces and Styling
// ----------------------
import { Action, State } from "../../../state/interfaces";
import "./list.css";

interface ListOfCertificatesProps {
  dispatch: React.Dispatch<Action>;
  state: State;
  setCertificateContractAddress: React.Dispatch<React.SetStateAction<string>>;
}

function ListOfCertificates({
  dispatch,
  state,
  setCertificateContractAddress,
}: ListOfCertificatesProps) {
  const [notificationActive, setNotificationActive] = useState(true);
  const desktopSize = useHandleResize(640, "small", "default");

  return (
    <div className="flex flex-col items-center justify-center w-full h-full relative font-bold">
      <div className="w-full h-full max-w-[1200px] p-[20px] sm:p-[40px]">
        <div className=" mb-[20px] font-TT tracking-wide">
          <p className="text-[18px] sm:text-[20px] bg-certificates-gradient bg-clip-text text-transparent mb-[-10px]">
            Choose
          </p>
          <p className="text-[36px] sm:text-[42px] bg-certificates-gradient bg-clip-text text-transparent">
            Your Certificate
          </p>
        </div>
        <div className="flex gap-[10px] justify-between items-center w-full">
          <Header
            text={desktopSize === "small" ? "Date" : "Purchase Date"}
            width={30}
          />
          <Header text="Contract Summary" width={70} />
        </div>
        <div
          className={`flex flex-col items-center overflow-y-auto ${
            notificationActive ? "max-h-[320px]" : "max-h-[500px]"
          } `}
        >
          {Object.values(state.allCertificates).map(
            (certificate: any, index: any) => {
              return (
                <CertificateListElement
                  key={index}
                  dispatch={dispatch}
                  proofId={certificate._id}
                  purchaseDate={certificate.createdAt}
                  proofJson={certificate.output}
                  contractSummary={certificate.contractOverview}
                  certificateContractAddress={certificate.contractAddress}
                  setCertificateContractAddress={setCertificateContractAddress}
                />
              );
            }
          )}
        </div>
        {notificationActive && (
          <VaiotPopup
            dispatch={dispatch}
            setNotificationActive={setNotificationActive}
          />
        )}
      </div>
    </div>
  );
}

export default ListOfCertificates;
