// ----------------------
// Packages
// ----------------------
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

// ----------------------
// Assets
// ----------------------
import chevronLeft from "../../../../../assets/images/chevronLeft.svg";

// ----------------------
// Components
// ----------------------
import DefaultChain from "./DefaultChain";
import ChainChosen from "./ChainChosen";
import ChainDropDown from "../../ChainDropDown/ChainDropDown";
import DefaultCurrency from "./DefaultCurrency";
import CurrencyChosen from "./CurrencyChosen";
import CurrencyDropDown from "../../CurrencyDropDown/CurrencyDropDown";

interface CryptoSelectedProps {
  firstDropdownOpen: boolean;
  setFirstDropDownOpen: React.Dispatch<React.SetStateAction<boolean>>;
  secondDropdownOpen: boolean;
  setSecondDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
  chainChosen: string;
  setChainChosen: React.Dispatch<React.SetStateAction<string>>;
  currencyChosen: string;
  setCurrencyChosen: React.Dispatch<React.SetStateAction<string>>;
}

function CryptoSelected({
  firstDropdownOpen,
  setFirstDropDownOpen,
  secondDropdownOpen,
  setSecondDropdownOpen,
  chainChosen,
  setChainChosen,
  currencyChosen,
  setCurrencyChosen,
}: CryptoSelectedProps) {
  const handleFirstDropDown = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (secondDropdownOpen) {
      setSecondDropdownOpen(false);
    }
    event.stopPropagation();
    setFirstDropDownOpen(!firstDropdownOpen);
  };

  const handleSecondDropdown = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (firstDropdownOpen) {
      setFirstDropDownOpen(false);
    }
    event.stopPropagation();
    setSecondDropdownOpen(!secondDropdownOpen);
  };

  return (
    <div className="flex gap-[5px] items-center ml-[-10px] sm:ml-0">
      <div className="hidden sm900:block mr-[10px] font-TT tracking-wide text-[18px]">
        Crypto Payment
      </div>
      <LazyLoadImage
        alt="Chevron left"
        effect="blur"
        src={chevronLeft}
        className="hidden sm:block sm900:hidden"
      />

      <div className="flex gap-4 items-center">
        <div
          className="bg-payment-background h-[25px] w-[120px] lg:w-[150px] relative cursor-pointer first-dropdown flex items-center justify-center"
          onClick={(event) => handleFirstDropDown(event)}
        >
          {chainChosen === "" ? (
            <DefaultChain firstDropdownOpen={firstDropdownOpen} />
          ) : (
            <ChainChosen
              firstDropdownOpen={firstDropdownOpen}
              chainChosen={chainChosen}
            />
          )}

          {firstDropdownOpen && (
            <ChainDropDown setChainChosen={setChainChosen} />
          )}
        </div>

        <div
          className="bg-payment-background h-[25px] w-[120px] lg:w-[150px] relative cursor-pointer second-dropdown flex items-center justify-center"
          onClick={(event) => handleSecondDropdown(event)}
        >
          {currencyChosen === "" ? (
            <DefaultCurrency secondDropdownOpen={secondDropdownOpen} />
          ) : (
            <CurrencyChosen
              secondDropdownOpen={secondDropdownOpen}
              currencyChosen={currencyChosen}
            />
          )}

          {secondDropdownOpen && (
            <CurrencyDropDown
              setCurrencyChosen={setCurrencyChosen}
              chainChosen={chainChosen}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default CryptoSelected;
