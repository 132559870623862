import { Action, State } from "../../../../state/interfaces";
import SocketHandler from "../../../../utils/sockets/handleSocket";

export const fetchPaymentDialogState = async (
  state: State,
  dispatch: React.Dispatch<Action>,
  setFirstCall: React.Dispatch<React.SetStateAction<boolean>>,
  firstCall: boolean
) => {
  if (!(state.socketHandler instanceof SocketHandler)) {
    state.socketHandler = new SocketHandler();
  }

  state.socketHandler.fetchPaymentDialogState(
    state,
    dispatch,
    setFirstCall,
    firstCall
  );
};
