import { ContractTerms } from "../../../state/interfaces";

function convertDateFormat(input: string) {
  let [day, month, year] = input.split(".");
  let dateObject = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));

  // Output the date in the new format.
  let formattedDate = `${
    dateObject.getMonth() + 1
  }/${dateObject.getDate()}/${dateObject.getFullYear()}`;

  return formattedDate;
}

export function getGeneralFields(contractInfo: ContractTerms | undefined) {
  return [
    {
      text: "Creation Date",
      value: contractInfo
        ? convertDateFormat(contractInfo.agreementTerms[2])
        : "",
    },
    {
      text: "Effective Date",
      value: contractInfo ? contractInfo.agreementTerms[1] : "",
    },
    {
      text: "Duration",
      value: contractInfo ? contractInfo.agreementTerms[3][0] : "",
    },
    {
      text: "Early Termination Possible",
      value: contractInfo ? contractInfo.agreementTerms[3][2] : "",
    },
    {
      text: "Termination Period",
      value: contractInfo ? contractInfo.agreementTerms[3][3] : "",
    },
    {
      text: "Supplier Reimbursement",
      value: contractInfo ? contractInfo.agreementTerms[3][4] : "",
    },
    {
      text: "Intellectual Property Owner",
      value: contractInfo ? contractInfo.agreementTerms[3][5] : "",
    },
    {
      text: "Court Jurisdiction",
      value: contractInfo ? contractInfo.agreementTerms[3][10] : "",
    },
    {
      text: "Currency",
      value: contractInfo ? contractInfo.agreementTerms[3][6] : "",
    },
    {
      text: "Currency including tax",
      value: contractInfo ? contractInfo.agreementTerms[3][8] : "",
    },
    {
      text: "Type of compensation",
      value: contractInfo ? contractInfo.agreementTerms[3][9] : "",
    },
    {
      text: "Amount",
      value: contractInfo ? contractInfo.agreementTerms[3][7] : "",
    },
    {
      text: "Services with KPIs",
      value: contractInfo
        ? contractInfo.services
            .map((service, idx) => {
              let serviceString = `Service number ${idx + 1}: ${service[0]}`;
              // Explicitly cast service[1] to an array of strings
              let kpis = Array.isArray(service[1]) ? service[1] : [service[1]];
              let kpiString =
                kpis.length > 0
                  ? `, KPIs for service number ${idx + 1}: ${kpis.join(", ")}`
                  : "";
              return serviceString + kpiString;
            })
            .join("; ")
        : "",
    },
  ];
}

export function getBuyerFields(contractInfo: ContractTerms | undefined) {
  return [
    {
      text: "Name",
      value: contractInfo ? contractInfo.parties[0][2] : "",
    },
    {
      text: "Street Name",
      value: contractInfo ? contractInfo.parties[0][3] : "",
    },
    {
      text: "Street Number",
      value: contractInfo ? contractInfo.parties[0][4] : "",
    },
    {
      text: "Postal Code",
      value: contractInfo ? contractInfo.parties[0][5] : "",
    },
    {
      text: "City",
      value: contractInfo ? contractInfo.parties[0][6] : "",
    },
    {
      text: "Country",
      value: contractInfo ? contractInfo.parties[0][7] : "",
    },
  ];
}

export function getSupplierFields(contractInfo: ContractTerms | undefined) {
  return [
    {
      text: "Name",
      value: contractInfo ? contractInfo.parties[1][2] : "",
    },
    {
      text: "Street Name",
      value: contractInfo ? contractInfo.parties[1][3] : "",
    },
    {
      text: "Street Number",
      value: contractInfo ? contractInfo.parties[1][4] : "",
    },
    {
      text: "Postal Code",
      value: contractInfo ? contractInfo.parties[1][5] : "",
    },
    {
      text: "City",
      value: contractInfo ? contractInfo.parties[1][6] : "",
    },
    {
      text: "Country",
      value: contractInfo ? contractInfo.parties[1][7] : "",
    },
  ];
}

export const generalFields = [
  {
    text: "Creation Date",
    getData: (contractInfo: ContractTerms | undefined) =>
      contractInfo ? convertDateFormat(contractInfo.agreementTerms[2]) : "",
  },
  {
    text: "Effective Date",
    getData: (contractInfo: ContractTerms | undefined) =>
      contractInfo ? contractInfo.agreementTerms[1] : "",
  },
  {
    text: "Duration",
    getData: (contractInfo: ContractTerms | undefined) =>
      contractInfo ? contractInfo.agreementTerms[3][0] : "",
  },
  {
    text: "Early Termination Possible",
    getData: (contractInfo: ContractTerms | undefined) =>
      contractInfo ? contractInfo.agreementTerms[3][2] : "",
  },
  {
    text: "Termination Period",
    getData: (contractInfo: ContractTerms | undefined) =>
      contractInfo ? contractInfo.agreementTerms[3][3] : "",
  },
  {
    text: "Supplier Reimbursement",
    getData: (contractInfo: ContractTerms | undefined) =>
      contractInfo ? contractInfo.agreementTerms[3][4] : "",
  },
  {
    text: "Intellectual Property Owner",
    getData: (contractInfo: ContractTerms | undefined) =>
      contractInfo ? contractInfo.agreementTerms[3][5] : "",
  },
  {
    text: "Court Jurisdiction",
    getData: (contractInfo: ContractTerms | undefined) =>
      contractInfo ? contractInfo.agreementTerms[3][10] : "",
  },
  {
    text: "Currency",
    getData: (contractInfo: ContractTerms | undefined) =>
      contractInfo ? contractInfo.agreementTerms[3][6] : "",
  },
  {
    text: "Currency including tax",
    getData: (contractInfo: ContractTerms | undefined) =>
      contractInfo ? contractInfo.agreementTerms[3][8] : "",
  },
  {
    text: "Type of compensation",
    getData: (contractInfo: ContractTerms | undefined) =>
      contractInfo ? contractInfo.agreementTerms[3][9] : "",
  },
];

export const buyerFields = [
  {
    text: "Name",
    getData: (contractInfo: ContractTerms | undefined) =>
      contractInfo ? contractInfo.agreementTerms[0][2] : "",
  },
  {
    text: "Street Name",
    getData: (contractInfo: ContractTerms | undefined) =>
      contractInfo ? contractInfo.agreementTerms[0][3] : "",
  },
  {
    text: "Street Number",
    getData: (contractInfo: ContractTerms | undefined) =>
      contractInfo ? contractInfo.agreementTerms[0][4] : "",
  },
  {
    text: "Postal Code",
    getData: (contractInfo: ContractTerms | undefined) =>
      contractInfo ? contractInfo.agreementTerms[0][5] : "",
  },
  {
    text: "City",
    getData: (contractInfo: ContractTerms | undefined) =>
      contractInfo ? contractInfo.agreementTerms[0][6] : "",
  },
  {
    text: "Country",
    getData: (contractInfo: ContractTerms | undefined) =>
      contractInfo ? contractInfo.agreementTerms[0][7] : "",
  },
];
export const supplierFields = [
  {
    text: "Name",
    getData: (contractInfo: ContractTerms | undefined) =>
      contractInfo ? contractInfo.agreementTerms[1][2] : "",
  },
  {
    text: "Street Name",
    getData: (contractInfo: ContractTerms | undefined) =>
      contractInfo ? contractInfo.agreementTerms[1][3] : "",
  },
  {
    text: "Street Number",
    getData: (contractInfo: ContractTerms | undefined) =>
      contractInfo ? contractInfo.agreementTerms[1][4] : "",
  },
  {
    text: "Postal Code",
    getData: (contractInfo: ContractTerms | undefined) =>
      contractInfo ? contractInfo.agreementTerms[1][5] : "",
  },
  {
    text: "City",
    getData: (contractInfo: ContractTerms | undefined) =>
      contractInfo ? contractInfo.agreementTerms[1][6] : "",
  },
  {
    text: "Country",
    getData: (contractInfo: ContractTerms | undefined) =>
      contractInfo ? contractInfo.agreementTerms[1][7] : "",
  },
];
