// ----------------------
// Assets
// ----------------------
import { ReactComponent as ChevronRight } from "../../../assets/images/chevronRight.svg";

interface HashFieldsProps {
  fieldName: string;
  fieldValue: string;
}

function HashFields({ fieldName, fieldValue }: HashFieldsProps) {
  return (
    <div className="text-[16px] sm400:text-[18px]  flex gap-[10px] items-center mb-[20px]">
      <input
        className="bg-hash-button w-[120px] sm400:w-[150px] h-[35px] px-[10px] py-[5px] flex items-center justify-start placeholder:italic outline-none placeholder:text-[dimgray] text-[black]"
        placeholder="Field Name"
        value={fieldName}
        readOnly
      />
      <ChevronRight />
      <input
        className="bg-hash-button w-[120px] sm400:w-[150px] h-[35px] px-[10px] py-[5px] flex items-center justify-start placeholder:italic outline-none placeholder:text-[dimgray] text-[black]"
        placeholder="Field Value"
        value={fieldValue}
        readOnly
      />
    </div>
  );
}

export default HashFields;
