// ----------------------
// Assets
// ----------------------
import dzin from "../../assets/images/dzin.svg";

// ----------------------
// Styles
// ----------------------
import "./slick.css";

// ----------------------
// Constants
// ----------------------
const buttonMap = [
  "Start again",
  "I want to change...",
  "Go back to...",
  "Tell me more about...",
];

function ThirdSlide() {
  return (
    <div className="slider-item gap-[60px] px-[20px] md:px-[40px] sm:ml-[50px] h-[100%] sm:h-[400px] lg:h-[500px]">
      <div className=" w-[120px] sm400:w-[150px] sm:w-[150px] md:w-[200px] lg:w-[250px] flex-shrink-0">
        <img src={dzin} className="w-full h-full" alt="Dzin Icon" />
      </div>
      <div className="flex flex-col items-center">
        <div className="bg-[#F7F8FD] mb-[10px] p-[20px] max-w-[450px] rounded-[6px]">
          <p className="font-bold text-[14px] md:text-[16px]">
            During the conversation you can always use the commands below and
            the AI Assistant will help you.
          </p>
        </div>
        <div className="flex flex-wrap gap-[5px] max-w-[450px]">
          {buttonMap.map((button, idx) => (
            <div className="relative" key={idx}>
              <div className="absolute inset-0 bg-gradient-to-r opacity-10 from-[#CF61E1] to-[#0023EF] rounded-[6px]" />
              <div className="relative">
                <div className="font-extrabold text-transparent text-[12px] sm400:text-[14px] sm:text-[16px] md:text-[18px] px-[20px] py-[10px] bg-clip-text bg-gradient-to-r from-[#CF61E1] to-[#0023EF] ">
                  {button}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ThirdSlide;
