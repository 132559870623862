import { State } from "../../state/interfaces";
import React, { useEffect } from "react";

export function useAutoScroll(
  chatContainerRef: React.RefObject<HTMLDivElement>,
  state: State
) {
  useEffect(() => {
    requestAnimationFrame(() => {
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop =
          chatContainerRef.current.scrollHeight;
      }
    });
  }, [
    state.messages,
    chatContainerRef,
    state.userInput,
    state.fetchingMessage,
  ]);
}
