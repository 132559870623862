// ----------------------
// External/Internal Packages
// ----------------------
import React from "react";
import { ImCheckmark } from "react-icons/im";

// ----------------------
// Interfaces
// ----------------------
import { Action, ActionType } from "../../../../state/interfaces";

interface AgreementFieldProps {
  label: string;
  value: string;
  fieldChosen: boolean;
  fieldChoice: boolean;
  dispatch: React.Dispatch<Action>;
  setFieldChosen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AgreementField = ({
  label,
  value,
  fieldChosen,
  setFieldChosen,
  dispatch,
  fieldChoice,
}: AgreementFieldProps) => (
  <div className="flex items-center gap-[10px] mb-[5px] ">
    {fieldChoice && (
      <button
        onClick={(event) => {
          if (fieldChosen) {
            setFieldChosen(false);
            dispatch({
              type: ActionType.REMOVE_CERTIFICATE_FIELDS,
              payload: {
                fieldName: label,
                value: value,
              },
            });
          } else {
            setFieldChosen(true);
            dispatch({
              type: ActionType.APPEND_CERTIFICATE_FIELDS,
              payload: {
                fieldName: label,
                value: value,
              },
            });
          }
          event.stopPropagation();
        }}
        className={`border-2 border-[gray] flex-shrink-0 flex justify-center items-center ${
          fieldChosen ? "text-gray-500" : "text-transparent"
        }`}
        style={{ width: "16px", height: "16px" }}
      >
        {fieldChosen ? <ImCheckmark color="#32a852" /> : null}
      </button>
    )}

    <p className="max-w-[330px] text-[14px] sm:text-[16px]">
      {label}: "{value}",
    </p>
  </div>
);

export default AgreementField;
