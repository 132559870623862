import { handlePaymentStatusUpdate } from "./handlePaymentStatusUpdate";
import * as ethers from "ethers";
import { Action, ActionType, State } from "../../../../state/interfaces";
import { fetchPaymentDialogState } from "./fetchPaymentDialogState";

export const setTransactionStatus = async (
  status: "PENDING" | "FAILED",
  chain: string,
  txHash: string,
  thinkingMessage: string,
  paymentMessage: string,
  state: State,
  dispatch: React.Dispatch<Action>,
  id: string,
  setNumOfCalls: React.Dispatch<React.SetStateAction<number>>,
  numOfCalls: number,
  setFirstCall: React.Dispatch<React.SetStateAction<boolean>>,
  firstCall: boolean
) => {
  dispatch({ type: ActionType.SET_THINKING_MESSAGE, payload: thinkingMessage });
  dispatch({
    type: ActionType.SET_FETCHING_MESSAGE,
    payload: true,
  });
  await handlePaymentStatusUpdate(
    status,
    chain,
    txHash,
    paymentMessage,
    state,
    id,
    setNumOfCalls,
    numOfCalls,
    dispatch
  );
  await fetchPaymentDialogState(state, dispatch, setFirstCall, firstCall);
};
