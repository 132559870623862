// ----------------------
// Components
// ----------------------
import UserChat from "./UserChat";
import ChatContent from "./ChatContent";

// ----------------------
// Interfaces
// ----------------------
import { Message, State, Action, QueueItem } from "../../state/interfaces";

interface ChatMessageRendererProps {
  item: Message;
  uniqueKey: string;
  textWritten: boolean;
  handleTypingDone: (messageId: string) => void;
  state: State;
  dispatch: React.Dispatch<Action>;
  messageQueue: React.MutableRefObject<QueueItem[]>;
  currentAudio: React.MutableRefObject<HTMLAudioElement | null>;
}

function ChatMessageRenderer({
  item,
  uniqueKey,
  handleTypingDone,
  state,
  dispatch,
  messageQueue,
  currentAudio,
}: ChatMessageRendererProps) {
  const isUserChat = item.type === "USER";

  const Component = isUserChat ? UserChat : ChatContent;

  const commonProps = {
    key: uniqueKey,
    handleTypingDone,
    state,
    dispatch,
    textWritten: item.textWritten,
    messageId: item.id,
    messageState: item.messageState,
    messageQueue: messageQueue,
    currentAudio: currentAudio,
  };

  if (!Component) return null;

  return (
    <Component
      {...commonProps}
      text={item.text}
      buttonText={Array.isArray(item.text) ? item.text : undefined}
      aboveButtonText={item.aboveButtons}
      widget={item.type.includes("WIDGET")}
      firstChat={
        item.type.includes("FIRST") ||
        item.widgetType === "date" ||
        item.widgetType === "sell"
      }
      widgetType={item.widgetType}
      additionalData={item.additionalData}
      message={item}
      values={item.values}
    />
  );
}
export default ChatMessageRenderer;
