// Interfaces
import { State, ActionTypes, Action } from "../interfaces";

// Internal functions
import {
  updateMessage,
  updateMessageById,
  updateNextNonUserMessage,
  updateAllMessages,
  updateMessageByIndex,
} from "../utils/messagesFunctions";
import {
  incrementNumOfCalls,
  setButtonState,
} from "../utils/buttonStateFunctions";
import {
  appendCertificateFields,
  removeCertificateFields,
} from "../utils/certificateFieldsFunctions";
import {
  resetContractReader,
  resetConversation,
} from "../utils/resetFunctions";

// Function allowing us to create Actions quickly, without repeating ourselves
const simpleUpdate = (property: string) => (state: State, action: Action) => {
  return {
    ...state,
    [property]: action.payload,
  };
};

// Actions for our reducer
export const actionHandlers: Record<
  ActionTypes,
  (state: State, action: Action) => State
> = {
  UPDATE_MESSAGE: updateMessage,
  APPEND_MESSAGE: (state: State, action: Action) => ({
    ...state,
    messages: [...state.messages, action.payload],
  }),
  UPDATE_MESSAGE_BY_ID: updateMessageById,
  UPDATE_NEXT_NON_USER_MESSAGE: updateNextNonUserMessage,
  SET_MESSAGES: simpleUpdate("messages"),
  SET_MODAL: simpleUpdate("modal"),
  UPDATE_MESSAGE_BY_INDEX: updateMessageByIndex,
  UPDATE_ALL_MESSAGES: updateAllMessages,
  SET_TUTORIAL_ACTIVE: simpleUpdate("tutorialActive"),
  SET_CONVERSATION_STARTED: simpleUpdate("conversationStarted"),
  INCREMENT_NUM_OF_CALLS: incrementNumOfCalls,
  SET_BUTTON_STATE: setButtonState,
  SET_CONNECTION_SCREEN_ACTIVE: simpleUpdate("connectionScreenActive"),
  SET_CHANGE_CHAIN_ACTIVE: simpleUpdate("changeChainActive"),
  SET_CONVERSATION_ID: simpleUpdate("conversationId"),
  SET_PDF_READER: simpleUpdate("pdfReader"),
  SET_DIALOG_STATE: simpleUpdate("dialogState"),
  SET_LANGUAGE_CHOICE_ACTIVE: simpleUpdate("languageChoiceActive"),
  SET_LISTENING: simpleUpdate("listening"),
  SET_WATERMARK: simpleUpdate("watermark"),
  SET_PROOF_OVERVIEW: simpleUpdate("proofOverview"),
  SET_SOCKET_CONNECTED: simpleUpdate("socketConnected"),
  SET_FETCHING_MESSAGE: simpleUpdate("fetchingMessage"),
  SET_USER_INPUT: simpleUpdate("userInput"),
  SET_SOUND_ON: simpleUpdate("soundOn"),
  SET_PROOF_VERIFICATION_ACTIVE: simpleUpdate("proofVerificationActive"),
  SET_PROOF_VERIFICATION_RESULT: simpleUpdate("proofVerificationResult"),
  SET_TYPE_OF_CHOSEN_CONTRACT: simpleUpdate("typeOfChosenContract"),
  SET_IS_SPEAKING: simpleUpdate("isSpeaking"),
  SET_PAYMENT_ACTIVE: simpleUpdate("paymentActive"),
  SET_VOICE_INPUT: simpleUpdate("voiceInput"),
  SET_LOGIN_MODAL_OPEN: simpleUpdate("loginModalOpen"),
  SET_PROOF_ID: simpleUpdate("proofId"),
  SET_ALL_CERTIFICATES: simpleUpdate("allCertificates"),
  SET_VOICE_MESSAGE_SENT: simpleUpdate("voiceMessageSent"),
  SET_ADMIN_ACCESS: simpleUpdate("adminAccess"),
  SET_ID_OF_CHOSEN_CONTRACT: simpleUpdate("idOfChosenContract"),
  SET_USER_ID: simpleUpdate("userId"),
  SET_DIALOG_IS_RESTARTING: simpleUpdate("dialogIsRestarting"),
  SET_PAYBACK_ACTIVE_SCREEN: simpleUpdate("paybackActiveScreen"),
  SET_READER_PAYMENT_ERROR: simpleUpdate("readerPaymentError"),
  SET_GET_CERTIFICATE: simpleUpdate("getCertificate"),
  SET_MOBILE_STEPS_ACTIVE: simpleUpdate("mobileStepsActive"),
  SET_WEBSOCKET: simpleUpdate("websocket"),
  SET_LOG_IN_PAGE_ACTIVE: simpleUpdate("logInPageActive"),
  SET_PROFILE_PAGE_ACTIVE: simpleUpdate("profilePageActive"),
  SET_WEB2_LOGIN: simpleUpdate("web2Login"),
  SET_CONTRACT_LIST: simpleUpdate("contractList"),
  SET_PDF_CONTENT: simpleUpdate("pdfContent"),
  SET_PROOF_JSON: simpleUpdate("proofJson"),
  SET_THINKING_MESSAGE: simpleUpdate("thinkingMessage"),
  SET_CONDITIONS_CHECKED: simpleUpdate("conditionsChecked"),
  SET_MOBILE_APPLICATIONS_SCREEN: simpleUpdate("mobileApplicationsScreen"),
  SET_QR_CODE_VERIFICATION_SCREEN: simpleUpdate("qrCodeVerificationScreen"),
  SET_SUMMARY: simpleUpdate("summary"),
  SET_CERTIFICATE_FIELD_CHOICE: simpleUpdate("certificateFieldChoice"),
  APPEND_CERTIFICATE_FIELDS: appendCertificateFields,
  REMOVE_CERTIFICATE_FIELDS: removeCertificateFields,
  SET_INITIAL_STATE: (state: State, action: Action) => ({
    ...action.payload,
  }),
  SET_PAYMENT_FINISHED: simpleUpdate("paymentFinished"),

  SET_CONTRACT_CHOSEN: simpleUpdate("contractChosen"),
  SET_CONTRACT_READER: simpleUpdate("contractReader"),
  SET_LIST_OF_CERTIFICATES: simpleUpdate("listOfCertificates"),
  SET_DECODING_ANIMATION_FINISHED: simpleUpdate("decodingAnimationFinished"),
  SET_CONTRACT_SUMMARY_ACTIVE: simpleUpdate("contractSummaryActive"),
  SET_HASH_WINDOW_ACTIVE: simpleUpdate("hashWindowActive"),
  SET_CONTRACT_LIST_ANIMATION_FINISHED: simpleUpdate(
    "contractListAnimationFinished"
  ),
  SET_VERIFY_PROOF_ACTIVE: simpleUpdate("verifyProofActive"),
  RESET_CONTRACT_READER: resetContractReader,
  RESET_CONVERSATION: resetConversation,
  SET_CONVERSATION_STATE: simpleUpdate("conversationState"),
  SET_MAX_MESSAGE_LENGTH: simpleUpdate("maxMessageLength"),
  SET_ACCOUNT_WINDOW_ACTIVE: simpleUpdate("accountWindowActive"),
  SET_HAMBURGER_OPEN: simpleUpdate("hamburgerMenuOpen"),
  SET_DIALOGUE_PAYMENT_PENDING: simpleUpdate("dialoguePaymentPending"),
  SET_CERTIFICATE_FIELDS: simpleUpdate("certificateFields"),
  SET_CERTIFICATE_PAID: simpleUpdate("certificatePaid"),
  SET_DOWNLOAD_CERTIFICATE: simpleUpdate("downloadCertificate"),
  SET_CREDIT_CARD_CHOSEN: simpleUpdate("creditCardChosen"),
  SET_READER_PAYMENT_ACTIVE: simpleUpdate("readerPaymentActive"),
  SET_CERTIFICATE_ERROR: simpleUpdate("certificateError"),
  SET_SIGN_UP_PAGE_ACTIVE: simpleUpdate("signUpPageActive"),
  SET_OUR_APPLICATIONS_ACTIVE: simpleUpdate("ourApplicationsActive"),
  SET_DELETE_ACCOUNT_MODAL_ACTIVE: simpleUpdate("deleteAccountModalActive"),
  SET_PAYBACK_PAGE_ACTIVE: simpleUpdate("paybackPageActive"),
  SET_LOGIN_CODE_MODAL_ACTIVE: simpleUpdate("loginCodeModalActive"),
  SET_CHANGE_PASSWORD_MODAL_ACTIVE: simpleUpdate("changePasswordModalActive"),
  SET_WEB2_EMAIL: simpleUpdate("web2Email"),
  SET_USER_AVATAR: simpleUpdate("userAvatar"),
  SET_WEB3_LOGIN: simpleUpdate("web3Login"),
  SET_RESET_PASSWORD_MODAL: simpleUpdate("resetPasswordModal"),
  SET_RESET_PASSWORD_TOKEN: simpleUpdate("resetPasswordToken"),
  SET_FORGOT_PASSWORD_EMAIL: simpleUpdate("forgotPasswordEmail"),
  SET_RESET_PASSWORD_CONFIRMATION_MODAL: simpleUpdate(
    "resetPasswordConfirmationModal"
  ),
};
