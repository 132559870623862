import React, { useState } from "react";
import { ImCheckmark } from "react-icons/im";
import { Action, ActionType } from "../../../../state/interfaces";

interface ServicesListProps {
  services: Array<[string, string[]]>;
  fieldChoice: boolean;
  dispatch: React.Dispatch<Action>;
}

function ServicesList({ services, fieldChoice, dispatch }: ServicesListProps) {
  const [listOpened, setListOpened] = useState(false);

  const [kpiOpened, setKpiOpened] = useState(
    new Array(services.length).fill(false)
  );

  const [serviceChosen, setServiceChosen] = useState(
    new Array(services.length).fill(false)
  );

  const toggleServiceChosen = (index: number) => {
    setServiceChosen((prev) => prev.map((v, i) => (i === index ? !v : v)));
  };

  const toggleKpi = (e: React.MouseEvent, index: number) => {
    e.stopPropagation();
    setKpiOpened((prev) => prev.map((v, i) => (i === index ? !v : v)));
  };

  return (
    <div
      onClick={() => setListOpened((prev) => !prev)}
      className="text-green-500 font-semibold cursor-pointer flex flex-col items-start"
    >
      {!listOpened ? (
        <p className="text-green-500">["{services[0][0]}", ...]</p>
      ) : (
        <div>
          [
          <div className="pl-5">
            {services.map((service, index) => (
              <div key={index} className="flex items-center gap-4">
                {fieldChoice && (
                  <button
                    onClick={(event) => {
                      event.stopPropagation();
                      toggleServiceChosen(index);
                      if (serviceChosen[index]) {
                        dispatch({
                          type: ActionType.REMOVE_CERTIFICATE_FIELDS,
                          payload: {
                            fieldName: `service${index + 1}`,
                            value: service[0],
                          },
                        });
                      } else {
                        dispatch({
                          type: ActionType.APPEND_CERTIFICATE_FIELDS,
                          payload: {
                            fieldName: `service${index + 1}`,
                            value: service[0],
                          },
                        });
                      }
                    }}
                    className={`border-2 border-[gray] w-[16px] h-[16px] flex-shrink-0 mt-[2px] flex justify-center items-center ${
                      serviceChosen[index]
                        ? "text-gray-500"
                        : "text-transparent"
                    }`}
                  >
                    {serviceChosen[index] ? (
                      <ImCheckmark color="#32a852" />
                    ) : null}
                  </button>
                )}
                <p className="text-green-500">
                  "{service[0]}",
                  <span
                    onClick={(e) => toggleKpi(e, index)}
                    className="font-semibold cursor-pointer ml-2"
                  >
                    kpi:{" "}
                    {kpiOpened[index]
                      ? `[${service[1].map((kpi) => `"${kpi}"`).join(", ")}]`
                      : "{...}"}
                  </span>
                </p>
              </div>
            ))}
          </div>
          ]
        </div>
      )}
    </div>
  );
}

export default ServicesList;
