// ----------------------
// Packages
// ----------------------
import { LazyLoadImage } from "react-lazy-load-image-component";

interface ImageDropDownProps {
  src: string;
}

function ImageDropDown(props: ImageDropDownProps) {
  return (
    <LazyLoadImage
      alt="Cryptocurrency logo"
      effect="blur"
      src={props.src}
      className="w-[15px] opacity-[50%]"
    />
  );
}

export default ImageDropDown;
