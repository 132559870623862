// ----------------------
// Components
// ----------------------
import ImageDropDown from "./ImageDropDown";

// ----------------------
// Assets
// ----------------------
import chevronDown from "../../../../../assets/images/chevronDown.svg";
import chevronUp from "../../../../../assets/images/chevronUp.svg";

interface DefaultChainProps {
  firstDropdownOpen: boolean;
}

function DefaultChain({ firstDropdownOpen }: DefaultChainProps) {
  return (
    <div className="flex items-center justify-between gap-[5px]">
      <p className="text-[gray] opacity-[30%] text-[12px]">Choose chain</p>
      {!firstDropdownOpen && <ImageDropDown src={chevronDown} />}
      {firstDropdownOpen && <ImageDropDown src={chevronUp} />}
    </div>
  );
}

export default DefaultChain;
