// ----------------------
// External/Internal Packages
// ----------------------
import React from "react";

interface TxSummaryWidgetProps {
  additionalData: any;
}

function TxSummaryWidget({ additionalData }: TxSummaryWidgetProps) {
  const redirectToChainExplorer = (e: React.MouseEvent) => {
    e.preventDefault();
    let url = "";

    if (additionalData.chain === "ethereum") {
      url = `https://sepolia.etherscan.io/tx/${additionalData.txHash}`;
    } else if (additionalData.chain === "polygon") {
      url = `https://mumbai.polygonscan.com/tx/${additionalData.txHash}`;
    }

    window.open(url, "_blank");
  };

  return (
    <button
      onClick={(event) => redirectToChainExplorer(event)}
      className="rounded-md hover:opacity-90 hover:text-[#ccd1f0] text-[#ffff] bg-gradient-button font-bold text-[12px] md:text-[14px] py-[10px]  bg-[#0023EF12] px-[20px]"
    >
      Your Transaction
    </button>
  );
}

export default TxSummaryWidget;
