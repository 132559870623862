import axios from "axios";
import handleToastError from "../../../utils/utility/handleToastError";

export const handleHash = async (
  fields: never[],
  setHashedInput: React.Dispatch<React.SetStateAction<never[]>>,
  setHashed: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (fields.length !== 0) {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/snarks/hashInput",
        {
          input: fields,
        }
      );

      if (response.data) {
        setHashedInput(response.data);
        setHashed(true);
      } else {
        setHashedInput([]);
        setHashed(false);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 403) {
          handleToastError(
            "Access denied! Please log out and log back in to solve this issue."
          );
        } else {
          console.error("An error occurred:", error);
        }
      } else {
        console.error("An unexpected error occurred:", error);
      }
    }
  }
};
