import React, { useState } from "react";
import TransactionHistoryCategories from "./TransactionHistoryCategories";
import TransactionHistoryOptions from "./TransactionHistoryOptions";
import TransactionHistoryElement from "./TransactionHistoryElement";
import TransactionDownloadHistoryMobile from "./TransactionDownloadHistoryMobile";
import chevronLeft from "../../../../assets/images/chevronLeft.svg";
import { Action, ActionType } from "../../../../state/interfaces";

interface UnifiedTransaction {
  type: "payback" | "vaiPoints";
  date: string;
  amount: string;
  address?: string;
  transactionHash?: string;
  blockNumber?: number;
  fromCollection?: string;
  fromId?: string;
}

interface TransactionHistoryProps {
  mobile: boolean;
  transactions: UnifiedTransaction[];
  dispatch: React.Dispatch<Action>;
}

const TransactionHistory: React.FC<TransactionHistoryProps> = ({
  mobile,
  transactions,
  dispatch,
}) => {
  const [sortOption, setSortOption] = useState("");
  const [activeCategory, setActiveCategory] = useState("ALL");

  const formatDate = (isoDateString: string): string => {
    // Check if the date is already in the desired format (dd.mm.yyyy)
    const alreadyFormattedPattern = /^\d{2}\.\d{2}\.\d{4}$/;
    if (alreadyFormattedPattern.test(isoDateString)) {
      return isoDateString; // Return as is if already in desired format
    }

    // If the date is in ISO format, convert it to dd.mm.yyyy format
    const date = new Date(isoDateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const handleSortOptionSelected = (option: string) => {
    setSortOption(option);
  };

  const filteredTransactions = React.useMemo(() => {
    if (activeCategory === "ALL") return transactions;
    return transactions.filter(
      (transaction) =>
        transaction.type.toLowerCase() ===
        activeCategory.toLowerCase().replace(" ", "")
    );
  }, [transactions, activeCategory]);

  const sortedTransactions = React.useMemo(() => {
    return filteredTransactions.slice().sort((a, b) => {
      if (sortOption === "Date") {
        // Use 'date' instead of 'timestamp'
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      } else if (sortOption === "Inflow/Outflow") {
        // Sorting by amount (no change needed here)
        return parseFloat(a.amount) - parseFloat(b.amount);
      }
      return 0;
    });
  }, [filteredTransactions, sortOption]);

  return (
    <div
      className={`flex flex-col justify-center tracking-wide font-TT w-[90%] md:w-[80%] gap-[40px] ${
        mobile ? "mt-[60px]" : "mt-[20px] "
      }`}
    >
      {!mobile && (
        <div className="w-full font-bold flex gap-[10px]">
          <img
            src={chevronLeft}
            className="cursor-pointer"
            onClick={() =>
              dispatch({
                type: ActionType.SET_PAYBACK_ACTIVE_SCREEN,
                payload: "",
              })
            }
          />
          <p className="text-[18px]">Transaction History</p>
        </div>
      )}
      <TransactionHistoryCategories
        active={activeCategory}
        setActive={setActiveCategory}
      />
      <TransactionHistoryOptions
        onSortOptionSelected={handleSortOptionSelected}
        transactions={sortedTransactions}
      />
      <div
        className={`flex flex-col items-center w-full overflow-y-auto ${
          mobile ? "max-h-[240px]" : "max-h-[300px]"
        } `}
      >
        {sortedTransactions.map((transaction, index) => (
          <TransactionHistoryElement
            key={index}
            amount={parseFloat(transaction.amount).toFixed(2)}
            date={formatDate(transaction.date)}
            mobile={mobile}
            type={transaction.type}
          />
        ))}
      </div>
      <TransactionDownloadHistoryMobile transactions={sortedTransactions} />
    </div>
  );
};

export default TransactionHistory;
