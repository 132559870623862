import React from "react";
import { Action, ActionType, State } from "../../../state/interfaces";
import { handleLogOut } from "../../../utils/api/handleLogOut";
import { useDisconnect } from "wagmi";
import { useAccount } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { handleOpenProfile } from "../../../utils/utility/handleOpenProfile";

interface Web2AccountModalProps {
  showModal: boolean;
  onClose: () => void;
  dispatch: React.Dispatch<Action>;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  state: State;
  setIsUpdatingWallet: React.Dispatch<React.SetStateAction<boolean>>;
}

const Web2ProfileModal: React.FC<Web2AccountModalProps> = ({
  showModal,
  onClose,
  dispatch,
  setModalOpen,
  state,
  setIsUpdatingWallet,
}) => {
  const { disconnect } = useDisconnect();
  const { address } = useAccount();
  const { open } = useWeb3Modal();

  const isActive = address !== undefined;

  if (!showModal) return null;

  return (
    <div className="absolute top-[120%] shadow-md right-0 z-[1000] flex flex-col items-center w-[140px] font-TT justify-center">
      <div className="bg-white font-bold text-[14px] w-full py-[5px] px-[10px]">
        <div className="flex flex-col gap-[10px] text-[14px] tracking-wider">
          <button
            onClick={() => handleOpenProfile(dispatch, setModalOpen)}
            className="w-[120px] h-[35px] text-center bg-connect-button border-[1px] border-[#0023EF12] font-bold text-[#fff]"
          >
            Settings
          </button>
          {!isActive && (
            <button
              onClick={() => {
                if (setIsUpdatingWallet) {
                  setIsUpdatingWallet(true);
                }
                try {
                  if (setModalOpen) {
                    setModalOpen(false);
                  }
                  open();
                } catch (error) {
                  console.error(error);
                }
              }}
              className="w-[120px] h-[35px] text-center bg-connect-button border-[1px] border-[#0023EF12] font-bold text-[#fff]"
            >
              Connect Wallet
            </button>
          )}

          <button
            onClick={() => {
              disconnect();
              handleLogOut(dispatch, onClose);
            }}
            className="w-[120px] h-[35px] text-center overflow-hidden bg-log-out-button border-[1px] border-[#0023EF12] text-[#fd1a1a] "
          >
            Log Out
          </button>
        </div>
      </div>
    </div>
  );
};

export default Web2ProfileModal;
