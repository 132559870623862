// ----------------------
// Packages
// ----------------------
import React, { useState, useEffect } from "react";

// ----------------------
// Components
// ----------------------
import HashComplete from "./HashComplete";
import CrossIcon from "./CrossIcon";

// ----------------------
// Utility Functions
// ----------------------
import { useFetchHashedInput } from "../utils/useFetchHashedInput";

// ----------------------
// Interfaces
// ----------------------
import { Action, State } from "../../../state/interfaces";
import HashIncomplete from "./HashIncomplete";

interface HashWindowProps {
  dispatch: React.Dispatch<Action>;
  state: State;
}

function HashWindow({ dispatch, state }: HashWindowProps) {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState(1);
  const [hashed, setHashed] = useState(false);
  const [fields, setFields] = useState([]);
  const [hashedInput, setHashedInput] = useState([]);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  useFetchHashedInput(state, setFields);

  return (
    <div className=" w-full h-full flex items-center justify-center bg-[#050934] bg-opacity-[75%] fixed z-50">
      <div
        className={`bg-[white]  w-full  max-w-[550px] h-[550px]  shadow-lg rounded-lg flex flex-col gap-[20px] justify-between
    transition-all duration-100 ease-in-out transform-gpu ${
      isVisible ? "scale-100" : "scale-[90%]"
    } 
    transition-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);`}
      >
        <div className="flex flex-col gap-[30px] pt-[20px] px-[20px]">
          <div className="w-full flex justify-between items-center">
            <p className="font-semibold text-[18px]">Hash Inputs</p>
            {!hashed && <CrossIcon dispatch={dispatch} />}
          </div>
        </div>
        <div className="w-full h-full bg-[#F7F8FD] p-[20px] pt-[30px] flex flex-col items-center">
          {!hashed ? (
            <HashIncomplete
              selectedNumber={selectedNumber}
              fields={fields}
              setSelectedNumber={setSelectedNumber}
              setHashed={setHashed}
              setHashedInput={setHashedInput}
            />
          ) : (
            <HashComplete hashedInput={hashedInput} dispatch={dispatch} />
          )}
        </div>
      </div>
    </div>
  );
}

export default HashWindow;
