import React from "react";
import { Action, ActionType } from "../../../state/interfaces";

export const handleChooseCertificate = (
  setCertificateContractAddress: React.Dispatch<React.SetStateAction<string>>,
  proofJson: any,
  dispatch: React.Dispatch<Action>,
  certificateContractAddress: string,
  proofId: string
) => {
  if (proofJson === undefined || proofJson.length === 0) {
    dispatch({ type: ActionType.SET_CERTIFICATE_ERROR, payload: true });
    return;
  }

  setCertificateContractAddress(certificateContractAddress);
  dispatch({
    type: ActionType.SET_PROOF_JSON,
    payload: proofJson,
  });
  dispatch({
    type: ActionType.SET_PROOF_ID,
    payload: proofId,
  });
  dispatch({
    type: ActionType.SET_LIST_OF_CERTIFICATES,
    payload: false,
  });
  dispatch({
    type: ActionType.SET_DOWNLOAD_CERTIFICATE,
    payload: true,
  });
};
