// ----------------------
// Internal/External Packages
// ----------------------
import React from "react";
import { RiSpeedFill } from "react-icons/ri";
import { useSpeechRecognition } from "react-speech-recognition";
import { useAccount } from "wagmi";

// ----------------------
// Utility Functions
// ----------------------
import handleKeyPress from "./inputUtils/handleKeyPress";
import { handleSendButtonEvent } from "./inputUtils/handleSendButtonEvent";

// ----------------------
// Assets
// ----------------------
import { ReactComponent as QuestionMark } from "../../../assets/images/questionmark.svg";
import { ReactComponent as SendIcon } from "../../../assets/images/send.svg";

// ----------------------
// Interfaces
// ----------------------
import {
  Action,
  State,
  ActionType,
  QueueItem,
} from "../../../state/interfaces";

interface InputAreaProps {
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  state: State;
  dispatch: React.Dispatch<Action>;
  messageQueue: React.MutableRefObject<QueueItem[]>;
  currentAudio: React.MutableRefObject<HTMLAudioElement | null>;
}

function InputArea({
  inputValue,
  setInputValue,
  state,
  dispatch,
  messageQueue,
  currentAudio,
}: InputAreaProps) {
  const { address } = useAccount();

  const allMessagesDisplayed = state.messages.every(
    (message) => message.messageState === "DISPLAYED"
  );

  const { listening, resetTranscript } = useSpeechRecognition();

  const handleTutorial = () => {
    dispatch({
      type: ActionType.SET_TUTORIAL_ACTIVE,
      payload: true,
    });
  };

  return (
    <div className="w-full h-full flex items-center justify-center gap-[15px] px-[20px] flex-shrink-0">
      <div className="bg-[#ffff] w-[90%] lg:w-[70%] rounded-[6px] shadow-lg flex justify-between items-center relative">
        <input
          value={inputValue}
          onKeyDown={(event) =>
            handleKeyPress(
              event,
              inputValue,
              allMessagesDisplayed,
              state,
              dispatch,
              setInputValue,
              messageQueue,
              currentAudio,
              address
            )
          }
          onChange={(event) => {
            setInputValue(event.target.value);
          }}
          placeholder="Write your message..."
          className={`py-[20px] px-[10px] sm:px-[30px] rounded-xl relative ${
            state.tutorialActive || state.connectionScreenActive
              ? "placeholder:opacity-[0]"
              : "placeholder:opacity-[0.5]"
          } placeholder:tracking-wide placeholder:text-[14px] placeholder:leading-[28px] lg:placeholder:text-[20px] text-[14px] lg:text-[20px] placeholder:opacity-50 w-full outline-none`}
        />
        <div className="flex gap-[5px] cursor-pointer">
          <button
            onClick={handleTutorial}
            className="h-[45px] md:w-[45px] w-[30px] sm450:w-[40px] p-[6px] lg:p-[10px] rounded-[2px] border-[1px] border-[#e8e8e8] bg-[#f0f1f7] flex items-center justify-center outline-none"
          >
            <QuestionMark className="w-[18px]" />
          </button>
          <button
            onClick={handleSendButtonEvent(
              listening,
              state,
              currentAudio,
              dispatch,
              messageQueue,
              resetTranscript,
              inputValue,
              allMessagesDisplayed,
              setInputValue,
              address
            )}
            className="h-[45px] cursor-pointer items-center py-[10px] lg:py-[10px] rounded-[2px] border-[1px] bg-[#f7f8fd] border-[#f0f1f7] flex gap-[10px] lg:ml-[10px] mr-[10px] px-[15px] sm:px-[20px] lg:px-[30px]"
          >
            <p className="hidden sm450:block font-bold text-[#d4d4d4] text-[16px] lg:text-[18px]">
              {state.messages.filter((msg) => msg.messageState === "DISPLAYING")
                .length > 0
                ? "Skip"
                : "Send"}
            </p>
            {state.messages.filter((msg) => msg.messageState === "DISPLAYING")
              .length > 0 ? (
              <div
                className={`text-[black]
placeholder:opacity-[50] w-[15px] sm:w-[20px]
`}
              >
                <RiSpeedFill className={"w-full h-full"} />
              </div>
            ) : (
              <div
                className={`text-[black]
placeholder:opacity-[50] w-[20px] sm450:w-[20px]
`}
              >
                <SendIcon className={"w-full h-full"} />
              </div>
            )}
          </button>
        </div>
        <p
          className={`absolute left-[0px] top-[110%] font-light text-[12px] ${
            inputValue.length > state.maxMessageLength ? "text-[red]" : ""
          }`}
        >
          {inputValue.length} / {state.maxMessageLength} characters
        </p>
      </div>
    </div>
  );
}

export default InputArea;
