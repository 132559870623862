// ----------------------
// Packages
// ----------------------
import React from "react";

interface AccountCreationRedirectProps {
  text: string;
  boldedText: string;
  redirect: () => void;
}

const AccountCreationRedirect: React.FC<AccountCreationRedirectProps> = ({
  text,
  redirect,
  boldedText,
}) => {
  return (
    <p className="text-[14px] font-TT text-[#050934]">
      {text}{" "}
      <span className="font-bold cursor-pointer underline " onClick={redirect}>
        {boldedText}
      </span>
    </p>
  );
};

export default AccountCreationRedirect;
