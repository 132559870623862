import axios from "axios";
import handleToastError from "../utility/handleToastError";

export const deleteAuthorizedDevice = async (ipAddress: string) => {
  try {
    await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/users/security/device`,
      {
        data: {
          ipAddress: ipAddress,
        },
        withCredentials: true,
      }
    );
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Check for a rate limiting error (status code 429)
      if (error.response && error.response.status === 429) {
        handleToastError("You are being rate limited.");
      } else if (error.response?.status === 403) {
        handleToastError(
          "Access denied! Please log out and log back in to solve this issue."
        );
      } else if (error.response && error.response.data) {
        const responseData = error.response.data;

        // Check if the response data contains the status 'prohibited'
        if (responseData.status === "prohibited") {
          handleToastError(
            "You can't remove a device from the same IP address!"
          );
          return;
        } else {
          // Handle other errors or statuses as needed
          console.error("Error in deleteAuthorizedDevice:", error);
        }
      } else {
        // Handle non-Axios errors
        console.error("An unexpected error occurred:", error);
      }
    } else {
      // Handle non-Axios errors
      console.error("An unexpected error occurred:", error);
    }
  }
};
