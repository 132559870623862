// ----------------------
// Packages
// ----------------------
import React, { useState } from "react";

// ----------------------
// Assets
// ----------------------
import passwordEye from "../../assets/images/passwordEye.svg";
import eyeOff from "../../assets/images/eye off.svg";
import wrongIcon from "../../assets/images/wrongIcon.svg";
import goodIcon from "../../assets/images/goodIcon.svg";

interface AccountCreationPasswordInputProps {
  popUpActive: boolean;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  password: string;
  setPasswordValidations: React.Dispatch<
    React.SetStateAction<{
      length: boolean;
      uppercase: boolean;
      digit: boolean;
    }>
  >;
  passwordValidations: {
    length: boolean;
    uppercase: boolean;
    digit: boolean;
  };
}

const AccountCreationPasswordInput = ({
  popUpActive,
  setPassword,
  password,
  setPasswordValidations,
  passwordValidations,
}: AccountCreationPasswordInputProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const [hasBeenFocused, setHasBeenFocused] = useState(false);

  const updatePasswordValidations = (password: string) => {
    setPasswordValidations({
      length: password.length >= 8 && password.length <= 60,
      uppercase: /[A-Z]/.test(password),
      digit: /\d/.test(password),
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    updatePasswordValidations(newPassword);
  };

  const handleFocus = () => setHasBeenFocused(true);

  const validationIcon = (isValid: boolean) =>
    isValid ? (
      <img src={goodIcon} alt="Good Icon" />
    ) : (
      <img src={wrongIcon} alt="Wrong Icon" />
    );

  // Determine border color
  const borderColor =
    hasBeenFocused &&
    !Object.values(passwordValidations).every(Boolean) &&
    popUpActive
      ? "border-red-500"
      : "border-[#CECDDA]";

  return (
    <div className="flex flex-col gap-[2px] w-full relative">
      <label
        htmlFor="password"
        className="text-[#050934] font-bold text-[12px] font-TT tracking-wide"
      >
        Password
      </label>
      <input
        type={showPassword ? "text" : "password"}
        id="password"
        value={password}
        onChange={handleChange}
        onFocus={handleFocus}
        className={`rounded-[6px] ${borderColor} border-[1px] w-full h-[48px] text-[14px] outline-none px-[10px]`}
      />
      <button
        className="absolute top-[35px] right-[10px]"
        onClick={() => setShowPassword(!showPassword)}
      >
        {showPassword ? (
          <img
            src={passwordEye}
            alt="Toggle password visibility"
            className="w-[20px]"
          />
        ) : (
          <img
            src={eyeOff}
            alt="Toggle password visibility"
            className="w-[20px]"
          />
        )}
      </button>
      {hasBeenFocused && popUpActive && (
        <div className="mt-2 bg-[#fff] w-[180px] absolute right-[-190px] border-[gray] top-[0px] text-[12px] px-[10px] py-[5px] shadow-md font-TT flex flex-col gap-[5px]">
          <div className="flex items-center gap-[5px]">
            {validationIcon(passwordValidations.length)} 8-60 characters
          </div>
          <div className="flex items-center gap-[5px]">
            {validationIcon(passwordValidations.uppercase)} At least one
            uppercase letter
          </div>
          <div className="flex items-center gap-[5px]">
            {validationIcon(passwordValidations.digit)} At least one digit
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountCreationPasswordInput;
