import React from "react";

export const handleDzinMessage = (
  setBubbleMessageActive: React.Dispatch<React.SetStateAction<boolean>>,
  setBubbleMessageContent: React.Dispatch<React.SetStateAction<string>>,
  active: boolean,
  content: string
) => {
  setBubbleMessageActive(active);
  setBubbleMessageContent(content);
};
