import { io } from "socket.io-client";
import { ActionType, State, Action } from "../../state/interfaces";
import { Socket } from "socket.io-client";
interface Activity {
  type: string;
  id: string;
  [key: string]: any; // This is to account for other properties in the activity object
}

class SocketHandler {
  socket: Socket;
  streamSocket?: WebSocket;
  creatingConversation: boolean;
  retryCount: number;
  dispatch?: Function;

  constructor() {
    const websocket_url =
      process.env.REACT_APP_WEBSOCKET_URL || "ws://localhost:5/conversations";
    this.socket = io(websocket_url, {
      withCredentials: true,
    });

    this.socket.on("connect", () => {});

    this.socket?.on("error", (data) => console.log("error:", data));

    this.creatingConversation = false;

    this.retryCount = 0;
  }

  async createConversation(
    state: State,
    dispatch: Function,
    restart: boolean = false
  ) {
    this.socket?.disconnect();

    setTimeout(() => {
      this.socket?.connect();
      let userId = localStorage.getItem("userId");
      if (userId) {
        userId = userId.replace(/^\"+|\"+$/g, "");
      }
      this.socket?.emit("create-conversation", { userId: userId });

      this.socket?.once("create-conversation", (data) => {
        this.connectToStream(data.streamUrl, dispatch, state);
        localStorage.setItem("conversationId", data.conversationId);
        localStorage.setItem("streamUrl", data.streamUrl);
        dispatch({
          type: ActionType.SET_CONVERSATION_ID,
          payload: data.conversationId,
        });
        dispatch({ type: ActionType.SET_DIALOG_IS_RESTARTING, payload: false });
      });
    }, 1000);
  }

  processActivities(
    activities: any,
    state: State,
    dispatch: Function,
    watermark: string
  ) {
    // Filter activities to include only new messages of type "message"
    const newMessageActivities = activities.filter(
      (activity: any) =>
        activity.type === "message" &&
        !state.messages.some((msg: any) => msg.id === activity.id)
    );

    dispatch({
      type: ActionType.SET_DIALOG_STATE,
      payload: newMessageActivities,
    });

    dispatch({ type: ActionType.SET_DIALOG_IS_RESTARTING, payload: false });

    dispatch({
      type: ActionType.SET_WATERMARK,
      payload: parseInt(watermark),
    });
  }

  attemptReconnect(state: State) {
    const conversationId = localStorage.getItem("conversationId");
    // const streamUrl = localStorage.getItem("streamUrl");

    if (
      conversationId &&
      this.dispatch &&
      (!this.streamSocket || this.streamSocket.readyState === WebSocket.CLOSED)
    ) {
      this.socket.emit("reconnect", {
        conversationId,
        watermark: state.watermark.toString(),
      });

      this.socket.once("reconnect", (response) => {
        const { streamUrl, activities, watermark } = response;
        if (streamUrl && this.dispatch) {
          localStorage.setItem("streamUrl", streamUrl);

          if (activities && activities.length > 0) {
            this.processActivities(activities, state, this.dispatch, watermark);
          }

          this.connectToStream(streamUrl, this.dispatch, state);
        } else {
          console.error("Failed to reconnect: streamUrl missing in response.");
        }
      });
    }
  }

  connectToStream(streamUrl: string, dispatch: Function, state: State) {
    if (this.streamSocket) {
      this.streamSocket.close();
    }

    this.streamSocket = new WebSocket(streamUrl);

    let accumulatedActivities: any[] = [];

    this.streamSocket.onopen = () => {};

    this.streamSocket.onmessage = (event) => {
      if (!event.data.trim()) {
        return;
      }

      try {
        const data = JSON.parse(event.data);
        if (data.activities && data.activities.length > 0) {
          // Push received activities into the array
          accumulatedActivities.push(...data.activities);

          // Check if the current message has inputHint === "expectingInput"
          const hasExpectingInputHint = data.activities.some(
            (activity: any) => activity.inputHint === "expectingInput"
          );

          if (hasExpectingInputHint) {
            // Process accumulated activities
            this.processActivities(
              accumulatedActivities,
              state,
              dispatch,
              data.watermark
            );
            // Clear the array after processing
            accumulatedActivities = [];
          }
        }
      } catch (error) {
        console.error("Error parsing incoming message:", error);
      }
    };

    this.streamSocket.onerror = (error) => {
      console.error("Stream WebSocket error:", error);
    };

    this.streamSocket.onclose = () => {};
  }

  // getDialogState(state: State, dispatch: Function) {
  //   let watermark = state.watermark;

  //   // If state's watermark is 0, try getting it from local storage
  //   if (watermark === 0) {
  //     console.error("Error! Watermark cannot be equal to 0!");
  //     const storedState = localStorage.getItem("state");
  //     if (storedState !== null) {
  //       const parsedState = JSON.parse(storedState);
  //       watermark =
  //         parsedState.watermark > 0
  //           ? parsedState.watermark
  //           : parsedState.messages.length;
  //     }
  //   }

  //   // If watermark is > 0, emit the get-dialog-state event

  //   let conversationId = localStorage.getItem("conversationId");

  //   this.socket?.emit("get-dialog-state", {
  //     conversationId: conversationId,
  //     ...(state.messages.length > 0 && { watermark: watermark.toString() }),
  //   });

  //   this.socket?.once("get-dialog-state", (data) => {
  //     const isGPT = data.activities.find(
  //       (activity: any) => activity.channelData && activity.channelData.gpt
  //     );

  //     const messageActivities = data.activities.filter(
  //       (activity: any) => activity.type === "message"
  //     );

  //     const fromCount = data.activities.filter(
  //       (activity: any) => activity.from.id === process.env.REACT_APP_VAIOT_NAME
  //     ).length;

  //     if (fromCount >= 1) {
  //       const storedState = localStorage.getItem("state");
  //       let storedMessagesLength = 0;

  //       if (storedState) {
  //         const parsedStoredState = JSON.parse(storedState);
  //         storedMessagesLength = parsedStoredState.messages
  //           ? parsedStoredState.messages.length
  //           : 0;
  //       }

  //       if (storedMessagesLength > 2) {
  //         if (isGPT) {
  //           if (messageActivities.length > 2) {
  //             dispatch({
  //               type: ActionType.SET_WATERMARK,
  //               payload: parseInt(data.watermark),
  //             });
  //             dispatch({
  //               type: ActionType.SET_DIALOG_STATE,
  //               payload: data.activities,
  //             });
  //           } else {
  //             setTimeout(() => this.getDialogState(state, dispatch), 500);
  //           }
  //         } else {
  //           const storedState = localStorage.getItem("state");

  //           if (storedState) {
  //             const parsedStoredState = JSON.parse(storedState);
  //             storedMessagesLength = parsedStoredState.messages
  //               ? parsedStoredState.messages.length
  //               : 0;
  //           }

  //           dispatch({
  //             type: ActionType.SET_WATERMARK,
  //             payload: parseInt(data.watermark),
  //           });
  //           dispatch({
  //             type: ActionType.SET_DIALOG_STATE,
  //             payload: data.activities,
  //           });
  //         }
  //       }
  //     } else {
  //       if (this.retryCount < 150) {
  //         setTimeout(() => this.getDialogState(state, dispatch), 2000);
  //         this.retryCount++;
  //       } else {
  //         this.retryCount = 0;
  //       }
  //     }
  //   });
  // }

  handleWalletConnection(
    conversationId: string,
    userId: string,
    isConnected: boolean,
    chainId: any,
    walletAddress: string | null | undefined
  ) {
    // Emitting the state to the "wallet-connect" event

    this.socket?.emit("wallet-connect", {
      conversationId: conversationId,
      userId: userId,
      state: isConnected,
      chainId: chainId === undefined ? -1 : chainId,
      walletAddress: walletAddress === undefined || null ? "" : walletAddress,
    });
  }

  setDispatch(dispatch: Function, state: State) {
    this.dispatch = dispatch;
    this.attemptReconnect(state);
  }

  sendMessage(
    state: State,
    userInput: string,
    walletAddress: string | null | undefined,
    value?: any
  ) {
    this.handleWalletConnection(
      state.conversationId,
      state.userId,
      walletAddress ? true : false,
      11155111,
      walletAddress
    );

    this.retryCount = 0;

    let valueToSend = undefined;
    if (
      value &&
      typeof value === "object" &&
      value !== null &&
      !Array.isArray(value)
    ) {
      valueToSend = value;
    }

    let userData = localStorage.getItem("userData");

    if (userData) {
      userData = JSON.parse(userData);
      this.socket?.emit("send-message", {
        conversationId: state.conversationId.toString(),
        userId: state.userId,
        text: userInput,
        value: valueToSend,
        userData: userData,
        walletAddress: walletAddress,
      });
    } else {
      this.socket?.emit("send-message", {
        conversationId: state.conversationId.toString(),
        userId: state.userId,
        text: userInput,
        value: valueToSend,
        walletAddress: walletAddress,
      });
    }
  }

  clientPaymentUpdate(
    status: string,
    state: State,
    txHash: string,
    tokenType: string
  ) {
    // Retrieve txId from the localStorage
    // const txId = window.localStorage.getItem("txId");

    this.socket?.emit("payment-status-update", {
      status: status,
      conversationId: state.conversationId.toString(),
      userId: state.userId,
      txHash: txHash,
      tokenType: tokenType === "" ? "MATIC" : tokenType.trim(),
      // txId: txId,
    });
  }

  agreementCreateCheckout(state: State) {
    this.socket?.emit("agreement-checkout", {
      conversationId: state.conversationId.toString(),
      userId: state.userId,
    });
    this.socket?.once("agreement-checkout", (data) => {
      const url = data.url;
      window.location.href = url;
    });
  }
  verifyPayment(token: string | null): Promise<any> {
    return new Promise((resolve, reject) => {
      if (token) {
        this.socket?.emit("verify-payment", { token });
        this.socket?.once("verify-payment", (data) => {
          resolve(data.result);
        });
      } else {
        reject(new Error("Token is null"));
      }
    });
  }

  fetchPaymentDialogState(
    state: State,
    dispatch: React.Dispatch<Action>,
    setFirstCall: React.Dispatch<React.SetStateAction<boolean>>,
    firstCall: boolean
  ) {
    this.socket?.emit("get-dialog-state", {
      conversationId: state.conversationId.toString(),
      watermark: state.watermark.toString(),
    });

    this.socket?.once("get-dialog-state", (data) => {
      let paymentStatus: string;

      const messageActivities = data.activities.filter(
        (activity: any) => activity.type === "message"
      );

      let seenIds = new Set<string>();
      let filteredActivities: Activity[] = [];

      data.activities.forEach((activity: Activity) => {
        if (!seenIds.has(activity.id)) {
          filteredActivities.push(activity);
          seenIds.add(activity.id);
        }
      });

      data.activities = filteredActivities;

      if (messageActivities.length >= 1) {
        const activityWithPaymentStatus = data.activities.find(
          (activity: any) =>
            activity.channelData && activity.channelData.paymentStatus
        );

        if (
          activityWithPaymentStatus &&
          activityWithPaymentStatus.channelData
        ) {
          paymentStatus = activityWithPaymentStatus.channelData.paymentStatus;

          if (
            (paymentStatus === "SUCCESS" && messageActivities.length >= 3) ||
            (paymentStatus === "FAILED" && messageActivities.length >= 1)
          ) {
            dispatch({
              type: ActionType.SET_WATERMARK,
              payload: parseInt(data.watermark),
            });

            dispatch({ type: ActionType.SET_THINKING_MESSAGE, payload: "" });
            dispatch({
              type: ActionType.SET_DIALOG_STATE,
              payload: data.activities,
            });
            dispatch({
              type: ActionType.SET_DIALOGUE_PAYMENT_PENDING,
              payload: false,
            });
            dispatch({
              type: ActionType.SET_FETCHING_MESSAGE,
              payload: false,
            });
            setFirstCall(false);
            return;
          }
        }
      }

      // if messages length less than 2, or paymentStatus not found, or conditions for SUCCESS and FAILED not met, retry
      setTimeout(
        () =>
          this.fetchPaymentDialogState(
            state,
            dispatch,
            setFirstCall,
            firstCall
          ),
        firstCall ? 2000 : 10000
      );
    });
  }
}

export default SocketHandler;
