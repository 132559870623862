export const getLastLongParagraph = () =>
  "In the final stage, we generate a smart contract with a “VerifyProof” function. Subsequently, we deploy this smart contract on the Polygon blockchain, which is known for its secure and scalable Ethereum sidechain. This deployment enables all users, regardless of whether they use our interface or not, to directly interact with the contract and independently verify the validity of the proof.";

export const getLastShortParagraph = () =>
  "We deploy a 'VerifyProof' smart contract on Polygon, enabling users to independently validate proofs. This deployment on the secure Ethereum sidechain highlights our commitment to decentralization and transparency.";

export const getParagraphs = (paragraphSize: string) => {
  const lastParagraph =
    paragraphSize === "shortParagraph"
      ? getLastShortParagraph()
      : getLastLongParagraph();
  return [
    "We start by taking computational problem at hand and turning it into a streamlined structure known as a circuit. This is an essential step that paves the way for the creation of zk-SNARK proofs.",
    "At this stage, we convert the defined circuit, typically outlined in a .circom file, into a constraint system. We do this to ensure that the circuit is properly structured and adheres to all necessary syntax rules.",
    "We will now proceed with the generation of two essential cryptographic keys: the proving key and the verification key. The proving key plays a crucial role in the creation of a proof, while the verification key will be employed later to validate the authenticity and validity of said proof.",
    "Here, we take the known inputs and compute what we call a “witness”. This Witness is a detailed collection of values specific to each gate in the circuit. It serves as an affirmation that the circuit has been correctly processed.",
    "Finally, we use the witness and the proving key to construct a zk-SNARK. This is a compact proof that we have the knowledge of the inputs that satisfy the circuit.",
    lastParagraph,
  ];
};
