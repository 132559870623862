// ----------------------
// Packages
// ----------------------
import React from "react";

// ----------------------
// Assets
// ----------------------
import { ReactComponent as CopyIcon } from "../../../assets/images/copyIconV2.svg";

// ----------------------
// Utility Functions
// ----------------------
import { copyToClipboard } from "../../../utils/utility/copyToClipboard";

// ----------------------
// Interfaces
// ----------------------
import { Action, ActionType } from "../../../state/interfaces";

interface HashCompleteProps {
  hashedInput: never[];
  dispatch: React.Dispatch<Action>;
}

function HashComplete({ hashedInput, dispatch }: HashCompleteProps) {
  return (
    <div className="w-full font-semibold text-[18px] flex flex-col items-center justify-between h-full">
      <div className="w-full">
        <p>Your Code</p>
        <div className="bg-hash-button py-[10px] px-[20px] mt-[20px] italic font-normal flex items-center justify-between text-[dimgraylebrown]">
          <input
            type="text"
            readOnly
            value={JSON.stringify(hashedInput)}
            className="w-[90%] bg-transparent"
          />

          <CopyIcon
            className="cursor-pointer"
            onClick={(e) => copyToClipboard(e, JSON.stringify(hashedInput))}
          />
        </div>
      </div>
      <button
        onClick={() =>
          dispatch({
            type: ActionType.SET_HASH_WINDOW_ACTIVE,
            payload: false,
          })
        }
        style={{ backgroundColor: "rgba(0, 35, 239, 0.03)" }}
        className="w-[150px] h-[50px] rounded-md text-[#0023EF] font-bold border-[gray] border-[2px] border-opacity-10"
      >
        Close
      </button>
    </div>
  );
}

export default HashComplete;
