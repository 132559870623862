import { v4 as uuidv4 } from "uuid";
import { Action, State, ActionType } from "../../state/interfaces";
import React from "react";

export async function handleCreateUserId(
  state: State,
  dispatch: React.Dispatch<Action>
) {
  let localStorageState = window.localStorage.getItem("userId");

  if (localStorageState) {
    let parsedState = JSON.parse(localStorageState);

    // check if the userId exists and is not an empty string
    if (parsedState && parsedState !== "") {
      dispatch({ type: ActionType.SET_USER_ID, payload: parsedState });
      return parsedState;
    } else {
      // create a new userId and update it
      let userId = uuidv4();
      parsedState = userId;
      dispatch({ type: ActionType.SET_USER_ID, payload: userId });

      // save the updated state back to localStorage
      window.localStorage.setItem("userId", JSON.stringify(parsedState));

      return userId;
    }
  } else {
    // create a new state object with a new userId
    let userId = uuidv4();
    let newState = userId;
    dispatch({ type: ActionType.SET_USER_ID, payload: userId });

    // save the new state to localStorage
    window.localStorage.setItem("userId", JSON.stringify(newState));

    return userId;
  }
}
