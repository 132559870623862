// ----------------------
// External/Internal Packages
// ----------------------
import React, { useState } from "react";

// ----------------------
// Components
// ----------------------
import InputArea from "./InputArea";
import ListeningButton from "./ListeningButton";
import KeyboardButton from "./KeyboardButton";
import QuestionMarkButton from "./QuestionMarkButton";

// ----------------------
// Interfaces
// ----------------------
import { State, Action, QueueItem } from "../../../state/interfaces";

interface InputProps {
  messageQueue: React.MutableRefObject<QueueItem[]>;
  currentAudio: React.MutableRefObject<HTMLAudioElement | null>;
  dispatch: React.Dispatch<Action>;
  state: State;
}

function Input({ messageQueue, currentAudio, dispatch, state }: InputProps) {
  const [inputValue, setInputValue] = useState("");

  return (
    <>
      {!state.listening ? (
        <InputArea
          inputValue={inputValue}
          setInputValue={setInputValue}
          state={state}
          dispatch={dispatch}
          currentAudio={currentAudio}
          messageQueue={messageQueue}
        />
      ) : (
        <div className="flex items-center w-full justify-around px-[30px]">
          <QuestionMarkButton dispatch={dispatch} />
          <ListeningButton
            state={state}
            dispatch={dispatch}
            messageQueue={messageQueue}
            currentAudio={currentAudio}
          />
          <KeyboardButton dispatch={dispatch} />
        </div>
      )}
    </>
  );
}

export default Input;
