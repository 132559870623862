import { useEffect } from "react";
import { State, Action, ActionType } from "../../state/interfaces";

interface useDialogStateHandlerProps {
  state: State;
  dispatch: React.Dispatch<Action>;
  processedMessageIds: React.MutableRefObject<Set<unknown>>;
}

interface ActionMessage {
  type: string;
  title: string;
  value: string;
}

export const useDialogStateHandler = ({
  state,
  dispatch,
  processedMessageIds,
}: useDialogStateHandlerProps) => {
  useEffect(() => {
    const messageActivities = state.dialogState.filter(
      (activity) => activity.type === "message"
    );

    let type = "";
    if (messageActivities.length > 0) {
      if (messageActivities[0].from.id === state.userId) {
        type = "USER-FIRST";
      } else {
        type = "BOT-FIRST";
      }
    }

    for (let index = 0; index < messageActivities.length; index++) {
      let message = messageActivities[index];
      if (
        message.from.id !== state.userId &&
        message.channelData !== undefined
      ) {
        dispatch({
          type: ActionType.SET_CONVERSATION_STATE,
          payload: message.channelData.conversationState,
        });
        dispatch({
          type: ActionType.SET_PDF_CONTENT,
          payload: message.channelData.previewItems,
        });
        dispatch({
          type: ActionType.SET_MAX_MESSAGE_LENGTH,
          payload: message.channelData.maxLength,
        });
      }

      let isDisplayingExists = state.messages.some(
        (msg) => msg.messageState === "DISPLAYING"
      );

      const messageState =
        !isDisplayingExists &&
        (index === 0 || (index === 1 && type === "USER-FIRST"))
          ? "DISPLAYING"
          : "NOT DISPLAYED";

      if (!processedMessageIds.current.has(message.id)) {
        if (
          message.from.id === process.env.REACT_APP_VAIOT_NAME &&
          message.suggestedActions === undefined &&
          message.label !== "date" &&
          message.label !== "sell" &&
          message.label !== "loopData" &&
          message.label !== "transactionHashSummary" &&
          message.label !== "stripe" &&
          message.label !== "downloadContract"
        ) {
          if (
            (type === "USER-FIRST" && index === 1) ||
            (type === "BOT-FIRST" && index === 0)
          ) {
            dispatch({
              type: "APPEND_MESSAGE",
              payload: {
                text: message.text,
                type: "BOT-FIRST",
                id: message.id,
                textWritten: true,
                messageState: messageState,
                doneReading: false,
              },
            });
          } else {
            dispatch({
              type: "APPEND_MESSAGE",
              payload: {
                text: message.text,
                type: "BOT-NEXT",
                id: message.id,
                textWritten: true,
                messageState: messageState,
                doneReading: false,
              },
            });
          }
        }

        if (
          message.from.id === process.env.REACT_APP_VAIOT_NAME &&
          message.inputHint === "expectingInput" &&
          message.suggestedActions !== undefined &&
          message.suggestedActions.actions !== undefined &&
          message.label !== "date" &&
          message.label !== "sell" &&
          message.label !== "transactionHashSummary" &&
          message.label !== "downloadContract"
        ) {
          if (
            (type === "USER-FIRST" && index === 1) ||
            (type === "BOT-FIRST" && index === 0)
          ) {
            const actions: ActionMessage[] = message.suggestedActions.actions;
            const titles = actions.map((action) => action.title);
            const values = actions.map((action) => action.value);

            dispatch({
              type: "APPEND_MESSAGE",
              payload: {
                text: titles,
                type: "BUTTONS-FIRST",
                id: message.id,
                aboveButtons: message.text,
                textWritten: true,
                messageState: messageState,
                doneReading: false,
                values: values,
              },
            });
          } else {
            const actions: ActionMessage[] = message.suggestedActions.actions;
            const titles = actions.map((action) => action.title);
            const values = actions.map((action) => action.value);
            dispatch({
              type: "APPEND_MESSAGE",
              payload: {
                text: titles,
                type: "BUTTONS",
                id: message.id,
                aboveButtons: message.text,
                textWritten: true,
                messageState: messageState,
                doneReading: false,
                values: values,
              },
            });
          }
        }
        if (
          message.from.id === process.env.REACT_APP_VAIOT_NAME &&
          message.inputHint === "expectingInput" &&
          message.label !== undefined &&
          message.label !== "option" &&
          message.label !== "dropdown"
        ) {
          if (type === "USER-FIRST" && index === 1) {
            dispatch({
              type: "APPEND_MESSAGE",
              payload: {
                text: message.text,
                type: "WIDGET-FIRST",
                id: message.id,
                aboveButtons: message.text,
                textWritten: true,
                messageState: messageState,
                doneReading: false,
                additionalData: message.channelData,
                widgetType: message.label,
              },
            });
          } else {
            dispatch({
              type: "APPEND_MESSAGE",
              payload: {
                text: message.text,
                type: "WIDGET",
                id: message.id,
                aboveButtons: message.text,
                textWritten: true,
                messageState: messageState,
                doneReading: false,
                additionalData: message.channelData,
                widgetType: message.label,
              },
            });
          }
        }
        processedMessageIds.current.add(message.id);
        dispatch({
          type: ActionType.SET_FETCHING_MESSAGE,
          payload: false,
        });
      }
    }
  }, [
    state.dialogState,
    dispatch,
    state.messages,
    processedMessageIds,
    state.userId,
  ]);
};
