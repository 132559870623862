import React, { useState, useEffect } from "react";
import handleToastError from "../../utils/utility/handleToastError";
import { Action } from "../../state/interfaces";
import { handleSetPassword } from "../../utils/api/handleSetPassword";
import { useParams } from "react-router-dom";
import wrongIcon from "../../assets/images/wrongIcon.svg";
import goodIcon from "../../assets/images/goodIcon.svg";
import { useNavigate } from "react-router-dom";

interface ResetPasswordModalProps {
  dispatch: React.Dispatch<Action>;
}

function ResetPasswordModal({ dispatch }: ResetPasswordModalProps) {
  const [isVisible, setIsVisible] = useState(true);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [passwordValidations, setPasswordValidations] = useState({
    length: false,
    uppercase: false,
    digit: false,
  });
  const [passwordChanged, setPasswordChanged] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const handleVisibility = () => {
      setIsVisible(true);
    };

    handleVisibility();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      handleToastError("The new passwords do not match. Please try again.");
      return;
    }
    if (newPassword === "" || confirmNewPassword === "") {
      handleToastError("Password cannot be empty!");
      return;
    }

    if (!validatePassword(newPassword)) {
      handleToastError("Password does not meet the requirements.");
      return;
    }

    if (token) {
      await handleSetPassword(token, newPassword);
      setPasswordChanged(true);
    } else {
      handleToastError("Unexpected error!");
    }
  };

  const validatePassword = (password: string) => {
    const lengthValid = password.length >= 8 && password.length <= 60;
    const uppercaseValid = /[A-Z]/.test(password);
    const digitValid = /\d/.test(password);

    return lengthValid && uppercaseValid && digitValid;
  };

  const validationIcon = (isValid: boolean) =>
    isValid ? (
      <img src={goodIcon} alt="Good Icon" />
    ) : (
      <img src={wrongIcon} alt="Wrong Icon" />
    );

  const updatePasswordValidations = (password: string) => {
    setPasswordValidations({
      length: password.length >= 8 && password.length <= 60,
      uppercase: /[A-Z]/.test(password),
      digit: /\d/.test(password),
    });
  };

  return (
    <div className="w-full h-full flex items-center justify-center bg-[#050934] bg-opacity-[75%] fixed z-[101] font-TT tracking-wide">
      <div className="flex flex-col w-full sm:w-auto gap-[40px] justify-center items-center relative ">
        <div
          className={`bg-white w-[90%] mt-[100px] sm:mt-0 sm:w-[540px] border-2 shadow-lg rounded-[6px] overflow-hidden transition-all duration-100 ease-in-out transform-gpu ${
            isVisible ? "scale-100" : "scale-90"
          }`}
        >
          {passwordChanged ? (
            <div className="flex flex-col items-center justify-center p-8">
              <h3 className="text-lg font-semibold mb-4">
                Password Changed Successfully
              </h3>
              <button
                onClick={() => navigate("/")}
                className="px-6 py-2 bg-blue-600 text-white font-bold rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Go Back
              </button>
            </div>
          ) : (
            <form
              onSubmit={handleSubmit}
              className="flex flex-col justify-between p-8 space-y-6 relative"
            >
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-semibold">Change Your Password</h3>
              </div>
              <input
                type={"password"}
                placeholder="New Password"
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                  updatePasswordValidations(e.target.value);
                }}
                required
                autoComplete="new-password"
              />

              <input
                type={"password"}
                placeholder="Confirm New Password"
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                required
                autoComplete="new-password"
              />

              <button
                type="submit"
                className="px-6 py-2 bg-blue-600 text-white font-bold rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Change Password
              </button>
            </form>
          )}
        </div>
        <div className="sm:absolute top-[110%] md:left-[105%] md:top-[29%] p-4 bg-[#050934] text-white shadow-md rounded-md space-y-2 w-[200px]">
          <div className="flex items-center gap-2">
            {validationIcon(passwordValidations.length)} 8-60 characters
          </div>
          <div className="flex items-center gap-2">
            {validationIcon(passwordValidations.uppercase)} At least one
            uppercase letter
          </div>
          <div className="flex items-center gap-2">
            {validationIcon(passwordValidations.digit)} At least one digit
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordModal;
