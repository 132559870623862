import { useEffect } from "react";
import { State } from "../../../state/interfaces";
import axios from "axios";
import handleToastError from "../../../utils/utility/handleToastError";

export const useFetchHashedInput = (
  state: State,
  setFields: React.Dispatch<React.SetStateAction<never[]>>
) => {
  useEffect(() => {
    const fetchProofToInput = async () => {
      if (state.proofJson !== undefined) {
        try {
          const response = await axios.post(
            process.env.REACT_APP_BASE_URL + "/snarks/proofToInput",
            {
              proof: state.proofJson,
            }
          );

          if (response.data) {
            setFields(response.data);
          } else {
            setFields([]);
          }
        } catch (error) {
          if (axios.isAxiosError(error)) {
            if (error.response?.status === 403) {
              handleToastError(
                "Access denied! Please log out and log back in to solve this issue."
              );
            } else {
              console.error("An error occurred:", error);
            }
          } else {
            console.error("An unexpected error occurred:", error);
          }
        }
      }
    };
    const fetchHashedInput = async () => {};

    fetchProofToInput();
    fetchHashedInput();
  }, [state.proofJson]);
};
