import handleToastError from "../../../utils/utility/handleToastError";

type OptionType = "Supplier" | "Buyer" | "General";

export const updateSummary = (
  field: string,
  value: string,
  supplier: string,
  fetchingSummary: boolean,
  setSelectedFields: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string[];
      Supplier: string[];
      Buyer: string[];
      General: string[];
    }>
  >,
  selectedOption: OptionType,
  messageForSummary: any[],
  setMessageForSummary: React.Dispatch<React.SetStateAction<any[]>>
) => {
  // Log the value for debugging

  if (fetchingSummary) {
    handleToastError("Wait for the summary to finish fetching!");
    return;
  }

  // Determine if it's a supplier, buyer, or a general question
  const isSupplier = selectedOption === "Supplier";
  const isBuyer = selectedOption === "Buyer";
  const generalQuestion = !isSupplier && !isBuyer;

  // Update the field name based on the supplier value
  const fieldName = generalQuestion
    ? field
    : isSupplier
    ? `supplier ${field}`
    : `buyer ${field}`;

  // Update the selectedFields state
  setSelectedFields((prev) => ({
    ...prev,
    [selectedOption]: prev[selectedOption].includes(field)
      ? prev[selectedOption].filter((f) => f !== field)
      : [...prev[selectedOption], field],
  }));

  // Check if the object already exists in messageForSummary
  const existingIndex = messageForSummary.findIndex(
    (item) => item[fieldName] === value
  );

  // If it exists, remove it
  if (existingIndex !== -1) {
    setMessageForSummary((prev) => [
      ...prev.slice(0, existingIndex),
      ...prev.slice(existingIndex + 1),
    ]);
  } else {
    // If it doesn't exist, append it
    setMessageForSummary((prev) => [...prev, { [fieldName]: value }]);
  }
};
