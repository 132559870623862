import { State, Action, ActionType } from "../../state/interfaces";
import { useSpeechRecognition } from "react-speech-recognition";
import React, { useCallback } from "react";

export function useTypingDoneHandler(
  state: State,
  dispatch: React.Dispatch<Action>
) {
  const { resetTranscript } = useSpeechRecognition();

  const handleTypingDone = useCallback(
    (messageId: string) => {
      const currentMessageIndex = state.messages.findIndex(
        (msg) => msg.id === messageId
      );

      dispatch({
        type: "UPDATE_MESSAGE_BY_ID",
        payload: {
          messageId,
        },
      });

      dispatch({
        type: "UPDATE_NEXT_NON_USER_MESSAGE",
        payload: {
          startingIndex: currentMessageIndex,
        },
      });

      setTimeout(() => {
        resetTranscript();
        dispatch({
          type: ActionType.SET_VOICE_MESSAGE_SENT,
          payload: false,
        });
      }, 3000);
    },
    [resetTranscript, dispatch, state.messages]
  );

  return handleTypingDone;
}
