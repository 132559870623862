import { Action, State } from "../../../state/interfaces";
import SocketHandlerReader from "../../../utils/sockets/handleSocketsReader";

export const updatePaybackPayment = async (
  state: State,
  dispatch: React.Dispatch<Action>,
  account: string | null | undefined,
  idOfChosenContract: string,
  typeOfContract: "SA" | "NDA" | "PoAG" | "PoAS" | "",
  paybackChosen: string
) => {
  if (!(state.socketHandlerReader instanceof SocketHandlerReader)) {
    state.socketHandlerReader = new SocketHandlerReader();
  }

  const responseStatus =
    await state.socketHandlerReader?.clientPaymentUpdatePaybackOnly(
      idOfChosenContract,
      typeOfContract,
      account,
      state.summary,
      state,
      dispatch,
      paybackChosen
    );

  return responseStatus;
};
