// ----------------------
// Components
// ----------------------
import ImageDropDown from "../Crypto/ImageDropDown";

// ----------------------
// Assets
// ----------------------
import chevronDown from "../../../../../assets/images/chevronDown.svg";
import chevronUp from "../../../../../assets/images/chevronUp.svg";

interface DefaultPaybackProps {
  dropdownOpen: boolean;
}

function DefaultPayback({ dropdownOpen }: DefaultPaybackProps) {
  return (
    <div className="flex items-center justify-between gap-[5px]">
      <p className="text-[gray] opacity-[30%] text-[12px]">Choose method</p>
      {!dropdownOpen && <ImageDropDown src={chevronDown} />}
      {dropdownOpen && <ImageDropDown src={chevronUp} />}
    </div>
  );
}

export default DefaultPayback;
