import { io } from "socket.io-client";
import { ActionType, State, Action } from "../../state/interfaces";
import { Socket } from "socket.io-client";
import handleToastError from "../utility/handleToastError";
class SocketHandlerReader {
  socket: Socket;

  constructor() {
    const websocket_url =
      process.env.REACT_APP_WEBSOCKET_READER_URL ||
      "http://localhost:5/contract-reader";
    this.socket = io(websocket_url, {
      withCredentials: true,
    });

    this.socket.on("connect", () => {});

    this.socket?.on("error", (data) => console.log("error:", data));
  }

  listenToStatusUpdates(callback: (data: any) => void) {
    this.socket?.on("newStatus", (data) => {
      callback(data);
    });
  }

  checkCertificatePaid(
    idOfChosenContract: string,
    account: string | null | undefined,
    dispatch: React.Dispatch<Action>,
    typeOfChosenContract: string
  ) {
    this.socket?.emit("fetch-all-certificates", {
      smartContractId: idOfChosenContract,
      walletAddress: account,
      typeOfContract: typeOfChosenContract,
    });

    this.socket?.once("fetch-all-certificates", (response) => {
      if (response.status === "noResults") {
        return;
      }

      if (response.status === "success" && response.certificates) {
        const matchingCertificate = response.certificates.find(
          (certificate: any) =>
            certificate.typeOfContract.toLowerCase() ===
            typeOfChosenContract.toLowerCase()
        );

        if (!matchingCertificate) {
          return;
        }

        // Emit the certificate event with the found _id
        this.socket?.emit("certificate", {
          contractId: idOfChosenContract,
          walletAddress: account,
          typeOfContract: typeOfChosenContract.toString(),
          proofId: matchingCertificate._id,
        });

        this.socket?.once("certificate", (data) => {
          dispatch({
            type: ActionType.SET_CERTIFICATE_PAID,
            payload: data.certificate,
          });
        });
      }
    });
  }

  fetchAgreementUrl(
    walletAddress: string | null | undefined,
    contractId: number,
    setAgreementUrl: React.Dispatch<React.SetStateAction<string>>
  ) {
    this.socket?.emit("pdf", {
      contractId: contractId.toString(),
      walletAddress: walletAddress,
    });

    this.socket?.once("pdf", (data) => {
      if (data) {
        setAgreementUrl(data.pdfUrl);
      }
    });
  }

  joinAgreementRoom(
    typeOfChosenContract: "SA" | "NDA" | "PoAG" | "PoAS" | "",

    contractId: number,
    walletAddress: string | null | undefined,
    dispatch: React.Dispatch<Action>
  ) {
    console.log("emitting:", {
      typeOfContract: typeOfChosenContract,
      smartContractId: contractId.toString(),
      walletAddress,
    });
    this.socket?.emit("join-agreement-room", {
      typeOfContract: typeOfChosenContract,
      smartContractId: contractId.toString(),
      walletAddress,
    });

    this.socket?.once("join-agreement-room", (data) => {
      console.log("received data", data);
      if (data.status === "notFound") {
        dispatch({
          type: ActionType.SET_READER_PAYMENT_ACTIVE,
          payload: false,
        });
        dispatch({ type: ActionType.SET_READER_PAYMENT_ERROR, payload: true });
        handleToastError("Unexpected Error!");
        return;
      }
    });
  }

  fetchAllCertificates(
    smartContractNumber: string,
    dispatch: React.Dispatch<Action>,
    account: string | null | undefined,
    typeOfChosenContract: "SA" | "NDA" | "PoAG" | "PoAS" | ""
  ) {
    this.socket?.emit("fetch-all-certificates", {
      smartContractId: smartContractNumber.toString(),
      walletAddress: account,
      typeOfContract: typeOfChosenContract,
    });

    this.socket?.once("fetch-all-certificates", (data) => {
      if (data) {
        // Dispatch action with filtered certificates
        dispatch({
          type: ActionType.SET_ALL_CERTIFICATES,
          payload: data.certificates,
        });
      }
    });
  }

  clientPaymentUpdatePaybackOnly(
    contractId: string,
    typeOfContract: "SA" | "NDA" | "PoAG" | "PoAS" | "",
    account: string | null | undefined,
    summary: string,
    state: State,
    dispatch: React.Dispatch<Action>,
    paybackChosen: string
  ) {
    if (paybackChosen === "Payback") {
      return new Promise((resolve) => {
        this.socket?.emit("client-payment-update-payback", {
          contractId: parseInt(contractId),
          typeOfContract: typeOfContract,
          walletAddress: account,
          contractOverview: summary,
          certificateFields: state.certificateFields,
        });

        dispatch({ type: ActionType.SET_CERTIFICATE_FIELDS, payload: [] });
        dispatch({ type: ActionType.SET_SUMMARY, payload: "" });

        this.socket?.once("client-payment-update-payback", (data) => {
          if (data) {
            resolve(data.status);
          }
        });
      });
    } else if (paybackChosen === "Points") {
      return new Promise((resolve) => {
        this.socket?.emit("client-payment-update-vaipoints", {
          contractId: parseInt(contractId),
          typeOfContract: typeOfContract,
          walletAddress: account,
          contractOverview: summary,
          certificateFields: state.certificateFields,
        });

        dispatch({ type: ActionType.SET_CERTIFICATE_FIELDS, payload: [] });
        dispatch({ type: ActionType.SET_SUMMARY, payload: "" });

        this.socket?.once("client-payment-update-vaipoints", (data) => {
          if (data) {
            resolve(data.status);
          }
        });
      });
    }
  }

  updateBackendPayment(
    txStatus: string,
    txHash: string,
    tokenType: string,
    dispatch: React.Dispatch<Action>,
    summary: string,
    walletAddress: string | null | undefined,
    contractId: string,
    state: State,
    paybackActive: boolean,
    typeOfChosenContract: string,
    paybackChosen?: string,
    inputValue?: string
  ): Promise<string> {
    return new Promise((resolve) => {
      if (
        paybackActive &&
        inputValue &&
        paybackChosen === "Payback" &&
        inputValue !== "" &&
        inputValue !== "0" &&
        inputValue !== "5"
      ) {
        this.socket?.emit("client-payment-update", {
          contractId: parseInt(contractId),
          walletAddress: walletAddress,
          status: txStatus,
          txHash: txHash,
          tokenType: tokenType,
          contractOverview: summary,
          certificateFields: state.certificateFields,
          payback: parseInt(inputValue),
          typeOfContract: typeOfChosenContract,
        });
      } else if (
        paybackActive &&
        inputValue &&
        paybackChosen === "Points" &&
        inputValue !== "" &&
        inputValue !== "0" &&
        inputValue !== "5"
      ) {
        this.socket?.emit("client-payment-update", {
          contractId: parseInt(contractId),
          walletAddress: walletAddress,
          status: txStatus,
          txHash: txHash,
          tokenType: tokenType,
          contractOverview: summary,
          certificateFields: state.certificateFields,
          vaiPoints: parseInt(inputValue),
          typeOfContract: typeOfChosenContract,
        });
      } else {
        this.socket?.emit("client-payment-update", {
          contractId: parseInt(contractId),
          walletAddress: walletAddress,
          status: txStatus,
          txHash: txHash,
          tokenType: tokenType,
          contractOverview: summary,
          certificateFields: state.certificateFields,
          typeOfContract: typeOfChosenContract,
        });
      }

      dispatch({ type: ActionType.SET_CERTIFICATE_FIELDS, payload: [] });
      dispatch({ type: ActionType.SET_SUMMARY, payload: "" });

      this.socket?.once("client-payment-update", (data) => {
        if (data) {
          resolve(data.status);
        }
      });
    });
  }
}

export default SocketHandlerReader;
