// ----------------------
// Packages
// ----------------------
import React from "react";
import Slider from "react-slick";

// ----------------------
// Components
// ----------------------
import SummaryDiv from "./SummaryDiv";
import SummaryOptionsSA from "./SummaryOptionsSA";

// ----------------------
// Assets
// ----------------------
import { ReactComponent as Chevron } from "../../../assets/images/chevronLeft.svg";

// ----------------------
// Interfaces
// ----------------------
import { Action, State, ContractTerms } from "../../../state/interfaces";
import SummaryOptionsNDA from "./SummaryOptionsNDA";

interface MobileSummaryProps {
  state: State;
  fetchingSummary: boolean;
  messageForSummary: any;
  dispatch: React.Dispatch<Action>;
  setFetchingSummary: React.Dispatch<React.SetStateAction<boolean>>;
  contractInfo: ContractTerms | undefined;
  setMessageForSummary: React.Dispatch<React.SetStateAction<any[]>>;
  goBack: () => void;
  typeOfChosenContract: "SA" | "NDA" | "PoAG" | "PoAS" | "";
  contractInfoNDA: ContractInfoNDA | undefined;
}

interface ContractInfoNDA {
  creationTimestamp: string;
  fieldNames: string[];
  fieldValues: string[];
  partyA: string;
  partyB: string;
}

function MobileSummary({
  state,
  fetchingSummary,
  messageForSummary,
  dispatch,
  setFetchingSummary,
  contractInfo,
  setMessageForSummary,
  goBack,
  typeOfChosenContract,
  contractInfoNDA,
}: MobileSummaryProps) {
  const slider = React.useRef<Slider>(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <>
      <div className="text-[12px]">
        <div className="text-[26px] flex items-center font-semibold font-TT text-[#050934] relative mb-[20px] ml-[-10px]">
          <button onClick={goBack} className="w-[30px] h-[30px]">
            <Chevron className="w-full h-full" />
          </button>
          <p>Contract Summary</p>
        </div>

        <p>You can</p>
        <p className="font-bold mb-[20px]">
          Swipe to add more descriptions to your contract overview
        </p>
      </div>
      <Slider {...settings} ref={slider}>
        <SummaryDiv
          state={state}
          dispatch={dispatch}
          fetchingSummary={fetchingSummary}
          setFetchingSummary={setFetchingSummary}
          messageForSummary={messageForSummary}
          mobile={true}
        />
        {typeOfChosenContract === "SA" && (
          <SummaryOptionsSA
            fetchingSummary={fetchingSummary}
            contractInfo={contractInfo}
            setMessageForSummary={setMessageForSummary}
            messageForSummary={messageForSummary}
            mobile={true}
          />
        )}
        {(typeOfChosenContract === "NDA" ||
          typeOfChosenContract === "PoAG" ||
          typeOfChosenContract === "PoAS") && (
          <SummaryOptionsNDA
            fetchingSummary={fetchingSummary}
            setMessageForSummary={setMessageForSummary}
            messageForSummary={messageForSummary}
            mobile={true}
            contractInfoNDA={contractInfoNDA}
          />
        )}
      </Slider>
    </>
  );
}

export default MobileSummary;
