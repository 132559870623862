import { Action, ActionType, QueueItem } from "../../state/interfaces";

export const handleVoiceSkip = (
  messageQueue: React.MutableRefObject<QueueItem[]>,
  currentAudio: React.MutableRefObject<HTMLAudioElement | null>,
  dispatch: React.Dispatch<Action>
) => {
  if (currentAudio !== undefined && currentAudio.current !== null) {
    currentAudio.current.pause();
    currentAudio.current.currentTime = 0;
    dispatch({ type: ActionType.SET_IS_SPEAKING, payload: false });
  }
  messageQueue.current = [];
};
