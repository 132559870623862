// Interfaces
import { Action, State } from "../interfaces";

export const resetContractReader = (state: State, action: Action): State => {
  if (action.type === "RESET_CONTRACT_READER") {
    return {
      ...state,
      decodingAnimationFinished: false,
      contractListAnimationFinished: false,
      contractChosen: false,
      paymentFinished: false,
      contractList: [],
      getCertificate: false,
      paymentActive: false,
      certificatePaid: false,
    };
  } else {
    return state;
  }
};

export const resetConversation = (state: State, action: Action): State => {
  if (action.type === "RESET_CONVERSATION") {
    return {
      ...state,
      conversationStarted: false,
      conversationId: "",
      watermark: 0,
      dialogState: [],
      messages: [],
    };
  } else {
    return state;
  }
};
