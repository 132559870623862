import writtenNumber from "written-number";

export const commands = Array.from({ length: 1000 }, (_, i) => {
  const number = i; // since we are starting from 1
  const command = writtenNumber(number);
  return {
    command,
    number,
  };
});

commands.push({ command: "hundred", number: 100 });
commands.push({ command: "hundredth", number: 100 });
commands.push({ command: "thousand", number: 1000 });
// You can add more such mappings if you need.
