// ----------------------
// Packages
// ----------------------
import React, { useState } from "react";

// ----------------------
// Interfaces
// ----------------------
interface ContractInfoNDA {
  creationTimestamp: string;
  fieldNames: string[];
  fieldValues: string[];
  partyA: string;
  partyB: string;
}

interface Element {
  name: string;
  value: string;
}

interface SummaryOptionsNDAProps {
  fetchingSummary: boolean;
  setMessageForSummary: React.Dispatch<React.SetStateAction<any[]>>;
  messageForSummary: any[];
  mobile: boolean;
  contractInfoNDA: ContractInfoNDA | undefined;
}

function SummaryOptionsNDA({
  fetchingSummary,
  setMessageForSummary,
  messageForSummary,
  mobile,
  contractInfoNDA,
}: SummaryOptionsNDAProps) {
  const [activeButtons, setActiveButtons] = useState<Set<string>>(new Set());

  if (!contractInfoNDA) return null;

  const { creationTimestamp, fieldNames, fieldValues, partyA, partyB } =
    contractInfoNDA;

  const elements: Element[] = [
    { name: "Creation Date", value: creationTimestamp },
    { name: "Party A", value: partyA },
    { name: "Party B", value: partyB },
    ...fieldNames.map((fieldName, index) => ({
      name: fieldName,
      value: fieldValues[index],
    })),
  ];

  const toggleButtonActive = (elementName: string) => {
    const newActiveButtons = new Set(activeButtons);
    if (newActiveButtons.has(elementName)) {
      newActiveButtons.delete(elementName);
    } else {
      newActiveButtons.add(elementName);
    }
    setActiveButtons(newActiveButtons);
  };

  const getButtonClass = (elementName: string) => {
    let baseClass =
      "p-[5px] border-[1px] border-black rounded-[2px] cursor-pointer";
    if (activeButtons.has(elementName)) {
      return `${baseClass} bg-[#0023EFB2] text-white`; // Active state
    }
    return `${baseClass} hover:bg-[#0023EF26]`; // Hover state
  };

  const handleButtonClick = (name: string, value: string) => {
    // Create an object with name as the key and value as the value
    const summaryObject = { [name]: value };
    // Check if this field already exists in the summary
    const existingIndex = messageForSummary.findIndex(
      (item) => Object.keys(item)[0] === name
    );
    let newSummary = [...messageForSummary];
    if (existingIndex >= 0) {
      // If it exists, remove it (toggle functionality)
      newSummary.splice(existingIndex, 1);
    } else {
      // Otherwise, add the new summary object
      newSummary.push(summaryObject);
    }
    setMessageForSummary(newSummary);
  };

  return (
    <div className="flex flex-col justify-between md:mt-[130px] overflow-y-auto bg-[#0023EF0D] simple-shadow-bottom-left rounded-b-md xl:w-[500px] xl:h-[360px] lg:w-[450px] lg:h-[300px] md:w-[400px] md:h-[340px] sm:w-[500px]  w-full h-[380px] p-[30px]">
      <div>
        <p className="font-TT text-[14px] mb-[10px]">
          Add data to the contract summary - click to add, click again to
          remove.
        </p>
        <div className="flex flex-wrap gap-[10px]">
          {elements.map((element, index) => (
            <button
              key={index}
              onClick={() => {
                toggleButtonActive(element.name);
                handleButtonClick(element.name, element.value);
              }}
              className={getButtonClass(element.name)}
            >
              {element.name}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SummaryOptionsNDA;
