// ----------------------
// Packages
// ----------------------
import React from "react";
import PulseLoader from "react-spinners/PulseLoader";

// ----------------------
// Assets
// ----------------------
import { ReactComponent as GreenCheckmark } from "../../../assets/images/greenCheckmark.svg";

interface IconProps {
  default: string;
  finished: string;
}

interface ProgressStepProps {
  isActive: boolean;
  isCompleted: boolean;
  label: string;
  icon: IconProps;
}

const ProgressStep: React.FC<ProgressStepProps> = ({
  isActive,
  isCompleted,
  label,
  icon,
}) => {
  return (
    <div
      className={`flex items-center justify-between gap-[20px] text-[14px] sm800:text-[16px] md:text-[20px] font-semibold ${
        !isActive && !isCompleted ? "opacity-50" : ""
      }`}
    >
      <div className="flex gap-[20px] items-center">
        <img
          className="w-[40px] h-[40px]"
          src={isActive || isCompleted ? icon.finished : icon.default}
          alt="Completed Icon"
        />
        <p>{label}</p>
      </div>
      {isActive && <PulseLoader color="#2973b6" size={7} />}
      {isCompleted && (
        <div className="w-[20px] h-[20px]">
          <GreenCheckmark className="w-full h-full" />
        </div>
      )}
    </div>
  );
};

export default ProgressStep;
