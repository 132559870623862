import React from "react";
import { Action, ActionType } from "../../../state/interfaces";

interface ContractTerms {
  agreementTerms: Array<any>;
  parties: Array<string>;
  services: Array<Array<string>>;
  onBlockchain: string;
}

interface ContractInfoNDA {
  creationTimestamp: string;
  fieldNames: string[];
  fieldValues: string[];
  partyA: string;
  partyB: string;
}

export const resetContractDecoded = (
  fieldChoice: boolean,
  dispatch: React.Dispatch<Action>,
  setBubbleMessageActive: React.Dispatch<React.SetStateAction<boolean>>,
  setBubbleMessageContent: React.Dispatch<React.SetStateAction<string>>,

  setContractInfoSA: React.Dispatch<
    React.SetStateAction<ContractTerms | undefined>
  >,
  setContractInfoNDA: React.Dispatch<
    React.SetStateAction<ContractInfoNDA | undefined>
  >
) => {
  dispatch({ type: ActionType.SET_CERTIFICATE_FIELDS, payload: [] });

  if (!fieldChoice) {
    dispatch({
      type: ActionType.SET_DECODING_ANIMATION_FINISHED,
      payload: false,
    });
    dispatch({
      type: ActionType.SET_CONTRACT_CHOSEN,
      payload: false,
    });
    dispatch({
      type: ActionType.SET_CERTIFICATE_PAID,
      payload: false,
    });
    dispatch({ type: ActionType.SET_TYPE_OF_CHOSEN_CONTRACT, payload: "" });
    setContractInfoNDA(undefined);
    setContractInfoSA(undefined);
  } else {
    dispatch({
      type: ActionType.SET_CERTIFICATE_FIELD_CHOICE,
      payload: false,
    });

    setBubbleMessageActive(false);
    setBubbleMessageContent("");
  }
};
