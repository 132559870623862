// ----------------------
// Packages
// ----------------------
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

// ----------------------
// Assets
// ----------------------

import { currencyOptions } from "../config";

interface CurrencyDropDownProps {
  setCurrencyChosen: React.Dispatch<React.SetStateAction<string>>;
  chainChosen: string;
}

function CurrencyDropDown({
  setCurrencyChosen,
  chainChosen,
}: CurrencyDropDownProps) {
  return (
    <div className="z-[100] w-[120px] lg:w-[150px] absolute top-full mt-2 font-bold bg-white divide-y divide-gray-100 shadow-lg rounded-lg overflow-hidden second-dropdown">
      {currencyOptions.map((option) => {
        if (option.chain === null || option.chain === chainChosen) {
          return (
            <button
              key={option.name}
              onClick={() => setCurrencyChosen(option.name)}
              className="flex items-center p-2 second-dropdown gap-[5px] w-full"
            >
              <LazyLoadImage
                alt={`${option.name} Logo`}
                effect="blur"
                src={option.image}
                className="w-[20px]"
              />
              <span>{option.name}</span>
            </button>
          );
        }
        return null;
      })}
    </div>
  );
}

export default CurrencyDropDown;
