// Function to generate random text of given length

export const generateRandomText = (length: number) => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  const charactersLength = characters.length;

  for (let i = 0; i < length; ) {
    let charCount = Math.floor(Math.random() * 5) + 4; // Random number between 4 and 8

    for (let j = 0; j < charCount && i < length; j++, i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    if (i < length) {
      result += " ";
      i++;
    }
  }

  return result;
};
