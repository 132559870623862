import React from "react";

interface PaybackOptionsDueProps {
  paybackChosen: string;
  inputValue: string;
}

function PaybackOptionsDue({
  paybackChosen,
  inputValue,
}: PaybackOptionsDueProps) {
  return (
    <div className="w-full flex-col flex items-center sm500:items-end justify-center font-TT tracking-wider gap-[10px]">
      <div className="flex items-center justify-between gap-[30px] w-[200px]">
        <p className="text-[18px]">Certificate Price</p>
        <p className="font-bold text-[24px]">5$</p>
      </div>
      <div className="flex items-center justify-between gap-[30px] w-[200px] h-[40px]">
        {paybackChosen === "Payback" ? (
          <p className="text-[18px]">Payback</p>
        ) : (
          <p className="text-[18px]">VAI Points</p>
        )}
        <p className="font-bold text-[24px]">
          {inputValue === "0" || inputValue === ""
            ? null
            : "-" + inputValue + "$"}
        </p>
      </div>
      <div className="h-[1px] w-[230px] bg-[#CECDDA]"></div>
      <div className="flex items-center justify-between gap-[30px] w-[200px] text-[#0023EF]">
        <p className="text-[18px]">Amount due</p>
        <p className="font-bold text-[32px]">
          {inputValue === "" ? 5 + "$" : 5 - parseInt(inputValue) + "$"}
        </p>
      </div>
    </div>
  );
}

export default PaybackOptionsDue;
