import React from "react";
import { Action, State, ActionType } from "../../state/interfaces";
import SocketHandler from "./handleSocket";

export async function handleUserMessage(
  userInput: string,
  dispatch: React.Dispatch<Action>,
  state: State,
  walletAddress: string | null | undefined,
  value?: any
) {
  dispatch({
    type: ActionType.APPEND_MESSAGE,
    payload: {
      text: userInput,
      type: "USER",
      id: "",
      messageState: "DISPLAYED",
      doneReading: false,
      textWritten: false,
    },
  });
  dispatch({ type: ActionType.SET_FETCHING_MESSAGE, payload: true });

  // If state.socketHandler isn't a SocketHandler instance yet, create one
  if (!(state.socketHandler instanceof SocketHandler)) {
    state.socketHandler = new SocketHandler();
  }

  state.socketHandler.setDispatch(dispatch, state);

  // Use the SocketHandler instance to send the message
  state.socketHandler.sendMessage(state, userInput, walletAddress, value);
}
