// ----------------------
// External/Internal Packages
// ----------------------
import React, { useState, useEffect, useMemo } from "react";
import blockies from "ethereum-blockies-base64";
import { useDisconnect } from "wagmi";
import { useAccount } from "wagmi";
import { useWeb3ModalState } from "@web3modal/wagmi/react";

// ----------------------
// Interfaces and Assets
// ----------------------
import { Action, ActionType } from "../../state/interfaces";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { BiCopy } from "react-icons/bi";
import defaultAvatar from "../../assets/images/defaultAvatar.png";
import cross from "../../assets/images/cross.svg";

// ----------------------
// Utility Functions
// ----------------------
import { useEscapeKey } from "../../utils/utility/useEscapeKey";
import { copyToClipboard } from "../../utils/utility/copyToClipboard";
import { redirectToChainExplorer } from "../../utils/utility/redirectToChainExplorer";
import { useHandleResize } from "../../utils/utility/useHandleResize";

// ----------------------
// Constants
// ----------------------
interface AccountProps {
  dispatch: React.Dispatch<Action>;
}

function Account(props: AccountProps) {
  const { address } = useAccount();
  const { selectedNetworkId } = useWeb3ModalState();
  const { disconnect } = useDisconnect();

  const chainId =
    selectedNetworkId !== undefined ? parseInt(selectedNetworkId) : undefined;

  const isActive = address !== undefined;

  const avatar = useMemo(() => {
    return address ? blockies(address) : defaultAvatar;
  }, [address]);

  const [isVisible, setIsVisible] = useState(false);
  const desktopSize = useHandleResize(410, "small", "default");

  useEffect(() => {
    const handleVisibility = () => {
      setIsVisible(true);
    };

    handleVisibility();
  }, []);

  useEscapeKey(props.dispatch, {
    type: "SET_ACCOUNT_WINDOW_ACTIVE",
    payload: false,
  });

  const closeWindow = async (shouldDisconnect: boolean) => {
    props.dispatch({
      type: ActionType.SET_ACCOUNT_WINDOW_ACTIVE,
      payload: false,
    });
    props.dispatch({
      type: ActionType.SET_WEB3_LOGIN,
      payload: false,
    });
    if (shouldDisconnect) {
      disconnect();
    }
  };

  const buttonStyle = "flex items-center gap-[5px]";

  return (
    <div className=" w-full h-full flex items-center justify-center bg-[#050934] bg-opacity-[75%] fixed z-[500]">
      <div
        className={`bg-[white] w-[90%] sm450:w-[450px] h-[270px] p-[20px] border-2 shadow-lg rounded-lg flex flex-col gap-[20px] justify-between
        transition-all duration-100 ease-in-out transform-gpu ${
          isVisible ? "scale-100" : "scale-[90%]"
        } 
        transition-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);`}
      >
        <div className="flex flex-col gap-[30px]">
          <div className="w-full flex justify-between items-center">
            <p className="font-semibold text-[18px]">Account</p>
            <button
              className="w-[25px] border-[#0023EF] border-[1px]"
              onClick={() => {
                props.dispatch({
                  type: "SET_ACCOUNT_WINDOW_ACTIVE",
                  payload: false,
                });
              }}
            >
              <img alt="Cross" src={cross} className="h-full w-full" />
            </button>
          </div>
          <div className="border rounded-2xl p-[15px] flex flex-col gap-[15px]">
            <div className="flex w-full justify-between items-center">
              <p className="font-semibold text-[14px] sm450:text-[18px]">
                Connected with
              </p>
              <button
                onClick={() => closeWindow(true)}
                className="bg-[red] bg-opacity-[70%] text-[#fff] text-[14px] sm450:text-[16px] px-[10px] py-[5px] rounded-md font-bold"
              >
                Disconnect
              </button>
            </div>
            {address && (
              <div className="flex items-center gap-[8px] font-bold text-[26px]">
                <img
                  src={avatar}
                  alt="avatar"
                  className="rounded-full w-[30px]"
                />
                <p>{address.slice(0, 4) + "..." + address.slice(38, 42)}</p>
              </div>
            )}
            <div className="flex justify-between items-center font-bold">
              <button
                onClick={(e) => copyToClipboard(e, address)}
                className={buttonStyle}
              >
                <BiCopy />
                <p className="text-[12px] sm450:text-[16px]">Copy Address</p>
              </button>
              <button
                onClick={(e) =>
                  redirectToChainExplorer(e, address, chainId, isActive)
                }
                className={buttonStyle}
              >
                <BsBoxArrowUpRight />
                <p className="text-[12px] sm450:text-[16px]">Block Explorer</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Account;
