import React, {
  useCallback,
  SVGProps,
  useRef,
  useEffect,
  useState,
  useMemo,
} from "react";
import { Action, ActionType, State } from "../../state/interfaces";
import MobileApplications from "./MobileApplications";
import MenuOptionParagraph from "./MenuOptionParagraph";
import Web2AccountButtons from "../Navbar/Web2Account/Web2AccountButtons";
import PaybackMobile from "./Payback/PaybackMobile/PaybackMobile";
import applicationsIconBlack from "../../assets/images/mobileOurApplicationsIconBlack.svg";
import paybackIcon from "../../assets/images/mobilePaybackIcon.svg";
import logoutIcon from "../../assets/images/mobileLogOutIcon.svg";
import { useDisconnect } from "wagmi";
import { handleLogOut } from "../../utils/api/handleLogOut";
import avatar from "../../assets/images/avatarProfile.svg";
import profileIcon from "../../assets/images/mobileProfileIcon.svg";
import { handleOpenProfile } from "../../utils/utility/handleOpenProfile";
import { useAccount } from "wagmi";
import blockies from "ethereum-blockies-base64";

interface MobileMenuProps {
  state: State;
  dispatch: React.Dispatch<Action>;
  navbarRef: React.RefObject<HTMLDivElement>;
}

type IconProps = SVGProps<SVGSVGElement>;

function MobileMenu({ state, dispatch, navbarRef }: MobileMenuProps) {
  const outerDivRef = useRef<HTMLDivElement>(null);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isUpdatingWallet, setIsUpdatingWallet] = useState(false);

  const { disconnect } = useDisconnect();
  const { address } = useAccount();

  const isUpdatingWalletRef = useRef(isUpdatingWallet);

  useEffect(() => {
    isUpdatingWalletRef.current = isUpdatingWallet;
  }, [isUpdatingWallet]);

  const handleResize = useCallback(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    const web3Login = () => {
      if (address && isUpdatingWallet) {
        dispatch({ type: ActionType.SET_WEB3_LOGIN, payload: true });
      }
    };

    if (isUpdatingWallet) {
      web3Login();
    }

    return () => {
      if (isUpdatingWallet) {
        setIsUpdatingWallet(false);
      }
    };
  }, [address, isUpdatingWallet]);

  const handleOpenAccountWindow = () => {
    dispatch({
      type: ActionType.SET_ACCOUNT_WINDOW_ACTIVE,
      payload: true,
    });
  };

  const web3Avatar = useMemo(() => {
    return address ? blockies(address) : avatar;
  }, [address]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        outerDivRef.current &&
        !outerDivRef.current.contains(event.target as Node) &&
        !(
          navbarRef.current && navbarRef.current.contains(event.target as Node)
        ) &&
        windowWidth <= 640 &&
        !isUpdatingWalletRef.current
      ) {
        dispatch({
          type: ActionType.SET_HAMBURGER_OPEN,
          payload: false,
        });
      }
    };

    // Adding the click event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Cleaning up the click event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch, windowWidth, navbarRef, outerDivRef]); // Don't forget to add all dependencies

  const openPayback = () => {
    dispatch({ type: ActionType.SET_PAYBACK_ACTIVE_SCREEN, payload: "" });
    dispatch({ type: ActionType.SET_PAYBACK_PAGE_ACTIVE, payload: true });
    dispatch({
      type: ActionType.SET_MOBILE_APPLICATIONS_SCREEN,
      payload: false,
    });
  };

  const openApplications = () => {
    dispatch({
      type: ActionType.SET_MOBILE_APPLICATIONS_SCREEN,
      payload: true,
    });
    dispatch({ type: ActionType.SET_PAYBACK_PAGE_ACTIVE, payload: false });
  };

  const logOutUser = () => {
    disconnect();
    handleLogOut(dispatch);
  };

  const emailPrefix = state.web2Email.split("@")[0];
  const displayEmail =
    emailPrefix.length > 12
      ? emailPrefix.substring(0, 12) + "..."
      : emailPrefix;

  const openProfileSettings = () => {
    handleOpenProfile(dispatch);
  };

  return state.mobileApplicationsScreen ? (
    <MobileApplications state={state} dispatch={dispatch} />
  ) : state.paybackPageActive ? (
    <PaybackMobile state={state} dispatch={dispatch} />
  ) : (
    <div
      ref={outerDivRef}
      className="w-full h-full bg-[#F7F8FD] flex items-start flex-col gap-[30px]"
    >
      {state.web3Login && !state.web2Login && address && (
        <div className="flex items-center gap-[10px] bg-[#fff] w-full h-[80px] px-[15px] border-y-[1px] border-[#CECDDA]">
          <img
            src={web3Avatar}
            onClick={handleOpenAccountWindow}
            alt="User Avatar"
            className="w-[40px] h-[40px] rounded-full bg-[#cacaca] cursor-pointer"
          />

          <p
            onClick={handleOpenAccountWindow}
            className="sm900:hidden text-primary-blue text-[20px] font-bold cursor-pointer"
          >
            {address ? `${address.slice(0, 6)}...${address.slice(-6)}` : ""}
          </p>
        </div>
      )}
      {!state.web2Login ? (
        <div className="w-full flex items-center justify-center ">
          <Web2AccountButtons
            state={state}
            dispatch={dispatch}
            mobile={true}
            setIsUpdatingWallet={setIsUpdatingWallet}
          />
        </div>
      ) : (
        <div className="flex items-center gap-[10px] bg-[#fff] w-full h-[80px] px-[15px] border-y-[1px] border-[#CECDDA]">
          <img
            src={
              state.userAvatar === ""
                ? avatar
                : `${state.userAvatar}?${new Date().getTime()}`
            }
            alt="User Avatar"
            className="w-[40px] h-[40px] rounded-full bg-[#cacaca]"
          />

          <p className="text-primary-blue text-[20px] font-bold">
            {displayEmail}
          </p>
        </div>
      )}

      <div className="w-full flex flex-col items-start justify-start gap-[30px]">
        <MenuOptionParagraph
          onClick={openApplications}
          text="Our Applications"
          highlighted={false}
          fontSize={18}
          icon={applicationsIconBlack}
        />
        {state.web2Login && (
          <MenuOptionParagraph
            onClick={openPayback}
            text="Your Payback"
            highlighted={false}
            fontSize={18}
            icon={paybackIcon}
          />
        )}
        {state.web2Login && (
          <MenuOptionParagraph
            onClick={openProfileSettings}
            text="Profile Settings"
            highlighted={false}
            fontSize={18}
            icon={profileIcon}
          />
        )}
        {state.web2Login && (
          <MenuOptionParagraph
            onClick={logOutUser}
            text="Log Out"
            highlighted={false}
            fontSize={18}
            icon={logoutIcon}
          />
        )}
      </div>
    </div>
  );
}

export default MobileMenu;
