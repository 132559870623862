// ----------------------
// Packages
// ----------------------
import React, { useState } from "react";
import { useHandleResize } from "../../../utils/utility/useHandleResize";

// ----------------------
// Interfaces
// ----------------------
import { Action, ActionType } from "../../../state/interfaces";
import SingleContract from "./SingleContract";

interface ContractInfoProps {
  dispatch: React.Dispatch<Action>;
  content: Array<string>;
}

function ContractInfo({ dispatch, content }: ContractInfoProps) {
  const [hover, setHover] = useState(false);

  const desktopSize = useHandleResize(900, "small", "default");

  const [id, type, partyA, partyB, date] = content;

  const handleClick = () => {
    dispatch({ type: ActionType.SET_ID_OF_CHOSEN_CONTRACT, payload: id });
    if (type === "SA" || type === "NDA" || type === "PoAG" || type === "PoAS") {
      dispatch({ type: ActionType.SET_TYPE_OF_CHOSEN_CONTRACT, payload: type });
    }
    dispatch({ type: ActionType.SET_CONTRACT_CHOSEN, payload: true });
  };
  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={handleClick}
      className="cursor-pointer flex items-center justify-between gap-[3px] sm:gap-[10px] border-2 border-transparent border-opacity-[50%]"
    >
      <SingleContract hover={hover} width={10} text={type} />
      <SingleContract
        hover={hover}
        width={desktopSize === "small" ? 30 : 35}
        text={partyA}
      />
      <SingleContract
        hover={hover}
        width={desktopSize === "small" ? 30 : 35}
        text={partyB}
      />
      <SingleContract
        hover={hover}
        width={desktopSize === "small" ? 30 : 20}
        text={date}
      />
    </div>
  );
}

export default ContractInfo;
