// ----------------------
// Packages
// ----------------------
import { useRef } from "react";
import { PuffLoader } from "react-spinners";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import { useAccount } from "wagmi";

// ----------------------
// Assets
// ----------------------
import { ReactComponent as DoubleArrow } from "../../../assets/images/doubleArrow.svg";

// ----------------------
// Utility Functions
// ----------------------
import { fetchSummary } from "../utils/fetchSummary";

// ----------------------
// Interfaces
// ----------------------
import { Action, State } from "../../../state/interfaces";

interface SummaryDivProps {
  state: State;
  fetchingSummary: boolean;
  messageForSummary: any;
  dispatch: React.Dispatch<Action>;
  setFetchingSummary: React.Dispatch<React.SetStateAction<boolean>>;
  mobile: boolean;
}

function SummaryDiv({
  state,
  fetchingSummary,
  messageForSummary,
  dispatch,
  setFetchingSummary,
  mobile,
}: SummaryDivProps) {
  const { address } = useAccount();

  const containerRef = useRef<HTMLDivElement>(null);
  const scrollAmount = 50;

  const scrollUp = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop -= scrollAmount;
    }
  };

  const scrollDown = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop += scrollAmount;
    }
  };

  return (
    <div className="bg-[#fff] xl:w-[500px] xl:h-[360px] lg:w-[450px] lg:h-[300px] md:w-[400px] md:h-[340px] sm:w-[500px]  w-full h-[380px] p-[30px] rounded-md shadow-sm flex flex-col justify-between items-center relative md:mr-[40px] ">
      {!mobile && (
        <>
          <div className="md:top-[42%] md:right-[-7%] bg-[#F7F8FD] h-[80px] w-[80px] rounded-full absolute top-[92%]"></div>
          <div
            className={`absolute w-[50px] h-[50px] md:top-[47%] md:right-[-8%] lg:top-[47%] lg:right-[-10%] top-[96%] rotate-90 md:rotate-0 ${
              state.paymentActive ? "hidden" : ""
            }`}
          >
            <DoubleArrow className="w-full h-full" />
          </div>
        </>
      )}
      {state.summary !== "" && (
        <FaChevronUp onClick={scrollUp} className="cursor-pointer mb-[10px]" />
      )}
      <div
        ref={containerRef}
        className={`overflow-hidden ${
          state.summary !== "" ? "h-[200px] max-w-[380px]" : ""
        } `}
      >
        {state.summary === "" ? (
          <p className="opacity-40">Here you will see your contract summary</p>
        ) : (
          <p>
            {state.summary !== undefined
              ? state.summary
              : "Here you will see your contract summary"}
          </p>
        )}
      </div>
      {state.summary !== "" && (
        <FaChevronDown
          onClick={scrollDown}
          className="cursor-pointer mt-[10px]"
        />
      )}
      {fetchingSummary && <PuffLoader />}
      <button
        onClick={() => {
          fetchSummary(
            dispatch,
            fetchingSummary,
            messageForSummary,
            setFetchingSummary,
            address
          );
        }}
        className="mr-auto bg-refresh-summary-button py-[10px] font-TT tracking-wide text-[#0023EF] border-[#0023EF] border-[1px] font-semibold shadow-md rounded-[2px] w-full"
      >
        Refresh Summary
      </button>
    </div>
  );
}

export default SummaryDiv;
