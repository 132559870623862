import React from "react";
import vaiPaybackIcon from "../../../../../assets/images/vaiPaybackIcon.svg";
import paybackIcon from "../../../../../assets/images/paybackIcon.svg";
import PaymentPaybackDropDown from "./PaymentPaybackDropdown";

interface PaymentPaybackProps {
  paymentChosen: string;
  dropdownOpen: boolean;
  setDropDownOpen: React.Dispatch<React.SetStateAction<boolean>>;
  methodChosen: string;
  setMethodChosen: React.Dispatch<React.SetStateAction<string>>;
  setPaybackActive: React.Dispatch<React.SetStateAction<boolean>>;
  paybackActive: boolean;
}

function PaymentPayback({
  paymentChosen,
  dropdownOpen,
  setDropDownOpen,
  methodChosen,
  setMethodChosen,
  setPaybackActive,
  paybackActive,
}: PaymentPaybackProps) {
  const choosePayment = () => {
    setPaybackActive(!paybackActive);
  };

  return (
    <button
      onClick={choosePayment}
      className={`bg-[#ffff] px-[20px] md:px-[40px] py-[20px] h-[90px] cursor-pointer flex justify-between items-center border border-transparent hover:border-[gray] hover:shadow-xl hover:border-opacity-[20%] ${
        paybackActive ? "border-gray border-opacity-[20%] shadow-xl" : ""
      } ${!paybackActive && paymentChosen !== "" ? "opacity-[50%]" : ""}`}
    >
      <div className="flex items-center gap-[40px]">
        <p className="font-TT tracking-wide md:text-[18px]">Payback</p>
        {paybackActive && (
          <PaymentPaybackDropDown
            dropdownOpen={dropdownOpen}
            setDropDownOpen={setDropDownOpen}
            methodChosen={methodChosen}
            setMethodChosen={setMethodChosen}
          />
        )}
      </div>
      {paybackActive &&
        (methodChosen === "Payback" ? (
          <div className="w-[35px] sm:w-[48px]">
            <img src={paybackIcon} className="w-full h-full" />
          </div>
        ) : methodChosen === "Points" ? (
          <div className="w-[35px] sm:w-[48px]">
            <img src={vaiPaybackIcon} className="w-full h-full" />
          </div>
        ) : null)}
    </button>
  );
}

export default PaymentPayback;
