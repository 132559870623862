import React, { useCallback, useRef } from "react";
import { BeatLoader } from "react-spinners";
import { useStep } from "../../../utils/utility/useStep";
import { Action, State, ActionType } from "../../../state/interfaces";
import { stepsData } from "../../ProgressBar/Progress/constants";
import { ReactComponent as Document } from "../../../assets/images/documentBlack.svg";
import { ReactComponent as Restart } from "../../../assets/images/restartBlack.svg";
import { ReactComponent as SoundOffIcon } from "../../../assets/images/soundOffIconBlack.svg";
import { ReactComponent as SoundOnIcon } from "../../../assets/images/soundOnIconBlack.svg";
import { handleRestartBot } from "../../../utils/utility/handleRestartBot";
import handleToastError from "../../../utils/utility/handleToastError";

interface MobileStepsProps {
  state: State;
  dispatch: React.Dispatch<Action>;
}

function MobileSteps({ state, dispatch }: MobileStepsProps) {
  const animationLock = useRef(false);

  const step = useStep(state);
  const SoundIcon = state.soundOn ? SoundOnIcon : SoundOffIcon;

  const restartBot = useCallback(() => {
    if (state.conversationStarted) {
      if (state.dialogIsRestarting) {
        handleToastError("Wait for the restart to complete first!");
      } else {
        dispatch({
          type: ActionType.SET_DIALOG_IS_RESTARTING,
          payload: true,
        });
        handleRestartBot(state, dispatch);
      }
    } else {
      handleToastError("You must start the conversation first!");
    }
  }, [dispatch, state]);

  const handleAgreement = useCallback(() => {
    dispatch({ type: ActionType.SET_PDF_READER, payload: true });
  }, [dispatch]);

  const toggleSound = () => {
    if (animationLock.current) return;
    const isSafari = () => {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    };

    if (isSafari()) {
      handleToastError("Text-to-speech is not available on this browser!");
      return;
    } else {
      animationLock.current = true;
      dispatch({
        type: ActionType.SET_SOUND_ON,
        payload: !state.soundOn,
      });
      setTimeout(() => {
        animationLock.current = false;
      }, 500);
    }
  };

  return (
    <div className="flex space-x-1 w-full px-[20px] flex-col gap-[20px]">
      <div className="flex items-center justify-center gap-[20px] pt-[10px]">
        <button onClick={handleAgreement}>
          <Document className="w-[20px] h-[20px]" />
        </button>
        <button onClick={restartBot}>
          <Restart className="w-[20px] h-[20px]" />
        </button>
        <button onClick={toggleSound}>
          <SoundIcon className="w-[20px] h-[20px]" />
        </button>
      </div>
      <div className="w-full flex gap-[5px]">
        {Array.from({ length: 7 }).map((_, idx) => (
          <div
            key={idx}
            className={`h-[10px] w-[24%] rounded ${
              idx < step ? "bg-connect-button" : "bg-gray-400"
            }`}
          ></div>
        ))}
      </div>
      <div className="w-full justify-between items-center flex">
        <p className="font-bold">{stepsData[step - 1].label}</p>
        <BeatLoader size={6} />
      </div>
    </div>
  );
}

export default MobileSteps;
