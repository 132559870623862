import React, { useState, ChangeEvent, MouseEvent } from "react";
import sendIcon from "../../assets/images/sendIconBlue.svg";
import { handleProfileSupport } from "../../utils/api/handleProfileSupport";

const ProfileSupport: React.FC = () => {
  const [message, setMessage] = useState<string>("");

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };

  const handleClick = async () => {
    await handleProfileSupport(message);
    setMessage("");
  };

  return (
    <div className="bg-[#F7F8FD] w-full py-[20px] px-[10px] sm500:px-[20px]">
      <div className="flex flex-col gap-[20px] font-TT tracking-wide bg-[#fff] px-[20px] py-[15px] rounded-[6px] shadow-sm border-[#E5E4F0] border-[1px]">
        <p className="font-bold">Support</p>
        <p className="font-semibold">
          Any questions? Contact us using the form below.
        </p>
        <textarea
          placeholder="Hello, how can we help you?"
          className="bg-profile-background-gradient px-[20px] py-[10px] w-full h-[100px] outline-none"
          value={message}
          onChange={handleChange}
        />
        <div className="flex items-center justify-center w-full">
          <button
            className="bg-second-connect-button flex text-[#0023EF] tracking-wide items-center justify-center gap-[8px] h-[40px] w-[150px] text-[18px] font-bold"
            onClick={handleClick}
          >
            <p>Send</p>
            <img src={sendIcon} alt="Send" className="mt-[4px]" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileSupport;
