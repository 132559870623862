// ----------------------
// Assets
// ----------------------
import dzin from "../../assets/images/dzin.svg";
// ----------------------
// Styles
// ----------------------
import "./slick.css";

function FirstSlide() {
  return (
    <div className="slider-item gap-[20px] sm:gap-[60px] px-[20px] md:px-[40px] sm:ml-[50px] h-[100%] sm:h-[400px] lg:h-[500px]">
      <div className=" w-[120px] sm400:w-[150px] sm:w-[150px] md:w-[200px] lg:w-[250px] flex-shrink-0">
        <img src={dzin} className="w-full h-full" alt="Dzin Icon" />
      </div>
      <div className="flex flex-col w-full items-center">
        <div className="bg-[#F7F8FD] mb-[10px] p-[20px] max-w-[450px] rounded-[6px]">
          <p className="font-bold text-[14px] md:text-[16px]">
            Welcome to AI Legal Assistant! My name is VAIOT and I'll help you
            create a service delivery agreement in no time!
          </p>
        </div>
        <div className="bg-[#F7F8FD] p-[20px] font-bold text-[14px] md:text-[16px] max-w-[450px] rounded-[6px]">
          <p>Please make sure that your sound is 'on' so you can hear me.</p>
          <br />
          <p>During our chat you can also answer by clicking or typing.</p>
        </div>
      </div>
    </div>
  );
}

export default FirstSlide;
