// ----------------------
// Packages
// ----------------------
import React from "react";

interface AccountCreationEmailInputProps {
  id: string;
  label: string;
  className: string;
  type: string;
  isValid: boolean;
  errorMessage?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  autoComplete: string;
}

const AccountCreationEmailInput: React.FC<AccountCreationEmailInputProps> = ({
  id,
  label,
  type,
  className,
  onChange,
  onBlur,
  isValid,
  errorMessage,
  autoComplete,
}) => {
  return (
    <div className="flex flex-col gap-[2px] h-[100px] w-full">
      <label
        htmlFor={id}
        className="text-[#050934] font-bold text-[12px] font-TT tracking-wide"
      >
        {label}
      </label>
      <input
        id={id}
        type={type}
        className={`rounded-[6px] ${
          isValid ? "border-[#CECDDA]" : "border-red-500"
        } border-[1px] w-full h-[48px] text-[14px] outline-none px-[10px] ${className}`}
        onChange={onChange}
        onBlur={onBlur}
        autoComplete={autoComplete}
      />
      {!isValid && errorMessage && (
        <p className="text-red-500 text-sm mt-1">{errorMessage}</p>
      )}
    </div>
  );
};

export default AccountCreationEmailInput;
