import { useEffect } from "react";
import SocketHandlerReader from "../../../utils/sockets/handleSocketsReader";
import { Action, ActionType, State } from "../../../state/interfaces";

export const useSnarkStatus = (
  setCurrent: React.Dispatch<React.SetStateAction<number>>,
  dispatch: React.Dispatch<Action>,
  setCertificateContractAddress: React.Dispatch<React.SetStateAction<string>>,
  state: State,
  setError: React.Dispatch<React.SetStateAction<boolean>>,
  error: boolean
) => {
  useEffect(() => {
    // The callback function to handle the received steps.
    const handleNewStatus = (data: any) => {
      console.log("Received data from backend", data);
      if (!error) {
        switch (data.status) {
          case "Circuit Definition":
            setCurrent(0);
            return;
          case "Circuit Compilation":
            setCurrent(1);
            return;
          case "Ceremonial Setup Phase":
            setCurrent(2);
            return;
          case "Witness Computation":
            setCurrent(3);
            return;
          case "Proof Construction":
            setCurrent(4);
            return;
          case "Smart Contract Generation":
            setCurrent(5);
            return;
          case "finished":
            dispatch({
              type: ActionType.SET_PROOF_ID,
              payload: data.proofId,
            });
            setCertificateContractAddress(data.contractAddress);

            dispatch({
              type: ActionType.SET_PROOF_JSON,
              payload: data.proof,
            });
            setCurrent(6);
            return;
          case "ERROR":
            setError(true);
            return;
        }
      }
    };

    if (!(state.socketHandlerReader instanceof SocketHandlerReader)) {
      state.socketHandlerReader = new SocketHandlerReader();
    }

    state.socketHandlerReader?.listenToStatusUpdates(handleNewStatus);
  }, []);
};
