// ----------------------
// Packages
// ----------------------
import React, { useState } from "react";

// ----------------------
// Components
// ----------------------
import TypeButton from "./typeButton";

// ----------------------
// Utility Functions
// ----------------------
import { renderButtons } from "./renderButtons";
import { getGeneralFields, getBuyerFields, getSupplierFields } from "./config";
import { ContractTerms, OptionType } from "../../../state/interfaces";

interface SummaryOptionsSAProps {
  fetchingSummary: boolean;
  contractInfo: ContractTerms | undefined;
  setMessageForSummary: React.Dispatch<React.SetStateAction<any[]>>;
  messageForSummary: any[];
  mobile: boolean;
}

function SummaryOptionsSA({
  contractInfo,
  fetchingSummary,
  setMessageForSummary,
  messageForSummary,
  mobile,
}: SummaryOptionsSAProps) {
  const [selectedOption, setSelectedOption] = useState<OptionType>("Supplier");
  const [selectedFields, setSelectedFields] = useState<{
    Supplier: string[];
    Buyer: string[];
    General: string[];
    [key: string]: string[];
  }>({
    Supplier: [],
    Buyer: [],
    General: [],
  });

  const generalFields = getGeneralFields(contractInfo);
  const supplierFields = getSupplierFields(contractInfo);
  const buyerFields = getBuyerFields(contractInfo);

  const renderBasedOnOption = () => {
    switch (selectedOption) {
      case "General":
        return renderButtons({
          fields: generalFields,
          selectedOption,
          contractInfo,
          fetchingSummary,
          messageForSummary,
          setMessageForSummary,
          selectedFields,
          setSelectedFields,
        });
      case "Supplier":
        return renderButtons({
          fields: supplierFields,
          selectedOption,
          contractInfo,
          fetchingSummary,
          messageForSummary,
          setMessageForSummary,
          selectedFields,
          setSelectedFields,
        });
      case "Buyer":
        return renderButtons({
          fields: buyerFields,
          selectedOption,
          contractInfo,
          fetchingSummary,
          messageForSummary,
          setMessageForSummary,
          selectedFields,
          setSelectedFields,
        });
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col md:h-[408px] lg:h-[368px] xl:h-[428px] justify-between sm:mt-[80px]">
      <div className="flex w-full justify-between items-end font-semibold font-TT text-[#050934] tracking-wide">
        <TypeButton
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          text="Supplier"
        />
        <TypeButton
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          text="Buyer"
        />
        <TypeButton
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          text="General"
        />
      </div>

      <div className="bg-[#0023EF0D] overflow-y-auto overflow-x-hidden  simple-shadow-bottom-left  xl:w-[500px] xl:h-[360px] lg:w-[450px] lg:h-[300px] md:w-[400px] md:h-[340px] sm:w-[500px] w-full h-[310px]  p-[30px] rounded-b-md flex flex-col justify-center gap-[20px] items-center relative ">
        <div>
          <div className="flex flex-wrap w-[300px] lg:w-[380px]  gap-[10px]  font-TT text-[14px] ">
            <p
              className={`${
                selectedOption === "General" ? "pt-[100px] md:pt-[0px]" : "pt-0"
              }   lg:pt-0`}
            >
              Add data to the contract summary - click to add, click again to
              remove.
            </p>

            {renderBasedOnOption()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SummaryOptionsSA;
