// ----------------------
// Packages
// ----------------------
import { LazyLoadImage } from "react-lazy-load-image-component";

// ----------------------
// Assets
// ----------------------
import polygonLogo from "../../../../../assets/images/readerPolygonLogo.svg";
import ethereumLogo from "../../../../../assets/images/ethereumLogo.svg";
import chevronDown from "../../../../../assets/images/chevronDown.svg";
import chevronUp from "../../../../../assets/images/chevronUp.svg";

// ----------------------
// Components
// ----------------------
import ImageDropDown from "./ImageDropDown";

interface ChainChosenProps {
  firstDropdownOpen: boolean;
  chainChosen: string;
}

function ChainChosen({ firstDropdownOpen, chainChosen }: ChainChosenProps) {
  return (
    <div className="font-bold flex items-center justify-around w-full">
      <div className="flex items-center gap-[10px]">
        <LazyLoadImage
          alt={`${chainChosen} logo`}
          effect="blur"
          src={chainChosen === "Polygon" ? polygonLogo : ethereumLogo}
          className="w-[18px]"
        />
        <p className="text-[14px]">
          {chainChosen === "Polygon" ? "Mumbai" : "Sepolia"}
        </p>
      </div>
      {!firstDropdownOpen && <ImageDropDown src={chevronDown} />}
      {firstDropdownOpen && <ImageDropDown src={chevronUp} />}
    </div>
  );
}

export default ChainChosen;
