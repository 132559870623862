// ----------------------
// Packages
// ----------------------
import React, { useState, useEffect } from "react";
import { PuffLoader } from "react-spinners";
import { useAccount } from "wagmi";

// ----------------------
// Assets
// ----------------------
import { ReactComponent as Cross } from "../../../../assets/images/cross.svg";

// ----------------------
// Components
// ----------------------
import PaymentTypeDiv from "./OnlinePayments/CreditCardDiv";
import CryptoDiv from "./Crypto/CryptoDiv";
import ReaderPaymentError from "../Error/ReaderPaymentError";
import PayButton from "../Button/PayButton";

// ----------------------
// Utility Functions
// ----------------------
import { useHandleDropDown } from "../../utils/useHandleDropdown";

// ----------------------
// Interfaces
// ----------------------
import { State, Action, ActionType } from "../../../../state/interfaces";
import PaymentPayback from "./PaymentPayback/PaymentPayback";
import AmountDue from "./AmountDue";
import PaybackOptions from "./PaybackOptions/PaybackOptions";
import { getUserDetails } from "../../../../utils/api/getUserDetails";
import { fetchPaybackUserInfo } from "../../../../utils/api/fetchPaybackUserInfo";
import handleToastError from "../../../../utils/utility/handleToastError";

interface PaymentMethodProps {
  setBubbleMessageActive: React.Dispatch<React.SetStateAction<boolean>>;
  setBubbleMessageContent: React.Dispatch<React.SetStateAction<string>>;
  setChainChosen: React.Dispatch<React.SetStateAction<string>>;
  chainChosen: string;
  state: State;
  idOfChosenContract: string;
  dispatch: React.Dispatch<Action>;
  typeOfChosenContract: "SA" | "NDA" | "PoAG" | "PoAS" | "";
}

function PaymentMethod(props: PaymentMethodProps) {
  const [paymentChosen, setPaymentChosen] = useState("");
  const [firstDropdownOpen, setFirstDropdownOpen] = useState(false);
  const [secondDropdownOpen, setSecondDropdownOpen] = useState(false);
  const [paybackDropDownOpen, setPaybackDropDownOpen] = useState(false);
  const [paybackChosen, setPaybackChosen] = useState("");
  const [currencyChosen, setCurrencyChosen] = useState("");
  const [vaiPoints, setVaiPoints] = useState(0);
  const [paybackAmount, setPaybackAmount] = useState(0);
  const [inputValue, setInputValue] = useState<string>("0"); // input value for the payback amount
  const [paybackActive, setPaybackActive] = useState(false);

  const { address } = useAccount();

  useHandleDropDown(setFirstDropdownOpen, firstDropdownOpen, ".first-dropdown");
  useHandleDropDown(
    setSecondDropdownOpen,
    secondDropdownOpen,
    ".second-dropdown"
  );

  useEffect(() => {
    if (
      (props.chainChosen === "Ethereum" && currencyChosen === "MATIC") ||
      (props.chainChosen === "Polygon" && currencyChosen === "ETH")
    ) {
      setCurrencyChosen("");
    }
  }, [props.chainChosen, currencyChosen]);

  const handleClosePayment = () => {
    props.setBubbleMessageActive(false);
    props.setBubbleMessageContent("");
    props.dispatch({
      type: ActionType.SET_PAYMENT_ACTIVE,
      payload: false,
    });
    props.dispatch({
      type: ActionType.SET_READER_PAYMENT_ERROR,
      payload: false,
    });
  };

  useEffect(() => {
    const getPaybackDetails = async () => {
      const userDetails = await getUserDetails();
      if (!userDetails) {
        handleToastError("Unexpected error!");
        return;
      }
      setVaiPoints(userDetails.userDetails.vaiPoints);
      if (address !== undefined) {
        const paybackDetails = await fetchPaybackUserInfo(address);
        setPaybackAmount(parseInt(paybackDetails.balance));
      }
    };
    getPaybackDetails();
  }, []);

  return (
    <div className="w-full h-full flex items-center justify-center bg-[#050934] bg-opacity-[75%] fixed z-50 top-0">
      <div className="bg-[#F7F8FD] w-[95%] md:w-[60%] max-w-[800px] h-[700px] box-border flex flex-col">
        <div className="flex items-center justify-between bg-[#ffff] w-full p-[30px]">
          <p className="font-bold tracking-wider text-[14px] sm:text-[16px]">
            Choose payment method
          </p>
          {!props.state.readerPaymentActive && (
            <div className="w-[20px] sm:w-[25px] border-[1px] border-[#0023EF] cursor-pointer">
              <Cross className="w-full h-full" onClick={handleClosePayment} />
            </div>
          )}
        </div>
        {props.state.readerPaymentError && (
          <ReaderPaymentError dispatch={props.dispatch} />
        )}
        <div className="flex flex-col items-center justify-around gap-[50px] h-full overflow-y-auto">
          {!props.state.readerPaymentActive ? (
            <div
              className={`px-[10px] sm:px-[30px] flex flex-col gap-[20px] ${
                paybackActive && paybackChosen !== ""
                  ? "mt-[310px]"
                  : "mt-[50px]"
              }  w-full max-w-[700px] `}
            >
              <CryptoDiv
                paymentChosen={paymentChosen}
                setPaymentChosen={setPaymentChosen}
                setCurrencyChosen={setCurrencyChosen}
                setChainChosen={props.setChainChosen}
                setSecondDropdownOpen={setSecondDropdownOpen}
                setFirstDropdownOpen={setFirstDropdownOpen}
                secondDropdownOpen={secondDropdownOpen}
                firstDropdownOpen={firstDropdownOpen}
                chainChosen={props.chainChosen}
                currencyChosen={currencyChosen}
                inputValue={inputValue}
                paybackActive={paybackActive}
              />
              <PaymentTypeDiv
                type="Online Payments"
                paymentChosen={paymentChosen}
                setPaymentChosen={setPaymentChosen}
                paybackActive={paybackActive}
                inputValue={inputValue}
              />
              <PaymentPayback
                paymentChosen={paymentChosen}
                dropdownOpen={paybackDropDownOpen}
                setDropDownOpen={setPaybackDropDownOpen}
                methodChosen={paybackChosen}
                setMethodChosen={setPaybackChosen}
                paybackActive={paybackActive}
                setPaybackActive={setPaybackActive}
              />
              {paybackActive && paybackChosen !== "" ? (
                <PaybackOptions
                  vaiPoints={vaiPoints}
                  paybackAmount={paybackAmount}
                  paybackChosen={paybackChosen}
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                />
              ) : (
                <AmountDue />
              )}
            </div>
          ) : (
            <div className="h-[49%] w-full flex items-center justify-center">
              <PuffLoader color="#2973b6" size={100} />
            </div>
          )}
          <PayButton
            paymentChosen={paymentChosen}
            chainChosen={props.chainChosen}
            currencyChosen={currencyChosen}
            dispatch={props.dispatch}
            setBubbleMessageContent={props.setBubbleMessageContent}
            state={props.state}
            idOfChosenContract={props.idOfChosenContract}
            paybackChosen={paybackChosen}
            inputValue={inputValue}
            paybackActive={paybackActive}
            vaiPoints={vaiPoints}
            paybackAmount={paybackAmount}
            typeOfChosenContract={props.typeOfChosenContract}
          />
        </div>
      </div>
    </div>
  );
}

export default PaymentMethod;
