// ----------------------
// Assets
// ----------------------
import { ImCheckmark } from "react-icons/im";

interface InputFieldProps {
  name: string;
  value: string;
  iterator: string;
}
function InputField(props: InputFieldProps) {
  return (
    <div className="grid sm:grid-cols-3 grid-cols-1 w-full gap-x-8 gap-y-4 px-[20px] py-[10px] bg-[#373a5d] mb-[5px] items-center">
      <div className="flex sm:items-center sm:gap-6 gap-2 sm:justify-start justify-center items-center">
        <div
          className={`border-2 border-[gray] w-[16px] h-[16px] mt-[2px] hidden sm:flex justify-center items-center text-gray-500 sm:justify-self-center`}
        >
          <ImCheckmark color="#32a852" />
        </div>

        <p className="text-gray-500 font-bold align-middle hidden sm:block">
          {props.iterator}
        </p>
        <p className="text-[#7682E6] font-semibold align-middle">
          {props.name}
        </p>
      </div>
      <div className="flex justify-center items-center">
        <p className="text-red-500 sm:text-[#373a5d] sm900:text-red-500">.</p>
      </div>
      <div className="flex justify-center items-center">
        <p className="text-green-500 font-semibold">
          {'"' + props.value + '";'}
        </p>
      </div>
    </div>
  );
}

export default InputField;
