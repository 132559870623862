import { Action, ActionType } from "../../state/interfaces";

export const handleOpenLogInModal = (
  dispatch: React.Dispatch<Action>,
  setModalOpen?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (setModalOpen) {
    setModalOpen(false);
  }
  dispatch({
    type: ActionType.SET_LOG_IN_PAGE_ACTIVE,
    payload: true,
  });

  dispatch({
    type: ActionType.SET_LOGIN_MODAL_OPEN,
    payload: false,
  });
};
