// ----------------------
// External/Internal Packages
// ----------------------
import BeatLoader from "react-spinners/BeatLoader";

// ----------------------
// Assets
// ----------------------
import { ReactComponent as DzinAvatar } from "../../assets/images/dzinAvatar.svg";

// ----------------------
// Components
// ----------------------
import UserChat from "./UserChat";

// ----------------------
// Interfaces
// ----------------------
import { State } from "../../state/interfaces";

interface ThinkingChatProps {
  state: State;
}

function ThinkingChat(props: ThinkingChatProps) {
  return (
    <>
      {props.state.userInput !== "" && (
        <UserChat text={props.state.userInput} />
      )}

      <div className="flex gap-[20px] items-end">
        <DzinAvatar />
        <div className="flex flex-col justify-center gap-[5px]">
          <p className="text-[12px]  font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-[#0023EF] via-[#77F3E0] to-[#77F3E0]  tracking-wider">
            VAIOT ADVISOR
          </p>
          <div className="relative p-4 rounded-md chat-box flex items-center justify-center">
            <div
              style={{ opacity: 0.3 }}
              className="flex gap-[10px] items-center"
            >
              {props.state.thinkingMessage !== "" && (
                <p className="text-[black] opacity-[100%]">
                  {props.state.thinkingMessage}
                </p>
              )}
              <BeatLoader color={"#0023EF"} size={6} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ThinkingChat;
