import React, { useState } from "react";
import { Action, ActionType, State } from "../../../state/interfaces";

interface ChangePasswordProps {
  dispatch: React.Dispatch<Action>;
  state: State;
}

function ChangePassword({ dispatch, state }: ChangePasswordProps) {
  const handleDeleteAccount = () => {
    dispatch({
      type: ActionType.SET_CHANGE_PASSWORD_MODAL_ACTIVE,
      payload: true,
    });
  };

  return (
    <div className="bg-[#F7F8FD] w-full py-[20px] px-[10px] sm500:px-[20px]">
      <div className="flex flex-col font-TT gap-[10px] tracking-wide bg-[#fff] px-[20px] py-[15px] rounded-[6px] shadow-sm border-[#E5E4F0] border-[1px]">
        <p className="font-bold">Change Password</p>
        <p className="font-bold">Would you like to change your password?</p>

        <div>
          <button
            onClick={handleDeleteAccount}
            className="text-[#0057FF] font-bold"
          >
            I want to change my password
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
