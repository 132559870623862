import React from "react";
import { Action, ActionType, State } from "../interfaces";

export const adminAccess = (
  token: string | null,
  state: State,
  dispatch: React.Dispatch<Action>
) => {
  if (token !== null && token === process.env.REACT_APP_ADMIN_PASSWORD) {
    if (!state.adminAccess) {
      dispatch({ type: ActionType.SET_ADMIN_ACCESS, payload: true });
    }
  }
};
