// ----------------------
// External/Internal Packages
// ----------------------
import React, { useRef, useEffect } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { useAccount } from "wagmi";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import {
  useSendTransaction,
  useSwitchChain,
  useWaitForTransactionReceipt,
} from "wagmi";
import * as ethers from "ethers";
import { setTransactionStatus } from "./paymentUtils/setTransactionStatus";

// ----------------------
// Utility functions
// ----------------------
import useHandleEmergencyPayment from "./paymentUtils/useEmergencyHandlePayment";
import handleToastError from "../../../utils/utility/handleToastError";
import { fetchTokenPrice } from "../../../utils/api/fetchTokenPrice";

// ----------------------
// Interfaces
// ----------------------
import { State, Action, ActionType } from "../../../state/interfaces";
import { getUserDetails } from "../../../utils/api/getUserDetails";
import { checkIfWalletIsRegistered } from "../../../utils/api/checkIfWalletIsRegistered";

interface PaymentWidgetProps {
  additionalData?: any;
  state: State;
  dispatch: React.Dispatch<Action>;
  id: string;
}

function PaymentWidget(props: PaymentWidgetProps) {
  const [firstCall, setFirstCall] = React.useState(true);
  const [numOfCalls, setNumOfCalls] = React.useState(0);
  const [normalPaymentPending, setNormalPaymentPending] = React.useState(false);

  const { selectedNetworkId } = useWeb3ModalState();
  const { switchChain } = useSwitchChain();
  const { address } = useAccount();

  const {
    data: hash,
    isPending,
    sendTransaction,
    error,
  } = useSendTransaction();

  const isActive = address !== undefined;

  const chainId =
    selectedNetworkId !== undefined ? parseInt(selectedNetworkId) : undefined;

  const { isLoading: isConfirming, isSuccess: isConfirmed } =
    useWaitForTransactionReceipt({
      hash,
    });

  const setButtonState = (
    state: "PAID" | "REVOKED" | "NOT CLICKED",
    txHash = "",
    chain = "",
    id: string,
    dispatch: React.Dispatch<Action>
  ) => {
    dispatch({
      type: "SET_BUTTON_STATE",
      payload: {
        id: id,
        state: state,
        numOfCalls: 0,
        txHash: txHash,
        chain: chain,
      },
    });
  };

  const { resetTranscript } = useSpeechRecognition();

  const hasRun = useRef(false);

  const handleClick = async () => {
    const buttonState = props.state.buttonStates.find(
      (button) => button.id === props.id
    );

    if (buttonState !== undefined && buttonState.state === "PAID") {
      handleToastError("You already paid once!");
      return;
    }
    if (buttonState !== undefined && buttonState.state === "REVOKED") {
      handleToastError("This transaction has been revoked!");

      return;
    }

    if (!isActive) {
      handleToastError("Connect your wallet first!");
      return;
    }

    if (!props.state.web3Login && !props.state.web2Login) {
      handleToastError("Please log in or connect your wallet first!");
      return;
    }

    if (props.state.web2Login) {
      const userDetails = await getUserDetails();
      if (!userDetails) {
        handleToastError("Unexpected error!");
        return;
      }
      const linkedWallet = userDetails.userDetails.walletAddress;
      if (address !== linkedWallet) {
        handleToastError(
          "You can only pay with the wallet linked to your account!"
        );
        return;
      }
    }

    if (!props.state.web2Login && props.state.web3Login && address) {
      const walletRegistered = await checkIfWalletIsRegistered(address);
      if (walletRegistered) {
        handleToastError(
          "Please log in to your account where your wallet is registered!"
        );
        return;
      }
    }

    if (chainId !== 11155111 && props.additionalData.chain === "ethereum") {
      switchChain({ chainId: 11155111 });
      if (chainId === 11155111) {
        finishPayment();
      }
    } else if (chainId !== 80001 && props.additionalData.chain === "polygon") {
      switchChain({ chainId: 80001 });
      if (chainId === 80001) {
        finishPayment();
      }
    } else {
      finishPayment();
    }
  };

  const turnOffListening = () => {
    SpeechRecognition.stopListening();
    resetTranscript();
    props.dispatch({ type: ActionType.SET_LISTENING, payload: false });
  };

  const finishPayment = async () => {
    if (!isActive) {
      handleToastError("Please connect your wallet!");
      return;
    }

    const { currency, total } = props.additionalData;
    turnOffListening();

    setNormalPaymentPending(true);

    const to: `0x${string}` =
      (process.env.REACT_APP_FEE_RECIPIENT_ADDRESS as `0x${string}`) ||
      "0x0000000000000000000000000000000000000000";

    const assetPrice = await fetchTokenPrice(currency);
    const paymentFee = (parseFloat(total) / parseFloat(assetPrice)).toFixed(4);
    const value = ethers.parseEther(
      parseFloat(paymentFee) === 0 ? "0.0006" : paymentFee.toString()
    );

    if (currency === "matic" || currency === "eth") {
      sendTransaction({
        to,
        value,
      });
    }
  };

  useEffect(() => {
    if (isConfirming && hash) {
      props.dispatch({
        type: ActionType.SET_DIALOGUE_PAYMENT_PENDING,
        payload: true,
      });
      const { chain } = props.additionalData;
      let chainCode = chain === "polygon" ? "MATIC " : "ETH";
      const statusMessage = "Transaction is pending";
      const userMessage = "Transaction accepted";
      setButtonState("PAID", hash, chainCode, props.id, props.dispatch);
      setTransactionStatus(
        "PENDING",
        chainCode,
        hash,
        statusMessage,
        userMessage,
        props.state,
        props.dispatch,
        props.id,
        setNumOfCalls,
        numOfCalls,
        setFirstCall,
        firstCall
      );
    }
  }, [isConfirming]);

  useEffect(() => {
    if (error) {
      props.dispatch({
        type: ActionType.SET_DIALOGUE_PAYMENT_PENDING,
        payload: false,
      });
      const statusMessage = "Transaction revoked";
      const userMessage = "Transaction declined";
      setButtonState("REVOKED", "", "", props.id, props.dispatch);
      setTransactionStatus(
        "FAILED",
        "",
        "",
        statusMessage,
        userMessage,
        props.state,
        props.dispatch,
        props.id,
        setNumOfCalls,
        numOfCalls,
        setFirstCall,
        firstCall
      );
    }
  }, [error]);

  // If someone refreshes, check if the payment was pending. If it was, continue calling the backend service
  useHandleEmergencyPayment(
    hasRun,
    numOfCalls,
    setNumOfCalls,
    firstCall,
    setFirstCall,
    props.state,
    props.dispatch,
    props.id,
    normalPaymentPending
  );

  useEffect(() => {
    const currentTxId = localStorage.getItem("txId");

    // If txId doesn't exist in localStorage or it's different from the received one
    if (
      !currentTxId ||
      (props.additionalData.txId && currentTxId !== props.additionalData.txId)
    ) {
      // Save/Update txId to localStorage
      localStorage.setItem("txId", props.additionalData.txId);
    }
  }, [props.additionalData.txId]);

  return (
    <button
      onClick={() => handleClick()}
      disabled={normalPaymentPending}
      className="rounded-md hover:opacity-90 hover:text-[#ccd1f0] text-[#ffff] bg-gradient-button font-bold text-[12px] md:text-[14px] py-[10px]  bg-[#0023EF12] px-[20px]"
    >
      Accept & Pay
    </button>
  );
}

export default PaymentWidget;
