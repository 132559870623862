// ----------------------
// Packages
// ----------------------
import React from "react";
import { handleOpenLogInModal } from "../../../utils/utility/handleOpenLogInModal";
import { handleOpenSignUpModal } from "../../../utils/utility/handleOpenSignUpModal";

// ----------------------
// Assets
// ----------------------
import { ReactComponent as VaiotAvatar } from "../../../assets/images/vaiotAvatarBig.svg";
import { ReactComponent as TextBox } from "../../../assets/images/textBox.svg";
import { ReactComponent as SmallTextBox } from "../../../assets/images/smallTextBox.svg";

// ----------------------
// Interfaces
// ----------------------
import { Action, State } from "../../../state/interfaces";

interface ConnectionScreenProps {
  dispatch: React.Dispatch<Action>;
  state: State;
}

function ConnectionScreen({ dispatch, state }: ConnectionScreenProps) {
  return (
    <div className="bg-[#F7F8FD] h-full w-full flex items-center justify-center">
      <div className="flex">
        <div
          className="flex flex-col w-full justify-between lg:justify-around items-center"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div className="sm:hidden mb-[40px] sm:mb-0 w-[300px] sm450:w-[400px]">
            <SmallTextBox className="w-full h-full" />
          </div>
          <div className="">
            <TextBox className="h-[auto] hidden sm:block sm:w-[450px] sm:md:w-[550px] lg:w-[600px]" />
          </div>
          <div className="w-full flex gap-[40px] ml-[10px] items-center justify-center">
            <button
              onClick={() => handleOpenLogInModal(dispatch)}
              className="bg-connect-button border-[1px] border-[#0023EF12] text-[#fff] text-[18px] h-[60px] w-[150px] lg:w-[180px] font-bold font-TT tracking-wider"
            >
              Log In
            </button>
            <button
              onClick={() => handleOpenSignUpModal(dispatch)}
              className="h-[60px] text-center hover:bg-gray-50  bg-second-connect-button w-[150px] lg:w-[180px] text-[#0023EF] text-[18px] font-bold font-TT tracking-wider"
            >
              Sign Up
            </button>
          </div>
        </div>
        <VaiotAvatar className="h-[auto] hidden sm:block sm:w-[200px] md:w-[300px] lg:w-[400px]" />
      </div>
    </div>
  );
}

export default ConnectionScreen;
