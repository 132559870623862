import { Action, ActionType } from "../../state/interfaces";

export const handleOpenProfile = (
  dispatch: React.Dispatch<Action>,
  setModalOpen?: (value: React.SetStateAction<boolean>) => void
) => {
  dispatch({
    type: ActionType.SET_HAMBURGER_OPEN,
    payload: false,
  });
  dispatch({
    type: ActionType.SET_SIGN_UP_PAGE_ACTIVE,
    payload: false,
  });
  dispatch({
    type: ActionType.SET_PROFILE_PAGE_ACTIVE,
    payload: true,
  });
  dispatch({
    type: ActionType.SET_LOG_IN_PAGE_ACTIVE,
    payload: false,
  });
  if (setModalOpen) {
    setModalOpen(false);
  }
};
