// ----------------------
// External/Internal Packages
// ----------------------
import React from "react";

// ----------------------
// Utility functions
// ----------------------
import { useAccount } from "wagmi";
import { handleVerifyProof } from "../ContractReader/utils/handleVerifyProof";

// ----------------------
// Interfaces
// ----------------------
import { Action } from "../../state/interfaces";

interface VerifyButtonProps {
  dispatch: React.Dispatch<Action>;
  proofJson: any;
}

function VerifyButton(props: VerifyButtonProps) {
  const { address } = useAccount();

  return (
    <div className="w-full items-center justify-center flex mt-[30px]">
      <button
        onClick={() =>
          handleVerifyProof(props.proofJson, props.dispatch, address)
        }
        className="bg-connect-button w-full sm:w-[140px] h-[40px] text-[#fff] text-[18px] md:text-[20px] md:w-[160px] md:h-[50px] font-bold flex items-center justify-center gap-[8px] tracking-wider"
      >
        <p>Verify</p>
      </button>
    </div>
  );
}

export default VerifyButton;
