import React, { useState } from "react";
import Slider from "react-slick";
import PaybackBalanceElements from "../PaybackBalanceElement";
import PaybackMobileSliderElement from "./PaybackMobileSliderElement";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slick.css";

interface PaybackMobileBalanceSliderProps {
  claimedVai: string;
  stakedVai: string;
  vaiPoints: string;
}

function PaybackMobileBalanceSlider({
  claimedVai,
  stakedVai,
  vaiPoints,
}: PaybackMobileBalanceSliderProps) {
  const [activeSlide, setActiveSlide] = useState(0);

  const slider = React.useRef<Slider>(null);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current: number) => setActiveSlide(current),
    arrows: false,
    swipeToSlide: true,
    draggable: true,
  };

  return (
    <div className="w-full">
      <Slider {...settings} ref={slider}>
        <PaybackMobileSliderElement
          bigText={stakedVai}
          smallText="STAKED VAI PAYBACK"
          darkStyle={false}
        />
        <PaybackMobileSliderElement
          bigText={vaiPoints}
          smallText="VAI POINTS"
          darkStyle={false}
        />
        <PaybackMobileSliderElement
          bigText={claimedVai}
          smallText="CLAIMED VAI PAYBACK"
          darkStyle={true}
        />
      </Slider>
    </div>
  );
}

export default PaybackMobileBalanceSlider;
