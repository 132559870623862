// ----------------------
// Packages
// ----------------------
import React from "react";

// ----------------------
// Assets
// ----------------------
import { ReactComponent as Restart } from "../../../assets/images/restart.svg";

// ----------------------
// Utility functions
// ----------------------
import { handleRestartBot } from "../../../utils/utility/handleRestartBot";

// ----------------------
// Interfaces
// ----------------------
import { Action, ActionType, State } from "../../../state/interfaces";
import handleToastError from "../../../utils/utility/handleToastError";

interface RestartIconProps {
  state: State;
  dispatch: React.Dispatch<Action>;
}

function RestartIcon({ state, dispatch }: RestartIconProps) {
  return (
    <button
      onClick={() => {
        if (state.conversationStarted) {
          if (state.dialogIsRestarting) {
            handleToastError("Wait for the restart to complete first!");
          } else {
            dispatch({
              type: ActionType.SET_DIALOG_IS_RESTARTING,
              payload: true,
            });
            handleRestartBot(state, dispatch);
          }
        } else {
          handleToastError("You must start the conversation first!");
        }
      }}
      className="flex items-center gap-[5px] cursor-pointer"
    >
      <div className="w-[16px]">
        <Restart className="w-full h-full" />
      </div>
      <p className="text-[#ffff] hidden lg:block text-[14px]">Start again</p>
      <p className="text-[#ffff] block lg:hidden text-[14px]">Restart</p>
    </button>
  );
}

export default RestartIcon;
