// ----------------------
// Packages
// ----------------------
import { LazyLoadImage } from "react-lazy-load-image-component";

// ----------------------
// Assets
// ----------------------
import chevronRight from "../../../../../assets/images/chevronRight.svg";
import bitcoin from "../../../../../assets/images/bitcoin.svg";

function CryptoNotSelected() {
  return (
    <div className="w-full flex justify-between">
      <div className="flex gap-[8px] items-center">
        <p className="font-TT tracking-wide md:text-[18px]">Crypto Payment</p>
      </div>
      <div className="w-[35px] sm:w-[48px] sm:hidden">
        <img src={bitcoin} className="w-full h-full" alt="Bitcoin Logo" />
      </div>
    </div>
  );
}

export default CryptoNotSelected;
