// ----------------------
// External/Internal Packages
// ----------------------
import React from "react";

// ----------------------
// Components
// ----------------------
import TxSummaryWidget from "./widgets/TxSummaryWidget";
import CalendarWidget from "./widgets/CalendarWidget";
import StripeWidget from "./widgets/StripeWidget";
import PaymentSummaryWidget from "./widgets/PaymentSummaryWidget";
import RedirectWidget from "./widgets/RedirectWidget";

// ----------------------
// Interfaces
// ----------------------
import { Action, State } from "../../state/interfaces";

interface WidgetRendererProps {
  widgetType: string | undefined;
  state: State;
  dispatch: React.Dispatch<Action>;
  messageId: string;
  additionalData?: any;
}

function WidgetRenderer({
  widgetType,
  state,
  dispatch,
  messageId,
  additionalData,
}: WidgetRendererProps) {
  switch (widgetType) {
    case "date":
      return (
        <CalendarWidget
          state={state}
          dispatch={dispatch}
          messageId={messageId}
        />
      );
    case "transactionHashSummary":
      return <TxSummaryWidget additionalData={additionalData} />;
    case "sell":
      return (
        <PaymentSummaryWidget
          state={state}
          dispatch={dispatch}
          id={messageId}
          additionalData={additionalData}
        />
      );
    case "stripe":
      return <StripeWidget state={state} dispatch={dispatch} id={messageId} />;
    case "downloadContract":
      return (
        <RedirectWidget
          url={additionalData?.pdfUrl}
          state={state}
          dispatch={dispatch}
        />
      );
    default:
      return null;
  }
}

export default WidgetRenderer;
