// ----------------------
// External/Internal Packages
// ----------------------
import { useMemo, useCallback } from "react";

// ----------------------
// Components
// ----------------------
import TypingText from "./TypingText";

interface TextRendererProps {
  text?: string | string[];
  textWritten: boolean;
  handleTypingDoneProp: (messageId: string) => void;
  messageState: string;
  messageId: string;
}

function TextRenderer({
  text,
  textWritten,
  handleTypingDoneProp,
  messageState,
  messageId,
}: TextRendererProps) {
  const typingSpeed = useMemo(
    () =>
      process.env.REACT_APP_BOT_TYPING_SPEED === undefined
        ? 1
        : parseInt(process.env.REACT_APP_BOT_TYPING_SPEED),
    []
  );

  const handleTypingDone = useCallback(
    () => handleTypingDoneProp(messageId),
    [handleTypingDoneProp, messageId]
  );

  return (
    <>
      {messageState === "DISPLAYING" && textWritten && (
        <TypingText
          typingSpeed={typingSpeed}
          text={text || ""}
          handleTypingDone={handleTypingDone}
        />
      )}

      {messageState === "DISPLAYED" && !textWritten && (
        <p
          dangerouslySetInnerHTML={{
            __html: Array.isArray(text) ? text.join(" ") : text || "",
          }}
          className="font-semibold text-[14px] sm:text-[16px] tracking-wide"
        />
      )}
    </>
  );
}

export default TextRenderer;
