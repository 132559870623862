// ----------------------
// External/Internal Packages
// ----------------------
import React from "react";

// ----------------------
// Assets
// ----------------------
import ContractReaderLogo from "../../assets/images/contractReaderLogo.svg";
import StakingLogo from "../../assets/images/stakingLogo.svg";
import VerifyContract from "../../assets/images/verifyContractIcon.svg";
import MenuDzin from "../../assets/images/menuDzin.svg";

// ----------------------
// Interfaces
// ----------------------
import { Action, ActionType, State } from "../../state/interfaces";

interface ApplicationsProps {
  dispatch: React.Dispatch<Action>;
  state: State;
  layoutClass: string;
  layoutPadding: string;
  applicationClass: string;
}

function Applications({
  dispatch,
  state,
  layoutClass,
  layoutPadding,
  applicationClass,
}: ApplicationsProps) {
  const handleSwitchToAssistant = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    const resetActions = [
      ActionType.SET_CONTRACT_READER,
      ActionType.SET_VERIFY_PROOF_ACTIVE,
      ActionType.SET_HAMBURGER_OPEN,
      ActionType.SET_MOBILE_APPLICATIONS_SCREEN,
      ActionType.SET_CERTIFICATE_ERROR,
      ActionType.SET_READER_PAYMENT_ERROR,
      ActionType.SET_SIGN_UP_PAGE_ACTIVE,
      ActionType.SET_PROFILE_PAGE_ACTIVE,
      ActionType.SET_LOG_IN_PAGE_ACTIVE,
    ];

    resetActions.forEach((action) =>
      dispatch({ type: action, payload: false })
    );
  };

  const handleSwitchToContractReader = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    const actions = {
      [ActionType.SET_GET_CERTIFICATE]: false,
      [ActionType.SET_DOWNLOAD_CERTIFICATE]: false,
      [ActionType.SET_DECODING_ANIMATION_FINISHED]: false,
      [ActionType.SET_CONTRACT_CHOSEN]: false,
      [ActionType.SET_PAYMENT_FINISHED]: false,
      [ActionType.SET_PAYMENT_ACTIVE]: false,
      [ActionType.SET_CONTRACT_LIST_ANIMATION_FINISHED]: state.contractReader
        ? true
        : false,
      [ActionType.SET_CERTIFICATE_FIELD_CHOICE]: false,
      [ActionType.SET_CERTIFICATE_FIELDS]: [],
      [ActionType.SET_LIST_OF_CERTIFICATES]: false,
      [ActionType.SET_CONTRACT_SUMMARY_ACTIVE]: false,
      [ActionType.SET_SUMMARY]: "",
      [ActionType.SET_VERIFY_PROOF_ACTIVE]: false,
      [ActionType.SET_MOBILE_APPLICATIONS_SCREEN]: false,
      [ActionType.SET_CERTIFICATE_ERROR]: false,
      [ActionType.SET_READER_PAYMENT_ERROR]: false,
      [ActionType.SET_SIGN_UP_PAGE_ACTIVE]: false,
      [ActionType.SET_PROFILE_PAGE_ACTIVE]: false,
      [ActionType.SET_LOG_IN_PAGE_ACTIVE]: false,
    };

    for (const [action, payload] of Object.entries(actions)) {
      dispatch({ type: action as ActionType, payload });
    }

    if (!state.contractReader) {
      dispatch({ type: ActionType.SET_CONTRACT_READER, payload: true });
    }

    dispatch({ type: ActionType.SET_HAMBURGER_OPEN, payload: false });
  };

  const applications = [
    {
      name: "VAIOT Assistant",
      image: MenuDzin,
      onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
        handleSwitchToAssistant(event),
    },
    {
      name: "Contract Reader",
      image: ContractReaderLogo,
      onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
        handleSwitchToContractReader(event),
    },
    {
      name: "Staking",
      image: StakingLogo,
      onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        window.open("https://stake.vaiot.ai/#/", "_blank");
      },
    },
    {
      name: "Verify Proof",
      image: VerifyContract,
      onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        dispatch({
          type: ActionType.SET_CONTRACT_READER,
          payload: false,
        });
        dispatch({ type: ActionType.SET_PROOF_JSON, payload: undefined });
        dispatch({
          type: ActionType.SET_VERIFY_PROOF_ACTIVE,
          payload: true,
        });
        dispatch({ type: ActionType.SET_HAMBURGER_OPEN, payload: false });
        dispatch({
          type: ActionType.SET_MOBILE_APPLICATIONS_SCREEN,
          payload: false,
        });
        dispatch({ type: ActionType.SET_CERTIFICATE_ERROR, payload: false });
        dispatch({ type: ActionType.SET_READER_PAYMENT_ERROR, payload: false });
        dispatch({ type: ActionType.SET_SIGN_UP_PAGE_ACTIVE, payload: false });
        dispatch({ type: ActionType.SET_PROFILE_PAGE_ACTIVE, payload: false });
        dispatch({ type: ActionType.SET_LOG_IN_PAGE_ACTIVE, payload: false });
      },
    },
  ];

  const buttons = applications.map((application) => (
    <button
      key={`application-${application.name}`}
      onClick={application.onClick}
      className="flex flex-col gap-[20px] items-center w-[120px] lg:w-[140px]"
    >
      <div className={applicationClass}>
        <img
          src={application.image}
          className="w-full h-full"
          alt="Application icon"
        />
      </div>
      <p className="lg:text-[18px] text-[14px] sm:text-[16px]">
        {application.name}
      </p>
    </button>
  ));
  return (
    <div className={`flex w-full ${layoutClass} items-center ${layoutPadding}`}>
      {buttons}
    </div>
  );
}

export default Applications;
