import React from "react";
import { ClipLoader } from "react-spinners";

interface PaybackBalanceElementsProps {
  darkStyle: boolean;
  bigText: string;
  smallText: string;
  fetchingBalance: boolean;
}

function PaybackBalanceElements({
  darkStyle,
  bigText,
  smallText,
  fetchingBalance,
}: PaybackBalanceElementsProps) {
  return (
    <div className="flex flex-col items-center justify-center">
      {fetchingBalance ? (
        <ClipLoader size={64} className="mb-[10px]" />
      ) : (
        <p
          className={`text-[54px] md:text-[64px] ${
            darkStyle ? "opacity-10" : "text-[#0023EF]"
          }`}
        >
          {bigText}
        </p>
      )}
      <p className="text-[12px]">{smallText}</p>
    </div>
  );
}

export default PaybackBalanceElements;
