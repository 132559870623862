import { useEffect, useCallback } from "react";
import { ActionType, State, Action } from "../../state/interfaces";
import { handleUserMessage } from "../sockets/handleUserMessage";
import handleToastError from "../utility/handleToastError";
import { wordsToNumbers } from "./wordsToNumber";

const useHandleVoiceInput = (
  state: State,
  dispatch: React.Dispatch<Action>,
  resetTranscript: Function,
  walletAddress: string | null | undefined
) => {
  const handleUserInput = useCallback(
    async (value: string) => {
      dispatch({ type: ActionType.SET_USER_INPUT, payload: "" });
      handleUserMessage(value, dispatch, state, walletAddress);
    },
    [dispatch, state]
  );

  useEffect(() => {
    if (
      state.voiceInput !== "" &&
      (state.messages.slice(-1)[0].type !== "USER" ||
        state.messages.slice(-1)[0].messageTooLong)
    ) {
      let firstLetter = state.voiceInput.charAt(0).toUpperCase();
      let remainingLetters = state.voiceInput.slice(1);

      if (state.voiceInput.length > state.maxMessageLength) {
        resetTranscript();
        handleToastError("Message is too long!");

        dispatch({
          type: "APPEND_MESSAGE",
          payload: {
            text: firstLetter + remainingLetters,
            type: "USER",
            id: "",
            messageState: "DISPLAYED",
            doneReading: false,
            textWritten: false,
            messageTooLong: true,
          },
        });
        dispatch({ type: ActionType.SET_FETCHING_MESSAGE, payload: false });
        dispatch({ type: ActionType.SET_VOICE_INPUT, payload: "" });
        dispatch({ type: ActionType.SET_USER_INPUT, payload: "" });

        return;
      }

      let text = firstLetter + remainingLetters;
      let numArray = wordsToNumbers(text.split(" "));
      let resultString = numArray
        .map((num) => (typeof num === "number" ? num.toString() : num))
        .join(" ");

      dispatch({ type: ActionType.SET_VOICE_INPUT, payload: "" });
      handleUserInput(resultString.toString());
    }
  }, [
    state.voiceInput,
    dispatch,
    handleUserInput,
    state.messages,
    resetTranscript,
  ]);
};

export default useHandleVoiceInput;
