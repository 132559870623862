// ----------------------
// Packages
// ----------------------
import React, { useState } from "react";

// ----------------------
// Assets
// ----------------------
import { ReactComponent as Chevron } from "../../../assets/images/chevronLeft.svg";

// ----------------------
// Components
// ----------------------
import SummaryDiv from "./SummaryDiv";
import SummaryOptionsSA from "./SummaryOptionsSA";
import ApproveButton from "./ApproveButton";

// ----------------------
// Styling and Interfaces
// ----------------------
import {
  Action,
  ActionType,
  State,
  ContractTerms,
} from "../../../state/interfaces";
import "./summary.css";
import MobileSummary from "./MobileSummary";
import SummaryOptionsNDA from "./SummaryOptionsNDA";

interface ContractSummaryProps {
  state: State;
  dispatch: React.Dispatch<Action>;
  contractId: number;
  contractInfo: undefined | ContractTerms;
  typeOfChosenContract: "SA" | "NDA" | "PoAG" | "PoAS" | "";
  contractInfoNDA: ContractInfoNDA | undefined;
}

interface ContractInfoNDA {
  creationTimestamp: string;
  fieldNames: string[];
  fieldValues: string[];
  partyA: string;
  partyB: string;
}

function ContractSummary(props: ContractSummaryProps) {
  const [messageForSummary, setMessageForSummary] = useState<Array<any>>([]);
  const [fetchingSummary, setFetchingSummary] = useState(false);

  const goBack = () => {
    props.dispatch({
      type: ActionType.SET_CONTRACT_SUMMARY_ACTIVE,
      payload: false,
    });
    props.dispatch({ type: ActionType.SET_CERTIFICATE_FIELDS, payload: [] });
    props.dispatch({ type: ActionType.SET_SUMMARY, payload: "" });
  };

  return (
    <div className="flex flex-col gap-[20px] items-center  sm:items-start  w-full h-full py-[40px] md:py-[80px]  px-[20px] sm:px-[50px] max-w-[1500px]">
      <div className="hidden sm:flex flex-col md:flex-row gap-[30px] justify-around items-center w-full ">
        <div>
          <div className="text-[34px] font-semibold font-TT text-[#050934] relative my-[40px]">
            <p>Contract Summary</p>
            <button
              onClick={goBack}
              className="w-[30px] h-[30px] absolute top-[22%] left-[-8%]"
            >
              <Chevron className="w-full h-full" />
            </button>
          </div>
          <SummaryDiv
            state={props.state}
            dispatch={props.dispatch}
            fetchingSummary={fetchingSummary}
            setFetchingSummary={setFetchingSummary}
            messageForSummary={messageForSummary}
            mobile={false}
          />
        </div>
        <div className="w-[170px] font-TT">
          <p className="text-[14px] text-[##050934] opacity-30">You can</p>
          <p className="font-bold">
            Add more descriptions by selecting the boxes on the right
          </p>
        </div>
        {props.typeOfChosenContract === "SA" && (
          <SummaryOptionsSA
            fetchingSummary={fetchingSummary}
            contractInfo={props.contractInfo}
            setMessageForSummary={setMessageForSummary}
            messageForSummary={messageForSummary}
            mobile={false}
          />
        )}
        {(props.typeOfChosenContract === "NDA" ||
          props.typeOfChosenContract === "PoAG" ||
          props.typeOfChosenContract === "PoAS") && (
          <SummaryOptionsNDA
            fetchingSummary={fetchingSummary}
            setMessageForSummary={setMessageForSummary}
            messageForSummary={messageForSummary}
            mobile={false}
            contractInfoNDA={props.contractInfoNDA}
          />
        )}
      </div>
      <div className="sm:hidden w-full">
        <MobileSummary
          state={props.state}
          dispatch={props.dispatch}
          fetchingSummary={fetchingSummary}
          setFetchingSummary={setFetchingSummary}
          messageForSummary={messageForSummary}
          contractInfo={props.contractInfo}
          setMessageForSummary={setMessageForSummary}
          goBack={goBack}
          typeOfChosenContract={props.typeOfChosenContract}
          contractInfoNDA={props.contractInfoNDA}
        />
      </div>
      <div className="w-full flex items-center justify-center my-[30px] pb-[50px]">
        <ApproveButton state={props.state} dispatch={props.dispatch} />
      </div>
    </div>
  );
}

export default ContractSummary;
