function CertificatePrice() {
  return (
    <div className="flex flex-col">
      <p className="text-[16px] sm400:text-[18px] sm:text-[20px] md:text-[24px] lg:text-[28px] font-bold">
        Certificate Price
      </p>
      <p className="text-[32px] sm400:text-[38px] sm:text-[48px] md:text-[52px] lg:text-[56px] font-bold">
        5$
      </p>
    </div>
  );
}

export default CertificatePrice;
