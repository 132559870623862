import axios from "axios";
import handleToastError from "../utility/handleToastError";

interface UserDetailsResponse {
  status: "success";
  userDetails: {
    avatar: string;
    walletAddress: string;
    vaiPoints: number;
    stakingActive: boolean;
    rewardType: "points" | "payback";
    devices: Array<{
      access: number;
      ipAddress: string;
      name: string;
      createdAt: Date;
    }>;
  };
}

export const getUserDetails = async (): Promise<
  UserDetailsResponse | undefined
> => {
  try {
    const response = await axios.get<UserDetailsResponse>(
      `${process.env.REACT_APP_BASE_URL}/users/data`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 403) {
        handleToastError(
          "Access denied! Please log out and log back in to solve this issue."
        );
      } else {
        console.error("Failed to update user wallet:", error);
      }
    } else {
      console.error("An unexpected error occurred:", error);
    }
  }
};
