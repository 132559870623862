// ----------------------
// External/Internal Packages
// ----------------------
import React, { useCallback, SVGProps } from "react";

// ----------------------
// Components
// ----------------------
import ConnectWallet from "../ContractReader/ConnectionScreen/ContractReaderWallet";

// ----------------------
// Interfaces and assets
// ----------------------

import { Action, ActionType, State } from "../../state/interfaces";
import { ReactComponent as Document } from "../../assets/images/documentBlack.svg";
import { ReactComponent as Restart } from "../../assets/images/restartBlack.svg";
import { ReactComponent as SoundOffIcon } from "../../assets/images/soundOffIconBlack.svg";
import { ReactComponent as SoundOnIcon } from "../../assets/images/soundOnIconBlack.svg";

// ----------------------
// Utils functions
// ----------------------

import { handleRestartBot } from "../../utils/utility/handleRestartBot";
import handleToastError from "../../utils/utility/handleToastError";
import MenuOptionParagraph from "./MenuOptionParagraph";

interface SidebarProps {
  dispatch: React.Dispatch<Action>;
  state: State;
}

type IconProps = SVGProps<SVGSVGElement>;

function Sidebar({ dispatch, state }: SidebarProps) {
  const handleAgreement = useCallback(() => {
    dispatch({ type: ActionType.SET_PDF_READER, payload: true });
  }, [dispatch]);

  const isSafari = () => {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  };

  const toggleSound = () => {
    if (!isSafari()) {
      dispatch({
        type: ActionType.SET_SOUND_ON,
        payload: !state.soundOn,
      });
    } else {
      handleToastError("Text-to-speech is not available on this browser!");
    }
  };

  const restartBot = useCallback(() => {
    dispatch({
      type: ActionType.SET_HAMBURGER_OPEN,
      payload: false,
    });
    setTimeout(() => {
      handleRestartBot(state, dispatch);
    }, 500);
  }, [dispatch, state]);

  const soundButtonText = state.soundOn ? "Sound On" : "Sound Off";
  const SoundIcon = state.soundOn ? SoundOnIcon : SoundOffIcon;

  const openPayback = () => {
    dispatch({ type: ActionType.SET_PAYBACK_ACTIVE_SCREEN, payload: "" });
    dispatch({ type: ActionType.SET_PAYBACK_PAGE_ACTIVE, payload: true });
    dispatch({ type: ActionType.SET_OUR_APPLICATIONS_ACTIVE, payload: false });
  };

  const openApplications = () => {
    dispatch({ type: ActionType.SET_OUR_APPLICATIONS_ACTIVE, payload: true });
    dispatch({ type: ActionType.SET_PAYBACK_PAGE_ACTIVE, payload: false });
  };

  const generateButton = (
    onClick: () => void,
    text: string,
    IconComponent: React.FC<IconProps>,
    customClass?: string
  ) => {
    return (
      <button
        onClick={onClick}
        className={`flex gap-[10px] items-center ${customClass || ""}`}
      >
        <p>{text}</p>
        <div className="w-[20px] h-[20px]">
          <IconComponent className="w-full h-full" />
        </div>
      </button>
    );
  };

  return (
    <div className="w-[200px] bg-[#F7F8FD] h-full flex items-start justify-between flex-col gap-[50px] flex-shrink-0">
      <div className="sm:hidden">
        {!state.contractReader && !state.verifyProofActive && (
          <div className="flex flex-col gap-[20px]">
            {generateButton(handleAgreement, "Your Agreement", Document)}
            {generateButton(restartBot, "Restart", Restart)}
            {generateButton(toggleSound, soundButtonText, SoundIcon)}
          </div>
        )}
      </div>

      <div className="w-full flex flex-col items-start justify-start ">
        <MenuOptionParagraph
          onClick={openApplications}
          text="Our Applications"
          highlighted={state.ourApplicationsActive}
          fontSize={14}
        />
        {(state.web2Login || state.web3Login) && (
          <MenuOptionParagraph
            onClick={openPayback}
            text="Your Payback"
            highlighted={state.paybackPageActive}
            fontSize={14}
          />
        )}
      </div>

      <div className="sm:hidden mb-[50px]">
        <ConnectWallet
          dispatch={dispatch}
          state={state}
          text="Connect Wallet"
          customClassName="py-[15px] text-[14px] w-[150px] text-[white]"
        />
      </div>
    </div>
  );
}

export default Sidebar;
