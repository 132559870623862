// Interfaces
import { State } from "../interfaces";

// Hard-coded state
import { initialState } from "../initialState";

export const resetLocalStorageState = (localStorageState: State): State => {
  return {
    ...initialState,
    ...localStorageState,
    dialogState: [],
    certificatePaid: false,
    decodingAnimationFinished: false,
    contractListAnimationFinished: false,
    contractChosen: false,
    paymentFinished: false,
    isSpeaking: false,
    getCertificate: false,
    certificateFieldChoice: false,
    certificateFields: [],
    contractSummaryActive: false,
    readerPaymentActive: false,
    paymentActive: false,
    downloadCertificate: false,
    certificateError: false,
    hashWindowActive: false,
    messages: localStorageState.messages.map((message) => ({
      ...message,
      messageState:
        message.type === "USER" ? message.messageState : "DISPLAYED",
      textWritten: false,
    })),
  };
};
