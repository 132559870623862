import axios from "axios";

export const handleSetPassword = async (token: string, password: string) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/auth/set-password`,
      { token, password },
      {
        withCredentials: true,
      }
    );
  } catch (error) {
    console.error("Error when setting new password:", error);
  }
};
