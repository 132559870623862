// ----------------------
// Packages
// ----------------------
import React from "react";

// ----------------------
// Assets
// ----------------------
import { ReactComponent as Document } from "../../../assets/images/document.svg";
import { stepsData } from "./constants";

// ----------------------
// Components
// ----------------------
import ProgramFeatures from "../Features/ProgramFeatures";
import ProgressStep from "./ProgressStep";
import SoundIcon from "../../Navbar/Icons/SoundIcon";
import RestartIcon from "../../Navbar/Icons/RestartIcon";

// ----------------------
// Utility functions
// ----------------------
import { useStep } from "../../../utils/utility/useStep";

// ----------------------
// Interfaces + styling
// ----------------------
import { State, Action, ActionType } from "../../../state/interfaces";
import "./progressBarStyles.css";

interface ProgressBarProps {
  state: State;
  dispatch: React.Dispatch<Action>;
}

function ProgressBar(props: ProgressBarProps) {
  const step = useStep(props.state);

  const openDocument = () => {
    props.dispatch({ type: ActionType.SET_PDF_READER, payload: true });
  };

  return (
    <div className="hidden sm:flex w-full lg:w-[450px] h-custom-bar ml-auto flex-col justify-between items-center">
      <div className="bg-[#12163E] flex items-center justify-around py-[20px] w-full">
        <RestartIcon dispatch={props.dispatch} state={props.state} />
        <div className="bg-[#E5E4F0] w-[1px] h-[15px] opacity-50"></div>
        <SoundIcon dispatch={props.dispatch} state={props.state} />
      </div>
      <div className="hidden sm:flex flex-col justify-around gap-[20px] h-full py-[10px] px-[20px] md:px-[30px] bg-gradient-to-b from-[#070b36] via-[#080e52] via-50% to-[#182287] text-[#ffff] w-full overflow-y-auto">
        <div className="flex justify-between items-center py-[10px]">
          <p className="font-bold md:text-[22px] lg:text-[24px] text-[18px] tracking-wide">
            Your Agreement
          </p>
          <Document
            onClick={openDocument}
            className="w-[30px] h-[30px] md:w-[40px] md:h-[40px] cursor-pointer"
          />
        </div>
        <div className="flex flex-col justify-between h-full gap-[20px] overflow-y-auto leading-[26px]">
          {stepsData.map((stepData, index) => (
            <ProgressStep
              key={index}
              isActive={step === index + 1}
              isCompleted={step > index + 1}
              label={stepData.label}
              icon={stepData.icon}
            />
          ))}
        </div>
      </div>
      <div className="h-[150px] w-full">
        <ProgramFeatures state={props.state} />
      </div>
    </div>
  );
}

export default ProgressBar;
