// ----------------------
// Packages
// ----------------------
import React from "react";

interface AccountCreationOtherLoginIconProps {
  image: string;
  width: string;
  type?: "apple" | "google";
  onClick?: any;
}

function AccountCreationOtherLoginIcon({
  image,
  width,
  onClick,
  type,
}: AccountCreationOtherLoginIconProps) {
  return onClick ? (
    <button
      onClick={onClick}
      className="border-[1px] border-[#e5e7eb] w-[70px] h-[40px] flex items-center justify-center rounded-md hover:bg-[#ebeaea] cursor-pointer"
    >
      <img style={{ width: width }} src={image} />
    </button>
  ) : (
    <a
      className="border-[1px] border-[#e5e7eb] w-[70px] h-[40px] flex items-center justify-center rounded-md hover:bg-[#ebeaea] cursor-pointer"
      href={`${process.env.REACT_APP_BASE_URL}/auth/${type}`}
    >
      <img style={{ width: width }} src={image} />
    </a>
  );
}

export default AccountCreationOtherLoginIcon;
