import axios from "axios";
import handleToastError from "../../../utils/utility/handleToastError";

interface ContractTerms {
  agreementTerms: Array<any>;
  parties: Array<string>;
  services: Array<Array<string>>;
  onBlockchain: string;
}

interface ContractInfoNDA {
  creationTimestamp: string;
  fieldNames: string[];
  fieldValues: string[];
  partyA: string;
  partyB: string;
}

export const fetchContractById = async (
  idOfChosenContract: string,
  account: string | null | undefined,
  setContractInfo: (
    value: React.SetStateAction<ContractTerms | undefined>
  ) => void,
  typeOfChosenContract: string,
  setContractInfoNDA: React.Dispatch<
    React.SetStateAction<ContractInfoNDA | undefined>
  >
) => {
  if (typeOfChosenContract === "SA") {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/transactions/get-contract",
        {
          owner: account,
          id: parseInt(idOfChosenContract),
        }
      );
      setContractInfo(response.data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 403) {
          handleToastError(
            "Access denied! Please log out and log back in to solve this issue."
          );
        } else {
          console.error("Error sending request to fetch contract:", error);
        }
      } else {
        console.error("An unexpected error occurred:", error);
      }
    }
  } else if (
    typeOfChosenContract === "NDA" ||
    typeOfChosenContract === "PoAG" ||
    typeOfChosenContract === "PoAS"
  ) {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/transactions/get-nda",
        {
          owner: account,
          contractId: parseInt(idOfChosenContract),
        }
      );
      setContractInfoNDA(response.data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 403) {
          handleToastError(
            "Access denied! Please log out and log back in to solve this issue."
          );
        } else {
          console.error("Error sending request to fetch contract:", error);
        }
      } else {
        console.error("An unexpected error occurred:", error);
      }
    }
  }
};
