import React from "react";
import errorVaiot from "../../../assets/images/errorVaiot.svg";
import errorSign from "../../../assets/images/errorCoin.svg";
import { Action, ActionType, State } from "../../../state/interfaces";

interface ConnectionScreenNoWalletLinkedProps {
  dispatch: React.Dispatch<Action>;
  state: State;
}

function ConnectionScreenNoWalletLinked({
  dispatch,
  state,
}: ConnectionScreenNoWalletLinkedProps) {
  const handleSwitchToProfile = () => {
    dispatch({
      type: ActionType.SET_HAMBURGER_OPEN,
      payload: false,
    });
    dispatch({
      type: ActionType.SET_SIGN_UP_PAGE_ACTIVE,
      payload: false,
    });
    dispatch({
      type: ActionType.SET_PROFILE_PAGE_ACTIVE,
      payload: true,
    });
    dispatch({
      type: ActionType.SET_LOG_IN_PAGE_ACTIVE,
      payload: false,
    });
    dispatch({
      type: ActionType.SET_VERIFY_PROOF_ACTIVE,
      payload: false,
    });
  };

  return (
    <div className="p-[20px] flex flex-col h-full gap-[40px] justify-center items-center">
      <img
        src={errorVaiot}
        alt="Error Sign"
        className="w-[60px] sm450:w-[80px] sm:w-[150px]"
      />
      <div className="flex items-center gap-[10px]">
        <img src={errorSign} alt="Error Sign" className="w-[30px]" />
        <p className="font-bold text-[24px] sm:text-[28px] gradient-text">
          Error
        </p>
      </div>
      <p className="text-center text-[gray] text-[14px] sm450:text-[16px] sm:text-[18px]">
        In order to use the Contract Reader functionality, you must link a web3
        wallet to your account.
      </p>
      <button
        onClick={handleSwitchToProfile}
        className="rounded-[2px] py-[10px] px-[40px] border-[2px] border-[#0023EF1A] text-[#0023EF] bg-[#0023EF12] font-semibold border-opacity-[10]"
      >
        Open Settings
      </button>
    </div>
  );
}

export default ConnectionScreenNoWalletLinked;
