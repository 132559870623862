// ----------------------
// Packages
// ----------------------
import React from "react";

// ----------------------
// Assets
// ----------------------
import { ReactComponent as VaiotAvatar } from "../../../assets/images/vaiotCertificateList.svg";
import { ReactComponent as Cross } from "../../../assets/images/cross.svg";

// ----------------------
// Utility Functions
// ----------------------
import { resetListOfCertificates } from "../utils/resetListOfCertificates";

// ----------------------
// Interfaces
// ----------------------
import { Action } from "../../../state/interfaces";

interface VaiotPopupProps {
  dispatch: React.Dispatch<Action>;
  setNotificationActive: React.Dispatch<React.SetStateAction<boolean>>;
}

function VaiotPopup({ dispatch, setNotificationActive }: VaiotPopupProps) {
  return (
    <div className="mt-[20px] relative">
      <div className="z-10 w-[100px] sm:w-[200px] sm450:w-[150px] relative">
        <VaiotAvatar className="w-full" />
      </div>
      <div className="bg-[#fff] w-[220px] h-[260px] sm400:h-[210px] sm400:w-[350px] rounded-md shadow-lg p-[20px]    flex items-center justify-between flex-col absolute top-[30%] left-[20%] z-[0] sm900:left-[14%] md:left-[12%]">
        <div className="w-full flex items-end justify-end">
          <button
            className="w-[25px] border-[#0023EF] border-[1px] cursor-pointer"
            onClick={() => setNotificationActive(false)}
          >
            <Cross className="h-full w-full" />
          </button>
        </div>
        <p className="font-normal mx-[40px] mb-[15px] text-[16px]">
          If you want, you can create a new certificate for this contract.
        </p>
        <button
          onClick={() => resetListOfCertificates(dispatch)}
          className="font-TT bg-connect-button tracking-widest text-[#ffff] py-[10px] px-[20px] sm400:py-[20px] sm400:px-[40px] rounded-sm font-bold text-[14px] sm:text-[16px]"
        >
          Buy New Certificate
        </button>
      </div>
    </div>
  );
}

export default VaiotPopup;
