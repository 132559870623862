import React, { useState, useEffect } from "react";
import errorVaiot from "../../../assets/images/errorVaiot.svg";
import errorSign from "../../../assets/images/errorCoin.svg";
import { Action, ActionType, State } from "../../../state/interfaces";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";

interface ConnectionScreenNoWeb3Props {
  dispatch: React.Dispatch<Action>;
  state: State;
}

function ConnectionScreenNoWeb3({
  dispatch,
  state,
}: ConnectionScreenNoWeb3Props) {
  const [isUpdatingWallet, setIsUpdatingWallet] = useState(false);
  const { open } = useWeb3Modal();
  const { address } = useAccount();

  const handleConnectWallet = () => {
    setIsUpdatingWallet(true);
    open();
  };

  useEffect(() => {
    const web3Login = () => {
      if (address && isUpdatingWallet) {
        dispatch({ type: ActionType.SET_WEB3_LOGIN, payload: true });
      }
    };

    if (isUpdatingWallet) {
      web3Login();
    }

    return () => {
      if (isUpdatingWallet) {
        setIsUpdatingWallet(false);
      }
    };
  }, [address, isUpdatingWallet]);

  return (
    <div className="p-[20px] flex flex-col h-full gap-[40px] justify-center items-center">
      <img
        src={errorVaiot}
        alt="Error Sign"
        className="w-[60px] sm450:w-[80px] sm:w-[150px]"
      />
      <div className="flex items-center gap-[10px]">
        <img src={errorSign} alt="Error Sign" className="w-[30px]" />
        <p className="font-bold text-[24px] sm:text-[28px] gradient-text">
          Error
        </p>
      </div>
      <p className="text-center text-[gray] text-[14px] sm450:text-[16px] sm:text-[18px]">
        Please log in to your web3 wallet that is linked to your account in
        order to access the Contract Reader functionality.
      </p>
      <button
        onClick={handleConnectWallet}
        className="rounded-[2px] py-[10px] px-[40px] border-[2px] border-[#0023EF1A] text-[#0023EF] bg-[#0023EF12] font-semibold border-opacity-[10]"
      >
        Connect Wallet
      </button>
    </div>
  );
}

export default ConnectionScreenNoWeb3;
