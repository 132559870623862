import React, { useEffect, useState } from "react";
import { Action, ActionType } from "../../../../state/interfaces";
import chevronLeft from "../../../../assets/images/chevronLeft.svg";
import { useWriteContract } from "wagmi";
import { abi } from "./paybackAbi";
import handleToastNotification from "../../../../utils/utility/handleToastNotification";
import handleToastError from "../../../../utils/utility/handleToastError";
import { PuffLoader } from "react-spinners";
import { useWaitForTransactionReceipt } from "wagmi";

//

interface PaybackClaimProps {
  dispatch: React.Dispatch<Action>;
  setRefetchData: React.Dispatch<React.SetStateAction<boolean>>;
  refetchData: boolean;
}

function PaybackClaim({
  dispatch,
  setRefetchData,
  refetchData,
}: PaybackClaimProps) {
  const { writeContractAsync, data: hash } = useWriteContract();
  const [isTransactionPending, setIsTransactionPending] = useState(false);

  const { isSuccess: isConfirmed } = useWaitForTransactionReceipt({
    hash,
  });

  const handleClaimClick = async () => {
    try {
      setIsTransactionPending(true);
      const address = process.env.REACT_APP_PAYBACK_ADDRESS || "";
      await writeContractAsync({
        abi: abi,
        address: address as `0x${string}`,
        functionName: "withdraw",
      });
    } catch (error) {
      handleToastError("Error when trying to withdraw payback!");
    }
  };

  useEffect(() => {
    if (isConfirmed) {
      setRefetchData(!refetchData);
      handleToastNotification("Payback has been withdrawn!");
      setIsTransactionPending(false);
    }
  }, [isConfirmed]);

  const closeClaimPayback = () => {
    dispatch({ type: ActionType.SET_PAYBACK_ACTIVE_SCREEN, payload: "" });
  };

  return (
    <div className="w-[90%] md:w-[80%] flex flex-col items-center justify-start py-[60px] sm:py-[20px] font-TT tracking-wider">
      <div className="flex items-center gap-[20px] w-full">
        <img
          src={chevronLeft}
          className="w-[30px] cursor-pointer"
          onClick={closeClaimPayback}
          alt="Back"
        />
        <p className="text-[18px] font-bold">Claim Your Payback</p>
      </div>
      <div className="flex flex-col gap-[30px] items-center mt-[40px] text-center">
        <p className="text-[red]">
          By claiming your payback, you will no longer receive the 10% APY on
          your staked tokens.
        </p>
        <p>
          The payback amount will be transferred to the wallet address currently
          linked to your account.
        </p>
        <button
          className="bg-second-connect-button w-[150px] h-[50px] font-bold text-[#0023EF] rounded-[6px] mt-[20px] outline-none flex items-center justify-center"
          onClick={handleClaimClick}
          disabled={isTransactionPending}
        >
          {isTransactionPending ? <PuffLoader size={26} /> : "Claim Payback"}
        </button>
      </div>
    </div>
  );
}

export default PaybackClaim;
