import React from "react";
import { getUserDetails } from "../../utils/api/getUserDetails";
import { updateUserAvatar } from "../../utils/api/updateUserAvatar";
import avatarDefault from "../../assets/images/avatarProfile.svg";
import { Action, ActionType, State } from "../../state/interfaces";
import { deleteUserAvatar } from "../../utils/api/deleteUserAvatar";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

interface ChangeAvatarProps {
  state: State;
  dispatch: React.Dispatch<Action>;
  isLoading: boolean;
}

const ChangeAvatar = ({ state, dispatch, isLoading }: ChangeAvatarProps) => {
  const handleAvatarChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      try {
        const response = await updateUserAvatar(e.target.files[0]);
        if (response && response.status === "success") {
          const data = await getUserDetails();
          if (!data) {
            return;
          }
          dispatch({
            type: ActionType.SET_USER_AVATAR,
            payload: data.userDetails.avatar,
          });
        }
      } catch (error) {
        console.error("Error updating avatar:", error);
        alert("An error occurred.");
      }
    }
  };

  const displayAvatar = state.userAvatar
    ? `${state.userAvatar}?${new Date().getTime()}`
    : avatarDefault;

  return (
    <div className="bg-[#F7F8FD] w-full py-[20px] px-[10px] sm500:px-[20px]">
      {!isLoading ? (
        <div className="flex flex-col gap-[20px] font-TT bg-[#fff] px-[20px] py-[15px] rounded-[6px] shadow-sm border-[#E5E4F0] border-[1px]">
          <p className="font-bold tracking-wide">Change Avatar</p>
          <div className="flex gap-[20px] items-center">
            <img
              key={state.userAvatar}
              src={displayAvatar}
              alt="User Avatar"
              className="w-[140px] h-[140px] rounded-full"
            />

            <div className="flex sm400:flex-row flex-col items-center gap-[30px]">
              <label className="bg-[#E2E8F0] px-[10px] py-[5px] hover:bg-[#d9dbdf] cursor-pointer">
                Change
                <input
                  type="file"
                  accept=".png,.jpg,.gif,.jpeg"
                  onChange={handleAvatarChange}
                  style={{ display: "none" }}
                />
              </label>
              <button
                onClick={() => deleteUserAvatar(dispatch)}
                className="hover:text-[red]"
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-[20px] font-TT bg-[#fff] px-[20px] py-[15px] rounded-[6px] shadow-sm border-[#E5E4F0] border-[1px]">
          <Skeleton className="w-[140px] h-[20px]" />
          <div className="flex gap-[20px] items-center">
            <Skeleton className="w-[140px] h-[140px] rounded-full" />
            <Skeleton className="w-[70px] h-[34px]" />
            <Skeleton className="w-[70px] h-[34px]" />
          </div>
        </div>
      )}
    </div>
  );
};

export default ChangeAvatar;
