// ----------------------
// Packages
// ----------------------
import React, { useState } from "react";

// ----------------------
// Components
// ----------------------
import AccountCreationWindow from "./AccountCreation";

// ----------------------
// Interfaces
// ----------------------
import { State, Action } from "../../state/interfaces";
import LoginCodeModal from "./LoginCodeModal";

interface LogInPageProps {
  dispatch: React.Dispatch<Action>;
  state: State;
}

function LogInPage({ state, dispatch }: LogInPageProps) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return state.loginCodeModalActive ? (
    <LoginCodeModal
      dispatch={dispatch}
      email={email}
      password={password}
      setPassword={setPassword}
      isLogIn={true}
    />
  ) : (
    <AccountCreationWindow
      state={state}
      dispatch={dispatch}
      headerText="Log In"
      isLogInPage={true}
      bottomText="Don't have an account?"
      bottomTextBolded="Sign Up"
      mainButtonText="Log In"
      email={email}
      password={password}
      setEmail={setEmail}
      setPassword={setPassword}
    />
  );
}

export default LogInPage;
