// ----------------------
// Packages
// ----------------------
import React from "react";
import PaybackButton from "./PaybackButton";

// ----------------------
// Assets
// ----------------------

interface PaybackDropDownProps {
  setMethodChosen: React.Dispatch<React.SetStateAction<string>>;
}

function PaybackDropDown(props: PaybackDropDownProps) {
  return (
    <div className="absolute top-full mt-2 font-bold z-[100]  w-[120px] sm400:w-[150px] lg:w-[200px] bg-white divide-y divide-gray-100 shadow-lg rounded-lg overflow-hidden first-dropdown">
      <PaybackButton setMethodChosen={props.setMethodChosen} type="Payback" />
      <PaybackButton setMethodChosen={props.setMethodChosen} type="Points" />
    </div>
  );
}

export default PaybackDropDown;
