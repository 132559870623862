// ----------------------
// Packages
// ----------------------
import React from "react";

// ----------------------
// Interfaces
// ----------------------
import { OptionType } from "../../../state/interfaces";

interface TypeButtonProps {
  selectedOption: OptionType;
  setSelectedOption: React.Dispatch<React.SetStateAction<OptionType>>;
  text: OptionType;
}

function TypeButton({
  selectedOption,
  setSelectedOption,
  text,
}: TypeButtonProps) {
  const getOptionClasses = (option: string) => {
    if (selectedOption === option) {
      return "w-1/3 text-[#0023EF] text-[20px] bg-[#0023EF0D] h-[68px]";
    } else {
      return "w-1/4 h-10 bg-white ";
    }
  };

  return (
    <button
      className={`${getOptionClasses(text)}   transition-all duration-300`}
      onClick={() => setSelectedOption(text)}
    >
      {text}
    </button>
  );
}

export default TypeButton;
