import React, { useState, useEffect } from "react";
import { Action, ActionType } from "../../../state/interfaces";
import crossIcon from "../../../assets/images/cross.svg";
import { handleDeleteAccount } from "../../../utils/api/handleDeleteAccount";
import { BeatLoader } from "react-spinners";

interface DeleteAccountModalProps {
  dispatch: React.Dispatch<Action>;
}

function DeleteAccountModal({ dispatch }: DeleteAccountModalProps) {
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleVisibility = () => {
      setIsVisible(true);
    };

    handleVisibility();
  }, []);

  const handleCloseModal = () => {
    dispatch({
      type: ActionType.SET_DELETE_ACCOUNT_MODAL_ACTIVE,
      payload: false,
    });
  };

  const handleGoBack = () => {
    handleCloseModal();
  };

  const handleConfirmDelete = async () => {
    setIsLoading(true);
    await handleDeleteAccount(dispatch, setIsLoading);
    setIsLoading(false);
  };

  return (
    <div className="w-full h-full flex items-center justify-center bg-[#050934] bg-opacity-[75%] fixed z-[91] font-TT tracking-wide">
      <div
        className={`bg-[#fff] w-[500px] h-[360px] border-2 shadow-lg rounded-[6px] flex flex-col gap-[30px] justify-between max-h-[80%] overflow-y-auto
  transition-all duration-100 ease-in-out transform-gpu ${
    isVisible ? "scale-100" : "scale-[90%]"
  }
  transition-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);`}
      >
        <div className="w-full flex items-center justify-between bg-[#E5E4F0] px-[30px] py-[20px]">
          <p className="font-bold">Delete Account</p>
          {!isLoading && (
            <button onClick={handleCloseModal}>
              <img src={crossIcon} alt="Close" className="w-[20px]" />
            </button>
          )}
        </div>
        {isLoading ? (
          <div className="flex items-center justify-center w-full h-full">
            <BeatLoader color="#0023EF" />
          </div>
        ) : (
          <div className="flex-col items-center gap-[30px] w-full flex text-center py-[20px]">
            <p>Are you sure you want to delete your account?</p>
            <p>
              Deleting the account will result in a{" "}
              <span className="text-[red]">complete loss of all data.</span>
            </p>

            <div className="flex flex-col gap-[10px] items-center">
              <button
                className="w-[200px] h-[50px] font-bold bg-connect-button text-[#fff]"
                onClick={handleGoBack}
              >
                Go Back
              </button>
              <button
                onClick={handleConfirmDelete}
                className="w-[200px] h-[50px] font-bold bg-second-connect-button text-[#0023EF]"
              >
                Yes
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DeleteAccountModal;
