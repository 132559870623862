import SocketHandlerReader from "../../../utils/sockets/handleSocketsReader";
import { Action, State } from "../../../state/interfaces";

export const checkIfCertificateIsPaid = (
  state: State,
  account: string | null | undefined,
  idOfChosenContract: string,
  dispatch: React.Dispatch<Action>,
  typeOfChosenContract: string
) => {
  if (!(state.socketHandlerReader instanceof SocketHandlerReader)) {
    state.socketHandlerReader = new SocketHandlerReader();
  }

  state.socketHandlerReader?.checkCertificatePaid(
    idOfChosenContract,
    account,
    dispatch,
    typeOfChosenContract
  );
};
