// ----------------------
// Packages
// ----------------------
import React, { useState, useEffect } from "react";
import { useAccount } from "wagmi";

// ----------------------
// Interfaces and Assets
// ----------------------
import { Action, ActionType, State } from "../../state/interfaces";
import cross from "../../assets/images/cross.svg";

// ----------------------
// Components
// ----------------------
import SignUpPasswordInput from "./AccountCreationPasswordInput";
import AccountCreationEmailInput from "./AccountCreationEmailInput";
import AccountCreationRedirect from "./AccountCreationRedirect";
import AccountCreationConfirmButton from "./AccountCreationConfirmButton";
import AccountCreationOrLine from "./AccountCreationOrLine";
import AccountCreationHeader from "./AccountCreationHeader";
import AccountCreationOtherLoginMethods from "./AccountCreationOtherLoginMethods";
import AccountCreationTos from "./AccountCreationTos";
import { handleResetPassword } from "../../utils/api/handleResetPassword";

interface AccountCreationProps {
  dispatch: React.Dispatch<Action>;
  state: State;
  headerText: string;
  isLogInPage: boolean;
  bottomText: string;
  bottomTextBolded: string;
  mainButtonText: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  email: string;
  password: string;
}

function AccountCreation({
  dispatch,
  state,
  headerText,
  isLogInPage,
  bottomText,
  bottomTextBolded,
  mainButtonText,
  email,
  password,
  setEmail,
  setPassword,
}: AccountCreationProps) {
  const [isValid, setIsValid] = useState(true);
  const [isUpdatingWallet, setIsUpdatingWallet] = useState(false);
  const [passwordValidations, setPasswordValidations] = useState({
    length: false,
    uppercase: false,
    digit: false,
  });
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const { address } = useAccount();

  const validateEmail = (email: string) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = event.target.value;
    setEmail(emailValue);
    setIsValid(validateEmail(emailValue));
  };

  const handleRedirect = () => {
    if (isLogInPage) {
      dispatch({ type: ActionType.SET_SIGN_UP_PAGE_ACTIVE, payload: true });
      dispatch({ type: ActionType.SET_LOG_IN_PAGE_ACTIVE, payload: false });
    } else {
      dispatch({ type: ActionType.SET_SIGN_UP_PAGE_ACTIVE, payload: false });
      dispatch({ type: ActionType.SET_LOG_IN_PAGE_ACTIVE, payload: true });
    }
  };

  const handleForgotPassword = async () => {
    dispatch({
      type: ActionType.SET_RESET_PASSWORD_CONFIRMATION_MODAL,
      payload: true,
    });
    await handleResetPassword(state.forgotPasswordEmail);
  };

  const handleEmailBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsValid(validateEmail(event.target.value));
  };

  const handleCloseModal = () => {
    if (isLogInPage) {
      dispatch({ type: ActionType.SET_LOG_IN_PAGE_ACTIVE, payload: false });
    } else {
      dispatch({ type: ActionType.SET_SIGN_UP_PAGE_ACTIVE, payload: false });
    }
  };

  useEffect(() => {
    const web3Login = () => {
      if (address && isUpdatingWallet) {
        dispatch({ type: ActionType.SET_LOG_IN_PAGE_ACTIVE, payload: false });
        dispatch({ type: ActionType.SET_SIGN_UP_PAGE_ACTIVE, payload: false });
        dispatch({ type: ActionType.SET_WEB3_LOGIN, payload: true });
      }
    };

    if (isUpdatingWallet) {
      web3Login();
    }

    return () => {
      if (isUpdatingWallet) {
        setIsUpdatingWallet(false);
      }
    };
  }, [address, isUpdatingWallet]);

  return (
    <div className=" w-full h-full flex items-center justify-center bg-[#050934] bg-opacity-[75%] fixed z-[100]">
      <div className="bg-[white] w-[400px] h-[670px] p-[20px] sm:p-[30px] border-2  shadow-lg rounded-lg flex flex-col gap-[20px] justify-between relative">
        <button onClick={handleCloseModal}>
          <img
            src={cross}
            className="absolute right-[10px] top-[10px] w-[20px] cursor-pointer"
          />
        </button>
        <div className="w-full flex flex-col items-center justify-center gap-[25px] lg:gap-[30px] ">
          <div className="flex flex-col gap-[20px] w-full">
            <AccountCreationHeader text={headerText} />
            <div>
              <AccountCreationEmailInput
                id="email"
                label="Email address"
                type="email"
                className=""
                isValid={isValid}
                errorMessage="Email address is required"
                onChange={handleEmailChange}
                onBlur={handleEmailBlur}
                autoComplete={isLogInPage ? "email" : "new-email"}
              />
              <SignUpPasswordInput
                popUpActive={!isLogInPage}
                setPassword={setPassword}
                password={password}
                passwordValidations={passwordValidations}
                setPasswordValidations={setPasswordValidations}
              />
            </div>
          </div>
          <AccountCreationConfirmButton
            text={mainButtonText}
            isLogInPage={isLogInPage}
            email={email}
            password={password}
            passwordValidations={passwordValidations}
            dispatch={dispatch}
            setShowForgotPassword={setShowForgotPassword}
            setPassword={setPassword}
          />
          <AccountCreationOrLine />
          <div className="flex w-full items-center gap-[15px] flex-col">
            <AccountCreationOtherLoginMethods
              state={state}
              setIsUpdatingWallet={setIsUpdatingWallet}
            />
            <AccountCreationTos />
          </div>
          <div className="flex-col w-full flex items-center gap-[15px]">
            <AccountCreationRedirect
              text={bottomText}
              redirect={handleRedirect}
              boldedText={bottomTextBolded}
            />
            {isLogInPage && showForgotPassword && (
              <AccountCreationRedirect
                text={"Forgot your password?"}
                redirect={handleForgotPassword}
                boldedText={"Click here"}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountCreation;
