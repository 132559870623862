import React, { useState, useRef, useEffect, ChangeEvent } from "react";
import { IoArrowBack, IoMailOpen } from "react-icons/io5";
import { Action, ActionType } from "../../state/interfaces";
import { handleLogIn } from "../../utils/api/handleLogIn";
import handleToastError from "../../utils/utility/handleToastError";
import { handleSignUp } from "../../utils/api/handleSignUp";
import { handleResendCode } from "../../utils/api/handleResendCode";

interface LoginCodeModalProps {
  email: string;
  password: string;
  dispatch: React.Dispatch<Action>;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  isLogIn: boolean;
}

const LoginCodeModal: React.FC<LoginCodeModalProps> = ({
  email,
  password,
  dispatch,
  setPassword,
  isLogIn,
}) => {
  const [code, setCode] = useState<string[]>(Array(6).fill(""));
  const [showResendLink, setShowResendLink] = useState<boolean>(false);

  const inputRefs = useRef<React.RefObject<HTMLInputElement>[]>(
    Array(6)
      .fill(null)
      .map(() => React.createRef<HTMLInputElement>())
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowResendLink(true);
    }, 30000); // 30 seconds

    // Cleanup timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  const handleChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const newCode = [...code];
    newCode[index] = event.target.value;
    setCode(newCode);

    if (
      event.target.value &&
      index < 5 &&
      inputRefs.current[index + 1].current
    ) {
      inputRefs.current[index + 1].current!.focus();
    }
  };

  const handleCloseModal = () => {
    dispatch({ type: ActionType.SET_LOGIN_CODE_MODAL_ACTIVE, payload: false });
  };

  const resendCode = () => {
    setShowResendLink(false);
    setTimeout(() => setShowResendLink(true), 30000);
    handleResendCode(email, password, isLogIn);
  };

  const loginWithCode = async () => {
    if (code.length !== 6 || !code.every((digit) => digit.length === 1)) {
      handleToastError("Enter the full 6-digit code first!");
      return;
    }
    const codeNumber = parseInt(code.join(""), 10);
    if (isLogIn) {
      await handleLogIn(
        email,
        password,
        dispatch,
        setPassword,
        undefined,
        codeNumber
      );
    } else {
      await handleSignUp(
        "emailCode",
        dispatch,
        {
          username: email,
          password: password,
        },
        codeNumber
      );
    }
  };

  const handleKeyDown = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Backspace" && index > 0 && !code[index]) {
      // Focus on the previous input when the current is empty and backspace is pressed
      setCode((prevCode) => {
        const newCode = [...prevCode];
        newCode[index - 1] = ""; // Clear the previous input
        return newCode;
      });
      inputRefs.current[index - 1].current!.focus();
    }
  };

  return (
    <div className="w-full h-full flex items-center justify-center bg-[#050934] bg-opacity-[75%] fixed z-[100] font-TT tracking-wide">
      <div className="bg-[white] w-[400px] h-[400px] p-[20px] sm:p-[30px] border-2 shadow-lg rounded-lg flex flex-col gap-[20px] relative">
        <button onClick={handleCloseModal}>
          <IoArrowBack className="absolute left-[15px] top-[15px] text-[20px] cursor-pointer text-[#0023EF]" />
        </button>
        <div className="w-full flex flex-col justify-center">
          <p className="font-bold text-[28px]">Verification Required</p>
          <p className="mb-[20px]">Enter the code sent to {email}</p>
          <div className="flex flex-col gap-[5px] mb-[10px]">
            <p className="font-bold">6-Digit Code</p>
            <div className="flex justify-between items-center">
              {code.map((_, index) => (
                <input
                  key={index}
                  ref={inputRefs.current[index]}
                  type="text"
                  maxLength={1}
                  value={code[index]}
                  onChange={(e) => handleChange(index, e)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  className="w-[15%] h-[50px] border-2 border-gray-300 text-center shadow-md"
                  pattern="\d*"
                />
              ))}
            </div>
          </div>
          <div className="flex items-center gap-[10px] bg-[#F7F8FD] p-[8px] rounded-[6px] mb-[20px]">
            <IoMailOpen className="text-[#0023EF] text-[40px]" />
            <p className="text-[14px]">
              Can't find your verification code? Make sure to check your spam
              folder.
            </p>
          </div>
          <button
            onClick={loginWithCode}
            className="w-full bg-[#0023EF] h-[40px] font-bold text-[#fff] rounded-[6px]"
          >
            {isLogIn ? "Log In" : "Sign Up"}
          </button>
          {showResendLink && (
            <p className="mt-4 cursor-pointer">
              Didn't get the code?{" "}
              <span onClick={resendCode} className="text-[#0023EF]">
                Resend code
              </span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginCodeModal;
