import axios from "axios";

export const fetchTokenPrice = async (currency: string) => {
  const data = await axios.post(
    process.env.REACT_APP_BASE_URL + "/transactions/get-asset-info",
    {
      symbol: currency,
    }
  );

  if (typeof data.data === "string") {
    return data.data;
  }

  let lowerCaseCurrency = currency.toLowerCase();

  if (data !== undefined) {
    const price =
      lowerCaseCurrency === "matic"
        ? data.data.MATIC[0].quote.USD.price
        : lowerCaseCurrency === "eth"
        ? data.data.ETH[0].quote.USD.price
        : lowerCaseCurrency === "vai"
        ? data.data.VAI[1].quote.USD.price
        : 1;
    return price;
  }
};
