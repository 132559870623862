import { useEffect } from "react";
import { State } from "../../state/interfaces";
import SocketHandler from "../sockets/handleSocket";
import { useAccount } from "wagmi";
import { useWeb3ModalState } from "@web3modal/wagmi/react";

const useHandleWalletConnection = (state: State) => {
  const { address } = useAccount();
  const { selectedNetworkId } = useWeb3ModalState();

  const chainId =
    selectedNetworkId !== undefined ? parseInt(selectedNetworkId) : undefined;

  const isActive = address !== undefined;

  useEffect(() => {
    let userId = localStorage.getItem("userId");

    if (userId) {
      userId = userId.replace(/^\"+|\"+$/g, "");
    }

    if (state.conversationId !== "" && userId !== null) {
      if (address && isActive) {
        handleWalletConnection(true, userId);
      } else if (!address && !isActive) {
        handleWalletConnection(false, userId);
      }
    }
  }, [address, isActive, state.conversationId]);

  const handleWalletConnection = (isConnected: boolean, userId: string) => {
    if (!(state.socketHandler instanceof SocketHandler)) {
      state.socketHandler = new SocketHandler();
    }
    state.socketHandler.handleWalletConnection(
      state.conversationId,
      userId,
      isConnected,
      chainId,
      address
    );
  };

  return { address, isActive, chainId };
};

export default useHandleWalletConnection;
