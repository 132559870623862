// Interfaces
import { Action, State } from "../interfaces";

export const incrementNumOfCalls = (state: State, action: Action): State => {
  if (action.type === "INCREMENT_NUM_OF_CALLS") {
    // Find index of the button state with the given id
    const index = state.buttonStates.findIndex(
      (button) => button.id === action.payload.id
    );

    if (index !== -1) {
      // Button state found. Create a new copy of buttonStates array
      const newButtonStates = [...state.buttonStates];

      // Increase numOfCalls of the button at the found index by 1
      newButtonStates[index] = {
        ...newButtonStates[index],
        numOfCalls: (newButtonStates[index].numOfCalls || 0) + 1,
      };

      return {
        ...state,
        buttonStates: newButtonStates,
      };
    }

    // Button state not found. Add it to the array with numOfCalls = 1
    return {
      ...state,
      buttonStates: [
        ...state.buttonStates,
        {
          ...action.payload,
          numOfCalls: 1,
          state: "NOT CLICKED",
          txHash: "",
          chain: "",
        },
      ],
    };
  } else {
    return state;
  }
};

export const setButtonState = (state: State, action: Action): State => {
  if (action.type === "SET_BUTTON_STATE") {
    // Find index of the button state with the given id
    const index = state.buttonStates.findIndex(
      (button) => button.id === action.payload.id
    );

    if (index !== -1) {
      // Button state found. Create a new copy of buttonStates array
      const newButtonStates = [...state.buttonStates];

      // Update the state of the button at the found index
      newButtonStates[index] = action.payload;

      return {
        ...state,
        buttonStates: newButtonStates,
      };
    }

    // Button state not found. Add it to the array
    return {
      ...state,
      buttonStates: [...state.buttonStates, action.payload],
    };
  } else {
    return state;
  }
};
