import * as Icons from "./icons";

export const stepMapping = {
  GetStarted: 1,
  ContractInformation: 2,
  Description: 3,
  Terms: 4,
  PaymentDetails: 5,
  FinalDetails: 6,
  Payment: 7,
  PaymentFinished: 8,
};

export const stepsData = [
  {
    label: "Get Started",
    icon: { default: Icons.circleOne, finished: Icons.circleOneFinished },
  },
  {
    label: "Contract Information",
    icon: { default: Icons.circleTwo, finished: Icons.circleTwoFinished },
  },
  {
    label: "Description",
    icon: { default: Icons.circleThree, finished: Icons.circleThreeFinished },
  },
  {
    label: "Terms",
    icon: { default: Icons.circleFour, finished: Icons.circleFourFinished },
  },
  {
    label: "Payment Details",
    icon: { default: Icons.circleFive, finished: Icons.circleFiveFinished },
  },
  {
    label: "Final Details",
    icon: { default: Icons.circleSix, finished: Icons.circleSixFinished },
  },
  {
    label: "Payment",
    icon: { default: Icons.circleSeven, finished: Icons.circleSevenFinished },
  },
];
