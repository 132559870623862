import React from "react";
import transactionHistoryIcon from "../../../assets/images/transactionHistoryIcon.svg";
import claimVaiPaybackIcon from "../../../assets/images/claimVaiPaybackIcon.svg";
import settingsIcon from "../../../assets/images/settingsIcon.svg";
import { Action, ActionType, State } from "../../../state/interfaces";
import handleToastError from "../../../utils/utility/handleToastError";

interface PaybackScreensChoiceProps {
  dispatch: React.Dispatch<Action>;
  walletRegistered: boolean;
  web2WalletAddress: string;
  address: `0x${string}` | undefined;
  state: State;
}

function PaybackScreensChoice({
  dispatch,
  walletRegistered,
  web2WalletAddress,
  address,
  state,
}: PaybackScreensChoiceProps) {
  const setActiveScreen = (screen: string) => {
    if (screen === "ClaimPayback") {
      if (address === undefined) {
        handleToastError("Please connect your wallet first!");
        return;
      }
    }
    dispatch({ type: ActionType.SET_PAYBACK_ACTIVE_SCREEN, payload: screen });
  };

  const setSettingsScreen = () => {
    if (address !== undefined) {
      if (walletRegistered && web2WalletAddress !== address) {
        handleToastError(
          "Your wallet is linked to a web2 account. Please log in to that account to access settings."
        );
        return;
      }
      if (state.web2Login && address !== web2WalletAddress) {
        handleToastError(
          "Please connect the wallet that is currently linked to your web2 account."
        );
        return;
      }
      dispatch({
        type: ActionType.SET_PAYBACK_ACTIVE_SCREEN,
        payload: "Settings",
      });
    } else {
      handleToastError("Please connect your web3 wallet first!");
      return;
    }
  };

  return (
    <div className="w-full h-full flex sm:flex-row flex-wrap justify-around items-center font-TT md:px-[200px] py-[40px]">
      <div className="flex justify-around w-full">
        <button
          onClick={() => setSettingsScreen()}
          className="flex flex-col items-center gap-[10px]"
        >
          <img
            src={settingsIcon}
            alt="Settings Icon"
            className="w-[90px] h-[90px]"
          />
          <p className="text-center">Settings</p>
        </button>
        <button
          onClick={() => setActiveScreen("TransactionHistory")}
          className="flex flex-col items-center gap-[10px]"
        >
          <img
            src={transactionHistoryIcon}
            alt="Transaction History Icon"
            className="w-[90px] h-[90px]"
          />
          <p className="text-center">
            Transaction <br />
            History
          </p>
        </button>
        <button
          onClick={() => setActiveScreen("ClaimPayback")}
          className="flex-col items-center gap-[10px] hidden sm:flex"
        >
          <img
            src={claimVaiPaybackIcon}
            alt="Claim VAI Payback Icon"
            className="w-[90px] h-[90px]"
          />
          <p className="text-center">
            Claim VAI <br />
            Payback
          </p>
        </button>
      </div>
      <div className="sm:hidden flex justify-center w-full">
        <button
          onClick={() => setActiveScreen("ClaimPayback")}
          className="flex flex-col items-center gap-[10px]"
        >
          <img
            src={claimVaiPaybackIcon}
            alt="Claim VAI Payback Icon"
            className="w-[90px] h-[90px]"
          />
          <p className="text-center">
            Claim VAI <br />
            Payback
          </p>
        </button>
      </div>
    </div>
  );
}

export default PaybackScreensChoice;
