import React from "react";
import axios from "axios";
import handleToastError from "../../../utils/utility/handleToastError";
import { Action, ActionType } from "../../../state/interfaces";
import { updateGameField } from "../../../utils/api/gameApi";

export const fetchSummary = async (
  dispatch: React.Dispatch<Action>,
  fetchingSummary: boolean,
  messageForSummary: any,
  setFetchingSummary: React.Dispatch<React.SetStateAction<boolean>>,
  account: string | null | undefined
) => {
  if (fetchingSummary) {
    handleToastError("Please wait for your overview to appear first!");
    return;
  }
  if (messageForSummary.length === 0) {
    handleToastError(
      "Please pick at least one field to be included in the overview!"
    );
    return;
  }

  try {
    dispatch({ type: ActionType.SET_SUMMARY, payload: "" });
    setFetchingSummary(true);
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/snarks/fetchOverview",
      {
        fields: messageForSummary,
      }
    );
    setFetchingSummary(false);
    dispatch({ type: ActionType.SET_SUMMARY, payload: response.data });
    await updateGameField(account, "summaryCreated", true);
  } catch (error) {
    setFetchingSummary(false);
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 403) {
        handleToastError(
          "Access denied! Please log out and log back in to solve this issue."
        );
      } else {
        console.error("Error sending certificate payment request:", error);
      }
    } else {
      console.error("An unexpected error occurred:", error);
    }
  }
};
