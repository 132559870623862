import React, { useState, useRef, useEffect, useMemo } from "react";
import Web2AccountModal from "./Web2AccountModal";
import accountLogo from "../../../assets/images/accountLogo.svg";
import chevronDown from "../../../assets/images/chevronDownBlue.svg";
import chevronUp from "../../../assets/images/chevronUpBlue.svg";
import { Action, State, ActionType } from "../../../state/interfaces";
import Web2ProfileModal from "./Web2ProfileModal";
import avatar from "../../../assets/images/avatarProfile.svg";
import { useAccount } from "wagmi";
import blockies from "ethereum-blockies-base64";

interface Web2AccountProps {
  dispatch: React.Dispatch<Action>;
  state: State;
}

function Web2Account({ dispatch, state }: Web2AccountProps) {
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [isUpdatingWallet, setIsUpdatingWallet] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const { address } = useAccount();

  useEffect(() => {
    const web3Login = () => {
      if (address && isUpdatingWallet) {
        dispatch({ type: ActionType.SET_WEB3_LOGIN, payload: true });
      }
    };

    if (isUpdatingWallet) {
      web3Login();
    }

    return () => {
      if (isUpdatingWallet) {
        setIsUpdatingWallet(false);
      }
    };
  }, [address, isUpdatingWallet]);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setLoginModalOpen(false);
      setProfileModalOpen(false);
    }
  };

  const handleOpenAccountWindow = () => {
    dispatch({
      type: ActionType.SET_ACCOUNT_WINDOW_ACTIVE,
      payload: true,
    });
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const web3Avatar = useMemo(() => {
    return address ? blockies(address) : avatar;
  }, [address]);

  const emailPrefix = state.web2Email.split("@")[0];
  const displayEmail =
    emailPrefix.length > 12
      ? emailPrefix.substring(0, 12) + "..."
      : emailPrefix;

  return (
    <div className="relative w-auto flex items-center justify-end" ref={ref}>
      {state.web3Login && address && !state.web2Login ? (
        <button className="flex items-center gap-[10px] cursor-default">
          <img
            src={web3Avatar}
            onClick={handleOpenAccountWindow}
            alt="User Avatar"
            className="w-[25px] h-[25px] rounded-full bg-[#cacaca] cursor-pointer"
          />

          <p
            onClick={handleOpenAccountWindow}
            className="hidden sm900:block text-primary-blue text-[14px] font-bold cursor-pointer"
          >
            {address ? `${address.slice(0, 6)}...${address.slice(-4)}` : ""}
          </p>
          <p
            onClick={handleOpenAccountWindow}
            className="sm900:hidden text-primary-blue text-[14px] font-bold cursor-pointer"
          >
            {address ? `${address.slice(0, 4)}...${address.slice(-3)}` : ""}
          </p>
          {loginModalOpen ? (
            <img
              onClick={() => setLoginModalOpen(false)}
              src={chevronUp}
              alt="Chevron Up Logo"
              className="w-[16px] mt-[2px] cursor-pointer"
            />
          ) : (
            <img
              onClick={() => setLoginModalOpen(true)}
              src={chevronDown}
              alt="Chevron Down Logo"
              className="w-[16px] mt-[2px] cursor-pointer"
            />
          )}
        </button>
      ) : !state.web2Login ? (
        <button
          onClick={() => setLoginModalOpen(!loginModalOpen)}
          className="flex items-center gap-[10px] sm900:gap-[25px] "
        >
          <div className="flex gap-[5px] items-center">
            <img
              src={accountLogo}
              alt="Account Logo"
              className="w-[25px] flex-shrink-0"
            />
            <p className="hidden sm900:block text-primary-blue text-[14px]">
              Account
            </p>
          </div>
          {loginModalOpen ? (
            <img src={chevronUp} alt="Chevron Up Logo" className="w-[16px]" />
          ) : (
            <img
              src={chevronDown}
              alt="Chevron Down Logo"
              className="w-[16px]"
            />
          )}
        </button>
      ) : (
        <button
          onClick={() => setProfileModalOpen(!profileModalOpen)}
          className="flex items-center gap-[10px]"
        >
          <img
            src={
              state.userAvatar === ""
                ? avatar
                : `${state.userAvatar}?${new Date().getTime()}`
            }
            alt="User Avatar"
            className="w-[25px] h-[25px] rounded-full bg-[#cacaca]"
          />

          <p className="text-primary-blue text-[14px] font-bold">
            {displayEmail}
          </p>
          {profileModalOpen ? (
            <img
              src={chevronUp}
              alt="Chevron Up Logo"
              className="w-[16px] mt-[2px]"
            />
          ) : (
            <img
              src={chevronDown}
              alt="Chevron Down Logo"
              className="w-[16px] mt-[2px]"
            />
          )}
        </button>
      )}
      <Web2AccountModal
        showModal={loginModalOpen}
        onClose={() => setLoginModalOpen(false)}
        dispatch={dispatch}
        setModalOpen={setLoginModalOpen}
        state={state}
        setIsUpdatingWallet={setIsUpdatingWallet}
      />
      <Web2ProfileModal
        showModal={profileModalOpen}
        onClose={() => setProfileModalOpen(false)}
        dispatch={dispatch}
        setModalOpen={setProfileModalOpen}
        state={state}
        setIsUpdatingWallet={setIsUpdatingWallet}
      />
    </div>
  );
}

export default Web2Account;
