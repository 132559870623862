// ----------------------
// External/Internal Packages
// ----------------------
import React, { useState } from "react";
import Slider from "react-slick";
import { createUseStyles } from "react-jss";

// ----------------------
// Components
// ----------------------
import FirstSlide from "./FirstSlide";
import SecondSlide from "./SecondSlide";
import ThirdSlide from "./ThirdSlide";
import FourthSlide from "./FourthSlide";

// ----------------------
// Assets
// ----------------------

import chevronLeft from "../../assets/images/chevronLeftWhite.svg";
import chevronRight from "../../assets/images/chevronRightWhite.svg";

// ----------------------
// Utility Functions
// ----------------------
import { useEscapeKey } from "../../utils/utility/useEscapeKey";
import handleToastError from "../../utils/utility/handleToastError";

// ----------------------
// Interfaces and styles
// ----------------------
import { Action, ActionType, State } from "../../state/interfaces";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slick.css";

interface TutorialProps {
  dispatch: React.Dispatch<Action>;
  state: State;
}

const useStyles = createUseStyles({
  sliderContainer: {
    "& .slick-list": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  },
});

function Tutorial({ dispatch, state }: TutorialProps) {
  const [activeSlide, setActiveSlide] = useState(0);

  const slider = React.useRef<Slider>(null);
  const navButtons = [0, 1, 2];

  const classes = useStyles();

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current: number) => setActiveSlide(current),
    arrows: false,
    className: classes.sliderContainer,
  };

  useEscapeKey(dispatch, {
    type: "SET_TUTORIAL_ACTIVE",
    payload: false,
  });

  const handleSkip = () => {
    if (!state.conditionsChecked) {
      handleToastError("Please go through the tutorial at least once!");
      return;
    }
    dispatch({ type: ActionType.SET_TUTORIAL_ACTIVE, payload: false });
  };

  const startAssistant = () => {
    if (!state.conditionsChecked) {
      handleToastError("Please accept the conditions first!");
      return;
    }
    dispatch({
      type: ActionType.SET_TUTORIAL_ACTIVE,
      payload: false,
    });
  };

  return (
    <div className=" w-full h-full flex items-center justify-center bg-[#050934] bg-opacity-[75%] fixed z-50">
      <div className="bg-[white] md:w-[80%] md:h-[90%] md:min-h-[600px] md:max-w-[1200px] md:max-h-[800px] w-full h-full p-[20px] sm:p-[30px] sm:pb-[60px] border-2 shadow-lg rounded-lg flex flex-col gap-[20px]  relative overflow-y-auto">
        <div className="flex flex-col gap-[30px]">
          <div className="w-full flex justify-between items-center">
            <div className="flex gap-[10px] items-center"></div>
            <button
              className="text-[14px] md:text-[16px] font-semibold cursor-pointer"
              onClick={handleSkip}
            >
              Skip
            </button>
          </div>
        </div>
        <div className="overflow-y-auto">
          <Slider {...settings} ref={slider}>
            <FirstSlide />
            {/* <SecondSlide /> */}
            <ThirdSlide />
            <FourthSlide dispatch={dispatch} state={state} />
          </Slider>
        </div>

        <div className="flex items-center justify-between absolute bottom-[10px] right-[0px] w-full px-[30px]">
          <button
            onClick={() => {
              if (activeSlide !== 0) {
                slider.current?.slickPrev();
              }
            }}
            className={`text-[#0023EF] rounded-[2px]  ${
              activeSlide === 0
                ? "opacity-0 hover:opacity-0 cursor-auto"
                : "hover:text-[#687cf2] hover:opacity-90"
            } font-bold text-[14px] md:text-[16px] bg-[#0023EF12] w-[150px] h-[50px] hidden sm:block flex-shrink-0`}
          >
            Previous{" "}
          </button>
          {activeSlide !== 2 && (
            <div className="flex items-center justify-between w-full">
              <button
                onClick={() => slider.current?.slickPrev()}
                className="h-[60px] w-[60px] rounded-full bg-[gray] flex items-center justify-center flex-shrink-0 sm:hidden"
              >
                <img
                  src={chevronLeft}
                  className="w-[40px] mr-[5px]"
                  alt="Chevron Left"
                />
              </button>
              <div className="items-center gap-[15px] w-full justify-center flex">
                {navButtons.map((num) => (
                  <button
                    key={num}
                    onClick={() => slider.current?.slickGoTo(num)}
                    className={`${
                      activeSlide === num ? "bg-[#0023EF] " : "bg-[#CECDDA]"
                    } rounded-[100%] p-[4px]`}
                  />
                ))}
              </div>
              <button
                onClick={() => slider.current?.slickNext()}
                className="h-[60px] w-[60px] rounded-full bg-[#0023EF] flex items-center justify-center flex-shrink-0 sm:hidden"
              >
                <img
                  src={chevronRight}
                  className="w-[40px] ml-[5px]"
                  alt="Chevron Right"
                />
              </button>
            </div>
          )}
          {activeSlide !== 2 ? (
            <button
              onClick={() => slider.current?.slickNext()}
              className="rounded-[2px] hover:opacity-90 hover:text-[#ccd1f0] text-[#ffff] bg-gradient-button font-bold text-[14px] md:text-[16px] py-[10px] bg-[#0023EF12] px-[40px] hidden sm:block"
            >
              Next
            </button>
          ) : (
            <div className="w-full sm:w-auto items-center justify-center flex">
              <button
                onClick={startAssistant}
                className="rounded-[2px] hover:opacity-90 hover:text-[#ccd1f0] text-[#ffff] bg-gradient-button font-bold text-[16px]  sm:w-[150px] h-[50px] w-full max-w-[450px]"
              >
                START
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Tutorial;
