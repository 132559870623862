import React, { useEffect } from "react";
import { State, Action } from "../../state/interfaces";
import SocketHandler from "./handleSocket";

// Handling edge case - when a user refreshes when message is being fetched, then we retry the call to get dialog state

export function useDialogEmergencyHandler(
  state: State,
  dispatch: React.Dispatch<Action>
) {
  useEffect(() => {
    const checkAndHandleUserMessage = async () => {
      const message = state.messages.at(-1);
      const messageState = message?.messageState;
      const type = message?.type;
      const dialogState = state.dialogState;

      if (
        dialogState.length === 0 &&
        type === "USER" &&
        messageState === "DISPLAYED" &&
        message !== undefined &&
        message.text
      ) {
        if (!(state.socketHandler instanceof SocketHandler)) {
          state.socketHandler = new SocketHandler();
        }
        state.socketHandler.setDispatch(dispatch, state);
      }
    };

    const timeoutId = setTimeout(() => checkAndHandleUserMessage(), 3000);

    return () => clearTimeout(timeoutId);
  }, [state, dispatch]);
}
