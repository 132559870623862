import { useEffect } from "react";
import { createGame } from "../api/gameApi";

export const useCreateGame = (account: string | null | undefined) => {
  useEffect(() => {
    const handleGame = async () => {
      if (account !== null && account !== undefined) {
        try {
          await createGame(account);
        } catch (error) {
          console.error("Error while trying to connect wallet", error);
        }
      }
    };
    handleGame();
  }, [account]);
};
