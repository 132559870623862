// ----------------------
// Packages
// ----------------------
import React from "react";

// ----------------------
// Assets
// ----------------------
import { ReactComponent as CreditCard } from "../../../../../assets/images/creditCard.svg";

interface CreditCardDivProps {
  setPaymentChosen: React.Dispatch<React.SetStateAction<string>>;
  paymentChosen: string;
  type: string;
  inputValue: string;
  paybackActive: boolean;
}

function CreditCardDiv(props: CreditCardDivProps) {
  const choosePayment = () => {
    if (props.paymentChosen !== props.type) {
      props.setPaymentChosen(props.type);
    } else {
      props.setPaymentChosen("");
    }
  };

  return (
    <button
      onClick={choosePayment}
      className={`bg-[#ffff] px-[20px] md:px-[40px] py-[20px] cursor-pointer flex justify-between items-center border border-transparent hover:border-[gray] hover:shadow-xl hover:border-opacity-[20%] ${
        props.paymentChosen === props.type && props.inputValue !== "5"
          ? "border-gray border-opacity-[20%] shadow-xl"
          : ""
      } ${
        (props.paymentChosen !== props.type && props.paymentChosen !== "") ||
        (props.paybackActive && props.inputValue === "5") ||
        (props.paybackActive && props.paymentChosen === "")
          ? "opacity-[50%]"
          : ""
      }`}
    >
      <p className="font-TT tracking-wide md:text-[18px]">{props.type}</p>
      {props.type === "Online Payments" && (
        <div className="w-[35px] sm:w-[48px]">
          <CreditCard className="w-full h-full" />
        </div>
      )}
    </button>
  );
}

export default CreditCardDiv;
