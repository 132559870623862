// ----------------------
// External/Internal Packages
// ----------------------
import React from "react";

// ----------------------
// Utility Functions
// ----------------------
import handleToastError from "../../../../utils/utility/handleToastError";

// ----------------------
// Assets
// ----------------------
import { ReactComponent as Send } from "../../../../assets/images/sendWhite.svg";

// ----------------------
// Interfaces
// ----------------------
import { Action, ActionType, State } from "../../../../state/interfaces";

interface NextButtonProps {
  setBubbleMessageActive: React.Dispatch<React.SetStateAction<boolean>>;
  setBubbleMessageContent: React.Dispatch<React.SetStateAction<string>>;
  dispatch: React.Dispatch<Action>;
  state: State;
}

function NextButton({
  setBubbleMessageActive,
  setBubbleMessageContent,
  dispatch,
  state,
}: NextButtonProps) {
  return (
    <button
      onClick={() => {
        if (state.certificateFields.length === 0) {
          handleToastError("Choose at least one field!");
          return;
        }
        setBubbleMessageActive(false);
        setBubbleMessageContent("");
        dispatch({
          type: ActionType.SET_CONTRACT_SUMMARY_ACTIVE,
          payload: true,
        });
      }}
      className={`${
        state.certificateFields.length === 0
          ? "bg-[#CECDDA]"
          : "bg-connect-button"
      } w-full md:w-[150px] h-[60px] rounded-[2px] text-[#fff] font-bold tracking-wider text-[18px] flex gap-[10px] items-center justify-center `}
    >
      <p>Next</p>
      <Send className="mt-[5px]" />
    </button>
  );
}

export default NextButton;
