// ----------------------
// External/Internal Packages
// ----------------------
import { useState, useEffect } from "react";
import Lottie from "lottie-react";
import animationData from "./loadingAnimation.json";
import BeatLoader from "react-spinners/BeatLoader";
import certificateLoader from "./certificateLoader.json";

function DecodingAnimation() {
  const [dimensions, setDimensions] = useState({ width: 550, height: 550 });

  // Hook to make the animation responsive

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 640) {
        setDimensions({ width: 250, height: 250 });
      } else if (window.innerWidth <= 1024) {
        setDimensions({ width: 400, height: 400 });
      } else {
        setDimensions({ width: 550, height: 550 });
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="flex justify-center items-center w-full h-full flex-col gap-[5px]">
      <Lottie animationData={certificateLoader} style={dimensions} />
      <div
        style={{ opacity: 0.3 }}
        className="flex items-center gap-[10px] sm:mb-[40px]"
      >
        <p className="text-[18px]">Decoding</p>
        <BeatLoader size={10} />
      </div>
    </div>
  );
}

export default DecodingAnimation;
