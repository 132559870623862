import React from "react";
import { Action, State } from "../../../state/interfaces";
import Web2AccountButtons from "./Web2AccountButtons";

interface Web2AccountModalProps {
  showModal: boolean;
  onClose: () => void;
  dispatch: React.Dispatch<Action>;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  state: State;
  setIsUpdatingWallet: React.Dispatch<React.SetStateAction<boolean>>;
}

const Web2AccountModal: React.FC<Web2AccountModalProps> = ({
  showModal,
  onClose,
  dispatch,
  setModalOpen,
  state,
  setIsUpdatingWallet,
}) => {
  if (!showModal) return null;

  return (
    <div className="absolute top-[120%] shadow-md right-[-20px] z-[1000] flex flex-col items-center w-[160px] justify-center">
      <div className="bg-white font-bold rounded-lg text-[14px] w-full py-[10px] flex flex-col items-center">
        <Web2AccountButtons
          state={state}
          dispatch={dispatch}
          setModalOpen={setModalOpen}
          mobile={false}
          setIsUpdatingWallet={setIsUpdatingWallet}
        />
      </div>
    </div>
  );
};

export default Web2AccountModal;
