// Interfaces

import { Action, State } from "../interfaces";

export const updateMessage = (state: State, action: Action): State => {
  if (action.type === "UPDATE_MESSAGE") {
    return {
      ...state,
      messages: state.messages.map((msg, index) =>
        index === action.payload.index
          ? { ...msg, ...action.payload.message }
          : msg
      ),
    };
  } else {
    return state;
  }
};

export const updateMessageById = (state: State, action: Action): State => {
  if (action.type === "UPDATE_MESSAGE_BY_ID") {
    const { messageId } = action.payload;
    const index = state.messages.findIndex((msg) => msg.id === messageId);

    if (index === -1 || state.messages[index].type === "USER") {
      // messageId not found or it's a "USER" type message
      return state;
    }

    return {
      ...state,
      messages: state.messages.map((msg, idx) =>
        idx === index
          ? { ...msg, messageState: "DISPLAYED", textWritten: false }
          : msg
      ),
    };
  } else {
    return state;
  }
};

export const updateNextNonUserMessage = (
  state: State,
  action: Action
): State => {
  if (action.type === "UPDATE_NEXT_NON_USER_MESSAGE") {
    let nextMessageIndex = -1;

    for (let i = 0; i < state.messages.length; i++) {
      if (
        state.messages[i].messageState !== "DISPLAYED" &&
        state.messages[i].type !== "USER"
      ) {
        nextMessageIndex = i;
        break;
      }
    }

    if (nextMessageIndex === -1) {
      // No next non-"USER" message
      return state;
    }

    return {
      ...state,
      messages: state.messages.map((msg, idx) =>
        idx === nextMessageIndex ? { ...msg, messageState: "DISPLAYING" } : msg
      ),
    };
  } else {
    return state;
  }
};

export const updateMessageByIndex = (state: State, action: Action): State => {
  if (action.type === "UPDATE_MESSAGE_BY_INDEX") {
    const { index, newMessageData } = action.payload;
    return {
      ...state,
      messages: state.messages.map((message, i) =>
        i === index ? { ...message, ...newMessageData } : message
      ),
    };
  } else {
    return state;
  }
};

interface UpdateAllMessagesAction extends Action {
  type: "UPDATE_ALL_MESSAGES";
  newMessageData: any;
}

export const updateAllMessages = (state: State, action: Action): State => {
  if (action.type === "UPDATE_ALL_MESSAGES") {
    const specificAction = action as UpdateAllMessagesAction;
    const { newMessageData } = specificAction;
    return {
      ...state,
      messages: state.messages.map((message) => ({
        ...message,
        ...newMessageData,
      })),
    };
  } else {
    return state;
  }
};
