// ----------------------
// Packages
// ----------------------
import React, { useState, useEffect } from "react";

// ----------------------
// Components
// ----------------------
import CertificateAnimation from "./CertificateAnimation";
import PaymentFinished from "./PaymentFinished";
import Error from "./Error";

// ----------------------
// Assets
// ----------------------
import { ReactComponent as DoneIcon } from "../../../assets/images/doneIcon.svg";
import { GridLoader } from "react-spinners";

// ----------------------
// Utility Functions
// ----------------------
import { useHandleResize } from "../../../utils/utility/useHandleResize";
import { getParagraphs } from "../utils/getParagraphs";

// ----------------------
// Interfaces
// ----------------------
import { Action, State } from "../../../state/interfaces";
import VaiotAvatar from "./VaiotAvatar";

interface CertificateCreationProps {
  dispatch: React.Dispatch<Action>;
  state: State;
  setCertificateContractAddress: React.Dispatch<React.SetStateAction<string>>;
}

function CertificateCreation({
  dispatch,
  state,
  setCertificateContractAddress,
}: CertificateCreationProps) {
  const [stepsFinished, setStepsFinished] = useState(false);
  const [current, setCurrent] = useState(0);
  const [error, setError] = useState(false);
  const [animationStarted, setAnimationStarted] = useState(false);

  const titles = [
    "Circuit Definition",
    "Circuit Compilation",
    "Ceremonial Setup Phase",
    "Witness Computation",
    "Proof Construction",
    "Smart Contract Generation",
    "Smart Contract Generation",
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimationStarted(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const paragraphSize = useHandleResize(577, "shortParagraph", "longParagraph");

  const paragraphs = getParagraphs(paragraphSize);

  return (
    <div className=" w-full h-full flex items-center justify-center bg-[#050934] bg-opacity-[75%] fixed z-50 top-0">
      <div className="bg-[#F7F8FD] min-h-[600px] h-[600px] w-[90%] sm:w-[95%] sm900:w-[800px] box-border flex flex-col relative">
        <div className="flex items-center justify-between bg-[#ffff] w-full p-[30px]">
          <p className="font-bold tracking-wider">Certificate Creation</p>
        </div>

        {!error ? (
          <div className="flex h-full gap-[10px] p-[20px] ">
            <div className="w-[30%] sm577:w-full h-full flex sm577:flex-col items-center justify-center ]">
              {!animationStarted && <PaymentFinished />}

              {!stepsFinished && animationStarted && (
                <h1 className="text-[#0023EF] hidden sm577:block font-bold text-[32px] py-[30px]">
                  {titles[current]}
                </h1>
              )}
              {animationStarted && (
                <CertificateAnimation
                  stepsFinished={stepsFinished}
                  setStepsFinished={setStepsFinished}
                  current={current}
                  setCurrent={setCurrent}
                  dispatch={dispatch}
                  state={state}
                  setCertificateContractAddress={setCertificateContractAddress}
                  setError={setError}
                  error={error}
                />
              )}
            </div>
            <div className="w-[70%] pr-[10px] flex items-center justify-between flex-col sm577:hidden">
              <div>
                <h1 className="text-[#0023EF] font-bold sm450:text-[24px] mb-[20px]">
                  {titles[current]}
                </h1>
                <div className="text-[16px] sm450:text-[18px]">
                  <p>{paragraphs[current]}</p>
                </div>
              </div>
              <div className="w-full items-center justify-end flex">
                <GridLoader color="gray" size={14} />
              </div>
            </div>
            {stepsFinished && animationStarted && (
              <div className="z-[10000]">
                <DoneIcon className="absolute top-[34%] left-[40%] sm900:top-[34%] sm900:left-[42%] " />
              </div>
            )}
          </div>
        ) : (
          <Error dispatch={dispatch} />
        )}
      </div>
      <VaiotAvatar
        state={state}
        text="Zk-snark proof generation may take up to 20 minutes"
      />
    </div>
  );
}

export default CertificateCreation;
