// ----------------------
// Packages
// ----------------------
import React from "react";

// ----------------------
// Assets
// ----------------------
import vaiLogo from "../../assets/images/vaiLogo.svg";
import logoVaiot from "../../assets/images/logoVaiot.svg";

interface AccountCreationHeaderProps {
  text: string;
}

function AccountCreationHeader({ text }: AccountCreationHeaderProps) {
  return (
    <div className="flex flex-col items-center justify-center gap-[5px]">
      <img src={logoVaiot} className="w-[140px] h-[38px]" />
      <p className="text-[32px] font-bold tracking-wide font-TT">{text}</p>
    </div>
  );
}

export default AccountCreationHeader;
