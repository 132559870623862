import React from "react";
import errorSign from "../../../../assets/images/errorCoin.svg";
import errorVaiot from "../../../../assets/images/errorVaiot.svg";
import { Action, ActionType } from "../../../../state/interfaces";

interface ReaderPaymentErrorProps {
  dispatch: React.Dispatch<Action>;
}

function ReaderPaymentError({ dispatch }: ReaderPaymentErrorProps) {
  const handleRestart = () => {
    dispatch({ type: ActionType.SET_READER_PAYMENT_ERROR, payload: false });
  };

  return (
    <div className="p-[20px] flex flex-col sm:flex-row h-full items-center gap-[20px] justify-around">
      <img
        src={errorVaiot}
        alt="Error Sign"
        className="w-[80px] sm450:w-[100px] sm:w-[150px]"
      />
      <div className="flex flex-col items-center justify-between sm:justify-around h-full">
        <div className="flex items-center gap-[20px] justify-center">
          <img src={errorSign} alt="Error Sign" className="w-[30px]" />
          <p className="font-bold text-[24px] sm:text-[28px] gradient-text hidden sm:block">
            Something Went Wrong
          </p>
          <p className="font-bold text-[24px] sm:text-[28px] gradient-text sm:hidden">
            Error
          </p>
        </div>
        <p className="text-center text-[gray] text-[14px] sm450:text-[16px] sm:text-[18px] max-w-[500px]">
          Your payment did not go through. Make sure you are on the right chain
          and have enough balance to pay for the certificate. If the error
          persists, try restarting your browser.
        </p>
        <button
          onClick={handleRestart}
          className="rounded-[2px] py-[10px] px-[40px] border-[2px] border-[#0023EF1A] text-[#0023EF] bg-[#0023EF12] font-semibold border-opacity-[10]"
        >
          Try Again
        </button>
      </div>
    </div>
  );
}

export default ReaderPaymentError;
