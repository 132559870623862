import React from "react";

// ----------------------
// Assets
// ----------------------
import dzin from "../../assets/images/dzin.svg";
import question from "../../assets/images/questionmark.svg";
import checkmarkWhite from "../../assets/images/whiteCheckmark.svg";
import checkmarkBlack from "../../assets/images/blackCheckmark.svg";

// ----------------------
// Styles
// ----------------------
import "./slick.css";
import { State, Action, ActionType } from "../../state/interfaces";

interface FourthSlideProps {
  dispatch: React.Dispatch<Action>;
  state: State;
}

function FourthSlide({ dispatch, state }: FourthSlideProps) {
  return (
    <div className="slider-item gap-[60px] px-[20px] md:px-[40px] h-[100%] sm:h-[400px] lg:h-[500px]">
      <div className=" w-[120px] sm400:w-[150px] sm:w-[150px] md:w-[200px] lg:w-[250px] flex-shrink-0">
        <img src={dzin} className="w-full h-full" alt="Dzin Icon" />
      </div>
      <div className="flex flex-col items-center">
        <div className="bg-[#F7F8FD] mb-[10px] p-[20px] max-w-[470px] font-bold text-[14px] md:text-[16px]">
          <p className="sm500:w-[450px] sm:w-auto sm700:w-[450px]">
            Tutorial will always be at your service under the
            <span className="inline-block align-middle mx-[2px]">
              <img
                src={question}
                className="inline w-[20px] md:w-[24px] mb-[5px]"
                alt="Question Icon"
              />
            </span>
            icon.
          </p>
        </div>
        <div className="bg-[#F7F8FD] p-[20px] font-bold text-[14px] md:text-[16px] max-w-[470px] mb-[40px]">
          <p className="font-bold ">
            Before we start I need you to accept our{" "}
            <a
              href="/PrivacyPolicy.pdf"
              download="PrivacyPolicy"
              target="_blank"
              rel="noopener noreferrer"
              className="text-[blue]"
            >
              Privacy Policy
            </a>{" "}
            and{" "}
            <a
              href="/TermsAndConditions.pdf"
              download="TermsAndConditions"
              target="_blank"
              rel="noopener noreferrer"
              className="text-[blue]"
            >
              Terms and Conditions
            </a>{" "}
            of our service.
          </p>
        </div>
        <div className="flex items-center justify-center gap-[20px] max-w-[470px] text-[14px]">
          <button
            onClick={() =>
              dispatch({
                type: ActionType.SET_CONDITIONS_CHECKED,
                payload: !state.conditionsChecked,
              })
            }
            className={`border-[1px] w-[25px] h-[25px] ${
              state.conditionsChecked
                ? "border-transparent bg-[blue]"
                : "border-[grey] bg-white"
            }  rounded-[2px] flex items-center justify-center cursor-pointer flex-shrink-0`}
          >
            {state.conditionsChecked ? (
              <img
                src={checkmarkWhite}
                className="w-[12px]"
                alt="White checkmark"
              />
            ) : (
              <img
                src={checkmarkBlack}
                className="w-[12px]"
                alt="Black checkmark"
              />
            )}
          </button>
          <p
            className={`text-[10px] ${
              state.conditionsChecked ? "text-[#272727]" : "text-[#6e6e6e]"
            } `}
          >
            I hereby confirm that I have read the Privacy Policy and give VAIOT
            Limited my consent to the processing of my personal data in
            accordance with its terms and conditions and for the purposes
            described in the Privacy Policy . I also understand that I have the
            right to withhold or withdraw my consent, in whole or in part, or at
            any time, without giving a reason in the ways provided for in the
            Privacy Policy .
          </p>
        </div>
      </div>
    </div>
  );
}

export default FourthSlide;
