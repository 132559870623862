import React from "react";
import { Action, ActionType, State } from "../../state/interfaces";
import { handleCreateUserId } from "../storage/handleCreateUserId";
import SocketHandler from "./handleSocket";

export async function handleStartConversation(
  state: State,
  dispatch: React.Dispatch<Action>,
  restart: boolean = false
) {
  if (!(state.socketHandler instanceof SocketHandler)) {
    state.socketHandler = new SocketHandler();
    // Consider dispatching an action to update state.socketHandler in your Redux store
  }
  state.socketHandler.setDispatch(dispatch, state);

  let userId = localStorage.getItem("userId");

  if (userId) {
    userId = userId.replace(/^\"+|\"+$/g, "");
  }

  if (state.userId === "" || userId === "") {
    const userId = await handleCreateUserId(state, dispatch);

    // Use SocketHandler's createConversation method
    state.socketHandler.createConversation(
      { ...state, userId },
      dispatch,
      restart
    );
  } else {
    // Use SocketHandler's createConversation method
    state.socketHandler.createConversation(state, dispatch, restart);
  }

  dispatch({
    type: ActionType.SET_CONVERSATION_STARTED,
    payload: true,
  });
}
