import React, { useState, useEffect } from "react";
import { useAccount } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useDisconnect } from "wagmi";
import { getUserDetails } from "../../utils/api/getUserDetails";
import axios from "axios";
import { updateUserWallet } from "../../utils/api/updateUserWallet";
import handleToastError from "../../utils/utility/handleToastError";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import handleToastNotification from "../../utils/utility/handleToastNotification";

interface ChangeWalletProps {
  wallet: string;
  setWallet: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
}

function ChangeWallet({ wallet, setWallet, isLoading }: ChangeWalletProps) {
  const [isUpdatingWallet, setIsUpdatingWallet] = useState(false);
  const { address } = useAccount();
  const { open } = useWeb3Modal();
  const { disconnectAsync } = useDisconnect();

  useEffect(() => {
    const updateUserWallet = async () => {
      if (address && isUpdatingWallet) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/users/data`,
            {
              walletAddress: address,
            },
            {
              withCredentials: true,
            }
          );
          const walletLinked = response.data.status;

          if (walletLinked === "walletAlreadyLinked") {
            handleToastError(
              "This wallet is already linked to a different account!"
            );
            return;
          }
          const userDetails = await getUserDetails();
          if (!userDetails) {
            handleToastError("Unexpected error!");
            return;
          }
          setWallet(userDetails.userDetails.walletAddress);
          handleToastNotification("Wallet has been linked!");
        } catch (error) {
          if (axios.isAxiosError(error)) {
            if (error.response?.status === 403) {
              handleToastError(
                "Access denied! Please log out and log back in to solve this issue."
              );
            } else {
              console.error("Failed to update user wallet:", error);
            }
          } else {
            console.error("An unexpected error occurred:", error);
          }
        } finally {
          setIsUpdatingWallet(false);
        }
      }
    };

    if (isUpdatingWallet) {
      updateUserWallet();
    }

    // Cleanup function if needed
    return () => {
      if (isUpdatingWallet) {
        setIsUpdatingWallet(false);
      }
    };
  }, [address, isUpdatingWallet]);

  const handleUnlinkWallet = async () => {
    await disconnectAsync();
    await updateUserWallet("");
    setWallet("");
    handleToastNotification("Wallet has been unlinked!");
  };

  const handleLinkWallet = async () => {
    setIsUpdatingWallet(true);
    try {
      await disconnectAsync();
      open();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="bg-[#F7F8FD] w-full py-[20px] px-[10px] sm500:px-[20px] font-TT tracking-wide">
      {!isLoading ? (
        <div className="flex flex-col gap-[20px] items-center font-TT bg-[#fff] px-[20px] py-[15px] rounded-[6px] shadow-sm border-[#E5E4F0] border-[1px]">
          <div className="flex flex-col gap-[10px] tracking-wide">
            <p className="font-bold">
              {wallet === "" ? "Link Wallet" : "Change Wallet"}
            </p>
            <p className="text-[#191919] font-light text-opacity-60">
              {wallet === ""
                ? "To link a new Web3 wallet to your account, first ensure you've disconnected any currently connected wallets through your wallet extension. This step is crucial for selecting the specific account you wish to connect. Once disconnected, you can easily link your wallet to enjoy features such as staking your payback and making payments with cryptocurrencies for services."
                : "To change the wallet linked to your account, please first disconnect your current wallet using your wallet extension (e.g MetaMask, Coinbase Wallet). It's important to note that any staked payback will remain associated with your previous wallet and will not transfer to the new wallet."}
            </p>
          </div>
          {wallet === "" ? (
            <button
              onClick={handleLinkWallet}
              className="bg-connect-button flex text-[#fff] items-center justify-center gap-[8px] h-[60px] w-[240px] text-[16px] sm:text-[18px] font-bold"
            >
              Connect Wallet
            </button>
          ) : (
            <div className="font-bold tracking-wide flex flex-col sm400:flex-row gap-[30px] items-center justify-center w-full">
              <div className="bg-connect-button flex text-[#fff] items-center justify-center gap-[8px] h-[60px] w-[240px] text-[16px] sm:text-[18px] font-bold">
                <p className="block sm400:hidden sm:block">
                  {wallet.slice(0, 14) + "..." + wallet.slice(36, 42)}
                </p>
                <p className="hidden sm500:block sm:hidden">
                  {wallet.slice(0, 10) + "..." + wallet.slice(36, 42)}
                </p>
                <p className="hidden sm400:block sm500:hidden">
                  {wallet.slice(0, 8) + "..." + wallet.slice(37, 42)}
                </p>
              </div>
              <button
                onClick={handleUnlinkWallet}
                className="bg-second-connect-button flex text-[#0023EF] items-center justify-center gap-[8px] h-[60px] w-[240px]  text-[18px] sm500:text-[20px] font-bold"
              >
                <p>Unlink Wallet</p>
              </button>
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-col gap-[20px] h-[380px] sm400:h-[290px] sm500:h-[266px] sm:h-[242px] md:h-[218px] items-center font-TT bg-[#fff] px-[20px] py-[15px] rounded-[6px] shadow-sm border-[#E5E4F0] border-[1px]">
          <div className="flex w-full justify-center flex-col gap-[10px]">
            <Skeleton className="w-[100px] h-[20px]" />
            <Skeleton className="w-[100%] sm400:w-[334px] h-[144px] sm500:w-[414px] sm500:h-[120px] sm:h-[96px] sm:w-[514px] md:h-[72px] md:w-[714px]" />
            <div className="flex items-center justify-center w-full gap-[30px]">
              <Skeleton className="h-[150px] w-[240px] sm400:w-[304px] sm500:w-[384px] sm:w-[480px] sm400:h-[60px]" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ChangeWallet;
