// ----------------------
// External/Internal Packages
// ----------------------
import React, { useState, useEffect } from "react";
import axios from "axios";

// ----------------------
// Assets
// ----------------------
import { ReactComponent as Checkmark } from "../../assets/images/verifyProofCheckmark.svg";

// ----------------------
// Components
// ----------------------
import VerifyButton from "./VerifyButton";
import VaiotBubble from "./VaiotBubble";
import ProofContent from "./ProofContent";

// ----------------------
// Interfaces
// ----------------------
import { Action, ActionType, State } from "../../state/interfaces";
import handleToastError from "../../utils/utility/handleToastError";

interface ProofProps {
  state: State;
  dispatch: React.Dispatch<Action>;
}

function Proof({ state, dispatch }: ProofProps) {
  const [fields, setFields] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (state.proofJson !== undefined) {
        try {
          const response = await axios.post(
            process.env.REACT_APP_BASE_URL + "/snarks/proofToInput",
            {
              proof: state.proofJson,
            }
          );

          if (response.data) {
            setFields(response.data);
          } else {
            setFields([]);
          }
        } catch (error) {
          if (axios.isAxiosError(error)) {
            if (error.response?.status === 403) {
              handleToastError(
                "Access denied! Please log out and log back in to solve this issue."
              );
            } else {
              console.error("An error occurred:", error);
            }
          } else {
            console.error("An unexpected error occurred:", error);
          }
        }
      }
    };

    fetchData();
  }, [state.proofJson]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (e.target.value === "") {
        dispatch({ type: ActionType.SET_PROOF_JSON, payload: undefined });
        setFields([]);
        return;
      }

      const parsedData = JSON.parse(e.target.value);
      if (
        parsedData.pi_a &&
        parsedData.pi_b &&
        parsedData.pi_c &&
        parsedData.protocol &&
        parsedData.curve
      ) {
        dispatch({
          type: ActionType.SET_PROOF_JSON,
          payload: parsedData,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="bg-[#050934] h-full w-full font-TT flex justify-center relative pt-[80px] px-[30px] sm400:px-[60px] sm:px-[100px] sm:pt-[50px]">
      <div className=" w-full max-w-[1000px]  flex flex-col justify-around max-h-[70%]">
        <div>
          <div className="">
            <div className="text-[#fff] font-bold text-[20px] tracking-wide md:text-[24px] relative ml-[35px] sm400:ml-0">
              Proof Json
              <Checkmark className="absolute sm:top-[-50%] sm:left-[-50px] top-[-15px] left-[-40px]  w-[25px] sm:w-[40px] md:top-[-35%] md:left-[-7%] sm900:left-[-7%]" />
            </div>
          </div>
          <input
            placeholder="Put your proof.json here"
            className="mt-[40px] bg-transparent border-[1px] border-[gray] p-[12px] placeholder:opacity-80 border-opacity-40 w-full mb-[60px] text-[#fff] outline-none"
            onChange={handleInputChange}
          />
          {state.proofJson === undefined && (
            <VerifyButton dispatch={dispatch} proofJson={state.proofJson} />
          )}
        </div>
        <div className="overflow-y-auto">
          <ProofContent proofJson={state.proofJson} fields={fields} />
        </div>
        {state.proofJson && (
          <VerifyButton dispatch={dispatch} proofJson={state.proofJson} />
        )}
      </div>
      {!state.proofVerificationActive && !state.hamburgerMenuOpen && (
        <VaiotBubble
          state={state}
          text="You can verify your ZK-Snark Proof here."
        />
      )}
    </div>
  );
}

export default Proof;
