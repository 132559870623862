import { Action, State } from "../../../state/interfaces";
import SocketHandlerReader from "../../../utils/sockets/handleSocketsReader";

export const updateAgreementRoom = async (
  state: State,
  dispatch: React.Dispatch<Action>,
  account: string | null | undefined,
  idOfChosenContract: string,
  typeOfChosenContract: "" | "SA" | "NDA" | "PoAG" | "PoAS"
) => {
  if (!(state.socketHandlerReader instanceof SocketHandlerReader)) {
    state.socketHandlerReader = new SocketHandlerReader();
  }

  const responseStatus = await state.socketHandlerReader?.joinAgreementRoom(
    typeOfChosenContract,
    parseInt(idOfChosenContract),
    account,
    dispatch
  );
};
