// Packages + internal functions
import React, { createContext, useReducer, useEffect } from "react";

// Interfaces + hard-coded data
import { State, Action, AppProviderProps, ActionType } from "./interfaces";
import { initialState } from "./initialState";

// Hooks + internal functions
import { loadFromLocalStorage } from "../utils/storage/loadFromStorage";
import { actionHandlers } from "./actions/actionHandlers";
import { resetLocalStorageState } from "./utils/resetLocalStorageState";
import { handlePaymentStatus } from "./utils/handlePaymentStatus";
import { useVerificationStatus } from "./utils/useVerificationStatus";
import { adminAccess } from "./utils/adminAccess";
import { useAccount } from "wagmi";

import useProviderLogin from "../utils/api/useProviderLogin";

function reducer(state: State, action: Action): State {
  const handleAction = actionHandlers[action.type];
  if (handleAction) {
    return handleAction(state, action);
  }

  throw new Error(`Unknown action type: ${action.type}`);
}

export const AppContext = createContext<{
  state: State;
  dispatch: React.Dispatch<Action>;
}>(undefined!);

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const isDebugMode = urlParams.get("debug") === "true";

  const token = urlParams.get("token");
  const paymentType = urlParams.get("type");
  const contractId = urlParams.get("contractId");
  const status = urlParams.get("status");
  const proofId = urlParams.get("proofId");
  const userId = urlParams.get("userId");
  const admin = urlParams.get("admin");
  const provider = urlParams.get("provider");
  const { address } = useAccount();

  if (userId) {
    window.localStorage.setItem("userId", JSON.stringify(userId));
  }

  // If u set debug mode in the browser (query paramater) it wont be loading from the local storage like crazy

  let localStorageState = !isDebugMode && loadFromLocalStorage();

  // Check if there's any local storage state
  if (
    localStorageState &&
    localStorageState.messages &&
    localStorageState.web2Login
  ) {
    localStorageState = resetLocalStorageState(localStorageState);
  } else {
    // If there's no local storage state, use the initial state
    localStorageState = initialState;
  }

  const [state, dispatch] = useReducer(reducer, localStorageState);

  useVerificationStatus(proofId, dispatch);
  useProviderLogin(provider, dispatch);

  useEffect(() => {
    handlePaymentStatus(
      token,
      paymentType,
      contractId,
      status,
      state.paymentFinished,
      state,
      dispatch,
      address
    );
  }, [token, contractId, status, state.paymentFinished, dispatch]);

  useEffect(() => {
    adminAccess(admin, state, dispatch);
  }, [admin]);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};
